import { Heading, HeadingProps } from '@chakra-ui/react';

const CardTitle: React.FC<HeadingProps> = ({ children, ...props }) => {
  return (
    <Heading fontSize={['md', 'md', 'lg']} {...props}>
      {children}
    </Heading>
  );
};

export default CardTitle;
