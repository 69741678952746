import { Location, Avatar, SocialMedia, City } from 'types';
import imageCompression from 'browser-image-compression';

export const createFormData = (obj: any) => {
  let formData = new FormData();
  for (let key in obj) {
    formData.append(key, obj[key]);
  }
  return formData;
};

export const isUndefinedSocialMedias = ({
  instagram,
  whatsapp,
  facebook,
  linkedin
}: SocialMedia) => {
  return !instagram && !whatsapp && !facebook && !linkedin;
};

function parseDateToUSFormat(date: string) {
  const parts = date.split('/');
  const day = parts[0];
  const month = parts[1];
  const year = parts[2];
  return year + '-' + ('0' + month).slice(-2) + '-' + ('0' + day).slice(-2);
}

export const validateBirthdateParts = (birthDate?: string) => {
  if (birthDate && birthDate.length === 10) {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();

    const parts = birthDate.split('/');
    const day = +parts[0];
    const month = +parts[1];
    const year = +parts[2];

    const validDay = day <= 31;
    const validMonth = month <= 12;
    const validYear = year >= 1940 && year <= currentYear;

    return validDay && validMonth && validYear;
  }
  return false;
};

export const validateLegalAge = (birthDate?: string) => {
  if (birthDate && birthDate.length === 10) {
    const formattedDate = parseDateToUSFormat(birthDate);
    const diff_ms = Date.now() - new Date(formattedDate).getTime();
    const age_dt = new Date(diff_ms);
    return Math.abs(age_dt.getUTCFullYear() - 1970) >= 18;
  }
  return false;
};

export const shuffle = (array: Array<any>) => {
  return array
    .map(value => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value);
};

export const getRandomInt = (min: number, max: number) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min);
};

export const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const createLocationLabel = (city: string, state: string) => {
  return `${city}, ${state}`;
};

export const createLocationOption = ({ id, state, city }: Location) => {
  return {
    label: createLocationLabel(city, state),
    value: id?.toString()
  };
};

export const createCityOption = ({ id, name, uf }: City) => {
  return {
    label: createLocationLabel(name, uf),
    value: id?.toString()
  };
};

export const scrollToBottom = () => {
  const element = document.documentElement;
  const bottom = element.scrollHeight - element.clientHeight;
  element.scroll({
    top: bottom,
    behavior: 'smooth'
  });
};

export const getRegistrationTimeInfo = (createdAt: string) => {
  const info = { isNew: false, createdAtLabel: '' };

  if (createdAt) {
    const date1 = new Date(createdAt);
    const date2 = new Date();

    const months =
      (date2.getFullYear() - date1.getFullYear()) * 12 +
      (date2.getMonth() - date1.getMonth());

    info.createdAtLabel = months === 1 ? `${months} mês` : `${months} meses`;
    info.isNew = months < 1;
  }

  return info;
};

export const renameFile = (originalFile: File, newName: string) => {
  const renamedFile = new File([originalFile], newName, {
    type: originalFile.type
  });
  return renamedFile;
};

export const resizeImage = (file: File) => {
  return new Promise<File>(resolve => {
    const img = new Image();

    img.onload = () => {
      const maxSize = 1000;
      let width = img.width;
      let height = img.height;

      const scale = Math.max(maxSize / width, maxSize / height);
      width *= scale;
      height *= scale;

      const canvas = document.createElement('canvas');
      canvas.width = maxSize;
      canvas.height = maxSize;

      const ctx = canvas.getContext('2d');
      ctx?.drawImage(
        img,
        (maxSize - width) / 2,
        (maxSize - height) / 2,
        width,
        height
      );

      canvas.toBlob(
        blob => {
          resolve(new File([blob!], file.name, { type: blob?.type }));
        },
        file.type,
        1
      );
    };

    img.src = URL.createObjectURL(file);
  });
};

export const compressImages = async (
  files: File[],
  compressionOptions: object
) => {
  try {
    const compressedAndResizedFiles = [];

    for (const file of files) {
      const resizedFile = await resizeImage(file);
      const compressedFile = await imageCompression(
        resizedFile,
        compressionOptions
      );
      compressedAndResizedFiles.push(compressedFile);
    }

    return compressedAndResizedFiles;
  } catch (error) {
    return [];
  }
};

export const generateString = (length: number) => {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  let result = '';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};

export const applyHashToAvatar = (avatar: Avatar) => {
  const hash = Math.random().toString();

  return Object.keys(avatar).reduce((result, key) => {
    if (avatar.hasOwnProperty(key)) {
      result[key as keyof Avatar] = `${
        avatar[key as keyof Avatar]
      }?hash=${hash}`;
    }
    return result;
  }, {} as Avatar);
};
