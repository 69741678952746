import api from 'api';
import { useState } from 'react';
import { useAuthProvider } from 'hooks';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Provider, GalleryFile, GalleryQuota } from 'types';
import { PROVIDER_GALLERY } from 'app/Router/Router.constants';
import { generateString, renameFile } from 'utils/functions';

export const createGalleryFile = (file: File): GalleryFile => ({
  description: '',
  size: file.size,
  visible: true,
  url: URL.createObjectURL(file),
  file: renameFile(file, generateString(13))
});

const useGallery = () => {
  const [files, setFiles] = useState<GalleryFile[]>([]);

  const { userData, setUserData } = useAuthProvider();
  const navigate = useNavigate();

  const updateGallery = (gallery: GalleryFile[], quota: GalleryQuota) => {
    setUserData(prev => {
      const prevProvider = prev as Provider;
      return {
        ...prevProvider,
        gallery,
        quota
      };
    });
  };

  const mutationSavePhotos = useMutation(api.providers.galleryAddPhotos, {
    onSuccess: ({ images, quota }) => {
      setUserData(prev => {
        const prevProvider = prev as Provider;
        return {
          ...prevProvider,
          gallery: [...prevProvider.gallery, ...images],
          quota
        };
      });
      navigate(PROVIDER_GALLERY, { replace: true });
    }
  });

  const mutationEditPhoto = useMutation(api.providers.galleryEditPhoto, {
    onSuccess: ({ image }) => {
      setUserData(prev => {
        const prevProvider = prev as Provider;
        return {
          ...prevProvider,
          gallery: prevProvider.gallery.map(photo =>
            photo.id === image.id ? image : photo
          )
        };
      });
      navigate(PROVIDER_GALLERY, { replace: true });
    }
  });

  const deletePhoto = useMutation(api.providers.galleryDeletePhoto);

  const savePhotos = () => {
    mutationSavePhotos.mutate({
      files
    });
  };

  const editPhoto = () => {
    if (files[0]) {
      mutationEditPhoto.mutate({
        photoId: files[0]?.id!,
        description: files[0].description
      });
    }
  };

  const setFileDescription = (index: number, description: string) => {
    if (description.length <= 150) {
      return setFiles(files =>
        files.map((file, i) => (i === index ? { ...file, description } : file))
      );
    }
  };

  const loadingSave = mutationSavePhotos.isLoading;
  const loadingEdit = mutationEditPhoto.isLoading;

  const disabledSaveButton = files.length === 0;
  const hasFiles = files.length > 0;

  return {
    files,
    hasFiles,
    loadingSave,
    loadingEdit,
    disabledSaveButton,
    updateGallery,
    editPhoto,
    savePhotos,
    deletePhoto,
    setFiles,
    setFileDescription,
    quota: userData.quota
  };
};

export default useGallery;
