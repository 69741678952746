import { UserType } from 'types';
import { useNavigate } from 'react-router-dom';
import { useCustomerLogin, useProviderLogin } from 'hooks';
import { ReactComponent as IconApple } from 'assets/svg/apple.svg';
import { ReactComponent as IconGoogle } from 'assets/svg/google.svg';
import { ReactComponent as IconFacebook } from 'assets/svg/facebook.svg';
import {
  Flex,
  Text,
  Center,
  VStack,
  Button,
  Heading,
  Icon
} from '@chakra-ui/react';

const Authentication: React.FC<AuthenticationProps> = ({ userType }) => {
  const navigate = useNavigate();
  const { customerLogin, customerLoginLoading } = useCustomerLogin();
  const { providerLogin, providerLoginLoading } = useProviderLogin();

  const isCustomer = userType === 'CUSTOMER';
  const isLoading = customerLoginLoading || providerLoginLoading;

  const handleClick = () => (isCustomer ? customerLogin() : providerLogin());
  const handleGoBack = () => navigate('/customers/search', { replace: true });

  return (
    <Center w="100vw" h="100vh">
      <Flex direction="column" gap={10} p={6}>
        <Flex direction="column" textAlign="center" gap={3}>
          <Heading fontSize="4xl" fontWeight="black">
            Olá
          </Heading>
          <Text fontSize="lg">
            Selecione um serviço para acessar a plataforma
          </Text>
        </Flex>
        <VStack align="stretch">
          <Button
            h="60px"
            rounded="xl"
            size="lg"
            color="black"
            variant="outline"
            display="flex"
            fontSize="md"
            justifyContent="flex-start"
            isLoading={isLoading}
            onClick={handleClick}
            leftIcon={<Icon as={IconGoogle} w="20px" h="20px" />}>
            <Heading
              as="span"
              flex={1}
              fontSize="md"
              textAlign="center"
              mr="20px">
              Continuar com Google
            </Heading>
          </Button>
          <Button
            h="60px"
            rounded="xl"
            isDisabled
            size="lg"
            color="black"
            variant="outline"
            display="flex"
            fontSize="md"
            justifyContent="flex-start"
            leftIcon={<Icon as={IconFacebook} w="20px" h="20px" />}>
            <Heading
              as="span"
              flex={1}
              fontSize="md"
              textAlign="center"
              mr="20px">
              Continuar com Facebook
            </Heading>
          </Button>
          <Button
            h="60px"
            rounded="xl"
            isDisabled
            size="lg"
            color="black"
            variant="outline"
            display="flex"
            fontSize="md"
            justifyContent="flex-start"
            leftIcon={<Icon as={IconApple} w="20px" h="20px" />}>
            <Heading
              as="span"
              flex={1}
              fontSize="md"
              textAlign="center"
              mr="20px">
              Continuar com Apple
            </Heading>
          </Button>
          <Button
            variant="link"
            color="black"
            textDecoration="underline"
            onClick={handleGoBack}>
            Voltar
          </Button>
        </VStack>
        <Text fontSize="md" textAlign="center" color="black">
          Todos os direitos reservados{' '}
          <Text fontWeight="bold" fontSize="md" color="black">
            @Workmind
          </Text>
        </Text>
      </Flex>
    </Center>
  );
};

interface AuthenticationProps {
  userType: UserType;
}

export default Authentication;
