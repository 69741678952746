import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Button,
  Heading,
  Text
} from '@chakra-ui/react';

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  onClose,
  question,
  onCancel,
  onConfirm,
  isLoading = false,
  title = 'Confirmar ação',
  cancelButtonText = 'Cancelar',
  confirmButtonText = 'Confirmar'
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent rounded="modal" pt={4} pb={1} m={3}>
        <ModalBody>
          <Heading fontSize="xl" maxW="95%" mb={1}>
            {title}
          </Heading>
          <Text>{question}</Text>
        </ModalBody>
        <ModalFooter>
          <Button flex={1} rounded="full" mr={2} onClick={onCancel}>
            {cancelButtonText}
          </Button>
          <Button
            flex={1}
            rounded="full"
            colorScheme="blue"
            onClick={onConfirm}
            isLoading={isLoading}>
            {confirmButtonText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

interface ConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  question: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  onConfirm: () => void;
  onCancel: () => void;
  isLoading?: boolean;
}

export default ConfirmationModal;
