import { City } from 'types';

const cities: City[] = [
  {
    id: 1100015,
    name: "Alta Floresta D'Oeste",
    uf: 'RO'
  },
  {
    id: 1100023,
    name: 'Ariquemes',
    uf: 'RO'
  },
  {
    id: 1100031,
    name: 'Cabixi',
    uf: 'RO'
  },
  {
    id: 1100049,
    name: 'Cacoal',
    uf: 'RO'
  },
  {
    id: 1100056,
    name: 'Cerejeiras',
    uf: 'RO'
  },
  {
    id: 1100064,
    name: 'Colorado do Oeste',
    uf: 'RO'
  },
  {
    id: 1100072,
    name: 'Corumbiara',
    uf: 'RO'
  },
  {
    id: 1100080,
    name: 'Costa Marques',
    uf: 'RO'
  },
  {
    id: 1100098,
    name: "Espigão D'Oeste",
    uf: 'RO'
  },
  {
    id: 1100106,
    name: 'Guajará-Mirim',
    uf: 'RO'
  },
  {
    id: 1100114,
    name: 'Jaru',
    uf: 'RO'
  },
  {
    id: 1100122,
    name: 'Ji-Paraná',
    uf: 'RO'
  },
  {
    id: 1100130,
    name: "Machadinho D'Oeste",
    uf: 'RO'
  },
  {
    id: 1100148,
    name: "Nova Brasilândia D'Oeste",
    uf: 'RO'
  },
  {
    id: 1100155,
    name: 'Ouro Preto do Oeste',
    uf: 'RO'
  },
  {
    id: 1100189,
    name: 'Pimenta Bueno',
    uf: 'RO'
  },
  {
    id: 1100205,
    name: 'Porto Velho',
    uf: 'RO'
  },
  {
    id: 1100254,
    name: 'Presidente Médici',
    uf: 'RO'
  },
  {
    id: 1100262,
    name: 'Rio Crespo',
    uf: 'RO'
  },
  {
    id: 1100288,
    name: 'Rolim de Moura',
    uf: 'RO'
  },
  {
    id: 1100296,
    name: "Santa Luzia D'Oeste",
    uf: 'RO'
  },
  {
    id: 1100304,
    name: 'Vilhena',
    uf: 'RO'
  },
  {
    id: 1100320,
    name: 'São Miguel do Guaporé',
    uf: 'RO'
  },
  {
    id: 1100338,
    name: 'Nova Mamoré',
    uf: 'RO'
  },
  {
    id: 1100346,
    name: "Alvorada D'Oeste",
    uf: 'RO'
  },
  {
    id: 1100379,
    name: 'Alto Alegre dos Parecis',
    uf: 'RO'
  },
  {
    id: 1100403,
    name: 'Alto Paraíso',
    uf: 'RO'
  },
  {
    id: 1100452,
    name: 'Buritis',
    uf: 'RO'
  },
  {
    id: 1100502,
    name: 'Novo Horizonte do Oeste',
    uf: 'RO'
  },
  {
    id: 1100601,
    name: 'Cacaulândia',
    uf: 'RO'
  },
  {
    id: 1100700,
    name: 'Campo Novo de Rondônia',
    uf: 'RO'
  },
  {
    id: 1100809,
    name: 'Candeias do Jamari',
    uf: 'RO'
  },
  {
    id: 1100908,
    name: 'Castanheiras',
    uf: 'RO'
  },
  {
    id: 1100924,
    name: 'Chupinguaia',
    uf: 'RO'
  },
  {
    id: 1100940,
    name: 'Cujubim',
    uf: 'RO'
  },
  {
    id: 1101005,
    name: 'Governador Jorge Teixeira',
    uf: 'RO'
  },
  {
    id: 1101104,
    name: 'Itapuã do Oeste',
    uf: 'RO'
  },
  {
    id: 1101203,
    name: 'Ministro Andreazza',
    uf: 'RO'
  },
  {
    id: 1101302,
    name: 'Mirante da Serra',
    uf: 'RO'
  },
  {
    id: 1101401,
    name: 'Monte Negro',
    uf: 'RO'
  },
  {
    id: 1101435,
    name: 'Nova União',
    uf: 'RO'
  },
  {
    id: 1101450,
    name: 'Parecis',
    uf: 'RO'
  },
  {
    id: 1101468,
    name: 'Pimenteiras do Oeste',
    uf: 'RO'
  },
  {
    id: 1101476,
    name: 'Primavera de Rondônia',
    uf: 'RO'
  },
  {
    id: 1101484,
    name: "São Felipe D'Oeste",
    uf: 'RO'
  },
  {
    id: 1101492,
    name: 'São Francisco do Guaporé',
    uf: 'RO'
  },
  {
    id: 1101500,
    name: 'Seringueiras',
    uf: 'RO'
  },
  {
    id: 1101559,
    name: 'Teixeirópolis',
    uf: 'RO'
  },
  {
    id: 1101609,
    name: 'Theobroma',
    uf: 'RO'
  },
  {
    id: 1101708,
    name: 'Urupá',
    uf: 'RO'
  },
  {
    id: 1101757,
    name: 'Vale do Anari',
    uf: 'RO'
  },
  {
    id: 1101807,
    name: 'Vale do Paraíso',
    uf: 'RO'
  },
  {
    id: 1200013,
    name: 'Acrelândia',
    uf: 'AC'
  },
  {
    id: 1200054,
    name: 'Assis Brasil',
    uf: 'AC'
  },
  {
    id: 1200104,
    name: 'Brasiléia',
    uf: 'AC'
  },
  {
    id: 1200138,
    name: 'Bujari',
    uf: 'AC'
  },
  {
    id: 1200179,
    name: 'Capixaba',
    uf: 'AC'
  },
  {
    id: 1200203,
    name: 'Cruzeiro do Sul',
    uf: 'AC'
  },
  {
    id: 1200252,
    name: 'Epitaciolândia',
    uf: 'AC'
  },
  {
    id: 1200302,
    name: 'Feijó',
    uf: 'AC'
  },
  {
    id: 1200328,
    name: 'Jordão',
    uf: 'AC'
  },
  {
    id: 1200336,
    name: 'Mâncio Lima',
    uf: 'AC'
  },
  {
    id: 1200344,
    name: 'Manoel Urbano',
    uf: 'AC'
  },
  {
    id: 1200351,
    name: 'Marechal Thaumaturgo',
    uf: 'AC'
  },
  {
    id: 1200385,
    name: 'Plácido de Castro',
    uf: 'AC'
  },
  {
    id: 1200393,
    name: 'Porto Walter',
    uf: 'AC'
  },
  {
    id: 1200401,
    name: 'Rio Branco',
    uf: 'AC'
  },
  {
    id: 1200427,
    name: 'Rodrigues Alves',
    uf: 'AC'
  },
  {
    id: 1200435,
    name: 'Santa Rosa do Purus',
    uf: 'AC'
  },
  {
    id: 1200450,
    name: 'Senador Guiomard',
    uf: 'AC'
  },
  {
    id: 1200500,
    name: 'Sena Madureira',
    uf: 'AC'
  },
  {
    id: 1200609,
    name: 'Tarauacá',
    uf: 'AC'
  },
  {
    id: 1200708,
    name: 'Xapuri',
    uf: 'AC'
  },
  {
    id: 1200807,
    name: 'Porto Acre',
    uf: 'AC'
  },
  {
    id: 1300029,
    name: 'Alvarães',
    uf: 'AM'
  },
  {
    id: 1300060,
    name: 'Amaturá',
    uf: 'AM'
  },
  {
    id: 1300086,
    name: 'Anamã',
    uf: 'AM'
  },
  {
    id: 1300102,
    name: 'Anori',
    uf: 'AM'
  },
  {
    id: 1300144,
    name: 'Apuí',
    uf: 'AM'
  },
  {
    id: 1300201,
    name: 'Atalaia do Norte',
    uf: 'AM'
  },
  {
    id: 1300300,
    name: 'Autazes',
    uf: 'AM'
  },
  {
    id: 1300409,
    name: 'Barcelos',
    uf: 'AM'
  },
  {
    id: 1300508,
    name: 'Barreirinha',
    uf: 'AM'
  },
  {
    id: 1300607,
    name: 'Benjamin Constant',
    uf: 'AM'
  },
  {
    id: 1300631,
    name: 'Beruri',
    uf: 'AM'
  },
  {
    id: 1300680,
    name: 'Boa Vista do Ramos',
    uf: 'AM'
  },
  {
    id: 1300706,
    name: 'Boca do Acre',
    uf: 'AM'
  },
  {
    id: 1300805,
    name: 'Borba',
    uf: 'AM'
  },
  {
    id: 1300839,
    name: 'Caapiranga',
    uf: 'AM'
  },
  {
    id: 1300904,
    name: 'Canutama',
    uf: 'AM'
  },
  {
    id: 1301001,
    name: 'Carauari',
    uf: 'AM'
  },
  {
    id: 1301100,
    name: 'Careiro',
    uf: 'AM'
  },
  {
    id: 1301159,
    name: 'Careiro da Várzea',
    uf: 'AM'
  },
  {
    id: 1301209,
    name: 'Coari',
    uf: 'AM'
  },
  {
    id: 1301308,
    name: 'Codajás',
    uf: 'AM'
  },
  {
    id: 1301407,
    name: 'Eirunepé',
    uf: 'AM'
  },
  {
    id: 1301506,
    name: 'Envira',
    uf: 'AM'
  },
  {
    id: 1301605,
    name: 'Fonte Boa',
    uf: 'AM'
  },
  {
    id: 1301654,
    name: 'Guajará',
    uf: 'AM'
  },
  {
    id: 1301704,
    name: 'Humaitá',
    uf: 'AM'
  },
  {
    id: 1301803,
    name: 'Ipixuna',
    uf: 'AM'
  },
  {
    id: 1301852,
    name: 'Iranduba',
    uf: 'AM'
  },
  {
    id: 1301902,
    name: 'Itacoatiara',
    uf: 'AM'
  },
  {
    id: 1301951,
    name: 'Itamarati',
    uf: 'AM'
  },
  {
    id: 1302009,
    name: 'Itapiranga',
    uf: 'AM'
  },
  {
    id: 1302108,
    name: 'Japurá',
    uf: 'AM'
  },
  {
    id: 1302207,
    name: 'Juruá',
    uf: 'AM'
  },
  {
    id: 1302306,
    name: 'Jutaí',
    uf: 'AM'
  },
  {
    id: 1302405,
    name: 'Lábrea',
    uf: 'AM'
  },
  {
    id: 1302504,
    name: 'Manacapuru',
    uf: 'AM'
  },
  {
    id: 1302553,
    name: 'Manaquiri',
    uf: 'AM'
  },
  {
    id: 1302603,
    name: 'Manaus',
    uf: 'AM'
  },
  {
    id: 1302702,
    name: 'Manicoré',
    uf: 'AM'
  },
  {
    id: 1302801,
    name: 'Maraã',
    uf: 'AM'
  },
  {
    id: 1302900,
    name: 'Maués',
    uf: 'AM'
  },
  {
    id: 1303007,
    name: 'Nhamundá',
    uf: 'AM'
  },
  {
    id: 1303106,
    name: 'Nova Olinda do Norte',
    uf: 'AM'
  },
  {
    id: 1303205,
    name: 'Novo Airão',
    uf: 'AM'
  },
  {
    id: 1303304,
    name: 'Novo Aripuanã',
    uf: 'AM'
  },
  {
    id: 1303403,
    name: 'Parintins',
    uf: 'AM'
  },
  {
    id: 1303502,
    name: 'Pauini',
    uf: 'AM'
  },
  {
    id: 1303536,
    name: 'Presidente Figueiredo',
    uf: 'AM'
  },
  {
    id: 1303569,
    name: 'Rio Preto da Eva',
    uf: 'AM'
  },
  {
    id: 1303601,
    name: 'Santa Isabel do Rio Negro',
    uf: 'AM'
  },
  {
    id: 1303700,
    name: 'Santo Antônio do Içá',
    uf: 'AM'
  },
  {
    id: 1303809,
    name: 'São Gabriel da Cachoeira',
    uf: 'AM'
  },
  {
    id: 1303908,
    name: 'São Paulo de Olivença',
    uf: 'AM'
  },
  {
    id: 1303957,
    name: 'São Sebastião do Uatumã',
    uf: 'AM'
  },
  {
    id: 1304005,
    name: 'Silves',
    uf: 'AM'
  },
  {
    id: 1304062,
    name: 'Tabatinga',
    uf: 'AM'
  },
  {
    id: 1304104,
    name: 'Tapauá',
    uf: 'AM'
  },
  {
    id: 1304203,
    name: 'Tefé',
    uf: 'AM'
  },
  {
    id: 1304237,
    name: 'Tonantins',
    uf: 'AM'
  },
  {
    id: 1304260,
    name: 'Uarini',
    uf: 'AM'
  },
  {
    id: 1304302,
    name: 'Urucará',
    uf: 'AM'
  },
  {
    id: 1304401,
    name: 'Urucurituba',
    uf: 'AM'
  },
  {
    id: 1400027,
    name: 'Amajari',
    uf: 'RR'
  },
  {
    id: 1400050,
    name: 'Alto Alegre',
    uf: 'RR'
  },
  {
    id: 1400100,
    name: 'Boa Vista',
    uf: 'RR'
  },
  {
    id: 1400159,
    name: 'Bonfim',
    uf: 'RR'
  },
  {
    id: 1400175,
    name: 'Cantá',
    uf: 'RR'
  },
  {
    id: 1400209,
    name: 'Caracaraí',
    uf: 'RR'
  },
  {
    id: 1400233,
    name: 'Caroebe',
    uf: 'RR'
  },
  {
    id: 1400282,
    name: 'Iracema',
    uf: 'RR'
  },
  {
    id: 1400308,
    name: 'Mucajaí',
    uf: 'RR'
  },
  {
    id: 1400407,
    name: 'Normandia',
    uf: 'RR'
  },
  {
    id: 1400456,
    name: 'Pacaraima',
    uf: 'RR'
  },
  {
    id: 1400472,
    name: 'Rorainópolis',
    uf: 'RR'
  },
  {
    id: 1400506,
    name: 'São João da Baliza',
    uf: 'RR'
  },
  {
    id: 1400605,
    name: 'São Luiz',
    uf: 'RR'
  },
  {
    id: 1400704,
    name: 'Uiramutã',
    uf: 'RR'
  },
  {
    id: 1500107,
    name: 'Abaetetuba',
    uf: 'PA'
  },
  {
    id: 1500131,
    name: 'Abel Figueiredo',
    uf: 'PA'
  },
  {
    id: 1500206,
    name: 'Acará',
    uf: 'PA'
  },
  {
    id: 1500305,
    name: 'Afuá',
    uf: 'PA'
  },
  {
    id: 1500347,
    name: 'Água Azul do Norte',
    uf: 'PA'
  },
  {
    id: 1500404,
    name: 'Alenquer',
    uf: 'PA'
  },
  {
    id: 1500503,
    name: 'Almeirim',
    uf: 'PA'
  },
  {
    id: 1500602,
    name: 'Altamira',
    uf: 'PA'
  },
  {
    id: 1500701,
    name: 'Anajás',
    uf: 'PA'
  },
  {
    id: 1500800,
    name: 'Ananindeua',
    uf: 'PA'
  },
  {
    id: 1500859,
    name: 'Anapu',
    uf: 'PA'
  },
  {
    id: 1500909,
    name: 'Augusto Corrêa',
    uf: 'PA'
  },
  {
    id: 1500958,
    name: 'Aurora do Pará',
    uf: 'PA'
  },
  {
    id: 1501006,
    name: 'Aveiro',
    uf: 'PA'
  },
  {
    id: 1501105,
    name: 'Bagre',
    uf: 'PA'
  },
  {
    id: 1501204,
    name: 'Baião',
    uf: 'PA'
  },
  {
    id: 1501253,
    name: 'Bannach',
    uf: 'PA'
  },
  {
    id: 1501303,
    name: 'Barcarena',
    uf: 'PA'
  },
  {
    id: 1501402,
    name: 'Belém',
    uf: 'PA'
  },
  {
    id: 1501451,
    name: 'Belterra',
    uf: 'PA'
  },
  {
    id: 1501501,
    name: 'Benevides',
    uf: 'PA'
  },
  {
    id: 1501576,
    name: 'Bom Jesus do Tocantins',
    uf: 'PA'
  },
  {
    id: 1501600,
    name: 'Bonito',
    uf: 'PA'
  },
  {
    id: 1501709,
    name: 'Bragança',
    uf: 'PA'
  },
  {
    id: 1501725,
    name: 'Brasil Novo',
    uf: 'PA'
  },
  {
    id: 1501758,
    name: 'Brejo Grande do Araguaia',
    uf: 'PA'
  },
  {
    id: 1501782,
    name: 'Breu Branco',
    uf: 'PA'
  },
  {
    id: 1501808,
    name: 'Breves',
    uf: 'PA'
  },
  {
    id: 1501907,
    name: 'Bujaru',
    uf: 'PA'
  },
  {
    id: 1501956,
    name: 'Cachoeira do Piriá',
    uf: 'PA'
  },
  {
    id: 1502004,
    name: 'Cachoeira do Arari',
    uf: 'PA'
  },
  {
    id: 1502103,
    name: 'Cametá',
    uf: 'PA'
  },
  {
    id: 1502152,
    name: 'Canaã dos Carajás',
    uf: 'PA'
  },
  {
    id: 1502202,
    name: 'Capanema',
    uf: 'PA'
  },
  {
    id: 1502301,
    name: 'Capitão Poço',
    uf: 'PA'
  },
  {
    id: 1502400,
    name: 'Castanhal',
    uf: 'PA'
  },
  {
    id: 1502509,
    name: 'Chaves',
    uf: 'PA'
  },
  {
    id: 1502608,
    name: 'Colares',
    uf: 'PA'
  },
  {
    id: 1502707,
    name: 'Conceição do Araguaia',
    uf: 'PA'
  },
  {
    id: 1502756,
    name: 'Concórdia do Pará',
    uf: 'PA'
  },
  {
    id: 1502764,
    name: 'Cumaru do Norte',
    uf: 'PA'
  },
  {
    id: 1502772,
    name: 'Curionópolis',
    uf: 'PA'
  },
  {
    id: 1502806,
    name: 'Curralinho',
    uf: 'PA'
  },
  {
    id: 1502855,
    name: 'Curuá',
    uf: 'PA'
  },
  {
    id: 1502905,
    name: 'Curuçá',
    uf: 'PA'
  },
  {
    id: 1502939,
    name: 'Dom Eliseu',
    uf: 'PA'
  },
  {
    id: 1502954,
    name: 'Eldorado do Carajás',
    uf: 'PA'
  },
  {
    id: 1503002,
    name: 'Faro',
    uf: 'PA'
  },
  {
    id: 1503044,
    name: 'Floresta do Araguaia',
    uf: 'PA'
  },
  {
    id: 1503077,
    name: 'Garrafão do Norte',
    uf: 'PA'
  },
  {
    id: 1503093,
    name: 'Goianésia do Pará',
    uf: 'PA'
  },
  {
    id: 1503101,
    name: 'Gurupá',
    uf: 'PA'
  },
  {
    id: 1503200,
    name: 'Igarapé-Açu',
    uf: 'PA'
  },
  {
    id: 1503309,
    name: 'Igarapé-Miri',
    uf: 'PA'
  },
  {
    id: 1503408,
    name: 'Inhangapi',
    uf: 'PA'
  },
  {
    id: 1503457,
    name: 'Ipixuna do Pará',
    uf: 'PA'
  },
  {
    id: 1503507,
    name: 'Irituia',
    uf: 'PA'
  },
  {
    id: 1503606,
    name: 'Itaituba',
    uf: 'PA'
  },
  {
    id: 1503705,
    name: 'Itupiranga',
    uf: 'PA'
  },
  {
    id: 1503754,
    name: 'Jacareacanga',
    uf: 'PA'
  },
  {
    id: 1503804,
    name: 'Jacundá',
    uf: 'PA'
  },
  {
    id: 1503903,
    name: 'Juruti',
    uf: 'PA'
  },
  {
    id: 1504000,
    name: 'Limoeiro do Ajuru',
    uf: 'PA'
  },
  {
    id: 1504059,
    name: 'Mãe do Rio',
    uf: 'PA'
  },
  {
    id: 1504109,
    name: 'Magalhães Barata',
    uf: 'PA'
  },
  {
    id: 1504208,
    name: 'Marabá',
    uf: 'PA'
  },
  {
    id: 1504307,
    name: 'Maracanã',
    uf: 'PA'
  },
  {
    id: 1504406,
    name: 'Marapanim',
    uf: 'PA'
  },
  {
    id: 1504422,
    name: 'Marituba',
    uf: 'PA'
  },
  {
    id: 1504455,
    name: 'Medicilândia',
    uf: 'PA'
  },
  {
    id: 1504505,
    name: 'Melgaço',
    uf: 'PA'
  },
  {
    id: 1504604,
    name: 'Mocajuba',
    uf: 'PA'
  },
  {
    id: 1504703,
    name: 'Moju',
    uf: 'PA'
  },
  {
    id: 1504752,
    name: 'Mojuí dos Campos',
    uf: 'PA'
  },
  {
    id: 1504802,
    name: 'Monte Alegre',
    uf: 'PA'
  },
  {
    id: 1504901,
    name: 'Muaná',
    uf: 'PA'
  },
  {
    id: 1504950,
    name: 'Nova Esperança do Piriá',
    uf: 'PA'
  },
  {
    id: 1504976,
    name: 'Nova Ipixuna',
    uf: 'PA'
  },
  {
    id: 1505007,
    name: 'Nova Timboteua',
    uf: 'PA'
  },
  {
    id: 1505031,
    name: 'Novo Progresso',
    uf: 'PA'
  },
  {
    id: 1505064,
    name: 'Novo Repartimento',
    uf: 'PA'
  },
  {
    id: 1505106,
    name: 'Óbidos',
    uf: 'PA'
  },
  {
    id: 1505205,
    name: 'Oeiras do Pará',
    uf: 'PA'
  },
  {
    id: 1505304,
    name: 'Oriximiná',
    uf: 'PA'
  },
  {
    id: 1505403,
    name: 'Ourém',
    uf: 'PA'
  },
  {
    id: 1505437,
    name: 'Ourilândia do Norte',
    uf: 'PA'
  },
  {
    id: 1505486,
    name: 'Pacajá',
    uf: 'PA'
  },
  {
    id: 1505494,
    name: 'Palestina do Pará',
    uf: 'PA'
  },
  {
    id: 1505502,
    name: 'Paragominas',
    uf: 'PA'
  },
  {
    id: 1505536,
    name: 'Parauapebas',
    uf: 'PA'
  },
  {
    id: 1505551,
    name: "Pau D'Arco",
    uf: 'PA'
  },
  {
    id: 1505601,
    name: 'Peixe-Boi',
    uf: 'PA'
  },
  {
    id: 1505635,
    name: 'Piçarra',
    uf: 'PA'
  },
  {
    id: 1505650,
    name: 'Placas',
    uf: 'PA'
  },
  {
    id: 1505700,
    name: 'Ponta de Pedras',
    uf: 'PA'
  },
  {
    id: 1505809,
    name: 'Portel',
    uf: 'PA'
  },
  {
    id: 1505908,
    name: 'Porto de Moz',
    uf: 'PA'
  },
  {
    id: 1506005,
    name: 'Prainha',
    uf: 'PA'
  },
  {
    id: 1506104,
    name: 'Primavera',
    uf: 'PA'
  },
  {
    id: 1506112,
    name: 'Quatipuru',
    uf: 'PA'
  },
  {
    id: 1506138,
    name: 'Redenção',
    uf: 'PA'
  },
  {
    id: 1506161,
    name: 'Rio Maria',
    uf: 'PA'
  },
  {
    id: 1506187,
    name: 'Rondon do Pará',
    uf: 'PA'
  },
  {
    id: 1506195,
    name: 'Rurópolis',
    uf: 'PA'
  },
  {
    id: 1506203,
    name: 'Salinópolis',
    uf: 'PA'
  },
  {
    id: 1506302,
    name: 'Salvaterra',
    uf: 'PA'
  },
  {
    id: 1506351,
    name: 'Santa Bárbara do Pará',
    uf: 'PA'
  },
  {
    id: 1506401,
    name: 'Santa Cruz do Arari',
    uf: 'PA'
  },
  {
    id: 1506500,
    name: 'Santa Izabel do Pará',
    uf: 'PA'
  },
  {
    id: 1506559,
    name: 'Santa Luzia do Pará',
    uf: 'PA'
  },
  {
    id: 1506583,
    name: 'Santa Maria das Barreiras',
    uf: 'PA'
  },
  {
    id: 1506609,
    name: 'Santa Maria do Pará',
    uf: 'PA'
  },
  {
    id: 1506708,
    name: 'Santana do Araguaia',
    uf: 'PA'
  },
  {
    id: 1506807,
    name: 'Santarém',
    uf: 'PA'
  },
  {
    id: 1506906,
    name: 'Santarém Novo',
    uf: 'PA'
  },
  {
    id: 1507003,
    name: 'Santo Antônio do Tauá',
    uf: 'PA'
  },
  {
    id: 1507102,
    name: 'São Caetano de Odivelas',
    uf: 'PA'
  },
  {
    id: 1507151,
    name: 'São Domingos do Araguaia',
    uf: 'PA'
  },
  {
    id: 1507201,
    name: 'São Domingos do Capim',
    uf: 'PA'
  },
  {
    id: 1507300,
    name: 'São Félix do Xingu',
    uf: 'PA'
  },
  {
    id: 1507409,
    name: 'São Francisco do Pará',
    uf: 'PA'
  },
  {
    id: 1507458,
    name: 'São Geraldo do Araguaia',
    uf: 'PA'
  },
  {
    id: 1507466,
    name: 'São João da Ponta',
    uf: 'PA'
  },
  {
    id: 1507474,
    name: 'São João de Pirabas',
    uf: 'PA'
  },
  {
    id: 1507508,
    name: 'São João do Araguaia',
    uf: 'PA'
  },
  {
    id: 1507607,
    name: 'São Miguel do Guamá',
    uf: 'PA'
  },
  {
    id: 1507706,
    name: 'São Sebastião da Boa Vista',
    uf: 'PA'
  },
  {
    id: 1507755,
    name: 'Sapucaia',
    uf: 'PA'
  },
  {
    id: 1507805,
    name: 'Senador José Porfírio',
    uf: 'PA'
  },
  {
    id: 1507904,
    name: 'Soure',
    uf: 'PA'
  },
  {
    id: 1507953,
    name: 'Tailândia',
    uf: 'PA'
  },
  {
    id: 1507961,
    name: 'Terra Alta',
    uf: 'PA'
  },
  {
    id: 1507979,
    name: 'Terra Santa',
    uf: 'PA'
  },
  {
    id: 1508001,
    name: 'Tomé-Açu',
    uf: 'PA'
  },
  {
    id: 1508035,
    name: 'Tracuateua',
    uf: 'PA'
  },
  {
    id: 1508050,
    name: 'Trairão',
    uf: 'PA'
  },
  {
    id: 1508084,
    name: 'Tucumã',
    uf: 'PA'
  },
  {
    id: 1508100,
    name: 'Tucuruí',
    uf: 'PA'
  },
  {
    id: 1508126,
    name: 'Ulianópolis',
    uf: 'PA'
  },
  {
    id: 1508159,
    name: 'Uruará',
    uf: 'PA'
  },
  {
    id: 1508209,
    name: 'Vigia',
    uf: 'PA'
  },
  {
    id: 1508308,
    name: 'Viseu',
    uf: 'PA'
  },
  {
    id: 1508357,
    name: 'Vitória do Xingu',
    uf: 'PA'
  },
  {
    id: 1508407,
    name: 'Xinguara',
    uf: 'PA'
  },
  {
    id: 1600055,
    name: 'Serra do Navio',
    uf: 'AP'
  },
  {
    id: 1600105,
    name: 'Amapá',
    uf: 'AP'
  },
  {
    id: 1600154,
    name: 'Pedra Branca do Amapari',
    uf: 'AP'
  },
  {
    id: 1600204,
    name: 'Calçoene',
    uf: 'AP'
  },
  {
    id: 1600212,
    name: 'Cutias',
    uf: 'AP'
  },
  {
    id: 1600238,
    name: 'Ferreira Gomes',
    uf: 'AP'
  },
  {
    id: 1600253,
    name: 'Itaubal',
    uf: 'AP'
  },
  {
    id: 1600279,
    name: 'Laranjal do Jari',
    uf: 'AP'
  },
  {
    id: 1600303,
    name: 'Macapá',
    uf: 'AP'
  },
  {
    id: 1600402,
    name: 'Mazagão',
    uf: 'AP'
  },
  {
    id: 1600501,
    name: 'Oiapoque',
    uf: 'AP'
  },
  {
    id: 1600535,
    name: 'Porto Grande',
    uf: 'AP'
  },
  {
    id: 1600550,
    name: 'Pracuúba',
    uf: 'AP'
  },
  {
    id: 1600600,
    name: 'Santana',
    uf: 'AP'
  },
  {
    id: 1600709,
    name: 'Tartarugalzinho',
    uf: 'AP'
  },
  {
    id: 1600808,
    name: 'Vitória do Jari',
    uf: 'AP'
  },
  {
    id: 1700251,
    name: 'Abreulândia',
    uf: 'TO'
  },
  {
    id: 1700301,
    name: 'Aguiarnópolis',
    uf: 'TO'
  },
  {
    id: 1700350,
    name: 'Aliança do Tocantins',
    uf: 'TO'
  },
  {
    id: 1700400,
    name: 'Almas',
    uf: 'TO'
  },
  {
    id: 1700707,
    name: 'Alvorada',
    uf: 'TO'
  },
  {
    id: 1701002,
    name: 'Ananás',
    uf: 'TO'
  },
  {
    id: 1701051,
    name: 'Angico',
    uf: 'TO'
  },
  {
    id: 1701101,
    name: 'Aparecida do Rio Negro',
    uf: 'TO'
  },
  {
    id: 1701309,
    name: 'Aragominas',
    uf: 'TO'
  },
  {
    id: 1701903,
    name: 'Araguacema',
    uf: 'TO'
  },
  {
    id: 1702000,
    name: 'Araguaçu',
    uf: 'TO'
  },
  {
    id: 1702109,
    name: 'Araguaína',
    uf: 'TO'
  },
  {
    id: 1702158,
    name: 'Araguanã',
    uf: 'TO'
  },
  {
    id: 1702208,
    name: 'Araguatins',
    uf: 'TO'
  },
  {
    id: 1702307,
    name: 'Arapoema',
    uf: 'TO'
  },
  {
    id: 1702406,
    name: 'Arraias',
    uf: 'TO'
  },
  {
    id: 1702554,
    name: 'Augustinópolis',
    uf: 'TO'
  },
  {
    id: 1702703,
    name: 'Aurora do Tocantins',
    uf: 'TO'
  },
  {
    id: 1702901,
    name: 'Axixá do Tocantins',
    uf: 'TO'
  },
  {
    id: 1703008,
    name: 'Babaçulândia',
    uf: 'TO'
  },
  {
    id: 1703057,
    name: 'Bandeirantes do Tocantins',
    uf: 'TO'
  },
  {
    id: 1703073,
    name: 'Barra do Ouro',
    uf: 'TO'
  },
  {
    id: 1703107,
    name: 'Barrolândia',
    uf: 'TO'
  },
  {
    id: 1703206,
    name: 'Bernardo Sayão',
    uf: 'TO'
  },
  {
    id: 1703305,
    name: 'Bom Jesus do Tocantins',
    uf: 'TO'
  },
  {
    id: 1703602,
    name: 'Brasilândia do Tocantins',
    uf: 'TO'
  },
  {
    id: 1703701,
    name: 'Brejinho de Nazaré',
    uf: 'TO'
  },
  {
    id: 1703800,
    name: 'Buriti do Tocantins',
    uf: 'TO'
  },
  {
    id: 1703826,
    name: 'Cachoeirinha',
    uf: 'TO'
  },
  {
    id: 1703842,
    name: 'Campos Lindos',
    uf: 'TO'
  },
  {
    id: 1703867,
    name: 'Cariri do Tocantins',
    uf: 'TO'
  },
  {
    id: 1703883,
    name: 'Carmolândia',
    uf: 'TO'
  },
  {
    id: 1703891,
    name: 'Carrasco Bonito',
    uf: 'TO'
  },
  {
    id: 1703909,
    name: 'Caseara',
    uf: 'TO'
  },
  {
    id: 1704105,
    name: 'Centenário',
    uf: 'TO'
  },
  {
    id: 1704600,
    name: 'Chapada de Areia',
    uf: 'TO'
  },
  {
    id: 1705102,
    name: 'Chapada da Natividade',
    uf: 'TO'
  },
  {
    id: 1705508,
    name: 'Colinas do Tocantins',
    uf: 'TO'
  },
  {
    id: 1705557,
    name: 'Combinado',
    uf: 'TO'
  },
  {
    id: 1705607,
    name: 'Conceição do Tocantins',
    uf: 'TO'
  },
  {
    id: 1706001,
    name: 'Couto Magalhães',
    uf: 'TO'
  },
  {
    id: 1706100,
    name: 'Cristalândia',
    uf: 'TO'
  },
  {
    id: 1706258,
    name: 'Crixás do Tocantins',
    uf: 'TO'
  },
  {
    id: 1706506,
    name: 'Darcinópolis',
    uf: 'TO'
  },
  {
    id: 1707009,
    name: 'Dianópolis',
    uf: 'TO'
  },
  {
    id: 1707108,
    name: 'Divinópolis do Tocantins',
    uf: 'TO'
  },
  {
    id: 1707207,
    name: 'Dois Irmãos do Tocantins',
    uf: 'TO'
  },
  {
    id: 1707306,
    name: 'Dueré',
    uf: 'TO'
  },
  {
    id: 1707405,
    name: 'Esperantina',
    uf: 'TO'
  },
  {
    id: 1707553,
    name: 'Fátima',
    uf: 'TO'
  },
  {
    id: 1707652,
    name: 'Figueirópolis',
    uf: 'TO'
  },
  {
    id: 1707702,
    name: 'Filadélfia',
    uf: 'TO'
  },
  {
    id: 1708205,
    name: 'Formoso do Araguaia',
    uf: 'TO'
  },
  {
    id: 1708254,
    name: 'Tabocão',
    uf: 'TO'
  },
  {
    id: 1708304,
    name: 'Goianorte',
    uf: 'TO'
  },
  {
    id: 1709005,
    name: 'Goiatins',
    uf: 'TO'
  },
  {
    id: 1709302,
    name: 'Guaraí',
    uf: 'TO'
  },
  {
    id: 1709500,
    name: 'Gurupi',
    uf: 'TO'
  },
  {
    id: 1709807,
    name: 'Ipueiras',
    uf: 'TO'
  },
  {
    id: 1710508,
    name: 'Itacajá',
    uf: 'TO'
  },
  {
    id: 1710706,
    name: 'Itaguatins',
    uf: 'TO'
  },
  {
    id: 1710904,
    name: 'Itapiratins',
    uf: 'TO'
  },
  {
    id: 1711100,
    name: 'Itaporã do Tocantins',
    uf: 'TO'
  },
  {
    id: 1711506,
    name: 'Jaú do Tocantins',
    uf: 'TO'
  },
  {
    id: 1711803,
    name: 'Juarina',
    uf: 'TO'
  },
  {
    id: 1711902,
    name: 'Lagoa da Confusão',
    uf: 'TO'
  },
  {
    id: 1711951,
    name: 'Lagoa do Tocantins',
    uf: 'TO'
  },
  {
    id: 1712009,
    name: 'Lajeado',
    uf: 'TO'
  },
  {
    id: 1712157,
    name: 'Lavandeira',
    uf: 'TO'
  },
  {
    id: 1712405,
    name: 'Lizarda',
    uf: 'TO'
  },
  {
    id: 1712454,
    name: 'Luzinópolis',
    uf: 'TO'
  },
  {
    id: 1712504,
    name: 'Marianópolis do Tocantins',
    uf: 'TO'
  },
  {
    id: 1712702,
    name: 'Mateiros',
    uf: 'TO'
  },
  {
    id: 1712801,
    name: 'Maurilândia do Tocantins',
    uf: 'TO'
  },
  {
    id: 1713205,
    name: 'Miracema do Tocantins',
    uf: 'TO'
  },
  {
    id: 1713304,
    name: 'Miranorte',
    uf: 'TO'
  },
  {
    id: 1713601,
    name: 'Monte do Carmo',
    uf: 'TO'
  },
  {
    id: 1713700,
    name: 'Monte Santo do Tocantins',
    uf: 'TO'
  },
  {
    id: 1713809,
    name: 'Palmeiras do Tocantins',
    uf: 'TO'
  },
  {
    id: 1713957,
    name: 'Muricilândia',
    uf: 'TO'
  },
  {
    id: 1714203,
    name: 'Natividade',
    uf: 'TO'
  },
  {
    id: 1714302,
    name: 'Nazaré',
    uf: 'TO'
  },
  {
    id: 1714880,
    name: 'Nova Olinda',
    uf: 'TO'
  },
  {
    id: 1715002,
    name: 'Nova Rosalândia',
    uf: 'TO'
  },
  {
    id: 1715101,
    name: 'Novo Acordo',
    uf: 'TO'
  },
  {
    id: 1715150,
    name: 'Novo Alegre',
    uf: 'TO'
  },
  {
    id: 1715259,
    name: 'Novo Jardim',
    uf: 'TO'
  },
  {
    id: 1715507,
    name: 'Oliveira de Fátima',
    uf: 'TO'
  },
  {
    id: 1715705,
    name: 'Palmeirante',
    uf: 'TO'
  },
  {
    id: 1715754,
    name: 'Palmeirópolis',
    uf: 'TO'
  },
  {
    id: 1716109,
    name: 'Paraíso do Tocantins',
    uf: 'TO'
  },
  {
    id: 1716208,
    name: 'Paranã',
    uf: 'TO'
  },
  {
    id: 1716307,
    name: "Pau D'Arco",
    uf: 'TO'
  },
  {
    id: 1716505,
    name: 'Pedro Afonso',
    uf: 'TO'
  },
  {
    id: 1716604,
    name: 'Peixe',
    uf: 'TO'
  },
  {
    id: 1716653,
    name: 'Pequizeiro',
    uf: 'TO'
  },
  {
    id: 1716703,
    name: 'Colméia',
    uf: 'TO'
  },
  {
    id: 1717008,
    name: 'Pindorama do Tocantins',
    uf: 'TO'
  },
  {
    id: 1717206,
    name: 'Piraquê',
    uf: 'TO'
  },
  {
    id: 1717503,
    name: 'Pium',
    uf: 'TO'
  },
  {
    id: 1717800,
    name: 'Ponte Alta do Bom Jesus',
    uf: 'TO'
  },
  {
    id: 1717909,
    name: 'Ponte Alta do Tocantins',
    uf: 'TO'
  },
  {
    id: 1718006,
    name: 'Porto Alegre do Tocantins',
    uf: 'TO'
  },
  {
    id: 1718204,
    name: 'Porto Nacional',
    uf: 'TO'
  },
  {
    id: 1718303,
    name: 'Praia Norte',
    uf: 'TO'
  },
  {
    id: 1718402,
    name: 'Presidente Kennedy',
    uf: 'TO'
  },
  {
    id: 1718451,
    name: 'Pugmil',
    uf: 'TO'
  },
  {
    id: 1718501,
    name: 'Recursolândia',
    uf: 'TO'
  },
  {
    id: 1718550,
    name: 'Riachinho',
    uf: 'TO'
  },
  {
    id: 1718659,
    name: 'Rio da Conceição',
    uf: 'TO'
  },
  {
    id: 1718709,
    name: 'Rio dos Bois',
    uf: 'TO'
  },
  {
    id: 1718758,
    name: 'Rio Sono',
    uf: 'TO'
  },
  {
    id: 1718808,
    name: 'Sampaio',
    uf: 'TO'
  },
  {
    id: 1718840,
    name: 'Sandolândia',
    uf: 'TO'
  },
  {
    id: 1718865,
    name: 'Santa Fé do Araguaia',
    uf: 'TO'
  },
  {
    id: 1718881,
    name: 'Santa Maria do Tocantins',
    uf: 'TO'
  },
  {
    id: 1718899,
    name: 'Santa Rita do Tocantins',
    uf: 'TO'
  },
  {
    id: 1718907,
    name: 'Santa Rosa do Tocantins',
    uf: 'TO'
  },
  {
    id: 1719004,
    name: 'Santa Tereza do Tocantins',
    uf: 'TO'
  },
  {
    id: 1720002,
    name: 'Santa Terezinha do Tocantins',
    uf: 'TO'
  },
  {
    id: 1720101,
    name: 'São Bento do Tocantins',
    uf: 'TO'
  },
  {
    id: 1720150,
    name: 'São Félix do Tocantins',
    uf: 'TO'
  },
  {
    id: 1720200,
    name: 'São Miguel do Tocantins',
    uf: 'TO'
  },
  {
    id: 1720259,
    name: 'São Salvador do Tocantins',
    uf: 'TO'
  },
  {
    id: 1720309,
    name: 'São Sebastião do Tocantins',
    uf: 'TO'
  },
  {
    id: 1720499,
    name: 'São Valério',
    uf: 'TO'
  },
  {
    id: 1720655,
    name: 'Silvanópolis',
    uf: 'TO'
  },
  {
    id: 1720804,
    name: 'Sítio Novo do Tocantins',
    uf: 'TO'
  },
  {
    id: 1720853,
    name: 'Sucupira',
    uf: 'TO'
  },
  {
    id: 1720903,
    name: 'Taguatinga',
    uf: 'TO'
  },
  {
    id: 1720937,
    name: 'Taipas do Tocantins',
    uf: 'TO'
  },
  {
    id: 1720978,
    name: 'Talismã',
    uf: 'TO'
  },
  {
    id: 1721000,
    name: 'Palmas',
    uf: 'TO'
  },
  {
    id: 1721109,
    name: 'Tocantínia',
    uf: 'TO'
  },
  {
    id: 1721208,
    name: 'Tocantinópolis',
    uf: 'TO'
  },
  {
    id: 1721257,
    name: 'Tupirama',
    uf: 'TO'
  },
  {
    id: 1721307,
    name: 'Tupiratins',
    uf: 'TO'
  },
  {
    id: 1722081,
    name: 'Wanderlândia',
    uf: 'TO'
  },
  {
    id: 1722107,
    name: 'Xambioá',
    uf: 'TO'
  },
  {
    id: 2100055,
    name: 'Açailândia',
    uf: 'MA'
  },
  {
    id: 2100105,
    name: 'Afonso Cunha',
    uf: 'MA'
  },
  {
    id: 2100154,
    name: 'Água Doce do Maranhão',
    uf: 'MA'
  },
  {
    id: 2100204,
    name: 'Alcântara',
    uf: 'MA'
  },
  {
    id: 2100303,
    name: 'Aldeias Altas',
    uf: 'MA'
  },
  {
    id: 2100402,
    name: 'Altamira do Maranhão',
    uf: 'MA'
  },
  {
    id: 2100436,
    name: 'Alto Alegre do Maranhão',
    uf: 'MA'
  },
  {
    id: 2100477,
    name: 'Alto Alegre do Pindaré',
    uf: 'MA'
  },
  {
    id: 2100501,
    name: 'Alto Parnaíba',
    uf: 'MA'
  },
  {
    id: 2100550,
    name: 'Amapá do Maranhão',
    uf: 'MA'
  },
  {
    id: 2100600,
    name: 'Amarante do Maranhão',
    uf: 'MA'
  },
  {
    id: 2100709,
    name: 'Anajatuba',
    uf: 'MA'
  },
  {
    id: 2100808,
    name: 'Anapurus',
    uf: 'MA'
  },
  {
    id: 2100832,
    name: 'Apicum-Açu',
    uf: 'MA'
  },
  {
    id: 2100873,
    name: 'Araguanã',
    uf: 'MA'
  },
  {
    id: 2100907,
    name: 'Araioses',
    uf: 'MA'
  },
  {
    id: 2100956,
    name: 'Arame',
    uf: 'MA'
  },
  {
    id: 2101004,
    name: 'Arari',
    uf: 'MA'
  },
  {
    id: 2101103,
    name: 'Axixá',
    uf: 'MA'
  },
  {
    id: 2101202,
    name: 'Bacabal',
    uf: 'MA'
  },
  {
    id: 2101251,
    name: 'Bacabeira',
    uf: 'MA'
  },
  {
    id: 2101301,
    name: 'Bacuri',
    uf: 'MA'
  },
  {
    id: 2101350,
    name: 'Bacurituba',
    uf: 'MA'
  },
  {
    id: 2101400,
    name: 'Balsas',
    uf: 'MA'
  },
  {
    id: 2101509,
    name: 'Barão de Grajaú',
    uf: 'MA'
  },
  {
    id: 2101608,
    name: 'Barra do Corda',
    uf: 'MA'
  },
  {
    id: 2101707,
    name: 'Barreirinhas',
    uf: 'MA'
  },
  {
    id: 2101731,
    name: 'Belágua',
    uf: 'MA'
  },
  {
    id: 2101772,
    name: 'Bela Vista do Maranhão',
    uf: 'MA'
  },
  {
    id: 2101806,
    name: 'Benedito Leite',
    uf: 'MA'
  },
  {
    id: 2101905,
    name: 'Bequimão',
    uf: 'MA'
  },
  {
    id: 2101939,
    name: 'Bernardo do Mearim',
    uf: 'MA'
  },
  {
    id: 2101970,
    name: 'Boa Vista do Gurupi',
    uf: 'MA'
  },
  {
    id: 2102002,
    name: 'Bom Jardim',
    uf: 'MA'
  },
  {
    id: 2102036,
    name: 'Bom Jesus das Selvas',
    uf: 'MA'
  },
  {
    id: 2102077,
    name: 'Bom Lugar',
    uf: 'MA'
  },
  {
    id: 2102101,
    name: 'Brejo',
    uf: 'MA'
  },
  {
    id: 2102150,
    name: 'Brejo de Areia',
    uf: 'MA'
  },
  {
    id: 2102200,
    name: 'Buriti',
    uf: 'MA'
  },
  {
    id: 2102309,
    name: 'Buriti Bravo',
    uf: 'MA'
  },
  {
    id: 2102325,
    name: 'Buriticupu',
    uf: 'MA'
  },
  {
    id: 2102358,
    name: 'Buritirana',
    uf: 'MA'
  },
  {
    id: 2102374,
    name: 'Cachoeira Grande',
    uf: 'MA'
  },
  {
    id: 2102408,
    name: 'Cajapió',
    uf: 'MA'
  },
  {
    id: 2102507,
    name: 'Cajari',
    uf: 'MA'
  },
  {
    id: 2102556,
    name: 'Campestre do Maranhão',
    uf: 'MA'
  },
  {
    id: 2102606,
    name: 'Cândido Mendes',
    uf: 'MA'
  },
  {
    id: 2102705,
    name: 'Cantanhede',
    uf: 'MA'
  },
  {
    id: 2102754,
    name: 'Capinzal do Norte',
    uf: 'MA'
  },
  {
    id: 2102804,
    name: 'Carolina',
    uf: 'MA'
  },
  {
    id: 2102903,
    name: 'Carutapera',
    uf: 'MA'
  },
  {
    id: 2103000,
    name: 'Caxias',
    uf: 'MA'
  },
  {
    id: 2103109,
    name: 'Cedral',
    uf: 'MA'
  },
  {
    id: 2103125,
    name: 'Central do Maranhão',
    uf: 'MA'
  },
  {
    id: 2103158,
    name: 'Centro do Guilherme',
    uf: 'MA'
  },
  {
    id: 2103174,
    name: 'Centro Novo do Maranhão',
    uf: 'MA'
  },
  {
    id: 2103208,
    name: 'Chapadinha',
    uf: 'MA'
  },
  {
    id: 2103257,
    name: 'Cidelândia',
    uf: 'MA'
  },
  {
    id: 2103307,
    name: 'Codó',
    uf: 'MA'
  },
  {
    id: 2103406,
    name: 'Coelho Neto',
    uf: 'MA'
  },
  {
    id: 2103505,
    name: 'Colinas',
    uf: 'MA'
  },
  {
    id: 2103554,
    name: 'Conceição do Lago-Açu',
    uf: 'MA'
  },
  {
    id: 2103604,
    name: 'Coroatá',
    uf: 'MA'
  },
  {
    id: 2103703,
    name: 'Cururupu',
    uf: 'MA'
  },
  {
    id: 2103752,
    name: 'Davinópolis',
    uf: 'MA'
  },
  {
    id: 2103802,
    name: 'Dom Pedro',
    uf: 'MA'
  },
  {
    id: 2103901,
    name: 'Duque Bacelar',
    uf: 'MA'
  },
  {
    id: 2104008,
    name: 'Esperantinópolis',
    uf: 'MA'
  },
  {
    id: 2104057,
    name: 'Estreito',
    uf: 'MA'
  },
  {
    id: 2104073,
    name: 'Feira Nova do Maranhão',
    uf: 'MA'
  },
  {
    id: 2104081,
    name: 'Fernando Falcão',
    uf: 'MA'
  },
  {
    id: 2104099,
    name: 'Formosa da Serra Negra',
    uf: 'MA'
  },
  {
    id: 2104107,
    name: 'Fortaleza dos Nogueiras',
    uf: 'MA'
  },
  {
    id: 2104206,
    name: 'Fortuna',
    uf: 'MA'
  },
  {
    id: 2104305,
    name: 'Godofredo Viana',
    uf: 'MA'
  },
  {
    id: 2104404,
    name: 'Gonçalves Dias',
    uf: 'MA'
  },
  {
    id: 2104503,
    name: 'Governador Archer',
    uf: 'MA'
  },
  {
    id: 2104552,
    name: 'Governador Edison Lobão',
    uf: 'MA'
  },
  {
    id: 2104602,
    name: 'Governador Eugênio Barros',
    uf: 'MA'
  },
  {
    id: 2104628,
    name: 'Governador Luiz Rocha',
    uf: 'MA'
  },
  {
    id: 2104651,
    name: 'Governador Newton Bello',
    uf: 'MA'
  },
  {
    id: 2104677,
    name: 'Governador Nunes Freire',
    uf: 'MA'
  },
  {
    id: 2104701,
    name: 'Graça Aranha',
    uf: 'MA'
  },
  {
    id: 2104800,
    name: 'Grajaú',
    uf: 'MA'
  },
  {
    id: 2104909,
    name: 'Guimarães',
    uf: 'MA'
  },
  {
    id: 2105005,
    name: 'Humberto de Campos',
    uf: 'MA'
  },
  {
    id: 2105104,
    name: 'Icatu',
    uf: 'MA'
  },
  {
    id: 2105153,
    name: 'Igarapé do Meio',
    uf: 'MA'
  },
  {
    id: 2105203,
    name: 'Igarapé Grande',
    uf: 'MA'
  },
  {
    id: 2105302,
    name: 'Imperatriz',
    uf: 'MA'
  },
  {
    id: 2105351,
    name: 'Itaipava do Grajaú',
    uf: 'MA'
  },
  {
    id: 2105401,
    name: 'Itapecuru Mirim',
    uf: 'MA'
  },
  {
    id: 2105427,
    name: 'Itinga do Maranhão',
    uf: 'MA'
  },
  {
    id: 2105450,
    name: 'Jatobá',
    uf: 'MA'
  },
  {
    id: 2105476,
    name: 'Jenipapo dos Vieiras',
    uf: 'MA'
  },
  {
    id: 2105500,
    name: 'João Lisboa',
    uf: 'MA'
  },
  {
    id: 2105609,
    name: 'Joselândia',
    uf: 'MA'
  },
  {
    id: 2105658,
    name: 'Junco do Maranhão',
    uf: 'MA'
  },
  {
    id: 2105708,
    name: 'Lago da Pedra',
    uf: 'MA'
  },
  {
    id: 2105807,
    name: 'Lago do Junco',
    uf: 'MA'
  },
  {
    id: 2105906,
    name: 'Lago Verde',
    uf: 'MA'
  },
  {
    id: 2105922,
    name: 'Lagoa do Mato',
    uf: 'MA'
  },
  {
    id: 2105948,
    name: 'Lago dos Rodrigues',
    uf: 'MA'
  },
  {
    id: 2105963,
    name: 'Lagoa Grande do Maranhão',
    uf: 'MA'
  },
  {
    id: 2105989,
    name: 'Lajeado Novo',
    uf: 'MA'
  },
  {
    id: 2106003,
    name: 'Lima Campos',
    uf: 'MA'
  },
  {
    id: 2106102,
    name: 'Loreto',
    uf: 'MA'
  },
  {
    id: 2106201,
    name: 'Luís Domingues',
    uf: 'MA'
  },
  {
    id: 2106300,
    name: 'Magalhães de Almeida',
    uf: 'MA'
  },
  {
    id: 2106326,
    name: 'Maracaçumé',
    uf: 'MA'
  },
  {
    id: 2106359,
    name: 'Marajá do Sena',
    uf: 'MA'
  },
  {
    id: 2106375,
    name: 'Maranhãozinho',
    uf: 'MA'
  },
  {
    id: 2106409,
    name: 'Mata Roma',
    uf: 'MA'
  },
  {
    id: 2106508,
    name: 'Matinha',
    uf: 'MA'
  },
  {
    id: 2106607,
    name: 'Matões',
    uf: 'MA'
  },
  {
    id: 2106631,
    name: 'Matões do Norte',
    uf: 'MA'
  },
  {
    id: 2106672,
    name: 'Milagres do Maranhão',
    uf: 'MA'
  },
  {
    id: 2106706,
    name: 'Mirador',
    uf: 'MA'
  },
  {
    id: 2106755,
    name: 'Miranda do Norte',
    uf: 'MA'
  },
  {
    id: 2106805,
    name: 'Mirinzal',
    uf: 'MA'
  },
  {
    id: 2106904,
    name: 'Monção',
    uf: 'MA'
  },
  {
    id: 2107001,
    name: 'Montes Altos',
    uf: 'MA'
  },
  {
    id: 2107100,
    name: 'Morros',
    uf: 'MA'
  },
  {
    id: 2107209,
    name: 'Nina Rodrigues',
    uf: 'MA'
  },
  {
    id: 2107258,
    name: 'Nova Colinas',
    uf: 'MA'
  },
  {
    id: 2107308,
    name: 'Nova Iorque',
    uf: 'MA'
  },
  {
    id: 2107357,
    name: 'Nova Olinda do Maranhão',
    uf: 'MA'
  },
  {
    id: 2107407,
    name: "Olho d'Água das Cunhãs",
    uf: 'MA'
  },
  {
    id: 2107456,
    name: 'Olinda Nova do Maranhão',
    uf: 'MA'
  },
  {
    id: 2107506,
    name: 'Paço do Lumiar',
    uf: 'MA'
  },
  {
    id: 2107605,
    name: 'Palmeirândia',
    uf: 'MA'
  },
  {
    id: 2107704,
    name: 'Paraibano',
    uf: 'MA'
  },
  {
    id: 2107803,
    name: 'Parnarama',
    uf: 'MA'
  },
  {
    id: 2107902,
    name: 'Passagem Franca',
    uf: 'MA'
  },
  {
    id: 2108009,
    name: 'Pastos Bons',
    uf: 'MA'
  },
  {
    id: 2108058,
    name: 'Paulino Neves',
    uf: 'MA'
  },
  {
    id: 2108108,
    name: 'Paulo Ramos',
    uf: 'MA'
  },
  {
    id: 2108207,
    name: 'Pedreiras',
    uf: 'MA'
  },
  {
    id: 2108256,
    name: 'Pedro do Rosário',
    uf: 'MA'
  },
  {
    id: 2108306,
    name: 'Penalva',
    uf: 'MA'
  },
  {
    id: 2108405,
    name: 'Peri Mirim',
    uf: 'MA'
  },
  {
    id: 2108454,
    name: 'Peritoró',
    uf: 'MA'
  },
  {
    id: 2108504,
    name: 'Pindaré-Mirim',
    uf: 'MA'
  },
  {
    id: 2108603,
    name: 'Pinheiro',
    uf: 'MA'
  },
  {
    id: 2108702,
    name: 'Pio XII',
    uf: 'MA'
  },
  {
    id: 2108801,
    name: 'Pirapemas',
    uf: 'MA'
  },
  {
    id: 2108900,
    name: 'Poção de Pedras',
    uf: 'MA'
  },
  {
    id: 2109007,
    name: 'Porto Franco',
    uf: 'MA'
  },
  {
    id: 2109056,
    name: 'Porto Rico do Maranhão',
    uf: 'MA'
  },
  {
    id: 2109106,
    name: 'Presidente Dutra',
    uf: 'MA'
  },
  {
    id: 2109205,
    name: 'Presidente Juscelino',
    uf: 'MA'
  },
  {
    id: 2109239,
    name: 'Presidente Médici',
    uf: 'MA'
  },
  {
    id: 2109270,
    name: 'Presidente Sarney',
    uf: 'MA'
  },
  {
    id: 2109304,
    name: 'Presidente Vargas',
    uf: 'MA'
  },
  {
    id: 2109403,
    name: 'Primeira Cruz',
    uf: 'MA'
  },
  {
    id: 2109452,
    name: 'Raposa',
    uf: 'MA'
  },
  {
    id: 2109502,
    name: 'Riachão',
    uf: 'MA'
  },
  {
    id: 2109551,
    name: 'Ribamar Fiquene',
    uf: 'MA'
  },
  {
    id: 2109601,
    name: 'Rosário',
    uf: 'MA'
  },
  {
    id: 2109700,
    name: 'Sambaíba',
    uf: 'MA'
  },
  {
    id: 2109759,
    name: 'Santa Filomena do Maranhão',
    uf: 'MA'
  },
  {
    id: 2109809,
    name: 'Santa Helena',
    uf: 'MA'
  },
  {
    id: 2109908,
    name: 'Santa Inês',
    uf: 'MA'
  },
  {
    id: 2110005,
    name: 'Santa Luzia',
    uf: 'MA'
  },
  {
    id: 2110039,
    name: 'Santa Luzia do Paruá',
    uf: 'MA'
  },
  {
    id: 2110104,
    name: 'Santa Quitéria do Maranhão',
    uf: 'MA'
  },
  {
    id: 2110203,
    name: 'Santa Rita',
    uf: 'MA'
  },
  {
    id: 2110237,
    name: 'Santana do Maranhão',
    uf: 'MA'
  },
  {
    id: 2110278,
    name: 'Santo Amaro do Maranhão',
    uf: 'MA'
  },
  {
    id: 2110302,
    name: 'Santo Antônio dos Lopes',
    uf: 'MA'
  },
  {
    id: 2110401,
    name: 'São Benedito do Rio Preto',
    uf: 'MA'
  },
  {
    id: 2110500,
    name: 'São Bento',
    uf: 'MA'
  },
  {
    id: 2110609,
    name: 'São Bernardo',
    uf: 'MA'
  },
  {
    id: 2110658,
    name: 'São Domingos do Azeitão',
    uf: 'MA'
  },
  {
    id: 2110708,
    name: 'São Domingos do Maranhão',
    uf: 'MA'
  },
  {
    id: 2110807,
    name: 'São Félix de Balsas',
    uf: 'MA'
  },
  {
    id: 2110856,
    name: 'São Francisco do Brejão',
    uf: 'MA'
  },
  {
    id: 2110906,
    name: 'São Francisco do Maranhão',
    uf: 'MA'
  },
  {
    id: 2111003,
    name: 'São João Batista',
    uf: 'MA'
  },
  {
    id: 2111029,
    name: 'São João do Carú',
    uf: 'MA'
  },
  {
    id: 2111052,
    name: 'São João do Paraíso',
    uf: 'MA'
  },
  {
    id: 2111078,
    name: 'São João do Soter',
    uf: 'MA'
  },
  {
    id: 2111102,
    name: 'São João dos Patos',
    uf: 'MA'
  },
  {
    id: 2111201,
    name: 'São José de Ribamar',
    uf: 'MA'
  },
  {
    id: 2111250,
    name: 'São José dos Basílios',
    uf: 'MA'
  },
  {
    id: 2111300,
    name: 'São Luís',
    uf: 'MA'
  },
  {
    id: 2111409,
    name: 'São Luís Gonzaga do Maranhão',
    uf: 'MA'
  },
  {
    id: 2111508,
    name: 'São Mateus do Maranhão',
    uf: 'MA'
  },
  {
    id: 2111532,
    name: 'São Pedro da Água Branca',
    uf: 'MA'
  },
  {
    id: 2111573,
    name: 'São Pedro dos Crentes',
    uf: 'MA'
  },
  {
    id: 2111607,
    name: 'São Raimundo das Mangabeiras',
    uf: 'MA'
  },
  {
    id: 2111631,
    name: 'São Raimundo do Doca Bezerra',
    uf: 'MA'
  },
  {
    id: 2111672,
    name: 'São Roberto',
    uf: 'MA'
  },
  {
    id: 2111706,
    name: 'São Vicente Ferrer',
    uf: 'MA'
  },
  {
    id: 2111722,
    name: 'Satubinha',
    uf: 'MA'
  },
  {
    id: 2111748,
    name: 'Senador Alexandre Costa',
    uf: 'MA'
  },
  {
    id: 2111763,
    name: 'Senador La Rocque',
    uf: 'MA'
  },
  {
    id: 2111789,
    name: 'Serrano do Maranhão',
    uf: 'MA'
  },
  {
    id: 2111805,
    name: 'Sítio Novo',
    uf: 'MA'
  },
  {
    id: 2111904,
    name: 'Sucupira do Norte',
    uf: 'MA'
  },
  {
    id: 2111953,
    name: 'Sucupira do Riachão',
    uf: 'MA'
  },
  {
    id: 2112001,
    name: 'Tasso Fragoso',
    uf: 'MA'
  },
  {
    id: 2112100,
    name: 'Timbiras',
    uf: 'MA'
  },
  {
    id: 2112209,
    name: 'Timon',
    uf: 'MA'
  },
  {
    id: 2112233,
    name: 'Trizidela do Vale',
    uf: 'MA'
  },
  {
    id: 2112274,
    name: 'Tufilândia',
    uf: 'MA'
  },
  {
    id: 2112308,
    name: 'Tuntum',
    uf: 'MA'
  },
  {
    id: 2112407,
    name: 'Turiaçu',
    uf: 'MA'
  },
  {
    id: 2112456,
    name: 'Turilândia',
    uf: 'MA'
  },
  {
    id: 2112506,
    name: 'Tutóia',
    uf: 'MA'
  },
  {
    id: 2112605,
    name: 'Urbano Santos',
    uf: 'MA'
  },
  {
    id: 2112704,
    name: 'Vargem Grande',
    uf: 'MA'
  },
  {
    id: 2112803,
    name: 'Viana',
    uf: 'MA'
  },
  {
    id: 2112852,
    name: 'Vila Nova dos Martírios',
    uf: 'MA'
  },
  {
    id: 2112902,
    name: 'Vitória do Mearim',
    uf: 'MA'
  },
  {
    id: 2113009,
    name: 'Vitorino Freire',
    uf: 'MA'
  },
  {
    id: 2114007,
    name: 'Zé Doca',
    uf: 'MA'
  },
  {
    id: 2200053,
    name: 'Acauã',
    uf: 'PI'
  },
  {
    id: 2200103,
    name: 'Agricolândia',
    uf: 'PI'
  },
  {
    id: 2200202,
    name: 'Água Branca',
    uf: 'PI'
  },
  {
    id: 2200251,
    name: 'Alagoinha do Piauí',
    uf: 'PI'
  },
  {
    id: 2200277,
    name: 'Alegrete do Piauí',
    uf: 'PI'
  },
  {
    id: 2200301,
    name: 'Alto Longá',
    uf: 'PI'
  },
  {
    id: 2200400,
    name: 'Altos',
    uf: 'PI'
  },
  {
    id: 2200459,
    name: 'Alvorada do Gurguéia',
    uf: 'PI'
  },
  {
    id: 2200509,
    name: 'Amarante',
    uf: 'PI'
  },
  {
    id: 2200608,
    name: 'Angical do Piauí',
    uf: 'PI'
  },
  {
    id: 2200707,
    name: 'Anísio de Abreu',
    uf: 'PI'
  },
  {
    id: 2200806,
    name: 'Antônio Almeida',
    uf: 'PI'
  },
  {
    id: 2200905,
    name: 'Aroazes',
    uf: 'PI'
  },
  {
    id: 2200954,
    name: 'Aroeiras do Itaim',
    uf: 'PI'
  },
  {
    id: 2201002,
    name: 'Arraial',
    uf: 'PI'
  },
  {
    id: 2201051,
    name: 'Assunção do Piauí',
    uf: 'PI'
  },
  {
    id: 2201101,
    name: 'Avelino Lopes',
    uf: 'PI'
  },
  {
    id: 2201150,
    name: 'Baixa Grande do Ribeiro',
    uf: 'PI'
  },
  {
    id: 2201176,
    name: "Barra D'Alcântara",
    uf: 'PI'
  },
  {
    id: 2201200,
    name: 'Barras',
    uf: 'PI'
  },
  {
    id: 2201309,
    name: 'Barreiras do Piauí',
    uf: 'PI'
  },
  {
    id: 2201408,
    name: 'Barro Duro',
    uf: 'PI'
  },
  {
    id: 2201507,
    name: 'Batalha',
    uf: 'PI'
  },
  {
    id: 2201556,
    name: 'Bela Vista do Piauí',
    uf: 'PI'
  },
  {
    id: 2201572,
    name: 'Belém do Piauí',
    uf: 'PI'
  },
  {
    id: 2201606,
    name: 'Beneditinos',
    uf: 'PI'
  },
  {
    id: 2201705,
    name: 'Bertolínia',
    uf: 'PI'
  },
  {
    id: 2201739,
    name: 'Betânia do Piauí',
    uf: 'PI'
  },
  {
    id: 2201770,
    name: 'Boa Hora',
    uf: 'PI'
  },
  {
    id: 2201804,
    name: 'Bocaina',
    uf: 'PI'
  },
  {
    id: 2201903,
    name: 'Bom Jesus',
    uf: 'PI'
  },
  {
    id: 2201919,
    name: 'Bom Princípio do Piauí',
    uf: 'PI'
  },
  {
    id: 2201929,
    name: 'Bonfim do Piauí',
    uf: 'PI'
  },
  {
    id: 2201945,
    name: 'Boqueirão do Piauí',
    uf: 'PI'
  },
  {
    id: 2201960,
    name: 'Brasileira',
    uf: 'PI'
  },
  {
    id: 2201988,
    name: 'Brejo do Piauí',
    uf: 'PI'
  },
  {
    id: 2202000,
    name: 'Buriti dos Lopes',
    uf: 'PI'
  },
  {
    id: 2202026,
    name: 'Buriti dos Montes',
    uf: 'PI'
  },
  {
    id: 2202059,
    name: 'Cabeceiras do Piauí',
    uf: 'PI'
  },
  {
    id: 2202075,
    name: 'Cajazeiras do Piauí',
    uf: 'PI'
  },
  {
    id: 2202083,
    name: 'Cajueiro da Praia',
    uf: 'PI'
  },
  {
    id: 2202091,
    name: 'Caldeirão Grande do Piauí',
    uf: 'PI'
  },
  {
    id: 2202109,
    name: 'Campinas do Piauí',
    uf: 'PI'
  },
  {
    id: 2202117,
    name: 'Campo Alegre do Fidalgo',
    uf: 'PI'
  },
  {
    id: 2202133,
    name: 'Campo Grande do Piauí',
    uf: 'PI'
  },
  {
    id: 2202174,
    name: 'Campo Largo do Piauí',
    uf: 'PI'
  },
  {
    id: 2202208,
    name: 'Campo Maior',
    uf: 'PI'
  },
  {
    id: 2202251,
    name: 'Canavieira',
    uf: 'PI'
  },
  {
    id: 2202307,
    name: 'Canto do Buriti',
    uf: 'PI'
  },
  {
    id: 2202406,
    name: 'Capitão de Campos',
    uf: 'PI'
  },
  {
    id: 2202455,
    name: 'Capitão Gervásio Oliveira',
    uf: 'PI'
  },
  {
    id: 2202505,
    name: 'Caracol',
    uf: 'PI'
  },
  {
    id: 2202539,
    name: 'Caraúbas do Piauí',
    uf: 'PI'
  },
  {
    id: 2202554,
    name: 'Caridade do Piauí',
    uf: 'PI'
  },
  {
    id: 2202604,
    name: 'Castelo do Piauí',
    uf: 'PI'
  },
  {
    id: 2202653,
    name: 'Caxingó',
    uf: 'PI'
  },
  {
    id: 2202703,
    name: 'Cocal',
    uf: 'PI'
  },
  {
    id: 2202711,
    name: 'Cocal de Telha',
    uf: 'PI'
  },
  {
    id: 2202729,
    name: 'Cocal dos Alves',
    uf: 'PI'
  },
  {
    id: 2202737,
    name: 'Coivaras',
    uf: 'PI'
  },
  {
    id: 2202752,
    name: 'Colônia do Gurguéia',
    uf: 'PI'
  },
  {
    id: 2202778,
    name: 'Colônia do Piauí',
    uf: 'PI'
  },
  {
    id: 2202802,
    name: 'Conceição do Canindé',
    uf: 'PI'
  },
  {
    id: 2202851,
    name: 'Coronel José Dias',
    uf: 'PI'
  },
  {
    id: 2202901,
    name: 'Corrente',
    uf: 'PI'
  },
  {
    id: 2203008,
    name: 'Cristalândia do Piauí',
    uf: 'PI'
  },
  {
    id: 2203107,
    name: 'Cristino Castro',
    uf: 'PI'
  },
  {
    id: 2203206,
    name: 'Curimatá',
    uf: 'PI'
  },
  {
    id: 2203230,
    name: 'Currais',
    uf: 'PI'
  },
  {
    id: 2203255,
    name: 'Curralinhos',
    uf: 'PI'
  },
  {
    id: 2203271,
    name: 'Curral Novo do Piauí',
    uf: 'PI'
  },
  {
    id: 2203305,
    name: 'Demerval Lobão',
    uf: 'PI'
  },
  {
    id: 2203354,
    name: 'Dirceu Arcoverde',
    uf: 'PI'
  },
  {
    id: 2203404,
    name: 'Dom Expedito Lopes',
    uf: 'PI'
  },
  {
    id: 2203420,
    name: 'Domingos Mourão',
    uf: 'PI'
  },
  {
    id: 2203453,
    name: 'Dom Inocêncio',
    uf: 'PI'
  },
  {
    id: 2203503,
    name: 'Elesbão Veloso',
    uf: 'PI'
  },
  {
    id: 2203602,
    name: 'Eliseu Martins',
    uf: 'PI'
  },
  {
    id: 2203701,
    name: 'Esperantina',
    uf: 'PI'
  },
  {
    id: 2203750,
    name: 'Fartura do Piauí',
    uf: 'PI'
  },
  {
    id: 2203800,
    name: 'Flores do Piauí',
    uf: 'PI'
  },
  {
    id: 2203859,
    name: 'Floresta do Piauí',
    uf: 'PI'
  },
  {
    id: 2203909,
    name: 'Floriano',
    uf: 'PI'
  },
  {
    id: 2204006,
    name: 'Francinópolis',
    uf: 'PI'
  },
  {
    id: 2204105,
    name: 'Francisco Ayres',
    uf: 'PI'
  },
  {
    id: 2204154,
    name: 'Francisco Macedo',
    uf: 'PI'
  },
  {
    id: 2204204,
    name: 'Francisco Santos',
    uf: 'PI'
  },
  {
    id: 2204303,
    name: 'Fronteiras',
    uf: 'PI'
  },
  {
    id: 2204352,
    name: 'Geminiano',
    uf: 'PI'
  },
  {
    id: 2204402,
    name: 'Gilbués',
    uf: 'PI'
  },
  {
    id: 2204501,
    name: 'Guadalupe',
    uf: 'PI'
  },
  {
    id: 2204550,
    name: 'Guaribas',
    uf: 'PI'
  },
  {
    id: 2204600,
    name: 'Hugo Napoleão',
    uf: 'PI'
  },
  {
    id: 2204659,
    name: 'Ilha Grande',
    uf: 'PI'
  },
  {
    id: 2204709,
    name: 'Inhuma',
    uf: 'PI'
  },
  {
    id: 2204808,
    name: 'Ipiranga do Piauí',
    uf: 'PI'
  },
  {
    id: 2204907,
    name: 'Isaías Coelho',
    uf: 'PI'
  },
  {
    id: 2205003,
    name: 'Itainópolis',
    uf: 'PI'
  },
  {
    id: 2205102,
    name: 'Itaueira',
    uf: 'PI'
  },
  {
    id: 2205151,
    name: 'Jacobina do Piauí',
    uf: 'PI'
  },
  {
    id: 2205201,
    name: 'Jaicós',
    uf: 'PI'
  },
  {
    id: 2205250,
    name: 'Jardim do Mulato',
    uf: 'PI'
  },
  {
    id: 2205276,
    name: 'Jatobá do Piauí',
    uf: 'PI'
  },
  {
    id: 2205300,
    name: 'Jerumenha',
    uf: 'PI'
  },
  {
    id: 2205359,
    name: 'João Costa',
    uf: 'PI'
  },
  {
    id: 2205409,
    name: 'Joaquim Pires',
    uf: 'PI'
  },
  {
    id: 2205458,
    name: 'Joca Marques',
    uf: 'PI'
  },
  {
    id: 2205508,
    name: 'José de Freitas',
    uf: 'PI'
  },
  {
    id: 2205516,
    name: 'Juazeiro do Piauí',
    uf: 'PI'
  },
  {
    id: 2205524,
    name: 'Júlio Borges',
    uf: 'PI'
  },
  {
    id: 2205532,
    name: 'Jurema',
    uf: 'PI'
  },
  {
    id: 2205540,
    name: 'Lagoinha do Piauí',
    uf: 'PI'
  },
  {
    id: 2205557,
    name: 'Lagoa Alegre',
    uf: 'PI'
  },
  {
    id: 2205565,
    name: 'Lagoa do Barro do Piauí',
    uf: 'PI'
  },
  {
    id: 2205573,
    name: 'Lagoa de São Francisco',
    uf: 'PI'
  },
  {
    id: 2205581,
    name: 'Lagoa do Piauí',
    uf: 'PI'
  },
  {
    id: 2205599,
    name: 'Lagoa do Sítio',
    uf: 'PI'
  },
  {
    id: 2205607,
    name: 'Landri Sales',
    uf: 'PI'
  },
  {
    id: 2205706,
    name: 'Luís Correia',
    uf: 'PI'
  },
  {
    id: 2205805,
    name: 'Luzilândia',
    uf: 'PI'
  },
  {
    id: 2205854,
    name: 'Madeiro',
    uf: 'PI'
  },
  {
    id: 2205904,
    name: 'Manoel Emídio',
    uf: 'PI'
  },
  {
    id: 2205953,
    name: 'Marcolândia',
    uf: 'PI'
  },
  {
    id: 2206001,
    name: 'Marcos Parente',
    uf: 'PI'
  },
  {
    id: 2206050,
    name: 'Massapê do Piauí',
    uf: 'PI'
  },
  {
    id: 2206100,
    name: 'Matias Olímpio',
    uf: 'PI'
  },
  {
    id: 2206209,
    name: 'Miguel Alves',
    uf: 'PI'
  },
  {
    id: 2206308,
    name: 'Miguel Leão',
    uf: 'PI'
  },
  {
    id: 2206357,
    name: 'Milton Brandão',
    uf: 'PI'
  },
  {
    id: 2206407,
    name: 'Monsenhor Gil',
    uf: 'PI'
  },
  {
    id: 2206506,
    name: 'Monsenhor Hipólito',
    uf: 'PI'
  },
  {
    id: 2206605,
    name: 'Monte Alegre do Piauí',
    uf: 'PI'
  },
  {
    id: 2206654,
    name: 'Morro Cabeça no Tempo',
    uf: 'PI'
  },
  {
    id: 2206670,
    name: 'Morro do Chapéu do Piauí',
    uf: 'PI'
  },
  {
    id: 2206696,
    name: 'Murici dos Portelas',
    uf: 'PI'
  },
  {
    id: 2206704,
    name: 'Nazaré do Piauí',
    uf: 'PI'
  },
  {
    id: 2206720,
    name: 'Nazária',
    uf: 'PI'
  },
  {
    id: 2206753,
    name: 'Nossa Senhora de Nazaré',
    uf: 'PI'
  },
  {
    id: 2206803,
    name: 'Nossa Senhora dos Remédios',
    uf: 'PI'
  },
  {
    id: 2206902,
    name: 'Novo Oriente do Piauí',
    uf: 'PI'
  },
  {
    id: 2206951,
    name: 'Novo Santo Antônio',
    uf: 'PI'
  },
  {
    id: 2207009,
    name: 'Oeiras',
    uf: 'PI'
  },
  {
    id: 2207108,
    name: "Olho D'Água do Piauí",
    uf: 'PI'
  },
  {
    id: 2207207,
    name: 'Padre Marcos',
    uf: 'PI'
  },
  {
    id: 2207306,
    name: 'Paes Landim',
    uf: 'PI'
  },
  {
    id: 2207355,
    name: 'Pajeú do Piauí',
    uf: 'PI'
  },
  {
    id: 2207405,
    name: 'Palmeira do Piauí',
    uf: 'PI'
  },
  {
    id: 2207504,
    name: 'Palmeirais',
    uf: 'PI'
  },
  {
    id: 2207553,
    name: 'Paquetá',
    uf: 'PI'
  },
  {
    id: 2207603,
    name: 'Parnaguá',
    uf: 'PI'
  },
  {
    id: 2207702,
    name: 'Parnaíba',
    uf: 'PI'
  },
  {
    id: 2207751,
    name: 'Passagem Franca do Piauí',
    uf: 'PI'
  },
  {
    id: 2207777,
    name: 'Patos do Piauí',
    uf: 'PI'
  },
  {
    id: 2207793,
    name: "Pau D'Arco do Piauí",
    uf: 'PI'
  },
  {
    id: 2207801,
    name: 'Paulistana',
    uf: 'PI'
  },
  {
    id: 2207850,
    name: 'Pavussu',
    uf: 'PI'
  },
  {
    id: 2207900,
    name: 'Pedro II',
    uf: 'PI'
  },
  {
    id: 2207934,
    name: 'Pedro Laurentino',
    uf: 'PI'
  },
  {
    id: 2207959,
    name: 'Nova Santa Rita',
    uf: 'PI'
  },
  {
    id: 2208007,
    name: 'Picos',
    uf: 'PI'
  },
  {
    id: 2208106,
    name: 'Pimenteiras',
    uf: 'PI'
  },
  {
    id: 2208205,
    name: 'Pio IX',
    uf: 'PI'
  },
  {
    id: 2208304,
    name: 'Piracuruca',
    uf: 'PI'
  },
  {
    id: 2208403,
    name: 'Piripiri',
    uf: 'PI'
  },
  {
    id: 2208502,
    name: 'Porto',
    uf: 'PI'
  },
  {
    id: 2208551,
    name: 'Porto Alegre do Piauí',
    uf: 'PI'
  },
  {
    id: 2208601,
    name: 'Prata do Piauí',
    uf: 'PI'
  },
  {
    id: 2208650,
    name: 'Queimada Nova',
    uf: 'PI'
  },
  {
    id: 2208700,
    name: 'Redenção do Gurguéia',
    uf: 'PI'
  },
  {
    id: 2208809,
    name: 'Regeneração',
    uf: 'PI'
  },
  {
    id: 2208858,
    name: 'Riacho Frio',
    uf: 'PI'
  },
  {
    id: 2208874,
    name: 'Ribeira do Piauí',
    uf: 'PI'
  },
  {
    id: 2208908,
    name: 'Ribeiro Gonçalves',
    uf: 'PI'
  },
  {
    id: 2209005,
    name: 'Rio Grande do Piauí',
    uf: 'PI'
  },
  {
    id: 2209104,
    name: 'Santa Cruz do Piauí',
    uf: 'PI'
  },
  {
    id: 2209153,
    name: 'Santa Cruz dos Milagres',
    uf: 'PI'
  },
  {
    id: 2209203,
    name: 'Santa Filomena',
    uf: 'PI'
  },
  {
    id: 2209302,
    name: 'Santa Luz',
    uf: 'PI'
  },
  {
    id: 2209351,
    name: 'Santana do Piauí',
    uf: 'PI'
  },
  {
    id: 2209377,
    name: 'Santa Rosa do Piauí',
    uf: 'PI'
  },
  {
    id: 2209401,
    name: 'Santo Antônio de Lisboa',
    uf: 'PI'
  },
  {
    id: 2209450,
    name: 'Santo Antônio dos Milagres',
    uf: 'PI'
  },
  {
    id: 2209500,
    name: 'Santo Inácio do Piauí',
    uf: 'PI'
  },
  {
    id: 2209559,
    name: 'São Braz do Piauí',
    uf: 'PI'
  },
  {
    id: 2209609,
    name: 'São Félix do Piauí',
    uf: 'PI'
  },
  {
    id: 2209658,
    name: 'São Francisco de Assis do Piauí',
    uf: 'PI'
  },
  {
    id: 2209708,
    name: 'São Francisco do Piauí',
    uf: 'PI'
  },
  {
    id: 2209757,
    name: 'São Gonçalo do Gurguéia',
    uf: 'PI'
  },
  {
    id: 2209807,
    name: 'São Gonçalo do Piauí',
    uf: 'PI'
  },
  {
    id: 2209856,
    name: 'São João da Canabrava',
    uf: 'PI'
  },
  {
    id: 2209872,
    name: 'São João da Fronteira',
    uf: 'PI'
  },
  {
    id: 2209906,
    name: 'São João da Serra',
    uf: 'PI'
  },
  {
    id: 2209955,
    name: 'São João da Varjota',
    uf: 'PI'
  },
  {
    id: 2209971,
    name: 'São João do Arraial',
    uf: 'PI'
  },
  {
    id: 2210003,
    name: 'São João do Piauí',
    uf: 'PI'
  },
  {
    id: 2210052,
    name: 'São José do Divino',
    uf: 'PI'
  },
  {
    id: 2210102,
    name: 'São José do Peixe',
    uf: 'PI'
  },
  {
    id: 2210201,
    name: 'São José do Piauí',
    uf: 'PI'
  },
  {
    id: 2210300,
    name: 'São Julião',
    uf: 'PI'
  },
  {
    id: 2210359,
    name: 'São Lourenço do Piauí',
    uf: 'PI'
  },
  {
    id: 2210375,
    name: 'São Luis do Piauí',
    uf: 'PI'
  },
  {
    id: 2210383,
    name: 'São Miguel da Baixa Grande',
    uf: 'PI'
  },
  {
    id: 2210391,
    name: 'São Miguel do Fidalgo',
    uf: 'PI'
  },
  {
    id: 2210409,
    name: 'São Miguel do Tapuio',
    uf: 'PI'
  },
  {
    id: 2210508,
    name: 'São Pedro do Piauí',
    uf: 'PI'
  },
  {
    id: 2210607,
    name: 'São Raimundo Nonato',
    uf: 'PI'
  },
  {
    id: 2210623,
    name: 'Sebastião Barros',
    uf: 'PI'
  },
  {
    id: 2210631,
    name: 'Sebastião Leal',
    uf: 'PI'
  },
  {
    id: 2210656,
    name: 'Sigefredo Pacheco',
    uf: 'PI'
  },
  {
    id: 2210706,
    name: 'Simões',
    uf: 'PI'
  },
  {
    id: 2210805,
    name: 'Simplício Mendes',
    uf: 'PI'
  },
  {
    id: 2210904,
    name: 'Socorro do Piauí',
    uf: 'PI'
  },
  {
    id: 2210938,
    name: 'Sussuapara',
    uf: 'PI'
  },
  {
    id: 2210953,
    name: 'Tamboril do Piauí',
    uf: 'PI'
  },
  {
    id: 2210979,
    name: 'Tanque do Piauí',
    uf: 'PI'
  },
  {
    id: 2211001,
    name: 'Teresina',
    uf: 'PI'
  },
  {
    id: 2211100,
    name: 'União',
    uf: 'PI'
  },
  {
    id: 2211209,
    name: 'Uruçuí',
    uf: 'PI'
  },
  {
    id: 2211308,
    name: 'Valença do Piauí',
    uf: 'PI'
  },
  {
    id: 2211357,
    name: 'Várzea Branca',
    uf: 'PI'
  },
  {
    id: 2211407,
    name: 'Várzea Grande',
    uf: 'PI'
  },
  {
    id: 2211506,
    name: 'Vera Mendes',
    uf: 'PI'
  },
  {
    id: 2211605,
    name: 'Vila Nova do Piauí',
    uf: 'PI'
  },
  {
    id: 2211704,
    name: 'Wall Ferraz',
    uf: 'PI'
  },
  {
    id: 2300101,
    name: 'Abaiara',
    uf: 'CE'
  },
  {
    id: 2300150,
    name: 'Acarape',
    uf: 'CE'
  },
  {
    id: 2300200,
    name: 'Acaraú',
    uf: 'CE'
  },
  {
    id: 2300309,
    name: 'Acopiara',
    uf: 'CE'
  },
  {
    id: 2300408,
    name: 'Aiuaba',
    uf: 'CE'
  },
  {
    id: 2300507,
    name: 'Alcântaras',
    uf: 'CE'
  },
  {
    id: 2300606,
    name: 'Altaneira',
    uf: 'CE'
  },
  {
    id: 2300705,
    name: 'Alto Santo',
    uf: 'CE'
  },
  {
    id: 2300754,
    name: 'Amontada',
    uf: 'CE'
  },
  {
    id: 2300804,
    name: 'Antonina do Norte',
    uf: 'CE'
  },
  {
    id: 2300903,
    name: 'Apuiarés',
    uf: 'CE'
  },
  {
    id: 2301000,
    name: 'Aquiraz',
    uf: 'CE'
  },
  {
    id: 2301109,
    name: 'Aracati',
    uf: 'CE'
  },
  {
    id: 2301208,
    name: 'Aracoiaba',
    uf: 'CE'
  },
  {
    id: 2301257,
    name: 'Ararendá',
    uf: 'CE'
  },
  {
    id: 2301307,
    name: 'Araripe',
    uf: 'CE'
  },
  {
    id: 2301406,
    name: 'Aratuba',
    uf: 'CE'
  },
  {
    id: 2301505,
    name: 'Arneiroz',
    uf: 'CE'
  },
  {
    id: 2301604,
    name: 'Assaré',
    uf: 'CE'
  },
  {
    id: 2301703,
    name: 'Aurora',
    uf: 'CE'
  },
  {
    id: 2301802,
    name: 'Baixio',
    uf: 'CE'
  },
  {
    id: 2301851,
    name: 'Banabuiú',
    uf: 'CE'
  },
  {
    id: 2301901,
    name: 'Barbalha',
    uf: 'CE'
  },
  {
    id: 2301950,
    name: 'Barreira',
    uf: 'CE'
  },
  {
    id: 2302008,
    name: 'Barro',
    uf: 'CE'
  },
  {
    id: 2302057,
    name: 'Barroquinha',
    uf: 'CE'
  },
  {
    id: 2302107,
    name: 'Baturité',
    uf: 'CE'
  },
  {
    id: 2302206,
    name: 'Beberibe',
    uf: 'CE'
  },
  {
    id: 2302305,
    name: 'Bela Cruz',
    uf: 'CE'
  },
  {
    id: 2302404,
    name: 'Boa Viagem',
    uf: 'CE'
  },
  {
    id: 2302503,
    name: 'Brejo Santo',
    uf: 'CE'
  },
  {
    id: 2302602,
    name: 'Camocim',
    uf: 'CE'
  },
  {
    id: 2302701,
    name: 'Campos Sales',
    uf: 'CE'
  },
  {
    id: 2302800,
    name: 'Canindé',
    uf: 'CE'
  },
  {
    id: 2302909,
    name: 'Capistrano',
    uf: 'CE'
  },
  {
    id: 2303006,
    name: 'Caridade',
    uf: 'CE'
  },
  {
    id: 2303105,
    name: 'Cariré',
    uf: 'CE'
  },
  {
    id: 2303204,
    name: 'Caririaçu',
    uf: 'CE'
  },
  {
    id: 2303303,
    name: 'Cariús',
    uf: 'CE'
  },
  {
    id: 2303402,
    name: 'Carnaubal',
    uf: 'CE'
  },
  {
    id: 2303501,
    name: 'Cascavel',
    uf: 'CE'
  },
  {
    id: 2303600,
    name: 'Catarina',
    uf: 'CE'
  },
  {
    id: 2303659,
    name: 'Catunda',
    uf: 'CE'
  },
  {
    id: 2303709,
    name: 'Caucaia',
    uf: 'CE'
  },
  {
    id: 2303808,
    name: 'Cedro',
    uf: 'CE'
  },
  {
    id: 2303907,
    name: 'Chaval',
    uf: 'CE'
  },
  {
    id: 2303931,
    name: 'Choró',
    uf: 'CE'
  },
  {
    id: 2303956,
    name: 'Chorozinho',
    uf: 'CE'
  },
  {
    id: 2304004,
    name: 'Coreaú',
    uf: 'CE'
  },
  {
    id: 2304103,
    name: 'Crateús',
    uf: 'CE'
  },
  {
    id: 2304202,
    name: 'Crato',
    uf: 'CE'
  },
  {
    id: 2304236,
    name: 'Croatá',
    uf: 'CE'
  },
  {
    id: 2304251,
    name: 'Cruz',
    uf: 'CE'
  },
  {
    id: 2304269,
    name: 'Deputado Irapuan Pinheiro',
    uf: 'CE'
  },
  {
    id: 2304277,
    name: 'Ereré',
    uf: 'CE'
  },
  {
    id: 2304285,
    name: 'Eusébio',
    uf: 'CE'
  },
  {
    id: 2304301,
    name: 'Farias Brito',
    uf: 'CE'
  },
  {
    id: 2304350,
    name: 'Forquilha',
    uf: 'CE'
  },
  {
    id: 2304400,
    name: 'Fortaleza',
    uf: 'CE'
  },
  {
    id: 2304459,
    name: 'Fortim',
    uf: 'CE'
  },
  {
    id: 2304509,
    name: 'Frecheirinha',
    uf: 'CE'
  },
  {
    id: 2304608,
    name: 'General Sampaio',
    uf: 'CE'
  },
  {
    id: 2304657,
    name: 'Graça',
    uf: 'CE'
  },
  {
    id: 2304707,
    name: 'Granja',
    uf: 'CE'
  },
  {
    id: 2304806,
    name: 'Granjeiro',
    uf: 'CE'
  },
  {
    id: 2304905,
    name: 'Groaíras',
    uf: 'CE'
  },
  {
    id: 2304954,
    name: 'Guaiúba',
    uf: 'CE'
  },
  {
    id: 2305001,
    name: 'Guaraciaba do Norte',
    uf: 'CE'
  },
  {
    id: 2305100,
    name: 'Guaramiranga',
    uf: 'CE'
  },
  {
    id: 2305209,
    name: 'Hidrolândia',
    uf: 'CE'
  },
  {
    id: 2305233,
    name: 'Horizonte',
    uf: 'CE'
  },
  {
    id: 2305266,
    name: 'Ibaretama',
    uf: 'CE'
  },
  {
    id: 2305308,
    name: 'Ibiapina',
    uf: 'CE'
  },
  {
    id: 2305332,
    name: 'Ibicuitinga',
    uf: 'CE'
  },
  {
    id: 2305357,
    name: 'Icapuí',
    uf: 'CE'
  },
  {
    id: 2305407,
    name: 'Icó',
    uf: 'CE'
  },
  {
    id: 2305506,
    name: 'Iguatu',
    uf: 'CE'
  },
  {
    id: 2305605,
    name: 'Independência',
    uf: 'CE'
  },
  {
    id: 2305654,
    name: 'Ipaporanga',
    uf: 'CE'
  },
  {
    id: 2305704,
    name: 'Ipaumirim',
    uf: 'CE'
  },
  {
    id: 2305803,
    name: 'Ipu',
    uf: 'CE'
  },
  {
    id: 2305902,
    name: 'Ipueiras',
    uf: 'CE'
  },
  {
    id: 2306009,
    name: 'Iracema',
    uf: 'CE'
  },
  {
    id: 2306108,
    name: 'Irauçuba',
    uf: 'CE'
  },
  {
    id: 2306207,
    name: 'Itaiçaba',
    uf: 'CE'
  },
  {
    id: 2306256,
    name: 'Itaitinga',
    uf: 'CE'
  },
  {
    id: 2306306,
    name: 'Itapajé',
    uf: 'CE'
  },
  {
    id: 2306405,
    name: 'Itapipoca',
    uf: 'CE'
  },
  {
    id: 2306504,
    name: 'Itapiúna',
    uf: 'CE'
  },
  {
    id: 2306553,
    name: 'Itarema',
    uf: 'CE'
  },
  {
    id: 2306603,
    name: 'Itatira',
    uf: 'CE'
  },
  {
    id: 2306702,
    name: 'Jaguaretama',
    uf: 'CE'
  },
  {
    id: 2306801,
    name: 'Jaguaribara',
    uf: 'CE'
  },
  {
    id: 2306900,
    name: 'Jaguaribe',
    uf: 'CE'
  },
  {
    id: 2307007,
    name: 'Jaguaruana',
    uf: 'CE'
  },
  {
    id: 2307106,
    name: 'Jardim',
    uf: 'CE'
  },
  {
    id: 2307205,
    name: 'Jati',
    uf: 'CE'
  },
  {
    id: 2307254,
    name: 'Jijoca de Jericoacoara',
    uf: 'CE'
  },
  {
    id: 2307304,
    name: 'Juazeiro do Norte',
    uf: 'CE'
  },
  {
    id: 2307403,
    name: 'Jucás',
    uf: 'CE'
  },
  {
    id: 2307502,
    name: 'Lavras da Mangabeira',
    uf: 'CE'
  },
  {
    id: 2307601,
    name: 'Limoeiro do Norte',
    uf: 'CE'
  },
  {
    id: 2307635,
    name: 'Madalena',
    uf: 'CE'
  },
  {
    id: 2307650,
    name: 'Maracanaú',
    uf: 'CE'
  },
  {
    id: 2307700,
    name: 'Maranguape',
    uf: 'CE'
  },
  {
    id: 2307809,
    name: 'Marco',
    uf: 'CE'
  },
  {
    id: 2307908,
    name: 'Martinópole',
    uf: 'CE'
  },
  {
    id: 2308005,
    name: 'Massapê',
    uf: 'CE'
  },
  {
    id: 2308104,
    name: 'Mauriti',
    uf: 'CE'
  },
  {
    id: 2308203,
    name: 'Meruoca',
    uf: 'CE'
  },
  {
    id: 2308302,
    name: 'Milagres',
    uf: 'CE'
  },
  {
    id: 2308351,
    name: 'Milhã',
    uf: 'CE'
  },
  {
    id: 2308377,
    name: 'Miraíma',
    uf: 'CE'
  },
  {
    id: 2308401,
    name: 'Missão Velha',
    uf: 'CE'
  },
  {
    id: 2308500,
    name: 'Mombaça',
    uf: 'CE'
  },
  {
    id: 2308609,
    name: 'Monsenhor Tabosa',
    uf: 'CE'
  },
  {
    id: 2308708,
    name: 'Morada Nova',
    uf: 'CE'
  },
  {
    id: 2308807,
    name: 'Moraújo',
    uf: 'CE'
  },
  {
    id: 2308906,
    name: 'Morrinhos',
    uf: 'CE'
  },
  {
    id: 2309003,
    name: 'Mucambo',
    uf: 'CE'
  },
  {
    id: 2309102,
    name: 'Mulungu',
    uf: 'CE'
  },
  {
    id: 2309201,
    name: 'Nova Olinda',
    uf: 'CE'
  },
  {
    id: 2309300,
    name: 'Nova Russas',
    uf: 'CE'
  },
  {
    id: 2309409,
    name: 'Novo Oriente',
    uf: 'CE'
  },
  {
    id: 2309458,
    name: 'Ocara',
    uf: 'CE'
  },
  {
    id: 2309508,
    name: 'Orós',
    uf: 'CE'
  },
  {
    id: 2309607,
    name: 'Pacajus',
    uf: 'CE'
  },
  {
    id: 2309706,
    name: 'Pacatuba',
    uf: 'CE'
  },
  {
    id: 2309805,
    name: 'Pacoti',
    uf: 'CE'
  },
  {
    id: 2309904,
    name: 'Pacujá',
    uf: 'CE'
  },
  {
    id: 2310001,
    name: 'Palhano',
    uf: 'CE'
  },
  {
    id: 2310100,
    name: 'Palmácia',
    uf: 'CE'
  },
  {
    id: 2310209,
    name: 'Paracuru',
    uf: 'CE'
  },
  {
    id: 2310258,
    name: 'Paraipaba',
    uf: 'CE'
  },
  {
    id: 2310308,
    name: 'Parambu',
    uf: 'CE'
  },
  {
    id: 2310407,
    name: 'Paramoti',
    uf: 'CE'
  },
  {
    id: 2310506,
    name: 'Pedra Branca',
    uf: 'CE'
  },
  {
    id: 2310605,
    name: 'Penaforte',
    uf: 'CE'
  },
  {
    id: 2310704,
    name: 'Pentecoste',
    uf: 'CE'
  },
  {
    id: 2310803,
    name: 'Pereiro',
    uf: 'CE'
  },
  {
    id: 2310852,
    name: 'Pindoretama',
    uf: 'CE'
  },
  {
    id: 2310902,
    name: 'Piquet Carneiro',
    uf: 'CE'
  },
  {
    id: 2310951,
    name: 'Pires Ferreira',
    uf: 'CE'
  },
  {
    id: 2311009,
    name: 'Poranga',
    uf: 'CE'
  },
  {
    id: 2311108,
    name: 'Porteiras',
    uf: 'CE'
  },
  {
    id: 2311207,
    name: 'Potengi',
    uf: 'CE'
  },
  {
    id: 2311231,
    name: 'Potiretama',
    uf: 'CE'
  },
  {
    id: 2311264,
    name: 'Quiterianópolis',
    uf: 'CE'
  },
  {
    id: 2311306,
    name: 'Quixadá',
    uf: 'CE'
  },
  {
    id: 2311355,
    name: 'Quixelô',
    uf: 'CE'
  },
  {
    id: 2311405,
    name: 'Quixeramobim',
    uf: 'CE'
  },
  {
    id: 2311504,
    name: 'Quixeré',
    uf: 'CE'
  },
  {
    id: 2311603,
    name: 'Redenção',
    uf: 'CE'
  },
  {
    id: 2311702,
    name: 'Reriutaba',
    uf: 'CE'
  },
  {
    id: 2311801,
    name: 'Russas',
    uf: 'CE'
  },
  {
    id: 2311900,
    name: 'Saboeiro',
    uf: 'CE'
  },
  {
    id: 2311959,
    name: 'Salitre',
    uf: 'CE'
  },
  {
    id: 2312007,
    name: 'Santana do Acaraú',
    uf: 'CE'
  },
  {
    id: 2312106,
    name: 'Santana do Cariri',
    uf: 'CE'
  },
  {
    id: 2312205,
    name: 'Santa Quitéria',
    uf: 'CE'
  },
  {
    id: 2312304,
    name: 'São Benedito',
    uf: 'CE'
  },
  {
    id: 2312403,
    name: 'São Gonçalo do Amarante',
    uf: 'CE'
  },
  {
    id: 2312502,
    name: 'São João do Jaguaribe',
    uf: 'CE'
  },
  {
    id: 2312601,
    name: 'São Luís do Curu',
    uf: 'CE'
  },
  {
    id: 2312700,
    name: 'Senador Pompeu',
    uf: 'CE'
  },
  {
    id: 2312809,
    name: 'Senador Sá',
    uf: 'CE'
  },
  {
    id: 2312908,
    name: 'Sobral',
    uf: 'CE'
  },
  {
    id: 2313005,
    name: 'Solonópole',
    uf: 'CE'
  },
  {
    id: 2313104,
    name: 'Tabuleiro do Norte',
    uf: 'CE'
  },
  {
    id: 2313203,
    name: 'Tamboril',
    uf: 'CE'
  },
  {
    id: 2313252,
    name: 'Tarrafas',
    uf: 'CE'
  },
  {
    id: 2313302,
    name: 'Tauá',
    uf: 'CE'
  },
  {
    id: 2313351,
    name: 'Tejuçuoca',
    uf: 'CE'
  },
  {
    id: 2313401,
    name: 'Tianguá',
    uf: 'CE'
  },
  {
    id: 2313500,
    name: 'Trairi',
    uf: 'CE'
  },
  {
    id: 2313559,
    name: 'Tururu',
    uf: 'CE'
  },
  {
    id: 2313609,
    name: 'Ubajara',
    uf: 'CE'
  },
  {
    id: 2313708,
    name: 'Umari',
    uf: 'CE'
  },
  {
    id: 2313757,
    name: 'Umirim',
    uf: 'CE'
  },
  {
    id: 2313807,
    name: 'Uruburetama',
    uf: 'CE'
  },
  {
    id: 2313906,
    name: 'Uruoca',
    uf: 'CE'
  },
  {
    id: 2313955,
    name: 'Varjota',
    uf: 'CE'
  },
  {
    id: 2314003,
    name: 'Várzea Alegre',
    uf: 'CE'
  },
  {
    id: 2314102,
    name: 'Viçosa do Ceará',
    uf: 'CE'
  },
  {
    id: 2400109,
    name: 'Acari',
    uf: 'RN'
  },
  {
    id: 2400208,
    name: 'Açu',
    uf: 'RN'
  },
  {
    id: 2400307,
    name: 'Afonso Bezerra',
    uf: 'RN'
  },
  {
    id: 2400406,
    name: 'Água Nova',
    uf: 'RN'
  },
  {
    id: 2400505,
    name: 'Alexandria',
    uf: 'RN'
  },
  {
    id: 2400604,
    name: 'Almino Afonso',
    uf: 'RN'
  },
  {
    id: 2400703,
    name: 'Alto do Rodrigues',
    uf: 'RN'
  },
  {
    id: 2400802,
    name: 'Angicos',
    uf: 'RN'
  },
  {
    id: 2400901,
    name: 'Antônio Martins',
    uf: 'RN'
  },
  {
    id: 2401008,
    name: 'Apodi',
    uf: 'RN'
  },
  {
    id: 2401107,
    name: 'Areia Branca',
    uf: 'RN'
  },
  {
    id: 2401206,
    name: 'Arês',
    uf: 'RN'
  },
  {
    id: 2401305,
    name: 'Campo Grande',
    uf: 'RN'
  },
  {
    id: 2401404,
    name: 'Baía Formosa',
    uf: 'RN'
  },
  {
    id: 2401453,
    name: 'Baraúna',
    uf: 'RN'
  },
  {
    id: 2401503,
    name: 'Barcelona',
    uf: 'RN'
  },
  {
    id: 2401602,
    name: 'Bento Fernandes',
    uf: 'RN'
  },
  {
    id: 2401651,
    name: 'Bodó',
    uf: 'RN'
  },
  {
    id: 2401701,
    name: 'Bom Jesus',
    uf: 'RN'
  },
  {
    id: 2401800,
    name: 'Brejinho',
    uf: 'RN'
  },
  {
    id: 2401859,
    name: 'Caiçara do Norte',
    uf: 'RN'
  },
  {
    id: 2401909,
    name: 'Caiçara do Rio do Vento',
    uf: 'RN'
  },
  {
    id: 2402006,
    name: 'Caicó',
    uf: 'RN'
  },
  {
    id: 2402105,
    name: 'Campo Redondo',
    uf: 'RN'
  },
  {
    id: 2402204,
    name: 'Canguaretama',
    uf: 'RN'
  },
  {
    id: 2402303,
    name: 'Caraúbas',
    uf: 'RN'
  },
  {
    id: 2402402,
    name: 'Carnaúba dos Dantas',
    uf: 'RN'
  },
  {
    id: 2402501,
    name: 'Carnaubais',
    uf: 'RN'
  },
  {
    id: 2402600,
    name: 'Ceará-Mirim',
    uf: 'RN'
  },
  {
    id: 2402709,
    name: 'Cerro Corá',
    uf: 'RN'
  },
  {
    id: 2402808,
    name: 'Coronel Ezequiel',
    uf: 'RN'
  },
  {
    id: 2402907,
    name: 'Coronel João Pessoa',
    uf: 'RN'
  },
  {
    id: 2403004,
    name: 'Cruzeta',
    uf: 'RN'
  },
  {
    id: 2403103,
    name: 'Currais Novos',
    uf: 'RN'
  },
  {
    id: 2403202,
    name: 'Doutor Severiano',
    uf: 'RN'
  },
  {
    id: 2403251,
    name: 'Parnamirim',
    uf: 'RN'
  },
  {
    id: 2403301,
    name: 'Encanto',
    uf: 'RN'
  },
  {
    id: 2403400,
    name: 'Equador',
    uf: 'RN'
  },
  {
    id: 2403509,
    name: 'Espírito Santo',
    uf: 'RN'
  },
  {
    id: 2403608,
    name: 'Extremoz',
    uf: 'RN'
  },
  {
    id: 2403707,
    name: 'Felipe Guerra',
    uf: 'RN'
  },
  {
    id: 2403756,
    name: 'Fernando Pedroza',
    uf: 'RN'
  },
  {
    id: 2403806,
    name: 'Florânia',
    uf: 'RN'
  },
  {
    id: 2403905,
    name: 'Francisco Dantas',
    uf: 'RN'
  },
  {
    id: 2404002,
    name: 'Frutuoso Gomes',
    uf: 'RN'
  },
  {
    id: 2404101,
    name: 'Galinhos',
    uf: 'RN'
  },
  {
    id: 2404200,
    name: 'Goianinha',
    uf: 'RN'
  },
  {
    id: 2404309,
    name: 'Governador Dix-Sept Rosado',
    uf: 'RN'
  },
  {
    id: 2404408,
    name: 'Grossos',
    uf: 'RN'
  },
  {
    id: 2404507,
    name: 'Guamaré',
    uf: 'RN'
  },
  {
    id: 2404606,
    name: 'Ielmo Marinho',
    uf: 'RN'
  },
  {
    id: 2404705,
    name: 'Ipanguaçu',
    uf: 'RN'
  },
  {
    id: 2404804,
    name: 'Ipueira',
    uf: 'RN'
  },
  {
    id: 2404853,
    name: 'Itajá',
    uf: 'RN'
  },
  {
    id: 2404903,
    name: 'Itaú',
    uf: 'RN'
  },
  {
    id: 2405009,
    name: 'Jaçanã',
    uf: 'RN'
  },
  {
    id: 2405108,
    name: 'Jandaíra',
    uf: 'RN'
  },
  {
    id: 2405207,
    name: 'Janduís',
    uf: 'RN'
  },
  {
    id: 2405306,
    name: 'Januário Cicco',
    uf: 'RN'
  },
  {
    id: 2405405,
    name: 'Japi',
    uf: 'RN'
  },
  {
    id: 2405504,
    name: 'Jardim de Angicos',
    uf: 'RN'
  },
  {
    id: 2405603,
    name: 'Jardim de Piranhas',
    uf: 'RN'
  },
  {
    id: 2405702,
    name: 'Jardim do Seridó',
    uf: 'RN'
  },
  {
    id: 2405801,
    name: 'João Câmara',
    uf: 'RN'
  },
  {
    id: 2405900,
    name: 'João Dias',
    uf: 'RN'
  },
  {
    id: 2406007,
    name: 'José da Penha',
    uf: 'RN'
  },
  {
    id: 2406106,
    name: 'Jucurutu',
    uf: 'RN'
  },
  {
    id: 2406155,
    name: 'Jundiá',
    uf: 'RN'
  },
  {
    id: 2406205,
    name: "Lagoa d'Anta",
    uf: 'RN'
  },
  {
    id: 2406304,
    name: 'Lagoa de Pedras',
    uf: 'RN'
  },
  {
    id: 2406403,
    name: 'Lagoa de Velhos',
    uf: 'RN'
  },
  {
    id: 2406502,
    name: 'Lagoa Nova',
    uf: 'RN'
  },
  {
    id: 2406601,
    name: 'Lagoa Salgada',
    uf: 'RN'
  },
  {
    id: 2406700,
    name: 'Lajes',
    uf: 'RN'
  },
  {
    id: 2406809,
    name: 'Lajes Pintadas',
    uf: 'RN'
  },
  {
    id: 2406908,
    name: 'Lucrécia',
    uf: 'RN'
  },
  {
    id: 2407005,
    name: 'Luís Gomes',
    uf: 'RN'
  },
  {
    id: 2407104,
    name: 'Macaíba',
    uf: 'RN'
  },
  {
    id: 2407203,
    name: 'Macau',
    uf: 'RN'
  },
  {
    id: 2407252,
    name: 'Major Sales',
    uf: 'RN'
  },
  {
    id: 2407302,
    name: 'Marcelino Vieira',
    uf: 'RN'
  },
  {
    id: 2407401,
    name: 'Martins',
    uf: 'RN'
  },
  {
    id: 2407500,
    name: 'Maxaranguape',
    uf: 'RN'
  },
  {
    id: 2407609,
    name: 'Messias Targino',
    uf: 'RN'
  },
  {
    id: 2407708,
    name: 'Montanhas',
    uf: 'RN'
  },
  {
    id: 2407807,
    name: 'Monte Alegre',
    uf: 'RN'
  },
  {
    id: 2407906,
    name: 'Monte das Gameleiras',
    uf: 'RN'
  },
  {
    id: 2408003,
    name: 'Mossoró',
    uf: 'RN'
  },
  {
    id: 2408102,
    name: 'Natal',
    uf: 'RN'
  },
  {
    id: 2408201,
    name: 'Nísia Floresta',
    uf: 'RN'
  },
  {
    id: 2408300,
    name: 'Nova Cruz',
    uf: 'RN'
  },
  {
    id: 2408409,
    name: "Olho d'Água do Borges",
    uf: 'RN'
  },
  {
    id: 2408508,
    name: 'Ouro Branco',
    uf: 'RN'
  },
  {
    id: 2408607,
    name: 'Paraná',
    uf: 'RN'
  },
  {
    id: 2408706,
    name: 'Paraú',
    uf: 'RN'
  },
  {
    id: 2408805,
    name: 'Parazinho',
    uf: 'RN'
  },
  {
    id: 2408904,
    name: 'Parelhas',
    uf: 'RN'
  },
  {
    id: 2408953,
    name: 'Rio do Fogo',
    uf: 'RN'
  },
  {
    id: 2409100,
    name: 'Passa e Fica',
    uf: 'RN'
  },
  {
    id: 2409209,
    name: 'Passagem',
    uf: 'RN'
  },
  {
    id: 2409308,
    name: 'Patu',
    uf: 'RN'
  },
  {
    id: 2409332,
    name: 'Santa Maria',
    uf: 'RN'
  },
  {
    id: 2409407,
    name: 'Pau dos Ferros',
    uf: 'RN'
  },
  {
    id: 2409506,
    name: 'Pedra Grande',
    uf: 'RN'
  },
  {
    id: 2409605,
    name: 'Pedra Preta',
    uf: 'RN'
  },
  {
    id: 2409704,
    name: 'Pedro Avelino',
    uf: 'RN'
  },
  {
    id: 2409803,
    name: 'Pedro Velho',
    uf: 'RN'
  },
  {
    id: 2409902,
    name: 'Pendências',
    uf: 'RN'
  },
  {
    id: 2410009,
    name: 'Pilões',
    uf: 'RN'
  },
  {
    id: 2410108,
    name: 'Poço Branco',
    uf: 'RN'
  },
  {
    id: 2410207,
    name: 'Portalegre',
    uf: 'RN'
  },
  {
    id: 2410256,
    name: 'Porto do Mangue',
    uf: 'RN'
  },
  {
    id: 2410306,
    name: 'Serra Caiada',
    uf: 'RN'
  },
  {
    id: 2410405,
    name: 'Pureza',
    uf: 'RN'
  },
  {
    id: 2410504,
    name: 'Rafael Fernandes',
    uf: 'RN'
  },
  {
    id: 2410603,
    name: 'Rafael Godeiro',
    uf: 'RN'
  },
  {
    id: 2410702,
    name: 'Riacho da Cruz',
    uf: 'RN'
  },
  {
    id: 2410801,
    name: 'Riacho de Santana',
    uf: 'RN'
  },
  {
    id: 2410900,
    name: 'Riachuelo',
    uf: 'RN'
  },
  {
    id: 2411007,
    name: 'Rodolfo Fernandes',
    uf: 'RN'
  },
  {
    id: 2411056,
    name: 'Tibau',
    uf: 'RN'
  },
  {
    id: 2411106,
    name: 'Ruy Barbosa',
    uf: 'RN'
  },
  {
    id: 2411205,
    name: 'Santa Cruz',
    uf: 'RN'
  },
  {
    id: 2411403,
    name: 'Santana do Matos',
    uf: 'RN'
  },
  {
    id: 2411429,
    name: 'Santana do Seridó',
    uf: 'RN'
  },
  {
    id: 2411502,
    name: 'Santo Antônio',
    uf: 'RN'
  },
  {
    id: 2411601,
    name: 'São Bento do Norte',
    uf: 'RN'
  },
  {
    id: 2411700,
    name: 'São Bento do Trairí',
    uf: 'RN'
  },
  {
    id: 2411809,
    name: 'São Fernando',
    uf: 'RN'
  },
  {
    id: 2411908,
    name: 'São Francisco do Oeste',
    uf: 'RN'
  },
  {
    id: 2412005,
    name: 'São Gonçalo do Amarante',
    uf: 'RN'
  },
  {
    id: 2412104,
    name: 'São João do Sabugi',
    uf: 'RN'
  },
  {
    id: 2412203,
    name: 'São José de Mipibu',
    uf: 'RN'
  },
  {
    id: 2412302,
    name: 'São José do Campestre',
    uf: 'RN'
  },
  {
    id: 2412401,
    name: 'São José do Seridó',
    uf: 'RN'
  },
  {
    id: 2412500,
    name: 'São Miguel',
    uf: 'RN'
  },
  {
    id: 2412559,
    name: 'São Miguel do Gostoso',
    uf: 'RN'
  },
  {
    id: 2412609,
    name: 'São Paulo do Potengi',
    uf: 'RN'
  },
  {
    id: 2412708,
    name: 'São Pedro',
    uf: 'RN'
  },
  {
    id: 2412807,
    name: 'São Rafael',
    uf: 'RN'
  },
  {
    id: 2412906,
    name: 'São Tomé',
    uf: 'RN'
  },
  {
    id: 2413003,
    name: 'São Vicente',
    uf: 'RN'
  },
  {
    id: 2413102,
    name: 'Senador Elói de Souza',
    uf: 'RN'
  },
  {
    id: 2413201,
    name: 'Senador Georgino Avelino',
    uf: 'RN'
  },
  {
    id: 2413300,
    name: 'Serra de São Bento',
    uf: 'RN'
  },
  {
    id: 2413359,
    name: 'Serra do Mel',
    uf: 'RN'
  },
  {
    id: 2413409,
    name: 'Serra Negra do Norte',
    uf: 'RN'
  },
  {
    id: 2413508,
    name: 'Serrinha',
    uf: 'RN'
  },
  {
    id: 2413557,
    name: 'Serrinha dos Pintos',
    uf: 'RN'
  },
  {
    id: 2413607,
    name: 'Severiano Melo',
    uf: 'RN'
  },
  {
    id: 2413706,
    name: 'Sítio Novo',
    uf: 'RN'
  },
  {
    id: 2413805,
    name: 'Taboleiro Grande',
    uf: 'RN'
  },
  {
    id: 2413904,
    name: 'Taipu',
    uf: 'RN'
  },
  {
    id: 2414001,
    name: 'Tangará',
    uf: 'RN'
  },
  {
    id: 2414100,
    name: 'Tenente Ananias',
    uf: 'RN'
  },
  {
    id: 2414159,
    name: 'Tenente Laurentino Cruz',
    uf: 'RN'
  },
  {
    id: 2414209,
    name: 'Tibau do Sul',
    uf: 'RN'
  },
  {
    id: 2414308,
    name: 'Timbaúba dos Batistas',
    uf: 'RN'
  },
  {
    id: 2414407,
    name: 'Touros',
    uf: 'RN'
  },
  {
    id: 2414456,
    name: 'Triunfo Potiguar',
    uf: 'RN'
  },
  {
    id: 2414506,
    name: 'Umarizal',
    uf: 'RN'
  },
  {
    id: 2414605,
    name: 'Upanema',
    uf: 'RN'
  },
  {
    id: 2414704,
    name: 'Várzea',
    uf: 'RN'
  },
  {
    id: 2414753,
    name: 'Venha-Ver',
    uf: 'RN'
  },
  {
    id: 2414803,
    name: 'Vera Cruz',
    uf: 'RN'
  },
  {
    id: 2414902,
    name: 'Viçosa',
    uf: 'RN'
  },
  {
    id: 2415008,
    name: 'Vila Flor',
    uf: 'RN'
  },
  {
    id: 2500106,
    name: 'Água Branca',
    uf: 'PB'
  },
  {
    id: 2500205,
    name: 'Aguiar',
    uf: 'PB'
  },
  {
    id: 2500304,
    name: 'Alagoa Grande',
    uf: 'PB'
  },
  {
    id: 2500403,
    name: 'Alagoa Nova',
    uf: 'PB'
  },
  {
    id: 2500502,
    name: 'Alagoinha',
    uf: 'PB'
  },
  {
    id: 2500536,
    name: 'Alcantil',
    uf: 'PB'
  },
  {
    id: 2500577,
    name: 'Algodão de Jandaíra',
    uf: 'PB'
  },
  {
    id: 2500601,
    name: 'Alhandra',
    uf: 'PB'
  },
  {
    id: 2500700,
    name: 'São João do Rio do Peixe',
    uf: 'PB'
  },
  {
    id: 2500734,
    name: 'Amparo',
    uf: 'PB'
  },
  {
    id: 2500775,
    name: 'Aparecida',
    uf: 'PB'
  },
  {
    id: 2500809,
    name: 'Araçagi',
    uf: 'PB'
  },
  {
    id: 2500908,
    name: 'Arara',
    uf: 'PB'
  },
  {
    id: 2501005,
    name: 'Araruna',
    uf: 'PB'
  },
  {
    id: 2501104,
    name: 'Areia',
    uf: 'PB'
  },
  {
    id: 2501153,
    name: 'Areia de Baraúnas',
    uf: 'PB'
  },
  {
    id: 2501203,
    name: 'Areial',
    uf: 'PB'
  },
  {
    id: 2501302,
    name: 'Aroeiras',
    uf: 'PB'
  },
  {
    id: 2501351,
    name: 'Assunção',
    uf: 'PB'
  },
  {
    id: 2501401,
    name: 'Baía da Traição',
    uf: 'PB'
  },
  {
    id: 2501500,
    name: 'Bananeiras',
    uf: 'PB'
  },
  {
    id: 2501534,
    name: 'Baraúna',
    uf: 'PB'
  },
  {
    id: 2501575,
    name: 'Barra de Santana',
    uf: 'PB'
  },
  {
    id: 2501609,
    name: 'Barra de Santa Rosa',
    uf: 'PB'
  },
  {
    id: 2501708,
    name: 'Barra de São Miguel',
    uf: 'PB'
  },
  {
    id: 2501807,
    name: 'Bayeux',
    uf: 'PB'
  },
  {
    id: 2501906,
    name: 'Belém',
    uf: 'PB'
  },
  {
    id: 2502003,
    name: 'Belém do Brejo do Cruz',
    uf: 'PB'
  },
  {
    id: 2502052,
    name: 'Bernardino Batista',
    uf: 'PB'
  },
  {
    id: 2502102,
    name: 'Boa Ventura',
    uf: 'PB'
  },
  {
    id: 2502151,
    name: 'Boa Vista',
    uf: 'PB'
  },
  {
    id: 2502201,
    name: 'Bom Jesus',
    uf: 'PB'
  },
  {
    id: 2502300,
    name: 'Bom Sucesso',
    uf: 'PB'
  },
  {
    id: 2502409,
    name: 'Bonito de Santa Fé',
    uf: 'PB'
  },
  {
    id: 2502508,
    name: 'Boqueirão',
    uf: 'PB'
  },
  {
    id: 2502607,
    name: 'Igaracy',
    uf: 'PB'
  },
  {
    id: 2502706,
    name: 'Borborema',
    uf: 'PB'
  },
  {
    id: 2502805,
    name: 'Brejo do Cruz',
    uf: 'PB'
  },
  {
    id: 2502904,
    name: 'Brejo dos Santos',
    uf: 'PB'
  },
  {
    id: 2503001,
    name: 'Caaporã',
    uf: 'PB'
  },
  {
    id: 2503100,
    name: 'Cabaceiras',
    uf: 'PB'
  },
  {
    id: 2503209,
    name: 'Cabedelo',
    uf: 'PB'
  },
  {
    id: 2503308,
    name: 'Cachoeira dos Índios',
    uf: 'PB'
  },
  {
    id: 2503407,
    name: 'Cacimba de Areia',
    uf: 'PB'
  },
  {
    id: 2503506,
    name: 'Cacimba de Dentro',
    uf: 'PB'
  },
  {
    id: 2503555,
    name: 'Cacimbas',
    uf: 'PB'
  },
  {
    id: 2503605,
    name: 'Caiçara',
    uf: 'PB'
  },
  {
    id: 2503704,
    name: 'Cajazeiras',
    uf: 'PB'
  },
  {
    id: 2503753,
    name: 'Cajazeirinhas',
    uf: 'PB'
  },
  {
    id: 2503803,
    name: 'Caldas Brandão',
    uf: 'PB'
  },
  {
    id: 2503902,
    name: 'Camalaú',
    uf: 'PB'
  },
  {
    id: 2504009,
    name: 'Campina Grande',
    uf: 'PB'
  },
  {
    id: 2504033,
    name: 'Capim',
    uf: 'PB'
  },
  {
    id: 2504074,
    name: 'Caraúbas',
    uf: 'PB'
  },
  {
    id: 2504108,
    name: 'Carrapateira',
    uf: 'PB'
  },
  {
    id: 2504157,
    name: 'Casserengue',
    uf: 'PB'
  },
  {
    id: 2504207,
    name: 'Catingueira',
    uf: 'PB'
  },
  {
    id: 2504306,
    name: 'Catolé do Rocha',
    uf: 'PB'
  },
  {
    id: 2504355,
    name: 'Caturité',
    uf: 'PB'
  },
  {
    id: 2504405,
    name: 'Conceição',
    uf: 'PB'
  },
  {
    id: 2504504,
    name: 'Condado',
    uf: 'PB'
  },
  {
    id: 2504603,
    name: 'Conde',
    uf: 'PB'
  },
  {
    id: 2504702,
    name: 'Congo',
    uf: 'PB'
  },
  {
    id: 2504801,
    name: 'Coremas',
    uf: 'PB'
  },
  {
    id: 2504850,
    name: 'Coxixola',
    uf: 'PB'
  },
  {
    id: 2504900,
    name: 'Cruz do Espírito Santo',
    uf: 'PB'
  },
  {
    id: 2505006,
    name: 'Cubati',
    uf: 'PB'
  },
  {
    id: 2505105,
    name: 'Cuité',
    uf: 'PB'
  },
  {
    id: 2505204,
    name: 'Cuitegi',
    uf: 'PB'
  },
  {
    id: 2505238,
    name: 'Cuité de Mamanguape',
    uf: 'PB'
  },
  {
    id: 2505279,
    name: 'Curral de Cima',
    uf: 'PB'
  },
  {
    id: 2505303,
    name: 'Curral Velho',
    uf: 'PB'
  },
  {
    id: 2505352,
    name: 'Damião',
    uf: 'PB'
  },
  {
    id: 2505402,
    name: 'Desterro',
    uf: 'PB'
  },
  {
    id: 2505501,
    name: 'Vista Serrana',
    uf: 'PB'
  },
  {
    id: 2505600,
    name: 'Diamante',
    uf: 'PB'
  },
  {
    id: 2505709,
    name: 'Dona Inês',
    uf: 'PB'
  },
  {
    id: 2505808,
    name: 'Duas Estradas',
    uf: 'PB'
  },
  {
    id: 2505907,
    name: 'Emas',
    uf: 'PB'
  },
  {
    id: 2506004,
    name: 'Esperança',
    uf: 'PB'
  },
  {
    id: 2506103,
    name: 'Fagundes',
    uf: 'PB'
  },
  {
    id: 2506202,
    name: 'Frei Martinho',
    uf: 'PB'
  },
  {
    id: 2506251,
    name: 'Gado Bravo',
    uf: 'PB'
  },
  {
    id: 2506301,
    name: 'Guarabira',
    uf: 'PB'
  },
  {
    id: 2506400,
    name: 'Gurinhém',
    uf: 'PB'
  },
  {
    id: 2506509,
    name: 'Gurjão',
    uf: 'PB'
  },
  {
    id: 2506608,
    name: 'Ibiara',
    uf: 'PB'
  },
  {
    id: 2506707,
    name: 'Imaculada',
    uf: 'PB'
  },
  {
    id: 2506806,
    name: 'Ingá',
    uf: 'PB'
  },
  {
    id: 2506905,
    name: 'Itabaiana',
    uf: 'PB'
  },
  {
    id: 2507002,
    name: 'Itaporanga',
    uf: 'PB'
  },
  {
    id: 2507101,
    name: 'Itapororoca',
    uf: 'PB'
  },
  {
    id: 2507200,
    name: 'Itatuba',
    uf: 'PB'
  },
  {
    id: 2507309,
    name: 'Jacaraú',
    uf: 'PB'
  },
  {
    id: 2507408,
    name: 'Jericó',
    uf: 'PB'
  },
  {
    id: 2507507,
    name: 'João Pessoa',
    uf: 'PB'
  },
  {
    id: 2507606,
    name: 'Juarez Távora',
    uf: 'PB'
  },
  {
    id: 2507705,
    name: 'Juazeirinho',
    uf: 'PB'
  },
  {
    id: 2507804,
    name: 'Junco do Seridó',
    uf: 'PB'
  },
  {
    id: 2507903,
    name: 'Juripiranga',
    uf: 'PB'
  },
  {
    id: 2508000,
    name: 'Juru',
    uf: 'PB'
  },
  {
    id: 2508109,
    name: 'Lagoa',
    uf: 'PB'
  },
  {
    id: 2508208,
    name: 'Lagoa de Dentro',
    uf: 'PB'
  },
  {
    id: 2508307,
    name: 'Lagoa Seca',
    uf: 'PB'
  },
  {
    id: 2508406,
    name: 'Lastro',
    uf: 'PB'
  },
  {
    id: 2508505,
    name: 'Livramento',
    uf: 'PB'
  },
  {
    id: 2508554,
    name: 'Logradouro',
    uf: 'PB'
  },
  {
    id: 2508604,
    name: 'Lucena',
    uf: 'PB'
  },
  {
    id: 2508703,
    name: "Mãe d'Água",
    uf: 'PB'
  },
  {
    id: 2508802,
    name: 'Malta',
    uf: 'PB'
  },
  {
    id: 2508901,
    name: 'Mamanguape',
    uf: 'PB'
  },
  {
    id: 2509008,
    name: 'Manaíra',
    uf: 'PB'
  },
  {
    id: 2509057,
    name: 'Marcação',
    uf: 'PB'
  },
  {
    id: 2509107,
    name: 'Mari',
    uf: 'PB'
  },
  {
    id: 2509156,
    name: 'Marizópolis',
    uf: 'PB'
  },
  {
    id: 2509206,
    name: 'Massaranduba',
    uf: 'PB'
  },
  {
    id: 2509305,
    name: 'Mataraca',
    uf: 'PB'
  },
  {
    id: 2509339,
    name: 'Matinhas',
    uf: 'PB'
  },
  {
    id: 2509370,
    name: 'Mato Grosso',
    uf: 'PB'
  },
  {
    id: 2509396,
    name: 'Maturéia',
    uf: 'PB'
  },
  {
    id: 2509404,
    name: 'Mogeiro',
    uf: 'PB'
  },
  {
    id: 2509503,
    name: 'Montadas',
    uf: 'PB'
  },
  {
    id: 2509602,
    name: 'Monte Horebe',
    uf: 'PB'
  },
  {
    id: 2509701,
    name: 'Monteiro',
    uf: 'PB'
  },
  {
    id: 2509800,
    name: 'Mulungu',
    uf: 'PB'
  },
  {
    id: 2509909,
    name: 'Natuba',
    uf: 'PB'
  },
  {
    id: 2510006,
    name: 'Nazarezinho',
    uf: 'PB'
  },
  {
    id: 2510105,
    name: 'Nova Floresta',
    uf: 'PB'
  },
  {
    id: 2510204,
    name: 'Nova Olinda',
    uf: 'PB'
  },
  {
    id: 2510303,
    name: 'Nova Palmeira',
    uf: 'PB'
  },
  {
    id: 2510402,
    name: "Olho d'Água",
    uf: 'PB'
  },
  {
    id: 2510501,
    name: 'Olivedos',
    uf: 'PB'
  },
  {
    id: 2510600,
    name: 'Ouro Velho',
    uf: 'PB'
  },
  {
    id: 2510659,
    name: 'Parari',
    uf: 'PB'
  },
  {
    id: 2510709,
    name: 'Passagem',
    uf: 'PB'
  },
  {
    id: 2510808,
    name: 'Patos',
    uf: 'PB'
  },
  {
    id: 2510907,
    name: 'Paulista',
    uf: 'PB'
  },
  {
    id: 2511004,
    name: 'Pedra Branca',
    uf: 'PB'
  },
  {
    id: 2511103,
    name: 'Pedra Lavrada',
    uf: 'PB'
  },
  {
    id: 2511202,
    name: 'Pedras de Fogo',
    uf: 'PB'
  },
  {
    id: 2511301,
    name: 'Piancó',
    uf: 'PB'
  },
  {
    id: 2511400,
    name: 'Picuí',
    uf: 'PB'
  },
  {
    id: 2511509,
    name: 'Pilar',
    uf: 'PB'
  },
  {
    id: 2511608,
    name: 'Pilões',
    uf: 'PB'
  },
  {
    id: 2511707,
    name: 'Pilõezinhos',
    uf: 'PB'
  },
  {
    id: 2511806,
    name: 'Pirpirituba',
    uf: 'PB'
  },
  {
    id: 2511905,
    name: 'Pitimbu',
    uf: 'PB'
  },
  {
    id: 2512002,
    name: 'Pocinhos',
    uf: 'PB'
  },
  {
    id: 2512036,
    name: 'Poço Dantas',
    uf: 'PB'
  },
  {
    id: 2512077,
    name: 'Poço de José de Moura',
    uf: 'PB'
  },
  {
    id: 2512101,
    name: 'Pombal',
    uf: 'PB'
  },
  {
    id: 2512200,
    name: 'Prata',
    uf: 'PB'
  },
  {
    id: 2512309,
    name: 'Princesa Isabel',
    uf: 'PB'
  },
  {
    id: 2512408,
    name: 'Puxinanã',
    uf: 'PB'
  },
  {
    id: 2512507,
    name: 'Queimadas',
    uf: 'PB'
  },
  {
    id: 2512606,
    name: 'Quixaba',
    uf: 'PB'
  },
  {
    id: 2512705,
    name: 'Remígio',
    uf: 'PB'
  },
  {
    id: 2512721,
    name: 'Pedro Régis',
    uf: 'PB'
  },
  {
    id: 2512747,
    name: 'Riachão',
    uf: 'PB'
  },
  {
    id: 2512754,
    name: 'Riachão do Bacamarte',
    uf: 'PB'
  },
  {
    id: 2512762,
    name: 'Riachão do Poço',
    uf: 'PB'
  },
  {
    id: 2512788,
    name: 'Riacho de Santo Antônio',
    uf: 'PB'
  },
  {
    id: 2512804,
    name: 'Riacho dos Cavalos',
    uf: 'PB'
  },
  {
    id: 2512903,
    name: 'Rio Tinto',
    uf: 'PB'
  },
  {
    id: 2513000,
    name: 'Salgadinho',
    uf: 'PB'
  },
  {
    id: 2513109,
    name: 'Salgado de São Félix',
    uf: 'PB'
  },
  {
    id: 2513158,
    name: 'Santa Cecília',
    uf: 'PB'
  },
  {
    id: 2513208,
    name: 'Santa Cruz',
    uf: 'PB'
  },
  {
    id: 2513307,
    name: 'Santa Helena',
    uf: 'PB'
  },
  {
    id: 2513356,
    name: 'Santa Inês',
    uf: 'PB'
  },
  {
    id: 2513406,
    name: 'Santa Luzia',
    uf: 'PB'
  },
  {
    id: 2513505,
    name: 'Santana de Mangueira',
    uf: 'PB'
  },
  {
    id: 2513604,
    name: 'Santana dos Garrotes',
    uf: 'PB'
  },
  {
    id: 2513653,
    name: 'Joca Claudino',
    uf: 'PB'
  },
  {
    id: 2513703,
    name: 'Santa Rita',
    uf: 'PB'
  },
  {
    id: 2513802,
    name: 'Santa Teresinha',
    uf: 'PB'
  },
  {
    id: 2513851,
    name: 'Santo André',
    uf: 'PB'
  },
  {
    id: 2513901,
    name: 'São Bento',
    uf: 'PB'
  },
  {
    id: 2513927,
    name: 'São Bentinho',
    uf: 'PB'
  },
  {
    id: 2513943,
    name: 'São Domingos do Cariri',
    uf: 'PB'
  },
  {
    id: 2513968,
    name: 'São Domingos',
    uf: 'PB'
  },
  {
    id: 2513984,
    name: 'São Francisco',
    uf: 'PB'
  },
  {
    id: 2514008,
    name: 'São João do Cariri',
    uf: 'PB'
  },
  {
    id: 2514107,
    name: 'São João do Tigre',
    uf: 'PB'
  },
  {
    id: 2514206,
    name: 'São José da Lagoa Tapada',
    uf: 'PB'
  },
  {
    id: 2514305,
    name: 'São José de Caiana',
    uf: 'PB'
  },
  {
    id: 2514404,
    name: 'São José de Espinharas',
    uf: 'PB'
  },
  {
    id: 2514453,
    name: 'São José dos Ramos',
    uf: 'PB'
  },
  {
    id: 2514503,
    name: 'São José de Piranhas',
    uf: 'PB'
  },
  {
    id: 2514552,
    name: 'São José de Princesa',
    uf: 'PB'
  },
  {
    id: 2514602,
    name: 'São José do Bonfim',
    uf: 'PB'
  },
  {
    id: 2514651,
    name: 'São José do Brejo do Cruz',
    uf: 'PB'
  },
  {
    id: 2514701,
    name: 'São José do Sabugi',
    uf: 'PB'
  },
  {
    id: 2514800,
    name: 'São José dos Cordeiros',
    uf: 'PB'
  },
  {
    id: 2514909,
    name: 'São Mamede',
    uf: 'PB'
  },
  {
    id: 2515005,
    name: 'São Miguel de Taipu',
    uf: 'PB'
  },
  {
    id: 2515104,
    name: 'São Sebastião de Lagoa de Roça',
    uf: 'PB'
  },
  {
    id: 2515203,
    name: 'São Sebastião do Umbuzeiro',
    uf: 'PB'
  },
  {
    id: 2515302,
    name: 'Sapé',
    uf: 'PB'
  },
  {
    id: 2515401,
    name: 'São Vicente do Seridó',
    uf: 'PB'
  },
  {
    id: 2515500,
    name: 'Serra Branca',
    uf: 'PB'
  },
  {
    id: 2515609,
    name: 'Serra da Raiz',
    uf: 'PB'
  },
  {
    id: 2515708,
    name: 'Serra Grande',
    uf: 'PB'
  },
  {
    id: 2515807,
    name: 'Serra Redonda',
    uf: 'PB'
  },
  {
    id: 2515906,
    name: 'Serraria',
    uf: 'PB'
  },
  {
    id: 2515930,
    name: 'Sertãozinho',
    uf: 'PB'
  },
  {
    id: 2515971,
    name: 'Sobrado',
    uf: 'PB'
  },
  {
    id: 2516003,
    name: 'Solânea',
    uf: 'PB'
  },
  {
    id: 2516102,
    name: 'Soledade',
    uf: 'PB'
  },
  {
    id: 2516151,
    name: 'Sossêgo',
    uf: 'PB'
  },
  {
    id: 2516201,
    name: 'Sousa',
    uf: 'PB'
  },
  {
    id: 2516300,
    name: 'Sumé',
    uf: 'PB'
  },
  {
    id: 2516409,
    name: 'Tacima',
    uf: 'PB'
  },
  {
    id: 2516508,
    name: 'Taperoá',
    uf: 'PB'
  },
  {
    id: 2516607,
    name: 'Tavares',
    uf: 'PB'
  },
  {
    id: 2516706,
    name: 'Teixeira',
    uf: 'PB'
  },
  {
    id: 2516755,
    name: 'Tenório',
    uf: 'PB'
  },
  {
    id: 2516805,
    name: 'Triunfo',
    uf: 'PB'
  },
  {
    id: 2516904,
    name: 'Uiraúna',
    uf: 'PB'
  },
  {
    id: 2517001,
    name: 'Umbuzeiro',
    uf: 'PB'
  },
  {
    id: 2517100,
    name: 'Várzea',
    uf: 'PB'
  },
  {
    id: 2517209,
    name: 'Vieirópolis',
    uf: 'PB'
  },
  {
    id: 2517407,
    name: 'Zabelê',
    uf: 'PB'
  },
  {
    id: 2600054,
    name: 'Abreu e Lima',
    uf: 'PE'
  },
  {
    id: 2600104,
    name: 'Afogados da Ingazeira',
    uf: 'PE'
  },
  {
    id: 2600203,
    name: 'Afrânio',
    uf: 'PE'
  },
  {
    id: 2600302,
    name: 'Agrestina',
    uf: 'PE'
  },
  {
    id: 2600401,
    name: 'Água Preta',
    uf: 'PE'
  },
  {
    id: 2600500,
    name: 'Águas Belas',
    uf: 'PE'
  },
  {
    id: 2600609,
    name: 'Alagoinha',
    uf: 'PE'
  },
  {
    id: 2600708,
    name: 'Aliança',
    uf: 'PE'
  },
  {
    id: 2600807,
    name: 'Altinho',
    uf: 'PE'
  },
  {
    id: 2600906,
    name: 'Amaraji',
    uf: 'PE'
  },
  {
    id: 2601003,
    name: 'Angelim',
    uf: 'PE'
  },
  {
    id: 2601052,
    name: 'Araçoiaba',
    uf: 'PE'
  },
  {
    id: 2601102,
    name: 'Araripina',
    uf: 'PE'
  },
  {
    id: 2601201,
    name: 'Arcoverde',
    uf: 'PE'
  },
  {
    id: 2601300,
    name: 'Barra de Guabiraba',
    uf: 'PE'
  },
  {
    id: 2601409,
    name: 'Barreiros',
    uf: 'PE'
  },
  {
    id: 2601508,
    name: 'Belém de Maria',
    uf: 'PE'
  },
  {
    id: 2601607,
    name: 'Belém do São Francisco',
    uf: 'PE'
  },
  {
    id: 2601706,
    name: 'Belo Jardim',
    uf: 'PE'
  },
  {
    id: 2601805,
    name: 'Betânia',
    uf: 'PE'
  },
  {
    id: 2601904,
    name: 'Bezerros',
    uf: 'PE'
  },
  {
    id: 2602001,
    name: 'Bodocó',
    uf: 'PE'
  },
  {
    id: 2602100,
    name: 'Bom Conselho',
    uf: 'PE'
  },
  {
    id: 2602209,
    name: 'Bom Jardim',
    uf: 'PE'
  },
  {
    id: 2602308,
    name: 'Bonito',
    uf: 'PE'
  },
  {
    id: 2602407,
    name: 'Brejão',
    uf: 'PE'
  },
  {
    id: 2602506,
    name: 'Brejinho',
    uf: 'PE'
  },
  {
    id: 2602605,
    name: 'Brejo da Madre de Deus',
    uf: 'PE'
  },
  {
    id: 2602704,
    name: 'Buenos Aires',
    uf: 'PE'
  },
  {
    id: 2602803,
    name: 'Buíque',
    uf: 'PE'
  },
  {
    id: 2602902,
    name: 'Cabo de Santo Agostinho',
    uf: 'PE'
  },
  {
    id: 2603009,
    name: 'Cabrobó',
    uf: 'PE'
  },
  {
    id: 2603108,
    name: 'Cachoeirinha',
    uf: 'PE'
  },
  {
    id: 2603207,
    name: 'Caetés',
    uf: 'PE'
  },
  {
    id: 2603306,
    name: 'Calçado',
    uf: 'PE'
  },
  {
    id: 2603405,
    name: 'Calumbi',
    uf: 'PE'
  },
  {
    id: 2603454,
    name: 'Camaragibe',
    uf: 'PE'
  },
  {
    id: 2603504,
    name: 'Camocim de São Félix',
    uf: 'PE'
  },
  {
    id: 2603603,
    name: 'Camutanga',
    uf: 'PE'
  },
  {
    id: 2603702,
    name: 'Canhotinho',
    uf: 'PE'
  },
  {
    id: 2603801,
    name: 'Capoeiras',
    uf: 'PE'
  },
  {
    id: 2603900,
    name: 'Carnaíba',
    uf: 'PE'
  },
  {
    id: 2603926,
    name: 'Carnaubeira da Penha',
    uf: 'PE'
  },
  {
    id: 2604007,
    name: 'Carpina',
    uf: 'PE'
  },
  {
    id: 2604106,
    name: 'Caruaru',
    uf: 'PE'
  },
  {
    id: 2604155,
    name: 'Casinhas',
    uf: 'PE'
  },
  {
    id: 2604205,
    name: 'Catende',
    uf: 'PE'
  },
  {
    id: 2604304,
    name: 'Cedro',
    uf: 'PE'
  },
  {
    id: 2604403,
    name: 'Chã de Alegria',
    uf: 'PE'
  },
  {
    id: 2604502,
    name: 'Chã Grande',
    uf: 'PE'
  },
  {
    id: 2604601,
    name: 'Condado',
    uf: 'PE'
  },
  {
    id: 2604700,
    name: 'Correntes',
    uf: 'PE'
  },
  {
    id: 2604809,
    name: 'Cortês',
    uf: 'PE'
  },
  {
    id: 2604908,
    name: 'Cumaru',
    uf: 'PE'
  },
  {
    id: 2605004,
    name: 'Cupira',
    uf: 'PE'
  },
  {
    id: 2605103,
    name: 'Custódia',
    uf: 'PE'
  },
  {
    id: 2605152,
    name: 'Dormentes',
    uf: 'PE'
  },
  {
    id: 2605202,
    name: 'Escada',
    uf: 'PE'
  },
  {
    id: 2605301,
    name: 'Exu',
    uf: 'PE'
  },
  {
    id: 2605400,
    name: 'Feira Nova',
    uf: 'PE'
  },
  {
    id: 2605459,
    name: 'Fernando de Noronha',
    uf: 'PE'
  },
  {
    id: 2605509,
    name: 'Ferreiros',
    uf: 'PE'
  },
  {
    id: 2605608,
    name: 'Flores',
    uf: 'PE'
  },
  {
    id: 2605707,
    name: 'Floresta',
    uf: 'PE'
  },
  {
    id: 2605806,
    name: 'Frei Miguelinho',
    uf: 'PE'
  },
  {
    id: 2605905,
    name: 'Gameleira',
    uf: 'PE'
  },
  {
    id: 2606002,
    name: 'Garanhuns',
    uf: 'PE'
  },
  {
    id: 2606101,
    name: 'Glória do Goitá',
    uf: 'PE'
  },
  {
    id: 2606200,
    name: 'Goiana',
    uf: 'PE'
  },
  {
    id: 2606309,
    name: 'Granito',
    uf: 'PE'
  },
  {
    id: 2606408,
    name: 'Gravatá',
    uf: 'PE'
  },
  {
    id: 2606507,
    name: 'Iati',
    uf: 'PE'
  },
  {
    id: 2606606,
    name: 'Ibimirim',
    uf: 'PE'
  },
  {
    id: 2606705,
    name: 'Ibirajuba',
    uf: 'PE'
  },
  {
    id: 2606804,
    name: 'Igarassu',
    uf: 'PE'
  },
  {
    id: 2606903,
    name: 'Iguaracy',
    uf: 'PE'
  },
  {
    id: 2607000,
    name: 'Inajá',
    uf: 'PE'
  },
  {
    id: 2607109,
    name: 'Ingazeira',
    uf: 'PE'
  },
  {
    id: 2607208,
    name: 'Ipojuca',
    uf: 'PE'
  },
  {
    id: 2607307,
    name: 'Ipubi',
    uf: 'PE'
  },
  {
    id: 2607406,
    name: 'Itacuruba',
    uf: 'PE'
  },
  {
    id: 2607505,
    name: 'Itaíba',
    uf: 'PE'
  },
  {
    id: 2607604,
    name: 'Ilha de Itamaracá',
    uf: 'PE'
  },
  {
    id: 2607653,
    name: 'Itambé',
    uf: 'PE'
  },
  {
    id: 2607703,
    name: 'Itapetim',
    uf: 'PE'
  },
  {
    id: 2607752,
    name: 'Itapissuma',
    uf: 'PE'
  },
  {
    id: 2607802,
    name: 'Itaquitinga',
    uf: 'PE'
  },
  {
    id: 2607901,
    name: 'Jaboatão dos Guararapes',
    uf: 'PE'
  },
  {
    id: 2607950,
    name: 'Jaqueira',
    uf: 'PE'
  },
  {
    id: 2608008,
    name: 'Jataúba',
    uf: 'PE'
  },
  {
    id: 2608057,
    name: 'Jatobá',
    uf: 'PE'
  },
  {
    id: 2608107,
    name: 'João Alfredo',
    uf: 'PE'
  },
  {
    id: 2608206,
    name: 'Joaquim Nabuco',
    uf: 'PE'
  },
  {
    id: 2608255,
    name: 'Jucati',
    uf: 'PE'
  },
  {
    id: 2608305,
    name: 'Jupi',
    uf: 'PE'
  },
  {
    id: 2608404,
    name: 'Jurema',
    uf: 'PE'
  },
  {
    id: 2608453,
    name: 'Lagoa do Carro',
    uf: 'PE'
  },
  {
    id: 2608503,
    name: 'Lagoa de Itaenga',
    uf: 'PE'
  },
  {
    id: 2608602,
    name: 'Lagoa do Ouro',
    uf: 'PE'
  },
  {
    id: 2608701,
    name: 'Lagoa dos Gatos',
    uf: 'PE'
  },
  {
    id: 2608750,
    name: 'Lagoa Grande',
    uf: 'PE'
  },
  {
    id: 2608800,
    name: 'Lajedo',
    uf: 'PE'
  },
  {
    id: 2608909,
    name: 'Limoeiro',
    uf: 'PE'
  },
  {
    id: 2609006,
    name: 'Macaparana',
    uf: 'PE'
  },
  {
    id: 2609105,
    name: 'Machados',
    uf: 'PE'
  },
  {
    id: 2609154,
    name: 'Manari',
    uf: 'PE'
  },
  {
    id: 2609204,
    name: 'Maraial',
    uf: 'PE'
  },
  {
    id: 2609303,
    name: 'Mirandiba',
    uf: 'PE'
  },
  {
    id: 2609402,
    name: 'Moreno',
    uf: 'PE'
  },
  {
    id: 2609501,
    name: 'Nazaré da Mata',
    uf: 'PE'
  },
  {
    id: 2609600,
    name: 'Olinda',
    uf: 'PE'
  },
  {
    id: 2609709,
    name: 'Orobó',
    uf: 'PE'
  },
  {
    id: 2609808,
    name: 'Orocó',
    uf: 'PE'
  },
  {
    id: 2609907,
    name: 'Ouricuri',
    uf: 'PE'
  },
  {
    id: 2610004,
    name: 'Palmares',
    uf: 'PE'
  },
  {
    id: 2610103,
    name: 'Palmeirina',
    uf: 'PE'
  },
  {
    id: 2610202,
    name: 'Panelas',
    uf: 'PE'
  },
  {
    id: 2610301,
    name: 'Paranatama',
    uf: 'PE'
  },
  {
    id: 2610400,
    name: 'Parnamirim',
    uf: 'PE'
  },
  {
    id: 2610509,
    name: 'Passira',
    uf: 'PE'
  },
  {
    id: 2610608,
    name: 'Paudalho',
    uf: 'PE'
  },
  {
    id: 2610707,
    name: 'Paulista',
    uf: 'PE'
  },
  {
    id: 2610806,
    name: 'Pedra',
    uf: 'PE'
  },
  {
    id: 2610905,
    name: 'Pesqueira',
    uf: 'PE'
  },
  {
    id: 2611002,
    name: 'Petrolândia',
    uf: 'PE'
  },
  {
    id: 2611101,
    name: 'Petrolina',
    uf: 'PE'
  },
  {
    id: 2611200,
    name: 'Poção',
    uf: 'PE'
  },
  {
    id: 2611309,
    name: 'Pombos',
    uf: 'PE'
  },
  {
    id: 2611408,
    name: 'Primavera',
    uf: 'PE'
  },
  {
    id: 2611507,
    name: 'Quipapá',
    uf: 'PE'
  },
  {
    id: 2611533,
    name: 'Quixaba',
    uf: 'PE'
  },
  {
    id: 2611606,
    name: 'Recife',
    uf: 'PE'
  },
  {
    id: 2611705,
    name: 'Riacho das Almas',
    uf: 'PE'
  },
  {
    id: 2611804,
    name: 'Ribeirão',
    uf: 'PE'
  },
  {
    id: 2611903,
    name: 'Rio Formoso',
    uf: 'PE'
  },
  {
    id: 2612000,
    name: 'Sairé',
    uf: 'PE'
  },
  {
    id: 2612109,
    name: 'Salgadinho',
    uf: 'PE'
  },
  {
    id: 2612208,
    name: 'Salgueiro',
    uf: 'PE'
  },
  {
    id: 2612307,
    name: 'Saloá',
    uf: 'PE'
  },
  {
    id: 2612406,
    name: 'Sanharó',
    uf: 'PE'
  },
  {
    id: 2612455,
    name: 'Santa Cruz',
    uf: 'PE'
  },
  {
    id: 2612471,
    name: 'Santa Cruz da Baixa Verde',
    uf: 'PE'
  },
  {
    id: 2612505,
    name: 'Santa Cruz do Capibaribe',
    uf: 'PE'
  },
  {
    id: 2612554,
    name: 'Santa Filomena',
    uf: 'PE'
  },
  {
    id: 2612604,
    name: 'Santa Maria da Boa Vista',
    uf: 'PE'
  },
  {
    id: 2612703,
    name: 'Santa Maria do Cambucá',
    uf: 'PE'
  },
  {
    id: 2612802,
    name: 'Santa Terezinha',
    uf: 'PE'
  },
  {
    id: 2612901,
    name: 'São Benedito do Sul',
    uf: 'PE'
  },
  {
    id: 2613008,
    name: 'São Bento do Una',
    uf: 'PE'
  },
  {
    id: 2613107,
    name: 'São Caitano',
    uf: 'PE'
  },
  {
    id: 2613206,
    name: 'São João',
    uf: 'PE'
  },
  {
    id: 2613305,
    name: 'São Joaquim do Monte',
    uf: 'PE'
  },
  {
    id: 2613404,
    name: 'São José da Coroa Grande',
    uf: 'PE'
  },
  {
    id: 2613503,
    name: 'São José do Belmonte',
    uf: 'PE'
  },
  {
    id: 2613602,
    name: 'São José do Egito',
    uf: 'PE'
  },
  {
    id: 2613701,
    name: 'São Lourenço da Mata',
    uf: 'PE'
  },
  {
    id: 2613800,
    name: 'São Vicente Férrer',
    uf: 'PE'
  },
  {
    id: 2613909,
    name: 'Serra Talhada',
    uf: 'PE'
  },
  {
    id: 2614006,
    name: 'Serrita',
    uf: 'PE'
  },
  {
    id: 2614105,
    name: 'Sertânia',
    uf: 'PE'
  },
  {
    id: 2614204,
    name: 'Sirinhaém',
    uf: 'PE'
  },
  {
    id: 2614303,
    name: 'Moreilândia',
    uf: 'PE'
  },
  {
    id: 2614402,
    name: 'Solidão',
    uf: 'PE'
  },
  {
    id: 2614501,
    name: 'Surubim',
    uf: 'PE'
  },
  {
    id: 2614600,
    name: 'Tabira',
    uf: 'PE'
  },
  {
    id: 2614709,
    name: 'Tacaimbó',
    uf: 'PE'
  },
  {
    id: 2614808,
    name: 'Tacaratu',
    uf: 'PE'
  },
  {
    id: 2614857,
    name: 'Tamandaré',
    uf: 'PE'
  },
  {
    id: 2615003,
    name: 'Taquaritinga do Norte',
    uf: 'PE'
  },
  {
    id: 2615102,
    name: 'Terezinha',
    uf: 'PE'
  },
  {
    id: 2615201,
    name: 'Terra Nova',
    uf: 'PE'
  },
  {
    id: 2615300,
    name: 'Timbaúba',
    uf: 'PE'
  },
  {
    id: 2615409,
    name: 'Toritama',
    uf: 'PE'
  },
  {
    id: 2615508,
    name: 'Tracunhaém',
    uf: 'PE'
  },
  {
    id: 2615607,
    name: 'Trindade',
    uf: 'PE'
  },
  {
    id: 2615706,
    name: 'Triunfo',
    uf: 'PE'
  },
  {
    id: 2615805,
    name: 'Tupanatinga',
    uf: 'PE'
  },
  {
    id: 2615904,
    name: 'Tuparetama',
    uf: 'PE'
  },
  {
    id: 2616001,
    name: 'Venturosa',
    uf: 'PE'
  },
  {
    id: 2616100,
    name: 'Verdejante',
    uf: 'PE'
  },
  {
    id: 2616183,
    name: 'Vertente do Lério',
    uf: 'PE'
  },
  {
    id: 2616209,
    name: 'Vertentes',
    uf: 'PE'
  },
  {
    id: 2616308,
    name: 'Vicência',
    uf: 'PE'
  },
  {
    id: 2616407,
    name: 'Vitória de Santo Antão',
    uf: 'PE'
  },
  {
    id: 2616506,
    name: 'Xexéu',
    uf: 'PE'
  },
  {
    id: 2700102,
    name: 'Água Branca',
    uf: 'AL'
  },
  {
    id: 2700201,
    name: 'Anadia',
    uf: 'AL'
  },
  {
    id: 2700300,
    name: 'Arapiraca',
    uf: 'AL'
  },
  {
    id: 2700409,
    name: 'Atalaia',
    uf: 'AL'
  },
  {
    id: 2700508,
    name: 'Barra de Santo Antônio',
    uf: 'AL'
  },
  {
    id: 2700607,
    name: 'Barra de São Miguel',
    uf: 'AL'
  },
  {
    id: 2700706,
    name: 'Batalha',
    uf: 'AL'
  },
  {
    id: 2700805,
    name: 'Belém',
    uf: 'AL'
  },
  {
    id: 2700904,
    name: 'Belo Monte',
    uf: 'AL'
  },
  {
    id: 2701001,
    name: 'Boca da Mata',
    uf: 'AL'
  },
  {
    id: 2701100,
    name: 'Branquinha',
    uf: 'AL'
  },
  {
    id: 2701209,
    name: 'Cacimbinhas',
    uf: 'AL'
  },
  {
    id: 2701308,
    name: 'Cajueiro',
    uf: 'AL'
  },
  {
    id: 2701357,
    name: 'Campestre',
    uf: 'AL'
  },
  {
    id: 2701407,
    name: 'Campo Alegre',
    uf: 'AL'
  },
  {
    id: 2701506,
    name: 'Campo Grande',
    uf: 'AL'
  },
  {
    id: 2701605,
    name: 'Canapi',
    uf: 'AL'
  },
  {
    id: 2701704,
    name: 'Capela',
    uf: 'AL'
  },
  {
    id: 2701803,
    name: 'Carneiros',
    uf: 'AL'
  },
  {
    id: 2701902,
    name: 'Chã Preta',
    uf: 'AL'
  },
  {
    id: 2702009,
    name: 'Coité do Nóia',
    uf: 'AL'
  },
  {
    id: 2702108,
    name: 'Colônia Leopoldina',
    uf: 'AL'
  },
  {
    id: 2702207,
    name: 'Coqueiro Seco',
    uf: 'AL'
  },
  {
    id: 2702306,
    name: 'Coruripe',
    uf: 'AL'
  },
  {
    id: 2702355,
    name: 'Craíbas',
    uf: 'AL'
  },
  {
    id: 2702405,
    name: 'Delmiro Gouveia',
    uf: 'AL'
  },
  {
    id: 2702504,
    name: 'Dois Riachos',
    uf: 'AL'
  },
  {
    id: 2702553,
    name: 'Estrela de Alagoas',
    uf: 'AL'
  },
  {
    id: 2702603,
    name: 'Feira Grande',
    uf: 'AL'
  },
  {
    id: 2702702,
    name: 'Feliz Deserto',
    uf: 'AL'
  },
  {
    id: 2702801,
    name: 'Flexeiras',
    uf: 'AL'
  },
  {
    id: 2702900,
    name: 'Girau do Ponciano',
    uf: 'AL'
  },
  {
    id: 2703007,
    name: 'Ibateguara',
    uf: 'AL'
  },
  {
    id: 2703106,
    name: 'Igaci',
    uf: 'AL'
  },
  {
    id: 2703205,
    name: 'Igreja Nova',
    uf: 'AL'
  },
  {
    id: 2703304,
    name: 'Inhapi',
    uf: 'AL'
  },
  {
    id: 2703403,
    name: 'Jacaré dos Homens',
    uf: 'AL'
  },
  {
    id: 2703502,
    name: 'Jacuípe',
    uf: 'AL'
  },
  {
    id: 2703601,
    name: 'Japaratinga',
    uf: 'AL'
  },
  {
    id: 2703700,
    name: 'Jaramataia',
    uf: 'AL'
  },
  {
    id: 2703759,
    name: 'Jequiá da Praia',
    uf: 'AL'
  },
  {
    id: 2703809,
    name: 'Joaquim Gomes',
    uf: 'AL'
  },
  {
    id: 2703908,
    name: 'Jundiá',
    uf: 'AL'
  },
  {
    id: 2704005,
    name: 'Junqueiro',
    uf: 'AL'
  },
  {
    id: 2704104,
    name: 'Lagoa da Canoa',
    uf: 'AL'
  },
  {
    id: 2704203,
    name: 'Limoeiro de Anadia',
    uf: 'AL'
  },
  {
    id: 2704302,
    name: 'Maceió',
    uf: 'AL'
  },
  {
    id: 2704401,
    name: 'Major Isidoro',
    uf: 'AL'
  },
  {
    id: 2704500,
    name: 'Maragogi',
    uf: 'AL'
  },
  {
    id: 2704609,
    name: 'Maravilha',
    uf: 'AL'
  },
  {
    id: 2704708,
    name: 'Marechal Deodoro',
    uf: 'AL'
  },
  {
    id: 2704807,
    name: 'Maribondo',
    uf: 'AL'
  },
  {
    id: 2704906,
    name: 'Mar Vermelho',
    uf: 'AL'
  },
  {
    id: 2705002,
    name: 'Mata Grande',
    uf: 'AL'
  },
  {
    id: 2705101,
    name: 'Matriz de Camaragibe',
    uf: 'AL'
  },
  {
    id: 2705200,
    name: 'Messias',
    uf: 'AL'
  },
  {
    id: 2705309,
    name: 'Minador do Negrão',
    uf: 'AL'
  },
  {
    id: 2705408,
    name: 'Monteirópolis',
    uf: 'AL'
  },
  {
    id: 2705507,
    name: 'Murici',
    uf: 'AL'
  },
  {
    id: 2705606,
    name: 'Novo Lino',
    uf: 'AL'
  },
  {
    id: 2705705,
    name: "Olho d'Água das Flores",
    uf: 'AL'
  },
  {
    id: 2705804,
    name: "Olho d'Água do Casado",
    uf: 'AL'
  },
  {
    id: 2705903,
    name: "Olho d'Água Grande",
    uf: 'AL'
  },
  {
    id: 2706000,
    name: 'Olivença',
    uf: 'AL'
  },
  {
    id: 2706109,
    name: 'Ouro Branco',
    uf: 'AL'
  },
  {
    id: 2706208,
    name: 'Palestina',
    uf: 'AL'
  },
  {
    id: 2706307,
    name: 'Palmeira dos Índios',
    uf: 'AL'
  },
  {
    id: 2706406,
    name: 'Pão de Açúcar',
    uf: 'AL'
  },
  {
    id: 2706422,
    name: 'Pariconha',
    uf: 'AL'
  },
  {
    id: 2706448,
    name: 'Paripueira',
    uf: 'AL'
  },
  {
    id: 2706505,
    name: 'Passo de Camaragibe',
    uf: 'AL'
  },
  {
    id: 2706604,
    name: 'Paulo Jacinto',
    uf: 'AL'
  },
  {
    id: 2706703,
    name: 'Penedo',
    uf: 'AL'
  },
  {
    id: 2706802,
    name: 'Piaçabuçu',
    uf: 'AL'
  },
  {
    id: 2706901,
    name: 'Pilar',
    uf: 'AL'
  },
  {
    id: 2707008,
    name: 'Pindoba',
    uf: 'AL'
  },
  {
    id: 2707107,
    name: 'Piranhas',
    uf: 'AL'
  },
  {
    id: 2707206,
    name: 'Poço das Trincheiras',
    uf: 'AL'
  },
  {
    id: 2707305,
    name: 'Porto Calvo',
    uf: 'AL'
  },
  {
    id: 2707404,
    name: 'Porto de Pedras',
    uf: 'AL'
  },
  {
    id: 2707503,
    name: 'Porto Real do Colégio',
    uf: 'AL'
  },
  {
    id: 2707602,
    name: 'Quebrangulo',
    uf: 'AL'
  },
  {
    id: 2707701,
    name: 'Rio Largo',
    uf: 'AL'
  },
  {
    id: 2707800,
    name: 'Roteiro',
    uf: 'AL'
  },
  {
    id: 2707909,
    name: 'Santa Luzia do Norte',
    uf: 'AL'
  },
  {
    id: 2708006,
    name: 'Santana do Ipanema',
    uf: 'AL'
  },
  {
    id: 2708105,
    name: 'Santana do Mundaú',
    uf: 'AL'
  },
  {
    id: 2708204,
    name: 'São Brás',
    uf: 'AL'
  },
  {
    id: 2708303,
    name: 'São José da Laje',
    uf: 'AL'
  },
  {
    id: 2708402,
    name: 'São José da Tapera',
    uf: 'AL'
  },
  {
    id: 2708501,
    name: 'São Luís do Quitunde',
    uf: 'AL'
  },
  {
    id: 2708600,
    name: 'São Miguel dos Campos',
    uf: 'AL'
  },
  {
    id: 2708709,
    name: 'São Miguel dos Milagres',
    uf: 'AL'
  },
  {
    id: 2708808,
    name: 'São Sebastião',
    uf: 'AL'
  },
  {
    id: 2708907,
    name: 'Satuba',
    uf: 'AL'
  },
  {
    id: 2708956,
    name: 'Senador Rui Palmeira',
    uf: 'AL'
  },
  {
    id: 2709004,
    name: "Tanque d'Arca",
    uf: 'AL'
  },
  {
    id: 2709103,
    name: 'Taquarana',
    uf: 'AL'
  },
  {
    id: 2709152,
    name: 'Teotônio Vilela',
    uf: 'AL'
  },
  {
    id: 2709202,
    name: 'Traipu',
    uf: 'AL'
  },
  {
    id: 2709301,
    name: 'União dos Palmares',
    uf: 'AL'
  },
  {
    id: 2709400,
    name: 'Viçosa',
    uf: 'AL'
  },
  {
    id: 2800100,
    name: 'Amparo do São Francisco',
    uf: 'SE'
  },
  {
    id: 2800209,
    name: 'Aquidabã',
    uf: 'SE'
  },
  {
    id: 2800308,
    name: 'Aracaju',
    uf: 'SE'
  },
  {
    id: 2800407,
    name: 'Arauá',
    uf: 'SE'
  },
  {
    id: 2800506,
    name: 'Areia Branca',
    uf: 'SE'
  },
  {
    id: 2800605,
    name: 'Barra dos Coqueiros',
    uf: 'SE'
  },
  {
    id: 2800670,
    name: 'Boquim',
    uf: 'SE'
  },
  {
    id: 2800704,
    name: 'Brejo Grande',
    uf: 'SE'
  },
  {
    id: 2801009,
    name: 'Campo do Brito',
    uf: 'SE'
  },
  {
    id: 2801108,
    name: 'Canhoba',
    uf: 'SE'
  },
  {
    id: 2801207,
    name: 'Canindé de São Francisco',
    uf: 'SE'
  },
  {
    id: 2801306,
    name: 'Capela',
    uf: 'SE'
  },
  {
    id: 2801405,
    name: 'Carira',
    uf: 'SE'
  },
  {
    id: 2801504,
    name: 'Carmópolis',
    uf: 'SE'
  },
  {
    id: 2801603,
    name: 'Cedro de São João',
    uf: 'SE'
  },
  {
    id: 2801702,
    name: 'Cristinápolis',
    uf: 'SE'
  },
  {
    id: 2801900,
    name: 'Cumbe',
    uf: 'SE'
  },
  {
    id: 2802007,
    name: 'Divina Pastora',
    uf: 'SE'
  },
  {
    id: 2802106,
    name: 'Estância',
    uf: 'SE'
  },
  {
    id: 2802205,
    name: 'Feira Nova',
    uf: 'SE'
  },
  {
    id: 2802304,
    name: 'Frei Paulo',
    uf: 'SE'
  },
  {
    id: 2802403,
    name: 'Gararu',
    uf: 'SE'
  },
  {
    id: 2802502,
    name: 'General Maynard',
    uf: 'SE'
  },
  {
    id: 2802601,
    name: 'Gracho Cardoso',
    uf: 'SE'
  },
  {
    id: 2802700,
    name: 'Ilha das Flores',
    uf: 'SE'
  },
  {
    id: 2802809,
    name: 'Indiaroba',
    uf: 'SE'
  },
  {
    id: 2802908,
    name: 'Itabaiana',
    uf: 'SE'
  },
  {
    id: 2803005,
    name: 'Itabaianinha',
    uf: 'SE'
  },
  {
    id: 2803104,
    name: 'Itabi',
    uf: 'SE'
  },
  {
    id: 2803203,
    name: "Itaporanga d'Ajuda",
    uf: 'SE'
  },
  {
    id: 2803302,
    name: 'Japaratuba',
    uf: 'SE'
  },
  {
    id: 2803401,
    name: 'Japoatã',
    uf: 'SE'
  },
  {
    id: 2803500,
    name: 'Lagarto',
    uf: 'SE'
  },
  {
    id: 2803609,
    name: 'Laranjeiras',
    uf: 'SE'
  },
  {
    id: 2803708,
    name: 'Macambira',
    uf: 'SE'
  },
  {
    id: 2803807,
    name: 'Malhada dos Bois',
    uf: 'SE'
  },
  {
    id: 2803906,
    name: 'Malhador',
    uf: 'SE'
  },
  {
    id: 2804003,
    name: 'Maruim',
    uf: 'SE'
  },
  {
    id: 2804102,
    name: 'Moita Bonita',
    uf: 'SE'
  },
  {
    id: 2804201,
    name: 'Monte Alegre de Sergipe',
    uf: 'SE'
  },
  {
    id: 2804300,
    name: 'Muribeca',
    uf: 'SE'
  },
  {
    id: 2804409,
    name: 'Neópolis',
    uf: 'SE'
  },
  {
    id: 2804458,
    name: 'Nossa Senhora Aparecida',
    uf: 'SE'
  },
  {
    id: 2804508,
    name: 'Nossa Senhora da Glória',
    uf: 'SE'
  },
  {
    id: 2804607,
    name: 'Nossa Senhora das Dores',
    uf: 'SE'
  },
  {
    id: 2804706,
    name: 'Nossa Senhora de Lourdes',
    uf: 'SE'
  },
  {
    id: 2804805,
    name: 'Nossa Senhora do Socorro',
    uf: 'SE'
  },
  {
    id: 2804904,
    name: 'Pacatuba',
    uf: 'SE'
  },
  {
    id: 2805000,
    name: 'Pedra Mole',
    uf: 'SE'
  },
  {
    id: 2805109,
    name: 'Pedrinhas',
    uf: 'SE'
  },
  {
    id: 2805208,
    name: 'Pinhão',
    uf: 'SE'
  },
  {
    id: 2805307,
    name: 'Pirambu',
    uf: 'SE'
  },
  {
    id: 2805406,
    name: 'Poço Redondo',
    uf: 'SE'
  },
  {
    id: 2805505,
    name: 'Poço Verde',
    uf: 'SE'
  },
  {
    id: 2805604,
    name: 'Porto da Folha',
    uf: 'SE'
  },
  {
    id: 2805703,
    name: 'Propriá',
    uf: 'SE'
  },
  {
    id: 2805802,
    name: 'Riachão do Dantas',
    uf: 'SE'
  },
  {
    id: 2805901,
    name: 'Riachuelo',
    uf: 'SE'
  },
  {
    id: 2806008,
    name: 'Ribeirópolis',
    uf: 'SE'
  },
  {
    id: 2806107,
    name: 'Rosário do Catete',
    uf: 'SE'
  },
  {
    id: 2806206,
    name: 'Salgado',
    uf: 'SE'
  },
  {
    id: 2806305,
    name: 'Santa Luzia do Itanhy',
    uf: 'SE'
  },
  {
    id: 2806404,
    name: 'Santana do São Francisco',
    uf: 'SE'
  },
  {
    id: 2806503,
    name: 'Santa Rosa de Lima',
    uf: 'SE'
  },
  {
    id: 2806602,
    name: 'Santo Amaro das Brotas',
    uf: 'SE'
  },
  {
    id: 2806701,
    name: 'São Cristóvão',
    uf: 'SE'
  },
  {
    id: 2806800,
    name: 'São Domingos',
    uf: 'SE'
  },
  {
    id: 2806909,
    name: 'São Francisco',
    uf: 'SE'
  },
  {
    id: 2807006,
    name: 'São Miguel do Aleixo',
    uf: 'SE'
  },
  {
    id: 2807105,
    name: 'Simão Dias',
    uf: 'SE'
  },
  {
    id: 2807204,
    name: 'Siriri',
    uf: 'SE'
  },
  {
    id: 2807303,
    name: 'Telha',
    uf: 'SE'
  },
  {
    id: 2807402,
    name: 'Tobias Barreto',
    uf: 'SE'
  },
  {
    id: 2807501,
    name: 'Tomar do Geru',
    uf: 'SE'
  },
  {
    id: 2807600,
    name: 'Umbaúba',
    uf: 'SE'
  },
  {
    id: 2900108,
    name: 'Abaíra',
    uf: 'BA'
  },
  {
    id: 2900207,
    name: 'Abaré',
    uf: 'BA'
  },
  {
    id: 2900306,
    name: 'Acajutiba',
    uf: 'BA'
  },
  {
    id: 2900355,
    name: 'Adustina',
    uf: 'BA'
  },
  {
    id: 2900405,
    name: 'Água Fria',
    uf: 'BA'
  },
  {
    id: 2900504,
    name: 'Érico Cardoso',
    uf: 'BA'
  },
  {
    id: 2900603,
    name: 'Aiquara',
    uf: 'BA'
  },
  {
    id: 2900702,
    name: 'Alagoinhas',
    uf: 'BA'
  },
  {
    id: 2900801,
    name: 'Alcobaça',
    uf: 'BA'
  },
  {
    id: 2900900,
    name: 'Almadina',
    uf: 'BA'
  },
  {
    id: 2901007,
    name: 'Amargosa',
    uf: 'BA'
  },
  {
    id: 2901106,
    name: 'Amélia Rodrigues',
    uf: 'BA'
  },
  {
    id: 2901155,
    name: 'América Dourada',
    uf: 'BA'
  },
  {
    id: 2901205,
    name: 'Anagé',
    uf: 'BA'
  },
  {
    id: 2901304,
    name: 'Andaraí',
    uf: 'BA'
  },
  {
    id: 2901353,
    name: 'Andorinha',
    uf: 'BA'
  },
  {
    id: 2901403,
    name: 'Angical',
    uf: 'BA'
  },
  {
    id: 2901502,
    name: 'Anguera',
    uf: 'BA'
  },
  {
    id: 2901601,
    name: 'Antas',
    uf: 'BA'
  },
  {
    id: 2901700,
    name: 'Antônio Cardoso',
    uf: 'BA'
  },
  {
    id: 2901809,
    name: 'Antônio Gonçalves',
    uf: 'BA'
  },
  {
    id: 2901908,
    name: 'Aporá',
    uf: 'BA'
  },
  {
    id: 2901957,
    name: 'Apuarema',
    uf: 'BA'
  },
  {
    id: 2902005,
    name: 'Aracatu',
    uf: 'BA'
  },
  {
    id: 2902054,
    name: 'Araçás',
    uf: 'BA'
  },
  {
    id: 2902104,
    name: 'Araci',
    uf: 'BA'
  },
  {
    id: 2902203,
    name: 'Aramari',
    uf: 'BA'
  },
  {
    id: 2902252,
    name: 'Arataca',
    uf: 'BA'
  },
  {
    id: 2902302,
    name: 'Aratuípe',
    uf: 'BA'
  },
  {
    id: 2902401,
    name: 'Aurelino Leal',
    uf: 'BA'
  },
  {
    id: 2902500,
    name: 'Baianópolis',
    uf: 'BA'
  },
  {
    id: 2902609,
    name: 'Baixa Grande',
    uf: 'BA'
  },
  {
    id: 2902658,
    name: 'Banzaê',
    uf: 'BA'
  },
  {
    id: 2902708,
    name: 'Barra',
    uf: 'BA'
  },
  {
    id: 2902807,
    name: 'Barra da Estiva',
    uf: 'BA'
  },
  {
    id: 2902906,
    name: 'Barra do Choça',
    uf: 'BA'
  },
  {
    id: 2903003,
    name: 'Barra do Mendes',
    uf: 'BA'
  },
  {
    id: 2903102,
    name: 'Barra do Rocha',
    uf: 'BA'
  },
  {
    id: 2903201,
    name: 'Barreiras',
    uf: 'BA'
  },
  {
    id: 2903235,
    name: 'Barro Alto',
    uf: 'BA'
  },
  {
    id: 2903276,
    name: 'Barrocas',
    uf: 'BA'
  },
  {
    id: 2903300,
    name: 'Barro Preto',
    uf: 'BA'
  },
  {
    id: 2903409,
    name: 'Belmonte',
    uf: 'BA'
  },
  {
    id: 2903508,
    name: 'Belo Campo',
    uf: 'BA'
  },
  {
    id: 2903607,
    name: 'Biritinga',
    uf: 'BA'
  },
  {
    id: 2903706,
    name: 'Boa Nova',
    uf: 'BA'
  },
  {
    id: 2903805,
    name: 'Boa Vista do Tupim',
    uf: 'BA'
  },
  {
    id: 2903904,
    name: 'Bom Jesus da Lapa',
    uf: 'BA'
  },
  {
    id: 2903953,
    name: 'Bom Jesus da Serra',
    uf: 'BA'
  },
  {
    id: 2904001,
    name: 'Boninal',
    uf: 'BA'
  },
  {
    id: 2904050,
    name: 'Bonito',
    uf: 'BA'
  },
  {
    id: 2904100,
    name: 'Boquira',
    uf: 'BA'
  },
  {
    id: 2904209,
    name: 'Botuporã',
    uf: 'BA'
  },
  {
    id: 2904308,
    name: 'Brejões',
    uf: 'BA'
  },
  {
    id: 2904407,
    name: 'Brejolândia',
    uf: 'BA'
  },
  {
    id: 2904506,
    name: 'Brotas de Macaúbas',
    uf: 'BA'
  },
  {
    id: 2904605,
    name: 'Brumado',
    uf: 'BA'
  },
  {
    id: 2904704,
    name: 'Buerarema',
    uf: 'BA'
  },
  {
    id: 2904753,
    name: 'Buritirama',
    uf: 'BA'
  },
  {
    id: 2904803,
    name: 'Caatiba',
    uf: 'BA'
  },
  {
    id: 2904852,
    name: 'Cabaceiras do Paraguaçu',
    uf: 'BA'
  },
  {
    id: 2904902,
    name: 'Cachoeira',
    uf: 'BA'
  },
  {
    id: 2905008,
    name: 'Caculé',
    uf: 'BA'
  },
  {
    id: 2905107,
    name: 'Caém',
    uf: 'BA'
  },
  {
    id: 2905156,
    name: 'Caetanos',
    uf: 'BA'
  },
  {
    id: 2905206,
    name: 'Caetité',
    uf: 'BA'
  },
  {
    id: 2905305,
    name: 'Cafarnaum',
    uf: 'BA'
  },
  {
    id: 2905404,
    name: 'Cairu',
    uf: 'BA'
  },
  {
    id: 2905503,
    name: 'Caldeirão Grande',
    uf: 'BA'
  },
  {
    id: 2905602,
    name: 'Camacan',
    uf: 'BA'
  },
  {
    id: 2905701,
    name: 'Camaçari',
    uf: 'BA'
  },
  {
    id: 2905800,
    name: 'Camamu',
    uf: 'BA'
  },
  {
    id: 2905909,
    name: 'Campo Alegre de Lourdes',
    uf: 'BA'
  },
  {
    id: 2906006,
    name: 'Campo Formoso',
    uf: 'BA'
  },
  {
    id: 2906105,
    name: 'Canápolis',
    uf: 'BA'
  },
  {
    id: 2906204,
    name: 'Canarana',
    uf: 'BA'
  },
  {
    id: 2906303,
    name: 'Canavieiras',
    uf: 'BA'
  },
  {
    id: 2906402,
    name: 'Candeal',
    uf: 'BA'
  },
  {
    id: 2906501,
    name: 'Candeias',
    uf: 'BA'
  },
  {
    id: 2906600,
    name: 'Candiba',
    uf: 'BA'
  },
  {
    id: 2906709,
    name: 'Cândido Sales',
    uf: 'BA'
  },
  {
    id: 2906808,
    name: 'Cansanção',
    uf: 'BA'
  },
  {
    id: 2906824,
    name: 'Canudos',
    uf: 'BA'
  },
  {
    id: 2906857,
    name: 'Capela do Alto Alegre',
    uf: 'BA'
  },
  {
    id: 2906873,
    name: 'Capim Grosso',
    uf: 'BA'
  },
  {
    id: 2906899,
    name: 'Caraíbas',
    uf: 'BA'
  },
  {
    id: 2906907,
    name: 'Caravelas',
    uf: 'BA'
  },
  {
    id: 2907004,
    name: 'Cardeal da Silva',
    uf: 'BA'
  },
  {
    id: 2907103,
    name: 'Carinhanha',
    uf: 'BA'
  },
  {
    id: 2907202,
    name: 'Casa Nova',
    uf: 'BA'
  },
  {
    id: 2907301,
    name: 'Castro Alves',
    uf: 'BA'
  },
  {
    id: 2907400,
    name: 'Catolândia',
    uf: 'BA'
  },
  {
    id: 2907509,
    name: 'Catu',
    uf: 'BA'
  },
  {
    id: 2907558,
    name: 'Caturama',
    uf: 'BA'
  },
  {
    id: 2907608,
    name: 'Central',
    uf: 'BA'
  },
  {
    id: 2907707,
    name: 'Chorrochó',
    uf: 'BA'
  },
  {
    id: 2907806,
    name: 'Cícero Dantas',
    uf: 'BA'
  },
  {
    id: 2907905,
    name: 'Cipó',
    uf: 'BA'
  },
  {
    id: 2908002,
    name: 'Coaraci',
    uf: 'BA'
  },
  {
    id: 2908101,
    name: 'Cocos',
    uf: 'BA'
  },
  {
    id: 2908200,
    name: 'Conceição da Feira',
    uf: 'BA'
  },
  {
    id: 2908309,
    name: 'Conceição do Almeida',
    uf: 'BA'
  },
  {
    id: 2908408,
    name: 'Conceição do Coité',
    uf: 'BA'
  },
  {
    id: 2908507,
    name: 'Conceição do Jacuípe',
    uf: 'BA'
  },
  {
    id: 2908606,
    name: 'Conde',
    uf: 'BA'
  },
  {
    id: 2908705,
    name: 'Condeúba',
    uf: 'BA'
  },
  {
    id: 2908804,
    name: 'Contendas do Sincorá',
    uf: 'BA'
  },
  {
    id: 2908903,
    name: 'Coração de Maria',
    uf: 'BA'
  },
  {
    id: 2909000,
    name: 'Cordeiros',
    uf: 'BA'
  },
  {
    id: 2909109,
    name: 'Coribe',
    uf: 'BA'
  },
  {
    id: 2909208,
    name: 'Coronel João Sá',
    uf: 'BA'
  },
  {
    id: 2909307,
    name: 'Correntina',
    uf: 'BA'
  },
  {
    id: 2909406,
    name: 'Cotegipe',
    uf: 'BA'
  },
  {
    id: 2909505,
    name: 'Cravolândia',
    uf: 'BA'
  },
  {
    id: 2909604,
    name: 'Crisópolis',
    uf: 'BA'
  },
  {
    id: 2909703,
    name: 'Cristópolis',
    uf: 'BA'
  },
  {
    id: 2909802,
    name: 'Cruz das Almas',
    uf: 'BA'
  },
  {
    id: 2909901,
    name: 'Curaçá',
    uf: 'BA'
  },
  {
    id: 2910008,
    name: 'Dário Meira',
    uf: 'BA'
  },
  {
    id: 2910057,
    name: "Dias d'Ávila",
    uf: 'BA'
  },
  {
    id: 2910107,
    name: 'Dom Basílio',
    uf: 'BA'
  },
  {
    id: 2910206,
    name: 'Dom Macedo Costa',
    uf: 'BA'
  },
  {
    id: 2910305,
    name: 'Elísio Medrado',
    uf: 'BA'
  },
  {
    id: 2910404,
    name: 'Encruzilhada',
    uf: 'BA'
  },
  {
    id: 2910503,
    name: 'Entre Rios',
    uf: 'BA'
  },
  {
    id: 2910602,
    name: 'Esplanada',
    uf: 'BA'
  },
  {
    id: 2910701,
    name: 'Euclides da Cunha',
    uf: 'BA'
  },
  {
    id: 2910727,
    name: 'Eunápolis',
    uf: 'BA'
  },
  {
    id: 2910750,
    name: 'Fátima',
    uf: 'BA'
  },
  {
    id: 2910776,
    name: 'Feira da Mata',
    uf: 'BA'
  },
  {
    id: 2910800,
    name: 'Feira de Santana',
    uf: 'BA'
  },
  {
    id: 2910859,
    name: 'Filadélfia',
    uf: 'BA'
  },
  {
    id: 2910909,
    name: 'Firmino Alves',
    uf: 'BA'
  },
  {
    id: 2911006,
    name: 'Floresta Azul',
    uf: 'BA'
  },
  {
    id: 2911105,
    name: 'Formosa do Rio Preto',
    uf: 'BA'
  },
  {
    id: 2911204,
    name: 'Gandu',
    uf: 'BA'
  },
  {
    id: 2911253,
    name: 'Gavião',
    uf: 'BA'
  },
  {
    id: 2911303,
    name: 'Gentio do Ouro',
    uf: 'BA'
  },
  {
    id: 2911402,
    name: 'Glória',
    uf: 'BA'
  },
  {
    id: 2911501,
    name: 'Gongogi',
    uf: 'BA'
  },
  {
    id: 2911600,
    name: 'Governador Mangabeira',
    uf: 'BA'
  },
  {
    id: 2911659,
    name: 'Guajeru',
    uf: 'BA'
  },
  {
    id: 2911709,
    name: 'Guanambi',
    uf: 'BA'
  },
  {
    id: 2911808,
    name: 'Guaratinga',
    uf: 'BA'
  },
  {
    id: 2911857,
    name: 'Heliópolis',
    uf: 'BA'
  },
  {
    id: 2911907,
    name: 'Iaçu',
    uf: 'BA'
  },
  {
    id: 2912004,
    name: 'Ibiassucê',
    uf: 'BA'
  },
  {
    id: 2912103,
    name: 'Ibicaraí',
    uf: 'BA'
  },
  {
    id: 2912202,
    name: 'Ibicoara',
    uf: 'BA'
  },
  {
    id: 2912301,
    name: 'Ibicuí',
    uf: 'BA'
  },
  {
    id: 2912400,
    name: 'Ibipeba',
    uf: 'BA'
  },
  {
    id: 2912509,
    name: 'Ibipitanga',
    uf: 'BA'
  },
  {
    id: 2912608,
    name: 'Ibiquera',
    uf: 'BA'
  },
  {
    id: 2912707,
    name: 'Ibirapitanga',
    uf: 'BA'
  },
  {
    id: 2912806,
    name: 'Ibirapuã',
    uf: 'BA'
  },
  {
    id: 2912905,
    name: 'Ibirataia',
    uf: 'BA'
  },
  {
    id: 2913002,
    name: 'Ibitiara',
    uf: 'BA'
  },
  {
    id: 2913101,
    name: 'Ibititá',
    uf: 'BA'
  },
  {
    id: 2913200,
    name: 'Ibotirama',
    uf: 'BA'
  },
  {
    id: 2913309,
    name: 'Ichu',
    uf: 'BA'
  },
  {
    id: 2913408,
    name: 'Igaporã',
    uf: 'BA'
  },
  {
    id: 2913457,
    name: 'Igrapiúna',
    uf: 'BA'
  },
  {
    id: 2913507,
    name: 'Iguaí',
    uf: 'BA'
  },
  {
    id: 2913606,
    name: 'Ilhéus',
    uf: 'BA'
  },
  {
    id: 2913705,
    name: 'Inhambupe',
    uf: 'BA'
  },
  {
    id: 2913804,
    name: 'Ipecaetá',
    uf: 'BA'
  },
  {
    id: 2913903,
    name: 'Ipiaú',
    uf: 'BA'
  },
  {
    id: 2914000,
    name: 'Ipirá',
    uf: 'BA'
  },
  {
    id: 2914109,
    name: 'Ipupiara',
    uf: 'BA'
  },
  {
    id: 2914208,
    name: 'Irajuba',
    uf: 'BA'
  },
  {
    id: 2914307,
    name: 'Iramaia',
    uf: 'BA'
  },
  {
    id: 2914406,
    name: 'Iraquara',
    uf: 'BA'
  },
  {
    id: 2914505,
    name: 'Irará',
    uf: 'BA'
  },
  {
    id: 2914604,
    name: 'Irecê',
    uf: 'BA'
  },
  {
    id: 2914653,
    name: 'Itabela',
    uf: 'BA'
  },
  {
    id: 2914703,
    name: 'Itaberaba',
    uf: 'BA'
  },
  {
    id: 2914802,
    name: 'Itabuna',
    uf: 'BA'
  },
  {
    id: 2914901,
    name: 'Itacaré',
    uf: 'BA'
  },
  {
    id: 2915007,
    name: 'Itaeté',
    uf: 'BA'
  },
  {
    id: 2915106,
    name: 'Itagi',
    uf: 'BA'
  },
  {
    id: 2915205,
    name: 'Itagibá',
    uf: 'BA'
  },
  {
    id: 2915304,
    name: 'Itagimirim',
    uf: 'BA'
  },
  {
    id: 2915353,
    name: 'Itaguaçu da Bahia',
    uf: 'BA'
  },
  {
    id: 2915403,
    name: 'Itaju do Colônia',
    uf: 'BA'
  },
  {
    id: 2915502,
    name: 'Itajuípe',
    uf: 'BA'
  },
  {
    id: 2915601,
    name: 'Itamaraju',
    uf: 'BA'
  },
  {
    id: 2915700,
    name: 'Itamari',
    uf: 'BA'
  },
  {
    id: 2915809,
    name: 'Itambé',
    uf: 'BA'
  },
  {
    id: 2915908,
    name: 'Itanagra',
    uf: 'BA'
  },
  {
    id: 2916005,
    name: 'Itanhém',
    uf: 'BA'
  },
  {
    id: 2916104,
    name: 'Itaparica',
    uf: 'BA'
  },
  {
    id: 2916203,
    name: 'Itapé',
    uf: 'BA'
  },
  {
    id: 2916302,
    name: 'Itapebi',
    uf: 'BA'
  },
  {
    id: 2916401,
    name: 'Itapetinga',
    uf: 'BA'
  },
  {
    id: 2916500,
    name: 'Itapicuru',
    uf: 'BA'
  },
  {
    id: 2916609,
    name: 'Itapitanga',
    uf: 'BA'
  },
  {
    id: 2916708,
    name: 'Itaquara',
    uf: 'BA'
  },
  {
    id: 2916807,
    name: 'Itarantim',
    uf: 'BA'
  },
  {
    id: 2916856,
    name: 'Itatim',
    uf: 'BA'
  },
  {
    id: 2916906,
    name: 'Itiruçu',
    uf: 'BA'
  },
  {
    id: 2917003,
    name: 'Itiúba',
    uf: 'BA'
  },
  {
    id: 2917102,
    name: 'Itororó',
    uf: 'BA'
  },
  {
    id: 2917201,
    name: 'Ituaçu',
    uf: 'BA'
  },
  {
    id: 2917300,
    name: 'Ituberá',
    uf: 'BA'
  },
  {
    id: 2917334,
    name: 'Iuiu',
    uf: 'BA'
  },
  {
    id: 2917359,
    name: 'Jaborandi',
    uf: 'BA'
  },
  {
    id: 2917409,
    name: 'Jacaraci',
    uf: 'BA'
  },
  {
    id: 2917508,
    name: 'Jacobina',
    uf: 'BA'
  },
  {
    id: 2917607,
    name: 'Jaguaquara',
    uf: 'BA'
  },
  {
    id: 2917706,
    name: 'Jaguarari',
    uf: 'BA'
  },
  {
    id: 2917805,
    name: 'Jaguaripe',
    uf: 'BA'
  },
  {
    id: 2917904,
    name: 'Jandaíra',
    uf: 'BA'
  },
  {
    id: 2918001,
    name: 'Jequié',
    uf: 'BA'
  },
  {
    id: 2918100,
    name: 'Jeremoabo',
    uf: 'BA'
  },
  {
    id: 2918209,
    name: 'Jiquiriçá',
    uf: 'BA'
  },
  {
    id: 2918308,
    name: 'Jitaúna',
    uf: 'BA'
  },
  {
    id: 2918357,
    name: 'João Dourado',
    uf: 'BA'
  },
  {
    id: 2918407,
    name: 'Juazeiro',
    uf: 'BA'
  },
  {
    id: 2918456,
    name: 'Jucuruçu',
    uf: 'BA'
  },
  {
    id: 2918506,
    name: 'Jussara',
    uf: 'BA'
  },
  {
    id: 2918555,
    name: 'Jussari',
    uf: 'BA'
  },
  {
    id: 2918605,
    name: 'Jussiape',
    uf: 'BA'
  },
  {
    id: 2918704,
    name: 'Lafaiete Coutinho',
    uf: 'BA'
  },
  {
    id: 2918753,
    name: 'Lagoa Real',
    uf: 'BA'
  },
  {
    id: 2918803,
    name: 'Laje',
    uf: 'BA'
  },
  {
    id: 2918902,
    name: 'Lajedão',
    uf: 'BA'
  },
  {
    id: 2919009,
    name: 'Lajedinho',
    uf: 'BA'
  },
  {
    id: 2919058,
    name: 'Lajedo do Tabocal',
    uf: 'BA'
  },
  {
    id: 2919108,
    name: 'Lamarão',
    uf: 'BA'
  },
  {
    id: 2919157,
    name: 'Lapão',
    uf: 'BA'
  },
  {
    id: 2919207,
    name: 'Lauro de Freitas',
    uf: 'BA'
  },
  {
    id: 2919306,
    name: 'Lençóis',
    uf: 'BA'
  },
  {
    id: 2919405,
    name: 'Licínio de Almeida',
    uf: 'BA'
  },
  {
    id: 2919504,
    name: 'Livramento de Nossa Senhora',
    uf: 'BA'
  },
  {
    id: 2919553,
    name: 'Luís Eduardo Magalhães',
    uf: 'BA'
  },
  {
    id: 2919603,
    name: 'Macajuba',
    uf: 'BA'
  },
  {
    id: 2919702,
    name: 'Macarani',
    uf: 'BA'
  },
  {
    id: 2919801,
    name: 'Macaúbas',
    uf: 'BA'
  },
  {
    id: 2919900,
    name: 'Macururé',
    uf: 'BA'
  },
  {
    id: 2919926,
    name: 'Madre de Deus',
    uf: 'BA'
  },
  {
    id: 2919959,
    name: 'Maetinga',
    uf: 'BA'
  },
  {
    id: 2920007,
    name: 'Maiquinique',
    uf: 'BA'
  },
  {
    id: 2920106,
    name: 'Mairi',
    uf: 'BA'
  },
  {
    id: 2920205,
    name: 'Malhada',
    uf: 'BA'
  },
  {
    id: 2920304,
    name: 'Malhada de Pedras',
    uf: 'BA'
  },
  {
    id: 2920403,
    name: 'Manoel Vitorino',
    uf: 'BA'
  },
  {
    id: 2920452,
    name: 'Mansidão',
    uf: 'BA'
  },
  {
    id: 2920502,
    name: 'Maracás',
    uf: 'BA'
  },
  {
    id: 2920601,
    name: 'Maragogipe',
    uf: 'BA'
  },
  {
    id: 2920700,
    name: 'Maraú',
    uf: 'BA'
  },
  {
    id: 2920809,
    name: 'Marcionílio Souza',
    uf: 'BA'
  },
  {
    id: 2920908,
    name: 'Mascote',
    uf: 'BA'
  },
  {
    id: 2921005,
    name: 'Mata de São João',
    uf: 'BA'
  },
  {
    id: 2921054,
    name: 'Matina',
    uf: 'BA'
  },
  {
    id: 2921104,
    name: 'Medeiros Neto',
    uf: 'BA'
  },
  {
    id: 2921203,
    name: 'Miguel Calmon',
    uf: 'BA'
  },
  {
    id: 2921302,
    name: 'Milagres',
    uf: 'BA'
  },
  {
    id: 2921401,
    name: 'Mirangaba',
    uf: 'BA'
  },
  {
    id: 2921450,
    name: 'Mirante',
    uf: 'BA'
  },
  {
    id: 2921500,
    name: 'Monte Santo',
    uf: 'BA'
  },
  {
    id: 2921609,
    name: 'Morpará',
    uf: 'BA'
  },
  {
    id: 2921708,
    name: 'Morro do Chapéu',
    uf: 'BA'
  },
  {
    id: 2921807,
    name: 'Mortugaba',
    uf: 'BA'
  },
  {
    id: 2921906,
    name: 'Mucugê',
    uf: 'BA'
  },
  {
    id: 2922003,
    name: 'Mucuri',
    uf: 'BA'
  },
  {
    id: 2922052,
    name: 'Mulungu do Morro',
    uf: 'BA'
  },
  {
    id: 2922102,
    name: 'Mundo Novo',
    uf: 'BA'
  },
  {
    id: 2922201,
    name: 'Muniz Ferreira',
    uf: 'BA'
  },
  {
    id: 2922250,
    name: 'Muquém do São Francisco',
    uf: 'BA'
  },
  {
    id: 2922300,
    name: 'Muritiba',
    uf: 'BA'
  },
  {
    id: 2922409,
    name: 'Mutuípe',
    uf: 'BA'
  },
  {
    id: 2922508,
    name: 'Nazaré',
    uf: 'BA'
  },
  {
    id: 2922607,
    name: 'Nilo Peçanha',
    uf: 'BA'
  },
  {
    id: 2922656,
    name: 'Nordestina',
    uf: 'BA'
  },
  {
    id: 2922706,
    name: 'Nova Canaã',
    uf: 'BA'
  },
  {
    id: 2922730,
    name: 'Nova Fátima',
    uf: 'BA'
  },
  {
    id: 2922755,
    name: 'Nova Ibiá',
    uf: 'BA'
  },
  {
    id: 2922805,
    name: 'Nova Itarana',
    uf: 'BA'
  },
  {
    id: 2922854,
    name: 'Nova Redenção',
    uf: 'BA'
  },
  {
    id: 2922904,
    name: 'Nova Soure',
    uf: 'BA'
  },
  {
    id: 2923001,
    name: 'Nova Viçosa',
    uf: 'BA'
  },
  {
    id: 2923035,
    name: 'Novo Horizonte',
    uf: 'BA'
  },
  {
    id: 2923050,
    name: 'Novo Triunfo',
    uf: 'BA'
  },
  {
    id: 2923100,
    name: 'Olindina',
    uf: 'BA'
  },
  {
    id: 2923209,
    name: 'Oliveira dos Brejinhos',
    uf: 'BA'
  },
  {
    id: 2923308,
    name: 'Ouriçangas',
    uf: 'BA'
  },
  {
    id: 2923357,
    name: 'Ourolândia',
    uf: 'BA'
  },
  {
    id: 2923407,
    name: 'Palmas de Monte Alto',
    uf: 'BA'
  },
  {
    id: 2923506,
    name: 'Palmeiras',
    uf: 'BA'
  },
  {
    id: 2923605,
    name: 'Paramirim',
    uf: 'BA'
  },
  {
    id: 2923704,
    name: 'Paratinga',
    uf: 'BA'
  },
  {
    id: 2923803,
    name: 'Paripiranga',
    uf: 'BA'
  },
  {
    id: 2923902,
    name: 'Pau Brasil',
    uf: 'BA'
  },
  {
    id: 2924009,
    name: 'Paulo Afonso',
    uf: 'BA'
  },
  {
    id: 2924058,
    name: 'Pé de Serra',
    uf: 'BA'
  },
  {
    id: 2924108,
    name: 'Pedrão',
    uf: 'BA'
  },
  {
    id: 2924207,
    name: 'Pedro Alexandre',
    uf: 'BA'
  },
  {
    id: 2924306,
    name: 'Piatã',
    uf: 'BA'
  },
  {
    id: 2924405,
    name: 'Pilão Arcado',
    uf: 'BA'
  },
  {
    id: 2924504,
    name: 'Pindaí',
    uf: 'BA'
  },
  {
    id: 2924603,
    name: 'Pindobaçu',
    uf: 'BA'
  },
  {
    id: 2924652,
    name: 'Pintadas',
    uf: 'BA'
  },
  {
    id: 2924678,
    name: 'Piraí do Norte',
    uf: 'BA'
  },
  {
    id: 2924702,
    name: 'Piripá',
    uf: 'BA'
  },
  {
    id: 2924801,
    name: 'Piritiba',
    uf: 'BA'
  },
  {
    id: 2924900,
    name: 'Planaltino',
    uf: 'BA'
  },
  {
    id: 2925006,
    name: 'Planalto',
    uf: 'BA'
  },
  {
    id: 2925105,
    name: 'Poções',
    uf: 'BA'
  },
  {
    id: 2925204,
    name: 'Pojuca',
    uf: 'BA'
  },
  {
    id: 2925253,
    name: 'Ponto Novo',
    uf: 'BA'
  },
  {
    id: 2925303,
    name: 'Porto Seguro',
    uf: 'BA'
  },
  {
    id: 2925402,
    name: 'Potiraguá',
    uf: 'BA'
  },
  {
    id: 2925501,
    name: 'Prado',
    uf: 'BA'
  },
  {
    id: 2925600,
    name: 'Presidente Dutra',
    uf: 'BA'
  },
  {
    id: 2925709,
    name: 'Presidente Jânio Quadros',
    uf: 'BA'
  },
  {
    id: 2925758,
    name: 'Presidente Tancredo Neves',
    uf: 'BA'
  },
  {
    id: 2925808,
    name: 'Queimadas',
    uf: 'BA'
  },
  {
    id: 2925907,
    name: 'Quijingue',
    uf: 'BA'
  },
  {
    id: 2925931,
    name: 'Quixabeira',
    uf: 'BA'
  },
  {
    id: 2925956,
    name: 'Rafael Jambeiro',
    uf: 'BA'
  },
  {
    id: 2926004,
    name: 'Remanso',
    uf: 'BA'
  },
  {
    id: 2926103,
    name: 'Retirolândia',
    uf: 'BA'
  },
  {
    id: 2926202,
    name: 'Riachão das Neves',
    uf: 'BA'
  },
  {
    id: 2926301,
    name: 'Riachão do Jacuípe',
    uf: 'BA'
  },
  {
    id: 2926400,
    name: 'Riacho de Santana',
    uf: 'BA'
  },
  {
    id: 2926509,
    name: 'Ribeira do Amparo',
    uf: 'BA'
  },
  {
    id: 2926608,
    name: 'Ribeira do Pombal',
    uf: 'BA'
  },
  {
    id: 2926657,
    name: 'Ribeirão do Largo',
    uf: 'BA'
  },
  {
    id: 2926707,
    name: 'Rio de Contas',
    uf: 'BA'
  },
  {
    id: 2926806,
    name: 'Rio do Antônio',
    uf: 'BA'
  },
  {
    id: 2926905,
    name: 'Rio do Pires',
    uf: 'BA'
  },
  {
    id: 2927002,
    name: 'Rio Real',
    uf: 'BA'
  },
  {
    id: 2927101,
    name: 'Rodelas',
    uf: 'BA'
  },
  {
    id: 2927200,
    name: 'Ruy Barbosa',
    uf: 'BA'
  },
  {
    id: 2927309,
    name: 'Salinas da Margarida',
    uf: 'BA'
  },
  {
    id: 2927408,
    name: 'Salvador',
    uf: 'BA'
  },
  {
    id: 2927507,
    name: 'Santa Bárbara',
    uf: 'BA'
  },
  {
    id: 2927606,
    name: 'Santa Brígida',
    uf: 'BA'
  },
  {
    id: 2927705,
    name: 'Santa Cruz Cabrália',
    uf: 'BA'
  },
  {
    id: 2927804,
    name: 'Santa Cruz da Vitória',
    uf: 'BA'
  },
  {
    id: 2927903,
    name: 'Santa Inês',
    uf: 'BA'
  },
  {
    id: 2928000,
    name: 'Santaluz',
    uf: 'BA'
  },
  {
    id: 2928059,
    name: 'Santa Luzia',
    uf: 'BA'
  },
  {
    id: 2928109,
    name: 'Santa Maria da Vitória',
    uf: 'BA'
  },
  {
    id: 2928208,
    name: 'Santana',
    uf: 'BA'
  },
  {
    id: 2928307,
    name: 'Santanópolis',
    uf: 'BA'
  },
  {
    id: 2928406,
    name: 'Santa Rita de Cássia',
    uf: 'BA'
  },
  {
    id: 2928505,
    name: 'Santa Terezinha',
    uf: 'BA'
  },
  {
    id: 2928604,
    name: 'Santo Amaro',
    uf: 'BA'
  },
  {
    id: 2928703,
    name: 'Santo Antônio de Jesus',
    uf: 'BA'
  },
  {
    id: 2928802,
    name: 'Santo Estêvão',
    uf: 'BA'
  },
  {
    id: 2928901,
    name: 'São Desidério',
    uf: 'BA'
  },
  {
    id: 2928950,
    name: 'São Domingos',
    uf: 'BA'
  },
  {
    id: 2929008,
    name: 'São Félix',
    uf: 'BA'
  },
  {
    id: 2929057,
    name: 'São Félix do Coribe',
    uf: 'BA'
  },
  {
    id: 2929107,
    name: 'São Felipe',
    uf: 'BA'
  },
  {
    id: 2929206,
    name: 'São Francisco do Conde',
    uf: 'BA'
  },
  {
    id: 2929255,
    name: 'São Gabriel',
    uf: 'BA'
  },
  {
    id: 2929305,
    name: 'São Gonçalo dos Campos',
    uf: 'BA'
  },
  {
    id: 2929354,
    name: 'São José da Vitória',
    uf: 'BA'
  },
  {
    id: 2929370,
    name: 'São José do Jacuípe',
    uf: 'BA'
  },
  {
    id: 2929404,
    name: 'São Miguel das Matas',
    uf: 'BA'
  },
  {
    id: 2929503,
    name: 'São Sebastião do Passé',
    uf: 'BA'
  },
  {
    id: 2929602,
    name: 'Sapeaçu',
    uf: 'BA'
  },
  {
    id: 2929701,
    name: 'Sátiro Dias',
    uf: 'BA'
  },
  {
    id: 2929750,
    name: 'Saubara',
    uf: 'BA'
  },
  {
    id: 2929800,
    name: 'Saúde',
    uf: 'BA'
  },
  {
    id: 2929909,
    name: 'Seabra',
    uf: 'BA'
  },
  {
    id: 2930006,
    name: 'Sebastião Laranjeiras',
    uf: 'BA'
  },
  {
    id: 2930105,
    name: 'Senhor do Bonfim',
    uf: 'BA'
  },
  {
    id: 2930154,
    name: 'Serra do Ramalho',
    uf: 'BA'
  },
  {
    id: 2930204,
    name: 'Sento Sé',
    uf: 'BA'
  },
  {
    id: 2930303,
    name: 'Serra Dourada',
    uf: 'BA'
  },
  {
    id: 2930402,
    name: 'Serra Preta',
    uf: 'BA'
  },
  {
    id: 2930501,
    name: 'Serrinha',
    uf: 'BA'
  },
  {
    id: 2930600,
    name: 'Serrolândia',
    uf: 'BA'
  },
  {
    id: 2930709,
    name: 'Simões Filho',
    uf: 'BA'
  },
  {
    id: 2930758,
    name: 'Sítio do Mato',
    uf: 'BA'
  },
  {
    id: 2930766,
    name: 'Sítio do Quinto',
    uf: 'BA'
  },
  {
    id: 2930774,
    name: 'Sobradinho',
    uf: 'BA'
  },
  {
    id: 2930808,
    name: 'Souto Soares',
    uf: 'BA'
  },
  {
    id: 2930907,
    name: 'Tabocas do Brejo Velho',
    uf: 'BA'
  },
  {
    id: 2931004,
    name: 'Tanhaçu',
    uf: 'BA'
  },
  {
    id: 2931053,
    name: 'Tanque Novo',
    uf: 'BA'
  },
  {
    id: 2931103,
    name: 'Tanquinho',
    uf: 'BA'
  },
  {
    id: 2931202,
    name: 'Taperoá',
    uf: 'BA'
  },
  {
    id: 2931301,
    name: 'Tapiramutá',
    uf: 'BA'
  },
  {
    id: 2931350,
    name: 'Teixeira de Freitas',
    uf: 'BA'
  },
  {
    id: 2931400,
    name: 'Teodoro Sampaio',
    uf: 'BA'
  },
  {
    id: 2931509,
    name: 'Teofilândia',
    uf: 'BA'
  },
  {
    id: 2931608,
    name: 'Teolândia',
    uf: 'BA'
  },
  {
    id: 2931707,
    name: 'Terra Nova',
    uf: 'BA'
  },
  {
    id: 2931806,
    name: 'Tremedal',
    uf: 'BA'
  },
  {
    id: 2931905,
    name: 'Tucano',
    uf: 'BA'
  },
  {
    id: 2932002,
    name: 'Uauá',
    uf: 'BA'
  },
  {
    id: 2932101,
    name: 'Ubaíra',
    uf: 'BA'
  },
  {
    id: 2932200,
    name: 'Ubaitaba',
    uf: 'BA'
  },
  {
    id: 2932309,
    name: 'Ubatã',
    uf: 'BA'
  },
  {
    id: 2932408,
    name: 'Uibaí',
    uf: 'BA'
  },
  {
    id: 2932457,
    name: 'Umburanas',
    uf: 'BA'
  },
  {
    id: 2932507,
    name: 'Una',
    uf: 'BA'
  },
  {
    id: 2932606,
    name: 'Urandi',
    uf: 'BA'
  },
  {
    id: 2932705,
    name: 'Uruçuca',
    uf: 'BA'
  },
  {
    id: 2932804,
    name: 'Utinga',
    uf: 'BA'
  },
  {
    id: 2932903,
    name: 'Valença',
    uf: 'BA'
  },
  {
    id: 2933000,
    name: 'Valente',
    uf: 'BA'
  },
  {
    id: 2933059,
    name: 'Várzea da Roça',
    uf: 'BA'
  },
  {
    id: 2933109,
    name: 'Várzea do Poço',
    uf: 'BA'
  },
  {
    id: 2933158,
    name: 'Várzea Nova',
    uf: 'BA'
  },
  {
    id: 2933174,
    name: 'Varzedo',
    uf: 'BA'
  },
  {
    id: 2933208,
    name: 'Vera Cruz',
    uf: 'BA'
  },
  {
    id: 2933257,
    name: 'Vereda',
    uf: 'BA'
  },
  {
    id: 2933307,
    name: 'Vitória da Conquista',
    uf: 'BA'
  },
  {
    id: 2933406,
    name: 'Wagner',
    uf: 'BA'
  },
  {
    id: 2933455,
    name: 'Wanderley',
    uf: 'BA'
  },
  {
    id: 2933505,
    name: 'Wenceslau Guimarães',
    uf: 'BA'
  },
  {
    id: 2933604,
    name: 'Xique-Xique',
    uf: 'BA'
  },
  {
    id: 3100104,
    name: 'Abadia dos Dourados',
    uf: 'MG'
  },
  {
    id: 3100203,
    name: 'Abaeté',
    uf: 'MG'
  },
  {
    id: 3100302,
    name: 'Abre Campo',
    uf: 'MG'
  },
  {
    id: 3100401,
    name: 'Acaiaca',
    uf: 'MG'
  },
  {
    id: 3100500,
    name: 'Açucena',
    uf: 'MG'
  },
  {
    id: 3100609,
    name: 'Água Boa',
    uf: 'MG'
  },
  {
    id: 3100708,
    name: 'Água Comprida',
    uf: 'MG'
  },
  {
    id: 3100807,
    name: 'Aguanil',
    uf: 'MG'
  },
  {
    id: 3100906,
    name: 'Águas Formosas',
    uf: 'MG'
  },
  {
    id: 3101003,
    name: 'Águas Vermelhas',
    uf: 'MG'
  },
  {
    id: 3101102,
    name: 'Aimorés',
    uf: 'MG'
  },
  {
    id: 3101201,
    name: 'Aiuruoca',
    uf: 'MG'
  },
  {
    id: 3101300,
    name: 'Alagoa',
    uf: 'MG'
  },
  {
    id: 3101409,
    name: 'Albertina',
    uf: 'MG'
  },
  {
    id: 3101508,
    name: 'Além Paraíba',
    uf: 'MG'
  },
  {
    id: 3101607,
    name: 'Alfenas',
    uf: 'MG'
  },
  {
    id: 3101631,
    name: 'Alfredo Vasconcelos',
    uf: 'MG'
  },
  {
    id: 3101706,
    name: 'Almenara',
    uf: 'MG'
  },
  {
    id: 3101805,
    name: 'Alpercata',
    uf: 'MG'
  },
  {
    id: 3101904,
    name: 'Alpinópolis',
    uf: 'MG'
  },
  {
    id: 3102001,
    name: 'Alterosa',
    uf: 'MG'
  },
  {
    id: 3102050,
    name: 'Alto Caparaó',
    uf: 'MG'
  },
  {
    id: 3102100,
    name: 'Alto Rio Doce',
    uf: 'MG'
  },
  {
    id: 3102209,
    name: 'Alvarenga',
    uf: 'MG'
  },
  {
    id: 3102308,
    name: 'Alvinópolis',
    uf: 'MG'
  },
  {
    id: 3102407,
    name: 'Alvorada de Minas',
    uf: 'MG'
  },
  {
    id: 3102506,
    name: 'Amparo do Serra',
    uf: 'MG'
  },
  {
    id: 3102605,
    name: 'Andradas',
    uf: 'MG'
  },
  {
    id: 3102704,
    name: 'Cachoeira de Pajeú',
    uf: 'MG'
  },
  {
    id: 3102803,
    name: 'Andrelândia',
    uf: 'MG'
  },
  {
    id: 3102852,
    name: 'Angelândia',
    uf: 'MG'
  },
  {
    id: 3102902,
    name: 'Antônio Carlos',
    uf: 'MG'
  },
  {
    id: 3103009,
    name: 'Antônio Dias',
    uf: 'MG'
  },
  {
    id: 3103108,
    name: 'Antônio Prado de Minas',
    uf: 'MG'
  },
  {
    id: 3103207,
    name: 'Araçaí',
    uf: 'MG'
  },
  {
    id: 3103306,
    name: 'Aracitaba',
    uf: 'MG'
  },
  {
    id: 3103405,
    name: 'Araçuaí',
    uf: 'MG'
  },
  {
    id: 3103504,
    name: 'Araguari',
    uf: 'MG'
  },
  {
    id: 3103603,
    name: 'Arantina',
    uf: 'MG'
  },
  {
    id: 3103702,
    name: 'Araponga',
    uf: 'MG'
  },
  {
    id: 3103751,
    name: 'Araporã',
    uf: 'MG'
  },
  {
    id: 3103801,
    name: 'Arapuá',
    uf: 'MG'
  },
  {
    id: 3103900,
    name: 'Araújos',
    uf: 'MG'
  },
  {
    id: 3104007,
    name: 'Araxá',
    uf: 'MG'
  },
  {
    id: 3104106,
    name: 'Arceburgo',
    uf: 'MG'
  },
  {
    id: 3104205,
    name: 'Arcos',
    uf: 'MG'
  },
  {
    id: 3104304,
    name: 'Areado',
    uf: 'MG'
  },
  {
    id: 3104403,
    name: 'Argirita',
    uf: 'MG'
  },
  {
    id: 3104452,
    name: 'Aricanduva',
    uf: 'MG'
  },
  {
    id: 3104502,
    name: 'Arinos',
    uf: 'MG'
  },
  {
    id: 3104601,
    name: 'Astolfo Dutra',
    uf: 'MG'
  },
  {
    id: 3104700,
    name: 'Ataléia',
    uf: 'MG'
  },
  {
    id: 3104809,
    name: 'Augusto de Lima',
    uf: 'MG'
  },
  {
    id: 3104908,
    name: 'Baependi',
    uf: 'MG'
  },
  {
    id: 3105004,
    name: 'Baldim',
    uf: 'MG'
  },
  {
    id: 3105103,
    name: 'Bambuí',
    uf: 'MG'
  },
  {
    id: 3105202,
    name: 'Bandeira',
    uf: 'MG'
  },
  {
    id: 3105301,
    name: 'Bandeira do Sul',
    uf: 'MG'
  },
  {
    id: 3105400,
    name: 'Barão de Cocais',
    uf: 'MG'
  },
  {
    id: 3105509,
    name: 'Barão de Monte Alto',
    uf: 'MG'
  },
  {
    id: 3105608,
    name: 'Barbacena',
    uf: 'MG'
  },
  {
    id: 3105707,
    name: 'Barra Longa',
    uf: 'MG'
  },
  {
    id: 3105905,
    name: 'Barroso',
    uf: 'MG'
  },
  {
    id: 3106002,
    name: 'Bela Vista de Minas',
    uf: 'MG'
  },
  {
    id: 3106101,
    name: 'Belmiro Braga',
    uf: 'MG'
  },
  {
    id: 3106200,
    name: 'Belo Horizonte',
    uf: 'MG'
  },
  {
    id: 3106309,
    name: 'Belo Oriente',
    uf: 'MG'
  },
  {
    id: 3106408,
    name: 'Belo Vale',
    uf: 'MG'
  },
  {
    id: 3106507,
    name: 'Berilo',
    uf: 'MG'
  },
  {
    id: 3106606,
    name: 'Bertópolis',
    uf: 'MG'
  },
  {
    id: 3106655,
    name: 'Berizal',
    uf: 'MG'
  },
  {
    id: 3106705,
    name: 'Betim',
    uf: 'MG'
  },
  {
    id: 3106804,
    name: 'Bias Fortes',
    uf: 'MG'
  },
  {
    id: 3106903,
    name: 'Bicas',
    uf: 'MG'
  },
  {
    id: 3107000,
    name: 'Biquinhas',
    uf: 'MG'
  },
  {
    id: 3107109,
    name: 'Boa Esperança',
    uf: 'MG'
  },
  {
    id: 3107208,
    name: 'Bocaina de Minas',
    uf: 'MG'
  },
  {
    id: 3107307,
    name: 'Bocaiúva',
    uf: 'MG'
  },
  {
    id: 3107406,
    name: 'Bom Despacho',
    uf: 'MG'
  },
  {
    id: 3107505,
    name: 'Bom Jardim de Minas',
    uf: 'MG'
  },
  {
    id: 3107604,
    name: 'Bom Jesus da Penha',
    uf: 'MG'
  },
  {
    id: 3107703,
    name: 'Bom Jesus do Amparo',
    uf: 'MG'
  },
  {
    id: 3107802,
    name: 'Bom Jesus do Galho',
    uf: 'MG'
  },
  {
    id: 3107901,
    name: 'Bom Repouso',
    uf: 'MG'
  },
  {
    id: 3108008,
    name: 'Bom Sucesso',
    uf: 'MG'
  },
  {
    id: 3108107,
    name: 'Bonfim',
    uf: 'MG'
  },
  {
    id: 3108206,
    name: 'Bonfinópolis de Minas',
    uf: 'MG'
  },
  {
    id: 3108255,
    name: 'Bonito de Minas',
    uf: 'MG'
  },
  {
    id: 3108305,
    name: 'Borda da Mata',
    uf: 'MG'
  },
  {
    id: 3108404,
    name: 'Botelhos',
    uf: 'MG'
  },
  {
    id: 3108503,
    name: 'Botumirim',
    uf: 'MG'
  },
  {
    id: 3108552,
    name: 'Brasilândia de Minas',
    uf: 'MG'
  },
  {
    id: 3108602,
    name: 'Brasília de Minas',
    uf: 'MG'
  },
  {
    id: 3108701,
    name: 'Brás Pires',
    uf: 'MG'
  },
  {
    id: 3108800,
    name: 'Braúnas',
    uf: 'MG'
  },
  {
    id: 3108909,
    name: 'Brazópolis',
    uf: 'MG'
  },
  {
    id: 3109006,
    name: 'Brumadinho',
    uf: 'MG'
  },
  {
    id: 3109105,
    name: 'Bueno Brandão',
    uf: 'MG'
  },
  {
    id: 3109204,
    name: 'Buenópolis',
    uf: 'MG'
  },
  {
    id: 3109253,
    name: 'Bugre',
    uf: 'MG'
  },
  {
    id: 3109303,
    name: 'Buritis',
    uf: 'MG'
  },
  {
    id: 3109402,
    name: 'Buritizeiro',
    uf: 'MG'
  },
  {
    id: 3109451,
    name: 'Cabeceira Grande',
    uf: 'MG'
  },
  {
    id: 3109501,
    name: 'Cabo Verde',
    uf: 'MG'
  },
  {
    id: 3109600,
    name: 'Cachoeira da Prata',
    uf: 'MG'
  },
  {
    id: 3109709,
    name: 'Cachoeira de Minas',
    uf: 'MG'
  },
  {
    id: 3109808,
    name: 'Cachoeira Dourada',
    uf: 'MG'
  },
  {
    id: 3109907,
    name: 'Caetanópolis',
    uf: 'MG'
  },
  {
    id: 3110004,
    name: 'Caeté',
    uf: 'MG'
  },
  {
    id: 3110103,
    name: 'Caiana',
    uf: 'MG'
  },
  {
    id: 3110202,
    name: 'Cajuri',
    uf: 'MG'
  },
  {
    id: 3110301,
    name: 'Caldas',
    uf: 'MG'
  },
  {
    id: 3110400,
    name: 'Camacho',
    uf: 'MG'
  },
  {
    id: 3110509,
    name: 'Camanducaia',
    uf: 'MG'
  },
  {
    id: 3110608,
    name: 'Cambuí',
    uf: 'MG'
  },
  {
    id: 3110707,
    name: 'Cambuquira',
    uf: 'MG'
  },
  {
    id: 3110806,
    name: 'Campanário',
    uf: 'MG'
  },
  {
    id: 3110905,
    name: 'Campanha',
    uf: 'MG'
  },
  {
    id: 3111002,
    name: 'Campestre',
    uf: 'MG'
  },
  {
    id: 3111101,
    name: 'Campina Verde',
    uf: 'MG'
  },
  {
    id: 3111150,
    name: 'Campo Azul',
    uf: 'MG'
  },
  {
    id: 3111200,
    name: 'Campo Belo',
    uf: 'MG'
  },
  {
    id: 3111309,
    name: 'Campo do Meio',
    uf: 'MG'
  },
  {
    id: 3111408,
    name: 'Campo Florido',
    uf: 'MG'
  },
  {
    id: 3111507,
    name: 'Campos Altos',
    uf: 'MG'
  },
  {
    id: 3111606,
    name: 'Campos Gerais',
    uf: 'MG'
  },
  {
    id: 3111705,
    name: 'Canaã',
    uf: 'MG'
  },
  {
    id: 3111804,
    name: 'Canápolis',
    uf: 'MG'
  },
  {
    id: 3111903,
    name: 'Cana Verde',
    uf: 'MG'
  },
  {
    id: 3112000,
    name: 'Candeias',
    uf: 'MG'
  },
  {
    id: 3112059,
    name: 'Cantagalo',
    uf: 'MG'
  },
  {
    id: 3112109,
    name: 'Caparaó',
    uf: 'MG'
  },
  {
    id: 3112208,
    name: 'Capela Nova',
    uf: 'MG'
  },
  {
    id: 3112307,
    name: 'Capelinha',
    uf: 'MG'
  },
  {
    id: 3112406,
    name: 'Capetinga',
    uf: 'MG'
  },
  {
    id: 3112505,
    name: 'Capim Branco',
    uf: 'MG'
  },
  {
    id: 3112604,
    name: 'Capinópolis',
    uf: 'MG'
  },
  {
    id: 3112653,
    name: 'Capitão Andrade',
    uf: 'MG'
  },
  {
    id: 3112703,
    name: 'Capitão Enéas',
    uf: 'MG'
  },
  {
    id: 3112802,
    name: 'Capitólio',
    uf: 'MG'
  },
  {
    id: 3112901,
    name: 'Caputira',
    uf: 'MG'
  },
  {
    id: 3113008,
    name: 'Caraí',
    uf: 'MG'
  },
  {
    id: 3113107,
    name: 'Caranaíba',
    uf: 'MG'
  },
  {
    id: 3113206,
    name: 'Carandaí',
    uf: 'MG'
  },
  {
    id: 3113305,
    name: 'Carangola',
    uf: 'MG'
  },
  {
    id: 3113404,
    name: 'Caratinga',
    uf: 'MG'
  },
  {
    id: 3113503,
    name: 'Carbonita',
    uf: 'MG'
  },
  {
    id: 3113602,
    name: 'Careaçu',
    uf: 'MG'
  },
  {
    id: 3113701,
    name: 'Carlos Chagas',
    uf: 'MG'
  },
  {
    id: 3113800,
    name: 'Carmésia',
    uf: 'MG'
  },
  {
    id: 3113909,
    name: 'Carmo da Cachoeira',
    uf: 'MG'
  },
  {
    id: 3114006,
    name: 'Carmo da Mata',
    uf: 'MG'
  },
  {
    id: 3114105,
    name: 'Carmo de Minas',
    uf: 'MG'
  },
  {
    id: 3114204,
    name: 'Carmo do Cajuru',
    uf: 'MG'
  },
  {
    id: 3114303,
    name: 'Carmo do Paranaíba',
    uf: 'MG'
  },
  {
    id: 3114402,
    name: 'Carmo do Rio Claro',
    uf: 'MG'
  },
  {
    id: 3114501,
    name: 'Carmópolis de Minas',
    uf: 'MG'
  },
  {
    id: 3114550,
    name: 'Carneirinho',
    uf: 'MG'
  },
  {
    id: 3114600,
    name: 'Carrancas',
    uf: 'MG'
  },
  {
    id: 3114709,
    name: 'Carvalhópolis',
    uf: 'MG'
  },
  {
    id: 3114808,
    name: 'Carvalhos',
    uf: 'MG'
  },
  {
    id: 3114907,
    name: 'Casa Grande',
    uf: 'MG'
  },
  {
    id: 3115003,
    name: 'Cascalho Rico',
    uf: 'MG'
  },
  {
    id: 3115102,
    name: 'Cássia',
    uf: 'MG'
  },
  {
    id: 3115201,
    name: 'Conceição da Barra de Minas',
    uf: 'MG'
  },
  {
    id: 3115300,
    name: 'Cataguases',
    uf: 'MG'
  },
  {
    id: 3115359,
    name: 'Catas Altas',
    uf: 'MG'
  },
  {
    id: 3115409,
    name: 'Catas Altas da Noruega',
    uf: 'MG'
  },
  {
    id: 3115458,
    name: 'Catuji',
    uf: 'MG'
  },
  {
    id: 3115474,
    name: 'Catuti',
    uf: 'MG'
  },
  {
    id: 3115508,
    name: 'Caxambu',
    uf: 'MG'
  },
  {
    id: 3115607,
    name: 'Cedro do Abaeté',
    uf: 'MG'
  },
  {
    id: 3115706,
    name: 'Central de Minas',
    uf: 'MG'
  },
  {
    id: 3115805,
    name: 'Centralina',
    uf: 'MG'
  },
  {
    id: 3115904,
    name: 'Chácara',
    uf: 'MG'
  },
  {
    id: 3116001,
    name: 'Chalé',
    uf: 'MG'
  },
  {
    id: 3116100,
    name: 'Chapada do Norte',
    uf: 'MG'
  },
  {
    id: 3116159,
    name: 'Chapada Gaúcha',
    uf: 'MG'
  },
  {
    id: 3116209,
    name: 'Chiador',
    uf: 'MG'
  },
  {
    id: 3116308,
    name: 'Cipotânea',
    uf: 'MG'
  },
  {
    id: 3116407,
    name: 'Claraval',
    uf: 'MG'
  },
  {
    id: 3116506,
    name: 'Claro dos Poções',
    uf: 'MG'
  },
  {
    id: 3116605,
    name: 'Cláudio',
    uf: 'MG'
  },
  {
    id: 3116704,
    name: 'Coimbra',
    uf: 'MG'
  },
  {
    id: 3116803,
    name: 'Coluna',
    uf: 'MG'
  },
  {
    id: 3116902,
    name: 'Comendador Gomes',
    uf: 'MG'
  },
  {
    id: 3117009,
    name: 'Comercinho',
    uf: 'MG'
  },
  {
    id: 3117108,
    name: 'Conceição da Aparecida',
    uf: 'MG'
  },
  {
    id: 3117207,
    name: 'Conceição das Pedras',
    uf: 'MG'
  },
  {
    id: 3117306,
    name: 'Conceição das Alagoas',
    uf: 'MG'
  },
  {
    id: 3117405,
    name: 'Conceição de Ipanema',
    uf: 'MG'
  },
  {
    id: 3117504,
    name: 'Conceição do Mato Dentro',
    uf: 'MG'
  },
  {
    id: 3117603,
    name: 'Conceição do Pará',
    uf: 'MG'
  },
  {
    id: 3117702,
    name: 'Conceição do Rio Verde',
    uf: 'MG'
  },
  {
    id: 3117801,
    name: 'Conceição dos Ouros',
    uf: 'MG'
  },
  {
    id: 3117836,
    name: 'Cônego Marinho',
    uf: 'MG'
  },
  {
    id: 3117876,
    name: 'Confins',
    uf: 'MG'
  },
  {
    id: 3117900,
    name: 'Congonhal',
    uf: 'MG'
  },
  {
    id: 3118007,
    name: 'Congonhas',
    uf: 'MG'
  },
  {
    id: 3118106,
    name: 'Congonhas do Norte',
    uf: 'MG'
  },
  {
    id: 3118205,
    name: 'Conquista',
    uf: 'MG'
  },
  {
    id: 3118304,
    name: 'Conselheiro Lafaiete',
    uf: 'MG'
  },
  {
    id: 3118403,
    name: 'Conselheiro Pena',
    uf: 'MG'
  },
  {
    id: 3118502,
    name: 'Consolação',
    uf: 'MG'
  },
  {
    id: 3118601,
    name: 'Contagem',
    uf: 'MG'
  },
  {
    id: 3118700,
    name: 'Coqueiral',
    uf: 'MG'
  },
  {
    id: 3118809,
    name: 'Coração de Jesus',
    uf: 'MG'
  },
  {
    id: 3118908,
    name: 'Cordisburgo',
    uf: 'MG'
  },
  {
    id: 3119005,
    name: 'Cordislândia',
    uf: 'MG'
  },
  {
    id: 3119104,
    name: 'Corinto',
    uf: 'MG'
  },
  {
    id: 3119203,
    name: 'Coroaci',
    uf: 'MG'
  },
  {
    id: 3119302,
    name: 'Coromandel',
    uf: 'MG'
  },
  {
    id: 3119401,
    name: 'Coronel Fabriciano',
    uf: 'MG'
  },
  {
    id: 3119500,
    name: 'Coronel Murta',
    uf: 'MG'
  },
  {
    id: 3119609,
    name: 'Coronel Pacheco',
    uf: 'MG'
  },
  {
    id: 3119708,
    name: 'Coronel Xavier Chaves',
    uf: 'MG'
  },
  {
    id: 3119807,
    name: 'Córrego Danta',
    uf: 'MG'
  },
  {
    id: 3119906,
    name: 'Córrego do Bom Jesus',
    uf: 'MG'
  },
  {
    id: 3119955,
    name: 'Córrego Fundo',
    uf: 'MG'
  },
  {
    id: 3120003,
    name: 'Córrego Novo',
    uf: 'MG'
  },
  {
    id: 3120102,
    name: 'Couto de Magalhães de Minas',
    uf: 'MG'
  },
  {
    id: 3120151,
    name: 'Crisólita',
    uf: 'MG'
  },
  {
    id: 3120201,
    name: 'Cristais',
    uf: 'MG'
  },
  {
    id: 3120300,
    name: 'Cristália',
    uf: 'MG'
  },
  {
    id: 3120409,
    name: 'Cristiano Otoni',
    uf: 'MG'
  },
  {
    id: 3120508,
    name: 'Cristina',
    uf: 'MG'
  },
  {
    id: 3120607,
    name: 'Crucilândia',
    uf: 'MG'
  },
  {
    id: 3120706,
    name: 'Cruzeiro da Fortaleza',
    uf: 'MG'
  },
  {
    id: 3120805,
    name: 'Cruzília',
    uf: 'MG'
  },
  {
    id: 3120839,
    name: 'Cuparaque',
    uf: 'MG'
  },
  {
    id: 3120870,
    name: 'Curral de Dentro',
    uf: 'MG'
  },
  {
    id: 3120904,
    name: 'Curvelo',
    uf: 'MG'
  },
  {
    id: 3121001,
    name: 'Datas',
    uf: 'MG'
  },
  {
    id: 3121100,
    name: 'Delfim Moreira',
    uf: 'MG'
  },
  {
    id: 3121209,
    name: 'Delfinópolis',
    uf: 'MG'
  },
  {
    id: 3121258,
    name: 'Delta',
    uf: 'MG'
  },
  {
    id: 3121308,
    name: 'Descoberto',
    uf: 'MG'
  },
  {
    id: 3121407,
    name: 'Desterro de Entre Rios',
    uf: 'MG'
  },
  {
    id: 3121506,
    name: 'Desterro do Melo',
    uf: 'MG'
  },
  {
    id: 3121605,
    name: 'Diamantina',
    uf: 'MG'
  },
  {
    id: 3121704,
    name: 'Diogo de Vasconcelos',
    uf: 'MG'
  },
  {
    id: 3121803,
    name: 'Dionísio',
    uf: 'MG'
  },
  {
    id: 3121902,
    name: 'Divinésia',
    uf: 'MG'
  },
  {
    id: 3122009,
    name: 'Divino',
    uf: 'MG'
  },
  {
    id: 3122108,
    name: 'Divino das Laranjeiras',
    uf: 'MG'
  },
  {
    id: 3122207,
    name: 'Divinolândia de Minas',
    uf: 'MG'
  },
  {
    id: 3122306,
    name: 'Divinópolis',
    uf: 'MG'
  },
  {
    id: 3122355,
    name: 'Divisa Alegre',
    uf: 'MG'
  },
  {
    id: 3122405,
    name: 'Divisa Nova',
    uf: 'MG'
  },
  {
    id: 3122454,
    name: 'Divisópolis',
    uf: 'MG'
  },
  {
    id: 3122470,
    name: 'Dom Bosco',
    uf: 'MG'
  },
  {
    id: 3122504,
    name: 'Dom Cavati',
    uf: 'MG'
  },
  {
    id: 3122603,
    name: 'Dom Joaquim',
    uf: 'MG'
  },
  {
    id: 3122702,
    name: 'Dom Silvério',
    uf: 'MG'
  },
  {
    id: 3122801,
    name: 'Dom Viçoso',
    uf: 'MG'
  },
  {
    id: 3122900,
    name: 'Dona Euzébia',
    uf: 'MG'
  },
  {
    id: 3123007,
    name: 'Dores de Campos',
    uf: 'MG'
  },
  {
    id: 3123106,
    name: 'Dores de Guanhães',
    uf: 'MG'
  },
  {
    id: 3123205,
    name: 'Dores do Indaiá',
    uf: 'MG'
  },
  {
    id: 3123304,
    name: 'Dores do Turvo',
    uf: 'MG'
  },
  {
    id: 3123403,
    name: 'Doresópolis',
    uf: 'MG'
  },
  {
    id: 3123502,
    name: 'Douradoquara',
    uf: 'MG'
  },
  {
    id: 3123528,
    name: 'Durandé',
    uf: 'MG'
  },
  {
    id: 3123601,
    name: 'Elói Mendes',
    uf: 'MG'
  },
  {
    id: 3123700,
    name: 'Engenheiro Caldas',
    uf: 'MG'
  },
  {
    id: 3123809,
    name: 'Engenheiro Navarro',
    uf: 'MG'
  },
  {
    id: 3123858,
    name: 'Entre Folhas',
    uf: 'MG'
  },
  {
    id: 3123908,
    name: 'Entre Rios de Minas',
    uf: 'MG'
  },
  {
    id: 3124005,
    name: 'Ervália',
    uf: 'MG'
  },
  {
    id: 3124104,
    name: 'Esmeraldas',
    uf: 'MG'
  },
  {
    id: 3124203,
    name: 'Espera Feliz',
    uf: 'MG'
  },
  {
    id: 3124302,
    name: 'Espinosa',
    uf: 'MG'
  },
  {
    id: 3124401,
    name: 'Espírito Santo do Dourado',
    uf: 'MG'
  },
  {
    id: 3124500,
    name: 'Estiva',
    uf: 'MG'
  },
  {
    id: 3124609,
    name: 'Estrela Dalva',
    uf: 'MG'
  },
  {
    id: 3124708,
    name: 'Estrela do Indaiá',
    uf: 'MG'
  },
  {
    id: 3124807,
    name: 'Estrela do Sul',
    uf: 'MG'
  },
  {
    id: 3124906,
    name: 'Eugenópolis',
    uf: 'MG'
  },
  {
    id: 3125002,
    name: 'Ewbank da Câmara',
    uf: 'MG'
  },
  {
    id: 3125101,
    name: 'Extrema',
    uf: 'MG'
  },
  {
    id: 3125200,
    name: 'Fama',
    uf: 'MG'
  },
  {
    id: 3125309,
    name: 'Faria Lemos',
    uf: 'MG'
  },
  {
    id: 3125408,
    name: 'Felício dos Santos',
    uf: 'MG'
  },
  {
    id: 3125507,
    name: 'São Gonçalo do Rio Preto',
    uf: 'MG'
  },
  {
    id: 3125606,
    name: 'Felisburgo',
    uf: 'MG'
  },
  {
    id: 3125705,
    name: 'Felixlândia',
    uf: 'MG'
  },
  {
    id: 3125804,
    name: 'Fernandes Tourinho',
    uf: 'MG'
  },
  {
    id: 3125903,
    name: 'Ferros',
    uf: 'MG'
  },
  {
    id: 3125952,
    name: 'Fervedouro',
    uf: 'MG'
  },
  {
    id: 3126000,
    name: 'Florestal',
    uf: 'MG'
  },
  {
    id: 3126109,
    name: 'Formiga',
    uf: 'MG'
  },
  {
    id: 3126208,
    name: 'Formoso',
    uf: 'MG'
  },
  {
    id: 3126307,
    name: 'Fortaleza de Minas',
    uf: 'MG'
  },
  {
    id: 3126406,
    name: 'Fortuna de Minas',
    uf: 'MG'
  },
  {
    id: 3126505,
    name: 'Francisco Badaró',
    uf: 'MG'
  },
  {
    id: 3126604,
    name: 'Francisco Dumont',
    uf: 'MG'
  },
  {
    id: 3126703,
    name: 'Francisco Sá',
    uf: 'MG'
  },
  {
    id: 3126752,
    name: 'Franciscópolis',
    uf: 'MG'
  },
  {
    id: 3126802,
    name: 'Frei Gaspar',
    uf: 'MG'
  },
  {
    id: 3126901,
    name: 'Frei Inocêncio',
    uf: 'MG'
  },
  {
    id: 3126950,
    name: 'Frei Lagonegro',
    uf: 'MG'
  },
  {
    id: 3127008,
    name: 'Fronteira',
    uf: 'MG'
  },
  {
    id: 3127057,
    name: 'Fronteira dos Vales',
    uf: 'MG'
  },
  {
    id: 3127073,
    name: 'Fruta de Leite',
    uf: 'MG'
  },
  {
    id: 3127107,
    name: 'Frutal',
    uf: 'MG'
  },
  {
    id: 3127206,
    name: 'Funilândia',
    uf: 'MG'
  },
  {
    id: 3127305,
    name: 'Galiléia',
    uf: 'MG'
  },
  {
    id: 3127339,
    name: 'Gameleiras',
    uf: 'MG'
  },
  {
    id: 3127354,
    name: 'Glaucilândia',
    uf: 'MG'
  },
  {
    id: 3127370,
    name: 'Goiabeira',
    uf: 'MG'
  },
  {
    id: 3127388,
    name: 'Goianá',
    uf: 'MG'
  },
  {
    id: 3127404,
    name: 'Gonçalves',
    uf: 'MG'
  },
  {
    id: 3127503,
    name: 'Gonzaga',
    uf: 'MG'
  },
  {
    id: 3127602,
    name: 'Gouveia',
    uf: 'MG'
  },
  {
    id: 3127701,
    name: 'Governador Valadares',
    uf: 'MG'
  },
  {
    id: 3127800,
    name: 'Grão Mogol',
    uf: 'MG'
  },
  {
    id: 3127909,
    name: 'Grupiara',
    uf: 'MG'
  },
  {
    id: 3128006,
    name: 'Guanhães',
    uf: 'MG'
  },
  {
    id: 3128105,
    name: 'Guapé',
    uf: 'MG'
  },
  {
    id: 3128204,
    name: 'Guaraciaba',
    uf: 'MG'
  },
  {
    id: 3128253,
    name: 'Guaraciama',
    uf: 'MG'
  },
  {
    id: 3128303,
    name: 'Guaranésia',
    uf: 'MG'
  },
  {
    id: 3128402,
    name: 'Guarani',
    uf: 'MG'
  },
  {
    id: 3128501,
    name: 'Guarará',
    uf: 'MG'
  },
  {
    id: 3128600,
    name: 'Guarda-Mor',
    uf: 'MG'
  },
  {
    id: 3128709,
    name: 'Guaxupé',
    uf: 'MG'
  },
  {
    id: 3128808,
    name: 'Guidoval',
    uf: 'MG'
  },
  {
    id: 3128907,
    name: 'Guimarânia',
    uf: 'MG'
  },
  {
    id: 3129004,
    name: 'Guiricema',
    uf: 'MG'
  },
  {
    id: 3129103,
    name: 'Gurinhatã',
    uf: 'MG'
  },
  {
    id: 3129202,
    name: 'Heliodora',
    uf: 'MG'
  },
  {
    id: 3129301,
    name: 'Iapu',
    uf: 'MG'
  },
  {
    id: 3129400,
    name: 'Ibertioga',
    uf: 'MG'
  },
  {
    id: 3129509,
    name: 'Ibiá',
    uf: 'MG'
  },
  {
    id: 3129608,
    name: 'Ibiaí',
    uf: 'MG'
  },
  {
    id: 3129657,
    name: 'Ibiracatu',
    uf: 'MG'
  },
  {
    id: 3129707,
    name: 'Ibiraci',
    uf: 'MG'
  },
  {
    id: 3129806,
    name: 'Ibirité',
    uf: 'MG'
  },
  {
    id: 3129905,
    name: 'Ibitiúra de Minas',
    uf: 'MG'
  },
  {
    id: 3130002,
    name: 'Ibituruna',
    uf: 'MG'
  },
  {
    id: 3130051,
    name: 'Icaraí de Minas',
    uf: 'MG'
  },
  {
    id: 3130101,
    name: 'Igarapé',
    uf: 'MG'
  },
  {
    id: 3130200,
    name: 'Igaratinga',
    uf: 'MG'
  },
  {
    id: 3130309,
    name: 'Iguatama',
    uf: 'MG'
  },
  {
    id: 3130408,
    name: 'Ijaci',
    uf: 'MG'
  },
  {
    id: 3130507,
    name: 'Ilicínea',
    uf: 'MG'
  },
  {
    id: 3130556,
    name: 'Imbé de Minas',
    uf: 'MG'
  },
  {
    id: 3130606,
    name: 'Inconfidentes',
    uf: 'MG'
  },
  {
    id: 3130655,
    name: 'Indaiabira',
    uf: 'MG'
  },
  {
    id: 3130705,
    name: 'Indianópolis',
    uf: 'MG'
  },
  {
    id: 3130804,
    name: 'Ingaí',
    uf: 'MG'
  },
  {
    id: 3130903,
    name: 'Inhapim',
    uf: 'MG'
  },
  {
    id: 3131000,
    name: 'Inhaúma',
    uf: 'MG'
  },
  {
    id: 3131109,
    name: 'Inimutaba',
    uf: 'MG'
  },
  {
    id: 3131158,
    name: 'Ipaba',
    uf: 'MG'
  },
  {
    id: 3131208,
    name: 'Ipanema',
    uf: 'MG'
  },
  {
    id: 3131307,
    name: 'Ipatinga',
    uf: 'MG'
  },
  {
    id: 3131406,
    name: 'Ipiaçu',
    uf: 'MG'
  },
  {
    id: 3131505,
    name: 'Ipuiúna',
    uf: 'MG'
  },
  {
    id: 3131604,
    name: 'Iraí de Minas',
    uf: 'MG'
  },
  {
    id: 3131703,
    name: 'Itabira',
    uf: 'MG'
  },
  {
    id: 3131802,
    name: 'Itabirinha',
    uf: 'MG'
  },
  {
    id: 3131901,
    name: 'Itabirito',
    uf: 'MG'
  },
  {
    id: 3132008,
    name: 'Itacambira',
    uf: 'MG'
  },
  {
    id: 3132107,
    name: 'Itacarambi',
    uf: 'MG'
  },
  {
    id: 3132206,
    name: 'Itaguara',
    uf: 'MG'
  },
  {
    id: 3132305,
    name: 'Itaipé',
    uf: 'MG'
  },
  {
    id: 3132404,
    name: 'Itajubá',
    uf: 'MG'
  },
  {
    id: 3132503,
    name: 'Itamarandiba',
    uf: 'MG'
  },
  {
    id: 3132602,
    name: 'Itamarati de Minas',
    uf: 'MG'
  },
  {
    id: 3132701,
    name: 'Itambacuri',
    uf: 'MG'
  },
  {
    id: 3132800,
    name: 'Itambé do Mato Dentro',
    uf: 'MG'
  },
  {
    id: 3132909,
    name: 'Itamogi',
    uf: 'MG'
  },
  {
    id: 3133006,
    name: 'Itamonte',
    uf: 'MG'
  },
  {
    id: 3133105,
    name: 'Itanhandu',
    uf: 'MG'
  },
  {
    id: 3133204,
    name: 'Itanhomi',
    uf: 'MG'
  },
  {
    id: 3133303,
    name: 'Itaobim',
    uf: 'MG'
  },
  {
    id: 3133402,
    name: 'Itapagipe',
    uf: 'MG'
  },
  {
    id: 3133501,
    name: 'Itapecerica',
    uf: 'MG'
  },
  {
    id: 3133600,
    name: 'Itapeva',
    uf: 'MG'
  },
  {
    id: 3133709,
    name: 'Itatiaiuçu',
    uf: 'MG'
  },
  {
    id: 3133758,
    name: 'Itaú de Minas',
    uf: 'MG'
  },
  {
    id: 3133808,
    name: 'Itaúna',
    uf: 'MG'
  },
  {
    id: 3133907,
    name: 'Itaverava',
    uf: 'MG'
  },
  {
    id: 3134004,
    name: 'Itinga',
    uf: 'MG'
  },
  {
    id: 3134103,
    name: 'Itueta',
    uf: 'MG'
  },
  {
    id: 3134202,
    name: 'Ituiutaba',
    uf: 'MG'
  },
  {
    id: 3134301,
    name: 'Itumirim',
    uf: 'MG'
  },
  {
    id: 3134400,
    name: 'Iturama',
    uf: 'MG'
  },
  {
    id: 3134509,
    name: 'Itutinga',
    uf: 'MG'
  },
  {
    id: 3134608,
    name: 'Jaboticatubas',
    uf: 'MG'
  },
  {
    id: 3134707,
    name: 'Jacinto',
    uf: 'MG'
  },
  {
    id: 3134806,
    name: 'Jacuí',
    uf: 'MG'
  },
  {
    id: 3134905,
    name: 'Jacutinga',
    uf: 'MG'
  },
  {
    id: 3135001,
    name: 'Jaguaraçu',
    uf: 'MG'
  },
  {
    id: 3135050,
    name: 'Jaíba',
    uf: 'MG'
  },
  {
    id: 3135076,
    name: 'Jampruca',
    uf: 'MG'
  },
  {
    id: 3135100,
    name: 'Janaúba',
    uf: 'MG'
  },
  {
    id: 3135209,
    name: 'Januária',
    uf: 'MG'
  },
  {
    id: 3135308,
    name: 'Japaraíba',
    uf: 'MG'
  },
  {
    id: 3135357,
    name: 'Japonvar',
    uf: 'MG'
  },
  {
    id: 3135407,
    name: 'Jeceaba',
    uf: 'MG'
  },
  {
    id: 3135456,
    name: 'Jenipapo de Minas',
    uf: 'MG'
  },
  {
    id: 3135506,
    name: 'Jequeri',
    uf: 'MG'
  },
  {
    id: 3135605,
    name: 'Jequitaí',
    uf: 'MG'
  },
  {
    id: 3135704,
    name: 'Jequitibá',
    uf: 'MG'
  },
  {
    id: 3135803,
    name: 'Jequitinhonha',
    uf: 'MG'
  },
  {
    id: 3135902,
    name: 'Jesuânia',
    uf: 'MG'
  },
  {
    id: 3136009,
    name: 'Joaíma',
    uf: 'MG'
  },
  {
    id: 3136108,
    name: 'Joanésia',
    uf: 'MG'
  },
  {
    id: 3136207,
    name: 'João Monlevade',
    uf: 'MG'
  },
  {
    id: 3136306,
    name: 'João Pinheiro',
    uf: 'MG'
  },
  {
    id: 3136405,
    name: 'Joaquim Felício',
    uf: 'MG'
  },
  {
    id: 3136504,
    name: 'Jordânia',
    uf: 'MG'
  },
  {
    id: 3136520,
    name: 'José Gonçalves de Minas',
    uf: 'MG'
  },
  {
    id: 3136553,
    name: 'José Raydan',
    uf: 'MG'
  },
  {
    id: 3136579,
    name: 'Josenópolis',
    uf: 'MG'
  },
  {
    id: 3136603,
    name: 'Nova União',
    uf: 'MG'
  },
  {
    id: 3136652,
    name: 'Juatuba',
    uf: 'MG'
  },
  {
    id: 3136702,
    name: 'Juiz de Fora',
    uf: 'MG'
  },
  {
    id: 3136801,
    name: 'Juramento',
    uf: 'MG'
  },
  {
    id: 3136900,
    name: 'Juruaia',
    uf: 'MG'
  },
  {
    id: 3136959,
    name: 'Juvenília',
    uf: 'MG'
  },
  {
    id: 3137007,
    name: 'Ladainha',
    uf: 'MG'
  },
  {
    id: 3137106,
    name: 'Lagamar',
    uf: 'MG'
  },
  {
    id: 3137205,
    name: 'Lagoa da Prata',
    uf: 'MG'
  },
  {
    id: 3137304,
    name: 'Lagoa dos Patos',
    uf: 'MG'
  },
  {
    id: 3137403,
    name: 'Lagoa Dourada',
    uf: 'MG'
  },
  {
    id: 3137502,
    name: 'Lagoa Formosa',
    uf: 'MG'
  },
  {
    id: 3137536,
    name: 'Lagoa Grande',
    uf: 'MG'
  },
  {
    id: 3137601,
    name: 'Lagoa Santa',
    uf: 'MG'
  },
  {
    id: 3137700,
    name: 'Lajinha',
    uf: 'MG'
  },
  {
    id: 3137809,
    name: 'Lambari',
    uf: 'MG'
  },
  {
    id: 3137908,
    name: 'Lamim',
    uf: 'MG'
  },
  {
    id: 3138005,
    name: 'Laranjal',
    uf: 'MG'
  },
  {
    id: 3138104,
    name: 'Lassance',
    uf: 'MG'
  },
  {
    id: 3138203,
    name: 'Lavras',
    uf: 'MG'
  },
  {
    id: 3138302,
    name: 'Leandro Ferreira',
    uf: 'MG'
  },
  {
    id: 3138351,
    name: 'Leme do Prado',
    uf: 'MG'
  },
  {
    id: 3138401,
    name: 'Leopoldina',
    uf: 'MG'
  },
  {
    id: 3138500,
    name: 'Liberdade',
    uf: 'MG'
  },
  {
    id: 3138609,
    name: 'Lima Duarte',
    uf: 'MG'
  },
  {
    id: 3138625,
    name: 'Limeira do Oeste',
    uf: 'MG'
  },
  {
    id: 3138658,
    name: 'Lontra',
    uf: 'MG'
  },
  {
    id: 3138674,
    name: 'Luisburgo',
    uf: 'MG'
  },
  {
    id: 3138682,
    name: 'Luislândia',
    uf: 'MG'
  },
  {
    id: 3138708,
    name: 'Luminárias',
    uf: 'MG'
  },
  {
    id: 3138807,
    name: 'Luz',
    uf: 'MG'
  },
  {
    id: 3138906,
    name: 'Machacalis',
    uf: 'MG'
  },
  {
    id: 3139003,
    name: 'Machado',
    uf: 'MG'
  },
  {
    id: 3139102,
    name: 'Madre de Deus de Minas',
    uf: 'MG'
  },
  {
    id: 3139201,
    name: 'Malacacheta',
    uf: 'MG'
  },
  {
    id: 3139250,
    name: 'Mamonas',
    uf: 'MG'
  },
  {
    id: 3139300,
    name: 'Manga',
    uf: 'MG'
  },
  {
    id: 3139409,
    name: 'Manhuaçu',
    uf: 'MG'
  },
  {
    id: 3139508,
    name: 'Manhumirim',
    uf: 'MG'
  },
  {
    id: 3139607,
    name: 'Mantena',
    uf: 'MG'
  },
  {
    id: 3139706,
    name: 'Maravilhas',
    uf: 'MG'
  },
  {
    id: 3139805,
    name: 'Mar de Espanha',
    uf: 'MG'
  },
  {
    id: 3139904,
    name: 'Maria da Fé',
    uf: 'MG'
  },
  {
    id: 3140001,
    name: 'Mariana',
    uf: 'MG'
  },
  {
    id: 3140100,
    name: 'Marilac',
    uf: 'MG'
  },
  {
    id: 3140159,
    name: 'Mário Campos',
    uf: 'MG'
  },
  {
    id: 3140209,
    name: 'Maripá de Minas',
    uf: 'MG'
  },
  {
    id: 3140308,
    name: 'Marliéria',
    uf: 'MG'
  },
  {
    id: 3140407,
    name: 'Marmelópolis',
    uf: 'MG'
  },
  {
    id: 3140506,
    name: 'Martinho Campos',
    uf: 'MG'
  },
  {
    id: 3140530,
    name: 'Martins Soares',
    uf: 'MG'
  },
  {
    id: 3140555,
    name: 'Mata Verde',
    uf: 'MG'
  },
  {
    id: 3140605,
    name: 'Materlândia',
    uf: 'MG'
  },
  {
    id: 3140704,
    name: 'Mateus Leme',
    uf: 'MG'
  },
  {
    id: 3140803,
    name: 'Matias Barbosa',
    uf: 'MG'
  },
  {
    id: 3140852,
    name: 'Matias Cardoso',
    uf: 'MG'
  },
  {
    id: 3140902,
    name: 'Matipó',
    uf: 'MG'
  },
  {
    id: 3141009,
    name: 'Mato Verde',
    uf: 'MG'
  },
  {
    id: 3141108,
    name: 'Matozinhos',
    uf: 'MG'
  },
  {
    id: 3141207,
    name: 'Matutina',
    uf: 'MG'
  },
  {
    id: 3141306,
    name: 'Medeiros',
    uf: 'MG'
  },
  {
    id: 3141405,
    name: 'Medina',
    uf: 'MG'
  },
  {
    id: 3141504,
    name: 'Mendes Pimentel',
    uf: 'MG'
  },
  {
    id: 3141603,
    name: 'Mercês',
    uf: 'MG'
  },
  {
    id: 3141702,
    name: 'Mesquita',
    uf: 'MG'
  },
  {
    id: 3141801,
    name: 'Minas Novas',
    uf: 'MG'
  },
  {
    id: 3141900,
    name: 'Minduri',
    uf: 'MG'
  },
  {
    id: 3142007,
    name: 'Mirabela',
    uf: 'MG'
  },
  {
    id: 3142106,
    name: 'Miradouro',
    uf: 'MG'
  },
  {
    id: 3142205,
    name: 'Miraí',
    uf: 'MG'
  },
  {
    id: 3142254,
    name: 'Miravânia',
    uf: 'MG'
  },
  {
    id: 3142304,
    name: 'Moeda',
    uf: 'MG'
  },
  {
    id: 3142403,
    name: 'Moema',
    uf: 'MG'
  },
  {
    id: 3142502,
    name: 'Monjolos',
    uf: 'MG'
  },
  {
    id: 3142601,
    name: 'Monsenhor Paulo',
    uf: 'MG'
  },
  {
    id: 3142700,
    name: 'Montalvânia',
    uf: 'MG'
  },
  {
    id: 3142809,
    name: 'Monte Alegre de Minas',
    uf: 'MG'
  },
  {
    id: 3142908,
    name: 'Monte Azul',
    uf: 'MG'
  },
  {
    id: 3143005,
    name: 'Monte Belo',
    uf: 'MG'
  },
  {
    id: 3143104,
    name: 'Monte Carmelo',
    uf: 'MG'
  },
  {
    id: 3143153,
    name: 'Monte Formoso',
    uf: 'MG'
  },
  {
    id: 3143203,
    name: 'Monte Santo de Minas',
    uf: 'MG'
  },
  {
    id: 3143302,
    name: 'Montes Claros',
    uf: 'MG'
  },
  {
    id: 3143401,
    name: 'Monte Sião',
    uf: 'MG'
  },
  {
    id: 3143450,
    name: 'Montezuma',
    uf: 'MG'
  },
  {
    id: 3143500,
    name: 'Morada Nova de Minas',
    uf: 'MG'
  },
  {
    id: 3143609,
    name: 'Morro da Garça',
    uf: 'MG'
  },
  {
    id: 3143708,
    name: 'Morro do Pilar',
    uf: 'MG'
  },
  {
    id: 3143807,
    name: 'Munhoz',
    uf: 'MG'
  },
  {
    id: 3143906,
    name: 'Muriaé',
    uf: 'MG'
  },
  {
    id: 3144003,
    name: 'Mutum',
    uf: 'MG'
  },
  {
    id: 3144102,
    name: 'Muzambinho',
    uf: 'MG'
  },
  {
    id: 3144201,
    name: 'Nacip Raydan',
    uf: 'MG'
  },
  {
    id: 3144300,
    name: 'Nanuque',
    uf: 'MG'
  },
  {
    id: 3144359,
    name: 'Naque',
    uf: 'MG'
  },
  {
    id: 3144375,
    name: 'Natalândia',
    uf: 'MG'
  },
  {
    id: 3144409,
    name: 'Natércia',
    uf: 'MG'
  },
  {
    id: 3144508,
    name: 'Nazareno',
    uf: 'MG'
  },
  {
    id: 3144607,
    name: 'Nepomuceno',
    uf: 'MG'
  },
  {
    id: 3144656,
    name: 'Ninheira',
    uf: 'MG'
  },
  {
    id: 3144672,
    name: 'Nova Belém',
    uf: 'MG'
  },
  {
    id: 3144706,
    name: 'Nova Era',
    uf: 'MG'
  },
  {
    id: 3144805,
    name: 'Nova Lima',
    uf: 'MG'
  },
  {
    id: 3144904,
    name: 'Nova Módica',
    uf: 'MG'
  },
  {
    id: 3145000,
    name: 'Nova Ponte',
    uf: 'MG'
  },
  {
    id: 3145059,
    name: 'Nova Porteirinha',
    uf: 'MG'
  },
  {
    id: 3145109,
    name: 'Nova Resende',
    uf: 'MG'
  },
  {
    id: 3145208,
    name: 'Nova Serrana',
    uf: 'MG'
  },
  {
    id: 3145307,
    name: 'Novo Cruzeiro',
    uf: 'MG'
  },
  {
    id: 3145356,
    name: 'Novo Oriente de Minas',
    uf: 'MG'
  },
  {
    id: 3145372,
    name: 'Novorizonte',
    uf: 'MG'
  },
  {
    id: 3145406,
    name: 'Olaria',
    uf: 'MG'
  },
  {
    id: 3145455,
    name: "Olhos-d'Água",
    uf: 'MG'
  },
  {
    id: 3145505,
    name: 'Olímpio Noronha',
    uf: 'MG'
  },
  {
    id: 3145604,
    name: 'Oliveira',
    uf: 'MG'
  },
  {
    id: 3145703,
    name: 'Oliveira Fortes',
    uf: 'MG'
  },
  {
    id: 3145802,
    name: 'Onça de Pitangui',
    uf: 'MG'
  },
  {
    id: 3145851,
    name: 'Oratórios',
    uf: 'MG'
  },
  {
    id: 3145877,
    name: 'Orizânia',
    uf: 'MG'
  },
  {
    id: 3145901,
    name: 'Ouro Branco',
    uf: 'MG'
  },
  {
    id: 3146008,
    name: 'Ouro Fino',
    uf: 'MG'
  },
  {
    id: 3146107,
    name: 'Ouro Preto',
    uf: 'MG'
  },
  {
    id: 3146206,
    name: 'Ouro Verde de Minas',
    uf: 'MG'
  },
  {
    id: 3146255,
    name: 'Padre Carvalho',
    uf: 'MG'
  },
  {
    id: 3146305,
    name: 'Padre Paraíso',
    uf: 'MG'
  },
  {
    id: 3146404,
    name: 'Paineiras',
    uf: 'MG'
  },
  {
    id: 3146503,
    name: 'Pains',
    uf: 'MG'
  },
  {
    id: 3146552,
    name: 'Pai Pedro',
    uf: 'MG'
  },
  {
    id: 3146602,
    name: 'Paiva',
    uf: 'MG'
  },
  {
    id: 3146701,
    name: 'Palma',
    uf: 'MG'
  },
  {
    id: 3146750,
    name: 'Palmópolis',
    uf: 'MG'
  },
  {
    id: 3146909,
    name: 'Papagaios',
    uf: 'MG'
  },
  {
    id: 3147006,
    name: 'Paracatu',
    uf: 'MG'
  },
  {
    id: 3147105,
    name: 'Pará de Minas',
    uf: 'MG'
  },
  {
    id: 3147204,
    name: 'Paraguaçu',
    uf: 'MG'
  },
  {
    id: 3147303,
    name: 'Paraisópolis',
    uf: 'MG'
  },
  {
    id: 3147402,
    name: 'Paraopeba',
    uf: 'MG'
  },
  {
    id: 3147501,
    name: 'Passabém',
    uf: 'MG'
  },
  {
    id: 3147600,
    name: 'Passa Quatro',
    uf: 'MG'
  },
  {
    id: 3147709,
    name: 'Passa Tempo',
    uf: 'MG'
  },
  {
    id: 3147808,
    name: 'Passa Vinte',
    uf: 'MG'
  },
  {
    id: 3147907,
    name: 'Passos',
    uf: 'MG'
  },
  {
    id: 3147956,
    name: 'Patis',
    uf: 'MG'
  },
  {
    id: 3148004,
    name: 'Patos de Minas',
    uf: 'MG'
  },
  {
    id: 3148103,
    name: 'Patrocínio',
    uf: 'MG'
  },
  {
    id: 3148202,
    name: 'Patrocínio do Muriaé',
    uf: 'MG'
  },
  {
    id: 3148301,
    name: 'Paula Cândido',
    uf: 'MG'
  },
  {
    id: 3148400,
    name: 'Paulistas',
    uf: 'MG'
  },
  {
    id: 3148509,
    name: 'Pavão',
    uf: 'MG'
  },
  {
    id: 3148608,
    name: 'Peçanha',
    uf: 'MG'
  },
  {
    id: 3148707,
    name: 'Pedra Azul',
    uf: 'MG'
  },
  {
    id: 3148756,
    name: 'Pedra Bonita',
    uf: 'MG'
  },
  {
    id: 3148806,
    name: 'Pedra do Anta',
    uf: 'MG'
  },
  {
    id: 3148905,
    name: 'Pedra do Indaiá',
    uf: 'MG'
  },
  {
    id: 3149002,
    name: 'Pedra Dourada',
    uf: 'MG'
  },
  {
    id: 3149101,
    name: 'Pedralva',
    uf: 'MG'
  },
  {
    id: 3149150,
    name: 'Pedras de Maria da Cruz',
    uf: 'MG'
  },
  {
    id: 3149200,
    name: 'Pedrinópolis',
    uf: 'MG'
  },
  {
    id: 3149309,
    name: 'Pedro Leopoldo',
    uf: 'MG'
  },
  {
    id: 3149408,
    name: 'Pedro Teixeira',
    uf: 'MG'
  },
  {
    id: 3149507,
    name: 'Pequeri',
    uf: 'MG'
  },
  {
    id: 3149606,
    name: 'Pequi',
    uf: 'MG'
  },
  {
    id: 3149705,
    name: 'Perdigão',
    uf: 'MG'
  },
  {
    id: 3149804,
    name: 'Perdizes',
    uf: 'MG'
  },
  {
    id: 3149903,
    name: 'Perdões',
    uf: 'MG'
  },
  {
    id: 3149952,
    name: 'Periquito',
    uf: 'MG'
  },
  {
    id: 3150000,
    name: 'Pescador',
    uf: 'MG'
  },
  {
    id: 3150109,
    name: 'Piau',
    uf: 'MG'
  },
  {
    id: 3150158,
    name: 'Piedade de Caratinga',
    uf: 'MG'
  },
  {
    id: 3150208,
    name: 'Piedade de Ponte Nova',
    uf: 'MG'
  },
  {
    id: 3150307,
    name: 'Piedade do Rio Grande',
    uf: 'MG'
  },
  {
    id: 3150406,
    name: 'Piedade dos Gerais',
    uf: 'MG'
  },
  {
    id: 3150505,
    name: 'Pimenta',
    uf: 'MG'
  },
  {
    id: 3150539,
    name: "Pingo-d'Água",
    uf: 'MG'
  },
  {
    id: 3150570,
    name: 'Pintópolis',
    uf: 'MG'
  },
  {
    id: 3150604,
    name: 'Piracema',
    uf: 'MG'
  },
  {
    id: 3150703,
    name: 'Pirajuba',
    uf: 'MG'
  },
  {
    id: 3150802,
    name: 'Piranga',
    uf: 'MG'
  },
  {
    id: 3150901,
    name: 'Piranguçu',
    uf: 'MG'
  },
  {
    id: 3151008,
    name: 'Piranguinho',
    uf: 'MG'
  },
  {
    id: 3151107,
    name: 'Pirapetinga',
    uf: 'MG'
  },
  {
    id: 3151206,
    name: 'Pirapora',
    uf: 'MG'
  },
  {
    id: 3151305,
    name: 'Piraúba',
    uf: 'MG'
  },
  {
    id: 3151404,
    name: 'Pitangui',
    uf: 'MG'
  },
  {
    id: 3151503,
    name: 'Piumhi',
    uf: 'MG'
  },
  {
    id: 3151602,
    name: 'Planura',
    uf: 'MG'
  },
  {
    id: 3151701,
    name: 'Poço Fundo',
    uf: 'MG'
  },
  {
    id: 3151800,
    name: 'Poços de Caldas',
    uf: 'MG'
  },
  {
    id: 3151909,
    name: 'Pocrane',
    uf: 'MG'
  },
  {
    id: 3152006,
    name: 'Pompéu',
    uf: 'MG'
  },
  {
    id: 3152105,
    name: 'Ponte Nova',
    uf: 'MG'
  },
  {
    id: 3152131,
    name: 'Ponto Chique',
    uf: 'MG'
  },
  {
    id: 3152170,
    name: 'Ponto dos Volantes',
    uf: 'MG'
  },
  {
    id: 3152204,
    name: 'Porteirinha',
    uf: 'MG'
  },
  {
    id: 3152303,
    name: 'Porto Firme',
    uf: 'MG'
  },
  {
    id: 3152402,
    name: 'Poté',
    uf: 'MG'
  },
  {
    id: 3152501,
    name: 'Pouso Alegre',
    uf: 'MG'
  },
  {
    id: 3152600,
    name: 'Pouso Alto',
    uf: 'MG'
  },
  {
    id: 3152709,
    name: 'Prados',
    uf: 'MG'
  },
  {
    id: 3152808,
    name: 'Prata',
    uf: 'MG'
  },
  {
    id: 3152907,
    name: 'Pratápolis',
    uf: 'MG'
  },
  {
    id: 3153004,
    name: 'Pratinha',
    uf: 'MG'
  },
  {
    id: 3153103,
    name: 'Presidente Bernardes',
    uf: 'MG'
  },
  {
    id: 3153202,
    name: 'Presidente Juscelino',
    uf: 'MG'
  },
  {
    id: 3153301,
    name: 'Presidente Kubitschek',
    uf: 'MG'
  },
  {
    id: 3153400,
    name: 'Presidente Olegário',
    uf: 'MG'
  },
  {
    id: 3153509,
    name: 'Alto Jequitibá',
    uf: 'MG'
  },
  {
    id: 3153608,
    name: 'Prudente de Morais',
    uf: 'MG'
  },
  {
    id: 3153707,
    name: 'Quartel Geral',
    uf: 'MG'
  },
  {
    id: 3153806,
    name: 'Queluzito',
    uf: 'MG'
  },
  {
    id: 3153905,
    name: 'Raposos',
    uf: 'MG'
  },
  {
    id: 3154002,
    name: 'Raul Soares',
    uf: 'MG'
  },
  {
    id: 3154101,
    name: 'Recreio',
    uf: 'MG'
  },
  {
    id: 3154150,
    name: 'Reduto',
    uf: 'MG'
  },
  {
    id: 3154200,
    name: 'Resende Costa',
    uf: 'MG'
  },
  {
    id: 3154309,
    name: 'Resplendor',
    uf: 'MG'
  },
  {
    id: 3154408,
    name: 'Ressaquinha',
    uf: 'MG'
  },
  {
    id: 3154457,
    name: 'Riachinho',
    uf: 'MG'
  },
  {
    id: 3154507,
    name: 'Riacho dos Machados',
    uf: 'MG'
  },
  {
    id: 3154606,
    name: 'Ribeirão das Neves',
    uf: 'MG'
  },
  {
    id: 3154705,
    name: 'Ribeirão Vermelho',
    uf: 'MG'
  },
  {
    id: 3154804,
    name: 'Rio Acima',
    uf: 'MG'
  },
  {
    id: 3154903,
    name: 'Rio Casca',
    uf: 'MG'
  },
  {
    id: 3155009,
    name: 'Rio Doce',
    uf: 'MG'
  },
  {
    id: 3155108,
    name: 'Rio do Prado',
    uf: 'MG'
  },
  {
    id: 3155207,
    name: 'Rio Espera',
    uf: 'MG'
  },
  {
    id: 3155306,
    name: 'Rio Manso',
    uf: 'MG'
  },
  {
    id: 3155405,
    name: 'Rio Novo',
    uf: 'MG'
  },
  {
    id: 3155504,
    name: 'Rio Paranaíba',
    uf: 'MG'
  },
  {
    id: 3155603,
    name: 'Rio Pardo de Minas',
    uf: 'MG'
  },
  {
    id: 3155702,
    name: 'Rio Piracicaba',
    uf: 'MG'
  },
  {
    id: 3155801,
    name: 'Rio Pomba',
    uf: 'MG'
  },
  {
    id: 3155900,
    name: 'Rio Preto',
    uf: 'MG'
  },
  {
    id: 3156007,
    name: 'Rio Vermelho',
    uf: 'MG'
  },
  {
    id: 3156106,
    name: 'Ritápolis',
    uf: 'MG'
  },
  {
    id: 3156205,
    name: 'Rochedo de Minas',
    uf: 'MG'
  },
  {
    id: 3156304,
    name: 'Rodeiro',
    uf: 'MG'
  },
  {
    id: 3156403,
    name: 'Romaria',
    uf: 'MG'
  },
  {
    id: 3156452,
    name: 'Rosário da Limeira',
    uf: 'MG'
  },
  {
    id: 3156502,
    name: 'Rubelita',
    uf: 'MG'
  },
  {
    id: 3156601,
    name: 'Rubim',
    uf: 'MG'
  },
  {
    id: 3156700,
    name: 'Sabará',
    uf: 'MG'
  },
  {
    id: 3156809,
    name: 'Sabinópolis',
    uf: 'MG'
  },
  {
    id: 3156908,
    name: 'Sacramento',
    uf: 'MG'
  },
  {
    id: 3157005,
    name: 'Salinas',
    uf: 'MG'
  },
  {
    id: 3157104,
    name: 'Salto da Divisa',
    uf: 'MG'
  },
  {
    id: 3157203,
    name: 'Santa Bárbara',
    uf: 'MG'
  },
  {
    id: 3157252,
    name: 'Santa Bárbara do Leste',
    uf: 'MG'
  },
  {
    id: 3157278,
    name: 'Santa Bárbara do Monte Verde',
    uf: 'MG'
  },
  {
    id: 3157302,
    name: 'Santa Bárbara do Tugúrio',
    uf: 'MG'
  },
  {
    id: 3157336,
    name: 'Santa Cruz de Minas',
    uf: 'MG'
  },
  {
    id: 3157377,
    name: 'Santa Cruz de Salinas',
    uf: 'MG'
  },
  {
    id: 3157401,
    name: 'Santa Cruz do Escalvado',
    uf: 'MG'
  },
  {
    id: 3157500,
    name: 'Santa Efigênia de Minas',
    uf: 'MG'
  },
  {
    id: 3157609,
    name: 'Santa Fé de Minas',
    uf: 'MG'
  },
  {
    id: 3157658,
    name: 'Santa Helena de Minas',
    uf: 'MG'
  },
  {
    id: 3157708,
    name: 'Santa Juliana',
    uf: 'MG'
  },
  {
    id: 3157807,
    name: 'Santa Luzia',
    uf: 'MG'
  },
  {
    id: 3157906,
    name: 'Santa Margarida',
    uf: 'MG'
  },
  {
    id: 3158003,
    name: 'Santa Maria de Itabira',
    uf: 'MG'
  },
  {
    id: 3158102,
    name: 'Santa Maria do Salto',
    uf: 'MG'
  },
  {
    id: 3158201,
    name: 'Santa Maria do Suaçuí',
    uf: 'MG'
  },
  {
    id: 3158300,
    name: 'Santana da Vargem',
    uf: 'MG'
  },
  {
    id: 3158409,
    name: 'Santana de Cataguases',
    uf: 'MG'
  },
  {
    id: 3158508,
    name: 'Santana de Pirapama',
    uf: 'MG'
  },
  {
    id: 3158607,
    name: 'Santana do Deserto',
    uf: 'MG'
  },
  {
    id: 3158706,
    name: 'Santana do Garambéu',
    uf: 'MG'
  },
  {
    id: 3158805,
    name: 'Santana do Jacaré',
    uf: 'MG'
  },
  {
    id: 3158904,
    name: 'Santana do Manhuaçu',
    uf: 'MG'
  },
  {
    id: 3158953,
    name: 'Santana do Paraíso',
    uf: 'MG'
  },
  {
    id: 3159001,
    name: 'Santana do Riacho',
    uf: 'MG'
  },
  {
    id: 3159100,
    name: 'Santana dos Montes',
    uf: 'MG'
  },
  {
    id: 3159209,
    name: 'Santa Rita de Caldas',
    uf: 'MG'
  },
  {
    id: 3159308,
    name: 'Santa Rita de Jacutinga',
    uf: 'MG'
  },
  {
    id: 3159357,
    name: 'Santa Rita de Minas',
    uf: 'MG'
  },
  {
    id: 3159407,
    name: 'Santa Rita de Ibitipoca',
    uf: 'MG'
  },
  {
    id: 3159506,
    name: 'Santa Rita do Itueto',
    uf: 'MG'
  },
  {
    id: 3159605,
    name: 'Santa Rita do Sapucaí',
    uf: 'MG'
  },
  {
    id: 3159704,
    name: 'Santa Rosa da Serra',
    uf: 'MG'
  },
  {
    id: 3159803,
    name: 'Santa Vitória',
    uf: 'MG'
  },
  {
    id: 3159902,
    name: 'Santo Antônio do Amparo',
    uf: 'MG'
  },
  {
    id: 3160009,
    name: 'Santo Antônio do Aventureiro',
    uf: 'MG'
  },
  {
    id: 3160108,
    name: 'Santo Antônio do Grama',
    uf: 'MG'
  },
  {
    id: 3160207,
    name: 'Santo Antônio do Itambé',
    uf: 'MG'
  },
  {
    id: 3160306,
    name: 'Santo Antônio do Jacinto',
    uf: 'MG'
  },
  {
    id: 3160405,
    name: 'Santo Antônio do Monte',
    uf: 'MG'
  },
  {
    id: 3160454,
    name: 'Santo Antônio do Retiro',
    uf: 'MG'
  },
  {
    id: 3160504,
    name: 'Santo Antônio do Rio Abaixo',
    uf: 'MG'
  },
  {
    id: 3160603,
    name: 'Santo Hipólito',
    uf: 'MG'
  },
  {
    id: 3160702,
    name: 'Santos Dumont',
    uf: 'MG'
  },
  {
    id: 3160801,
    name: 'São Bento Abade',
    uf: 'MG'
  },
  {
    id: 3160900,
    name: 'São Brás do Suaçuí',
    uf: 'MG'
  },
  {
    id: 3160959,
    name: 'São Domingos das Dores',
    uf: 'MG'
  },
  {
    id: 3161007,
    name: 'São Domingos do Prata',
    uf: 'MG'
  },
  {
    id: 3161056,
    name: 'São Félix de Minas',
    uf: 'MG'
  },
  {
    id: 3161106,
    name: 'São Francisco',
    uf: 'MG'
  },
  {
    id: 3161205,
    name: 'São Francisco de Paula',
    uf: 'MG'
  },
  {
    id: 3161304,
    name: 'São Francisco de Sales',
    uf: 'MG'
  },
  {
    id: 3161403,
    name: 'São Francisco do Glória',
    uf: 'MG'
  },
  {
    id: 3161502,
    name: 'São Geraldo',
    uf: 'MG'
  },
  {
    id: 3161601,
    name: 'São Geraldo da Piedade',
    uf: 'MG'
  },
  {
    id: 3161650,
    name: 'São Geraldo do Baixio',
    uf: 'MG'
  },
  {
    id: 3161700,
    name: 'São Gonçalo do Abaeté',
    uf: 'MG'
  },
  {
    id: 3161809,
    name: 'São Gonçalo do Pará',
    uf: 'MG'
  },
  {
    id: 3161908,
    name: 'São Gonçalo do Rio Abaixo',
    uf: 'MG'
  },
  {
    id: 3162005,
    name: 'São Gonçalo do Sapucaí',
    uf: 'MG'
  },
  {
    id: 3162104,
    name: 'São Gotardo',
    uf: 'MG'
  },
  {
    id: 3162203,
    name: 'São João Batista do Glória',
    uf: 'MG'
  },
  {
    id: 3162252,
    name: 'São João da Lagoa',
    uf: 'MG'
  },
  {
    id: 3162302,
    name: 'São João da Mata',
    uf: 'MG'
  },
  {
    id: 3162401,
    name: 'São João da Ponte',
    uf: 'MG'
  },
  {
    id: 3162450,
    name: 'São João das Missões',
    uf: 'MG'
  },
  {
    id: 3162500,
    name: 'São João del Rei',
    uf: 'MG'
  },
  {
    id: 3162559,
    name: 'São João do Manhuaçu',
    uf: 'MG'
  },
  {
    id: 3162575,
    name: 'São João do Manteninha',
    uf: 'MG'
  },
  {
    id: 3162609,
    name: 'São João do Oriente',
    uf: 'MG'
  },
  {
    id: 3162658,
    name: 'São João do Pacuí',
    uf: 'MG'
  },
  {
    id: 3162708,
    name: 'São João do Paraíso',
    uf: 'MG'
  },
  {
    id: 3162807,
    name: 'São João Evangelista',
    uf: 'MG'
  },
  {
    id: 3162906,
    name: 'São João Nepomuceno',
    uf: 'MG'
  },
  {
    id: 3162922,
    name: 'São Joaquim de Bicas',
    uf: 'MG'
  },
  {
    id: 3162948,
    name: 'São José da Barra',
    uf: 'MG'
  },
  {
    id: 3162955,
    name: 'São José da Lapa',
    uf: 'MG'
  },
  {
    id: 3163003,
    name: 'São José da Safira',
    uf: 'MG'
  },
  {
    id: 3163102,
    name: 'São José da Varginha',
    uf: 'MG'
  },
  {
    id: 3163201,
    name: 'São José do Alegre',
    uf: 'MG'
  },
  {
    id: 3163300,
    name: 'São José do Divino',
    uf: 'MG'
  },
  {
    id: 3163409,
    name: 'São José do Goiabal',
    uf: 'MG'
  },
  {
    id: 3163508,
    name: 'São José do Jacuri',
    uf: 'MG'
  },
  {
    id: 3163607,
    name: 'São José do Mantimento',
    uf: 'MG'
  },
  {
    id: 3163706,
    name: 'São Lourenço',
    uf: 'MG'
  },
  {
    id: 3163805,
    name: 'São Miguel do Anta',
    uf: 'MG'
  },
  {
    id: 3163904,
    name: 'São Pedro da União',
    uf: 'MG'
  },
  {
    id: 3164001,
    name: 'São Pedro dos Ferros',
    uf: 'MG'
  },
  {
    id: 3164100,
    name: 'São Pedro do Suaçuí',
    uf: 'MG'
  },
  {
    id: 3164209,
    name: 'São Romão',
    uf: 'MG'
  },
  {
    id: 3164308,
    name: 'São Roque de Minas',
    uf: 'MG'
  },
  {
    id: 3164407,
    name: 'São Sebastião da Bela Vista',
    uf: 'MG'
  },
  {
    id: 3164431,
    name: 'São Sebastião da Vargem Alegre',
    uf: 'MG'
  },
  {
    id: 3164472,
    name: 'São Sebastião do Anta',
    uf: 'MG'
  },
  {
    id: 3164506,
    name: 'São Sebastião do Maranhão',
    uf: 'MG'
  },
  {
    id: 3164605,
    name: 'São Sebastião do Oeste',
    uf: 'MG'
  },
  {
    id: 3164704,
    name: 'São Sebastião do Paraíso',
    uf: 'MG'
  },
  {
    id: 3164803,
    name: 'São Sebastião do Rio Preto',
    uf: 'MG'
  },
  {
    id: 3164902,
    name: 'São Sebastião do Rio Verde',
    uf: 'MG'
  },
  {
    id: 3165008,
    name: 'São Tiago',
    uf: 'MG'
  },
  {
    id: 3165107,
    name: 'São Tomás de Aquino',
    uf: 'MG'
  },
  {
    id: 3165206,
    name: 'São Tomé das Letras',
    uf: 'MG'
  },
  {
    id: 3165305,
    name: 'São Vicente de Minas',
    uf: 'MG'
  },
  {
    id: 3165404,
    name: 'Sapucaí-Mirim',
    uf: 'MG'
  },
  {
    id: 3165503,
    name: 'Sardoá',
    uf: 'MG'
  },
  {
    id: 3165537,
    name: 'Sarzedo',
    uf: 'MG'
  },
  {
    id: 3165552,
    name: 'Setubinha',
    uf: 'MG'
  },
  {
    id: 3165560,
    name: 'Sem-Peixe',
    uf: 'MG'
  },
  {
    id: 3165578,
    name: 'Senador Amaral',
    uf: 'MG'
  },
  {
    id: 3165602,
    name: 'Senador Cortes',
    uf: 'MG'
  },
  {
    id: 3165701,
    name: 'Senador Firmino',
    uf: 'MG'
  },
  {
    id: 3165800,
    name: 'Senador José Bento',
    uf: 'MG'
  },
  {
    id: 3165909,
    name: 'Senador Modestino Gonçalves',
    uf: 'MG'
  },
  {
    id: 3166006,
    name: 'Senhora de Oliveira',
    uf: 'MG'
  },
  {
    id: 3166105,
    name: 'Senhora do Porto',
    uf: 'MG'
  },
  {
    id: 3166204,
    name: 'Senhora dos Remédios',
    uf: 'MG'
  },
  {
    id: 3166303,
    name: 'Sericita',
    uf: 'MG'
  },
  {
    id: 3166402,
    name: 'Seritinga',
    uf: 'MG'
  },
  {
    id: 3166501,
    name: 'Serra Azul de Minas',
    uf: 'MG'
  },
  {
    id: 3166600,
    name: 'Serra da Saudade',
    uf: 'MG'
  },
  {
    id: 3166709,
    name: 'Serra dos Aimorés',
    uf: 'MG'
  },
  {
    id: 3166808,
    name: 'Serra do Salitre',
    uf: 'MG'
  },
  {
    id: 3166907,
    name: 'Serrania',
    uf: 'MG'
  },
  {
    id: 3166956,
    name: 'Serranópolis de Minas',
    uf: 'MG'
  },
  {
    id: 3167004,
    name: 'Serranos',
    uf: 'MG'
  },
  {
    id: 3167103,
    name: 'Serro',
    uf: 'MG'
  },
  {
    id: 3167202,
    name: 'Sete Lagoas',
    uf: 'MG'
  },
  {
    id: 3167301,
    name: 'Silveirânia',
    uf: 'MG'
  },
  {
    id: 3167400,
    name: 'Silvianópolis',
    uf: 'MG'
  },
  {
    id: 3167509,
    name: 'Simão Pereira',
    uf: 'MG'
  },
  {
    id: 3167608,
    name: 'Simonésia',
    uf: 'MG'
  },
  {
    id: 3167707,
    name: 'Sobrália',
    uf: 'MG'
  },
  {
    id: 3167806,
    name: 'Soledade de Minas',
    uf: 'MG'
  },
  {
    id: 3167905,
    name: 'Tabuleiro',
    uf: 'MG'
  },
  {
    id: 3168002,
    name: 'Taiobeiras',
    uf: 'MG'
  },
  {
    id: 3168051,
    name: 'Taparuba',
    uf: 'MG'
  },
  {
    id: 3168101,
    name: 'Tapira',
    uf: 'MG'
  },
  {
    id: 3168200,
    name: 'Tapiraí',
    uf: 'MG'
  },
  {
    id: 3168309,
    name: 'Taquaraçu de Minas',
    uf: 'MG'
  },
  {
    id: 3168408,
    name: 'Tarumirim',
    uf: 'MG'
  },
  {
    id: 3168507,
    name: 'Teixeiras',
    uf: 'MG'
  },
  {
    id: 3168606,
    name: 'Teófilo Otoni',
    uf: 'MG'
  },
  {
    id: 3168705,
    name: 'Timóteo',
    uf: 'MG'
  },
  {
    id: 3168804,
    name: 'Tiradentes',
    uf: 'MG'
  },
  {
    id: 3168903,
    name: 'Tiros',
    uf: 'MG'
  },
  {
    id: 3169000,
    name: 'Tocantins',
    uf: 'MG'
  },
  {
    id: 3169059,
    name: 'Tocos do Moji',
    uf: 'MG'
  },
  {
    id: 3169109,
    name: 'Toledo',
    uf: 'MG'
  },
  {
    id: 3169208,
    name: 'Tombos',
    uf: 'MG'
  },
  {
    id: 3169307,
    name: 'Três Corações',
    uf: 'MG'
  },
  {
    id: 3169356,
    name: 'Três Marias',
    uf: 'MG'
  },
  {
    id: 3169406,
    name: 'Três Pontas',
    uf: 'MG'
  },
  {
    id: 3169505,
    name: 'Tumiritinga',
    uf: 'MG'
  },
  {
    id: 3169604,
    name: 'Tupaciguara',
    uf: 'MG'
  },
  {
    id: 3169703,
    name: 'Turmalina',
    uf: 'MG'
  },
  {
    id: 3169802,
    name: 'Turvolândia',
    uf: 'MG'
  },
  {
    id: 3169901,
    name: 'Ubá',
    uf: 'MG'
  },
  {
    id: 3170008,
    name: 'Ubaí',
    uf: 'MG'
  },
  {
    id: 3170057,
    name: 'Ubaporanga',
    uf: 'MG'
  },
  {
    id: 3170107,
    name: 'Uberaba',
    uf: 'MG'
  },
  {
    id: 3170206,
    name: 'Uberlândia',
    uf: 'MG'
  },
  {
    id: 3170305,
    name: 'Umburatiba',
    uf: 'MG'
  },
  {
    id: 3170404,
    name: 'Unaí',
    uf: 'MG'
  },
  {
    id: 3170438,
    name: 'União de Minas',
    uf: 'MG'
  },
  {
    id: 3170479,
    name: 'Uruana de Minas',
    uf: 'MG'
  },
  {
    id: 3170503,
    name: 'Urucânia',
    uf: 'MG'
  },
  {
    id: 3170529,
    name: 'Urucuia',
    uf: 'MG'
  },
  {
    id: 3170578,
    name: 'Vargem Alegre',
    uf: 'MG'
  },
  {
    id: 3170602,
    name: 'Vargem Bonita',
    uf: 'MG'
  },
  {
    id: 3170651,
    name: 'Vargem Grande do Rio Pardo',
    uf: 'MG'
  },
  {
    id: 3170701,
    name: 'Varginha',
    uf: 'MG'
  },
  {
    id: 3170750,
    name: 'Varjão de Minas',
    uf: 'MG'
  },
  {
    id: 3170800,
    name: 'Várzea da Palma',
    uf: 'MG'
  },
  {
    id: 3170909,
    name: 'Varzelândia',
    uf: 'MG'
  },
  {
    id: 3171006,
    name: 'Vazante',
    uf: 'MG'
  },
  {
    id: 3171030,
    name: 'Verdelândia',
    uf: 'MG'
  },
  {
    id: 3171071,
    name: 'Veredinha',
    uf: 'MG'
  },
  {
    id: 3171105,
    name: 'Veríssimo',
    uf: 'MG'
  },
  {
    id: 3171154,
    name: 'Vermelho Novo',
    uf: 'MG'
  },
  {
    id: 3171204,
    name: 'Vespasiano',
    uf: 'MG'
  },
  {
    id: 3171303,
    name: 'Viçosa',
    uf: 'MG'
  },
  {
    id: 3171402,
    name: 'Vieiras',
    uf: 'MG'
  },
  {
    id: 3171501,
    name: 'Mathias Lobato',
    uf: 'MG'
  },
  {
    id: 3171600,
    name: 'Virgem da Lapa',
    uf: 'MG'
  },
  {
    id: 3171709,
    name: 'Virgínia',
    uf: 'MG'
  },
  {
    id: 3171808,
    name: 'Virginópolis',
    uf: 'MG'
  },
  {
    id: 3171907,
    name: 'Virgolândia',
    uf: 'MG'
  },
  {
    id: 3172004,
    name: 'Visconde do Rio Branco',
    uf: 'MG'
  },
  {
    id: 3172103,
    name: 'Volta Grande',
    uf: 'MG'
  },
  {
    id: 3172202,
    name: 'Wenceslau Braz',
    uf: 'MG'
  },
  {
    id: 3200102,
    name: 'Afonso Cláudio',
    uf: 'ES'
  },
  {
    id: 3200136,
    name: 'Águia Branca',
    uf: 'ES'
  },
  {
    id: 3200169,
    name: 'Água Doce do Norte',
    uf: 'ES'
  },
  {
    id: 3200201,
    name: 'Alegre',
    uf: 'ES'
  },
  {
    id: 3200300,
    name: 'Alfredo Chaves',
    uf: 'ES'
  },
  {
    id: 3200359,
    name: 'Alto Rio Novo',
    uf: 'ES'
  },
  {
    id: 3200409,
    name: 'Anchieta',
    uf: 'ES'
  },
  {
    id: 3200508,
    name: 'Apiacá',
    uf: 'ES'
  },
  {
    id: 3200607,
    name: 'Aracruz',
    uf: 'ES'
  },
  {
    id: 3200706,
    name: 'Atílio Vivácqua',
    uf: 'ES'
  },
  {
    id: 3200805,
    name: 'Baixo Guandu',
    uf: 'ES'
  },
  {
    id: 3200904,
    name: 'Barra de São Francisco',
    uf: 'ES'
  },
  {
    id: 3201001,
    name: 'Boa Esperança',
    uf: 'ES'
  },
  {
    id: 3201100,
    name: 'Bom Jesus do Norte',
    uf: 'ES'
  },
  {
    id: 3201159,
    name: 'Brejetuba',
    uf: 'ES'
  },
  {
    id: 3201209,
    name: 'Cachoeiro de Itapemirim',
    uf: 'ES'
  },
  {
    id: 3201308,
    name: 'Cariacica',
    uf: 'ES'
  },
  {
    id: 3201407,
    name: 'Castelo',
    uf: 'ES'
  },
  {
    id: 3201506,
    name: 'Colatina',
    uf: 'ES'
  },
  {
    id: 3201605,
    name: 'Conceição da Barra',
    uf: 'ES'
  },
  {
    id: 3201704,
    name: 'Conceição do Castelo',
    uf: 'ES'
  },
  {
    id: 3201803,
    name: 'Divino de São Lourenço',
    uf: 'ES'
  },
  {
    id: 3201902,
    name: 'Domingos Martins',
    uf: 'ES'
  },
  {
    id: 3202009,
    name: 'Dores do Rio Preto',
    uf: 'ES'
  },
  {
    id: 3202108,
    name: 'Ecoporanga',
    uf: 'ES'
  },
  {
    id: 3202207,
    name: 'Fundão',
    uf: 'ES'
  },
  {
    id: 3202256,
    name: 'Governador Lindenberg',
    uf: 'ES'
  },
  {
    id: 3202306,
    name: 'Guaçuí',
    uf: 'ES'
  },
  {
    id: 3202405,
    name: 'Guarapari',
    uf: 'ES'
  },
  {
    id: 3202454,
    name: 'Ibatiba',
    uf: 'ES'
  },
  {
    id: 3202504,
    name: 'Ibiraçu',
    uf: 'ES'
  },
  {
    id: 3202553,
    name: 'Ibitirama',
    uf: 'ES'
  },
  {
    id: 3202603,
    name: 'Iconha',
    uf: 'ES'
  },
  {
    id: 3202652,
    name: 'Irupi',
    uf: 'ES'
  },
  {
    id: 3202702,
    name: 'Itaguaçu',
    uf: 'ES'
  },
  {
    id: 3202801,
    name: 'Itapemirim',
    uf: 'ES'
  },
  {
    id: 3202900,
    name: 'Itarana',
    uf: 'ES'
  },
  {
    id: 3203007,
    name: 'Iúna',
    uf: 'ES'
  },
  {
    id: 3203056,
    name: 'Jaguaré',
    uf: 'ES'
  },
  {
    id: 3203106,
    name: 'Jerônimo Monteiro',
    uf: 'ES'
  },
  {
    id: 3203130,
    name: 'João Neiva',
    uf: 'ES'
  },
  {
    id: 3203163,
    name: 'Laranja da Terra',
    uf: 'ES'
  },
  {
    id: 3203205,
    name: 'Linhares',
    uf: 'ES'
  },
  {
    id: 3203304,
    name: 'Mantenópolis',
    uf: 'ES'
  },
  {
    id: 3203320,
    name: 'Marataízes',
    uf: 'ES'
  },
  {
    id: 3203346,
    name: 'Marechal Floriano',
    uf: 'ES'
  },
  {
    id: 3203353,
    name: 'Marilândia',
    uf: 'ES'
  },
  {
    id: 3203403,
    name: 'Mimoso do Sul',
    uf: 'ES'
  },
  {
    id: 3203502,
    name: 'Montanha',
    uf: 'ES'
  },
  {
    id: 3203601,
    name: 'Mucurici',
    uf: 'ES'
  },
  {
    id: 3203700,
    name: 'Muniz Freire',
    uf: 'ES'
  },
  {
    id: 3203809,
    name: 'Muqui',
    uf: 'ES'
  },
  {
    id: 3203908,
    name: 'Nova Venécia',
    uf: 'ES'
  },
  {
    id: 3204005,
    name: 'Pancas',
    uf: 'ES'
  },
  {
    id: 3204054,
    name: 'Pedro Canário',
    uf: 'ES'
  },
  {
    id: 3204104,
    name: 'Pinheiros',
    uf: 'ES'
  },
  {
    id: 3204203,
    name: 'Piúma',
    uf: 'ES'
  },
  {
    id: 3204252,
    name: 'Ponto Belo',
    uf: 'ES'
  },
  {
    id: 3204302,
    name: 'Presidente Kennedy',
    uf: 'ES'
  },
  {
    id: 3204351,
    name: 'Rio Bananal',
    uf: 'ES'
  },
  {
    id: 3204401,
    name: 'Rio Novo do Sul',
    uf: 'ES'
  },
  {
    id: 3204500,
    name: 'Santa Leopoldina',
    uf: 'ES'
  },
  {
    id: 3204559,
    name: 'Santa Maria de Jetibá',
    uf: 'ES'
  },
  {
    id: 3204609,
    name: 'Santa Teresa',
    uf: 'ES'
  },
  {
    id: 3204658,
    name: 'São Domingos do Norte',
    uf: 'ES'
  },
  {
    id: 3204708,
    name: 'São Gabriel da Palha',
    uf: 'ES'
  },
  {
    id: 3204807,
    name: 'São José do Calçado',
    uf: 'ES'
  },
  {
    id: 3204906,
    name: 'São Mateus',
    uf: 'ES'
  },
  {
    id: 3204955,
    name: 'São Roque do Canaã',
    uf: 'ES'
  },
  {
    id: 3205002,
    name: 'Serra',
    uf: 'ES'
  },
  {
    id: 3205010,
    name: 'Sooretama',
    uf: 'ES'
  },
  {
    id: 3205036,
    name: 'Vargem Alta',
    uf: 'ES'
  },
  {
    id: 3205069,
    name: 'Venda Nova do Imigrante',
    uf: 'ES'
  },
  {
    id: 3205101,
    name: 'Viana',
    uf: 'ES'
  },
  {
    id: 3205150,
    name: 'Vila Pavão',
    uf: 'ES'
  },
  {
    id: 3205176,
    name: 'Vila Valério',
    uf: 'ES'
  },
  {
    id: 3205200,
    name: 'Vila Velha',
    uf: 'ES'
  },
  {
    id: 3205309,
    name: 'Vitória',
    uf: 'ES'
  },
  {
    id: 3300100,
    name: 'Angra dos Reis',
    uf: 'RJ'
  },
  {
    id: 3300159,
    name: 'Aperibé',
    uf: 'RJ'
  },
  {
    id: 3300209,
    name: 'Araruama',
    uf: 'RJ'
  },
  {
    id: 3300225,
    name: 'Areal',
    uf: 'RJ'
  },
  {
    id: 3300233,
    name: 'Armação dos Búzios',
    uf: 'RJ'
  },
  {
    id: 3300258,
    name: 'Arraial do Cabo',
    uf: 'RJ'
  },
  {
    id: 3300308,
    name: 'Barra do Piraí',
    uf: 'RJ'
  },
  {
    id: 3300407,
    name: 'Barra Mansa',
    uf: 'RJ'
  },
  {
    id: 3300456,
    name: 'Belford Roxo',
    uf: 'RJ'
  },
  {
    id: 3300506,
    name: 'Bom Jardim',
    uf: 'RJ'
  },
  {
    id: 3300605,
    name: 'Bom Jesus do Itabapoana',
    uf: 'RJ'
  },
  {
    id: 3300704,
    name: 'Cabo Frio',
    uf: 'RJ'
  },
  {
    id: 3300803,
    name: 'Cachoeiras de Macacu',
    uf: 'RJ'
  },
  {
    id: 3300902,
    name: 'Cambuci',
    uf: 'RJ'
  },
  {
    id: 3300936,
    name: 'Carapebus',
    uf: 'RJ'
  },
  {
    id: 3300951,
    name: 'Comendador Levy Gasparian',
    uf: 'RJ'
  },
  {
    id: 3301009,
    name: 'Campos dos Goytacazes',
    uf: 'RJ'
  },
  {
    id: 3301108,
    name: 'Cantagalo',
    uf: 'RJ'
  },
  {
    id: 3301157,
    name: 'Cardoso Moreira',
    uf: 'RJ'
  },
  {
    id: 3301207,
    name: 'Carmo',
    uf: 'RJ'
  },
  {
    id: 3301306,
    name: 'Casimiro de Abreu',
    uf: 'RJ'
  },
  {
    id: 3301405,
    name: 'Conceição de Macabu',
    uf: 'RJ'
  },
  {
    id: 3301504,
    name: 'Cordeiro',
    uf: 'RJ'
  },
  {
    id: 3301603,
    name: 'Duas Barras',
    uf: 'RJ'
  },
  {
    id: 3301702,
    name: 'Duque de Caxias',
    uf: 'RJ'
  },
  {
    id: 3301801,
    name: 'Engenheiro Paulo de Frontin',
    uf: 'RJ'
  },
  {
    id: 3301850,
    name: 'Guapimirim',
    uf: 'RJ'
  },
  {
    id: 3301876,
    name: 'Iguaba Grande',
    uf: 'RJ'
  },
  {
    id: 3301900,
    name: 'Itaboraí',
    uf: 'RJ'
  },
  {
    id: 3302007,
    name: 'Itaguaí',
    uf: 'RJ'
  },
  {
    id: 3302056,
    name: 'Italva',
    uf: 'RJ'
  },
  {
    id: 3302106,
    name: 'Itaocara',
    uf: 'RJ'
  },
  {
    id: 3302205,
    name: 'Itaperuna',
    uf: 'RJ'
  },
  {
    id: 3302254,
    name: 'Itatiaia',
    uf: 'RJ'
  },
  {
    id: 3302270,
    name: 'Japeri',
    uf: 'RJ'
  },
  {
    id: 3302304,
    name: 'Laje do Muriaé',
    uf: 'RJ'
  },
  {
    id: 3302403,
    name: 'Macaé',
    uf: 'RJ'
  },
  {
    id: 3302452,
    name: 'Macuco',
    uf: 'RJ'
  },
  {
    id: 3302502,
    name: 'Magé',
    uf: 'RJ'
  },
  {
    id: 3302601,
    name: 'Mangaratiba',
    uf: 'RJ'
  },
  {
    id: 3302700,
    name: 'Maricá',
    uf: 'RJ'
  },
  {
    id: 3302809,
    name: 'Mendes',
    uf: 'RJ'
  },
  {
    id: 3302858,
    name: 'Mesquita',
    uf: 'RJ'
  },
  {
    id: 3302908,
    name: 'Miguel Pereira',
    uf: 'RJ'
  },
  {
    id: 3303005,
    name: 'Miracema',
    uf: 'RJ'
  },
  {
    id: 3303104,
    name: 'Natividade',
    uf: 'RJ'
  },
  {
    id: 3303203,
    name: 'Nilópolis',
    uf: 'RJ'
  },
  {
    id: 3303302,
    name: 'Niterói',
    uf: 'RJ'
  },
  {
    id: 3303401,
    name: 'Nova Friburgo',
    uf: 'RJ'
  },
  {
    id: 3303500,
    name: 'Nova Iguaçu',
    uf: 'RJ'
  },
  {
    id: 3303609,
    name: 'Paracambi',
    uf: 'RJ'
  },
  {
    id: 3303708,
    name: 'Paraíba do Sul',
    uf: 'RJ'
  },
  {
    id: 3303807,
    name: 'Paraty',
    uf: 'RJ'
  },
  {
    id: 3303856,
    name: 'Paty do Alferes',
    uf: 'RJ'
  },
  {
    id: 3303906,
    name: 'Petrópolis',
    uf: 'RJ'
  },
  {
    id: 3303955,
    name: 'Pinheiral',
    uf: 'RJ'
  },
  {
    id: 3304003,
    name: 'Piraí',
    uf: 'RJ'
  },
  {
    id: 3304102,
    name: 'Porciúncula',
    uf: 'RJ'
  },
  {
    id: 3304110,
    name: 'Porto Real',
    uf: 'RJ'
  },
  {
    id: 3304128,
    name: 'Quatis',
    uf: 'RJ'
  },
  {
    id: 3304144,
    name: 'Queimados',
    uf: 'RJ'
  },
  {
    id: 3304151,
    name: 'Quissamã',
    uf: 'RJ'
  },
  {
    id: 3304201,
    name: 'Resende',
    uf: 'RJ'
  },
  {
    id: 3304300,
    name: 'Rio Bonito',
    uf: 'RJ'
  },
  {
    id: 3304409,
    name: 'Rio Claro',
    uf: 'RJ'
  },
  {
    id: 3304508,
    name: 'Rio das Flores',
    uf: 'RJ'
  },
  {
    id: 3304524,
    name: 'Rio das Ostras',
    uf: 'RJ'
  },
  {
    id: 3304557,
    name: 'Rio de Janeiro',
    uf: 'RJ'
  },
  {
    id: 3304607,
    name: 'Santa Maria Madalena',
    uf: 'RJ'
  },
  {
    id: 3304706,
    name: 'Santo Antônio de Pádua',
    uf: 'RJ'
  },
  {
    id: 3304755,
    name: 'São Francisco de Itabapoana',
    uf: 'RJ'
  },
  {
    id: 3304805,
    name: 'São Fidélis',
    uf: 'RJ'
  },
  {
    id: 3304904,
    name: 'São Gonçalo',
    uf: 'RJ'
  },
  {
    id: 3305000,
    name: 'São João da Barra',
    uf: 'RJ'
  },
  {
    id: 3305109,
    name: 'São João de Meriti',
    uf: 'RJ'
  },
  {
    id: 3305133,
    name: 'São José de Ubá',
    uf: 'RJ'
  },
  {
    id: 3305158,
    name: 'São José do Vale do Rio Preto',
    uf: 'RJ'
  },
  {
    id: 3305208,
    name: 'São Pedro da Aldeia',
    uf: 'RJ'
  },
  {
    id: 3305307,
    name: 'São Sebastião do Alto',
    uf: 'RJ'
  },
  {
    id: 3305406,
    name: 'Sapucaia',
    uf: 'RJ'
  },
  {
    id: 3305505,
    name: 'Saquarema',
    uf: 'RJ'
  },
  {
    id: 3305554,
    name: 'Seropédica',
    uf: 'RJ'
  },
  {
    id: 3305604,
    name: 'Silva Jardim',
    uf: 'RJ'
  },
  {
    id: 3305703,
    name: 'Sumidouro',
    uf: 'RJ'
  },
  {
    id: 3305752,
    name: 'Tanguá',
    uf: 'RJ'
  },
  {
    id: 3305802,
    name: 'Teresópolis',
    uf: 'RJ'
  },
  {
    id: 3305901,
    name: 'Trajano de Moraes',
    uf: 'RJ'
  },
  {
    id: 3306008,
    name: 'Três Rios',
    uf: 'RJ'
  },
  {
    id: 3306107,
    name: 'Valença',
    uf: 'RJ'
  },
  {
    id: 3306156,
    name: 'Varre-Sai',
    uf: 'RJ'
  },
  {
    id: 3306206,
    name: 'Vassouras',
    uf: 'RJ'
  },
  {
    id: 3306305,
    name: 'Volta Redonda',
    uf: 'RJ'
  },
  {
    id: 3500105,
    name: 'Adamantina',
    uf: 'SP'
  },
  {
    id: 3500204,
    name: 'Adolfo',
    uf: 'SP'
  },
  {
    id: 3500303,
    name: 'Aguaí',
    uf: 'SP'
  },
  {
    id: 3500402,
    name: 'Águas da Prata',
    uf: 'SP'
  },
  {
    id: 3500501,
    name: 'Águas de Lindóia',
    uf: 'SP'
  },
  {
    id: 3500550,
    name: 'Águas de Santa Bárbara',
    uf: 'SP'
  },
  {
    id: 3500600,
    name: 'Águas de São Pedro',
    uf: 'SP'
  },
  {
    id: 3500709,
    name: 'Agudos',
    uf: 'SP'
  },
  {
    id: 3500758,
    name: 'Alambari',
    uf: 'SP'
  },
  {
    id: 3500808,
    name: 'Alfredo Marcondes',
    uf: 'SP'
  },
  {
    id: 3500907,
    name: 'Altair',
    uf: 'SP'
  },
  {
    id: 3501004,
    name: 'Altinópolis',
    uf: 'SP'
  },
  {
    id: 3501103,
    name: 'Alto Alegre',
    uf: 'SP'
  },
  {
    id: 3501152,
    name: 'Alumínio',
    uf: 'SP'
  },
  {
    id: 3501202,
    name: 'Álvares Florence',
    uf: 'SP'
  },
  {
    id: 3501301,
    name: 'Álvares Machado',
    uf: 'SP'
  },
  {
    id: 3501400,
    name: 'Álvaro de Carvalho',
    uf: 'SP'
  },
  {
    id: 3501509,
    name: 'Alvinlândia',
    uf: 'SP'
  },
  {
    id: 3501608,
    name: 'Americana',
    uf: 'SP'
  },
  {
    id: 3501707,
    name: 'Américo Brasiliense',
    uf: 'SP'
  },
  {
    id: 3501806,
    name: 'Américo de Campos',
    uf: 'SP'
  },
  {
    id: 3501905,
    name: 'Amparo',
    uf: 'SP'
  },
  {
    id: 3502002,
    name: 'Analândia',
    uf: 'SP'
  },
  {
    id: 3502101,
    name: 'Andradina',
    uf: 'SP'
  },
  {
    id: 3502200,
    name: 'Angatuba',
    uf: 'SP'
  },
  {
    id: 3502309,
    name: 'Anhembi',
    uf: 'SP'
  },
  {
    id: 3502408,
    name: 'Anhumas',
    uf: 'SP'
  },
  {
    id: 3502507,
    name: 'Aparecida',
    uf: 'SP'
  },
  {
    id: 3502606,
    name: "Aparecida d'Oeste",
    uf: 'SP'
  },
  {
    id: 3502705,
    name: 'Apiaí',
    uf: 'SP'
  },
  {
    id: 3502754,
    name: 'Araçariguama',
    uf: 'SP'
  },
  {
    id: 3502804,
    name: 'Araçatuba',
    uf: 'SP'
  },
  {
    id: 3502903,
    name: 'Araçoiaba da Serra',
    uf: 'SP'
  },
  {
    id: 3503000,
    name: 'Aramina',
    uf: 'SP'
  },
  {
    id: 3503109,
    name: 'Arandu',
    uf: 'SP'
  },
  {
    id: 3503158,
    name: 'Arapeí',
    uf: 'SP'
  },
  {
    id: 3503208,
    name: 'Araraquara',
    uf: 'SP'
  },
  {
    id: 3503307,
    name: 'Araras',
    uf: 'SP'
  },
  {
    id: 3503356,
    name: 'Arco-Íris',
    uf: 'SP'
  },
  {
    id: 3503406,
    name: 'Arealva',
    uf: 'SP'
  },
  {
    id: 3503505,
    name: 'Areias',
    uf: 'SP'
  },
  {
    id: 3503604,
    name: 'Areiópolis',
    uf: 'SP'
  },
  {
    id: 3503703,
    name: 'Ariranha',
    uf: 'SP'
  },
  {
    id: 3503802,
    name: 'Artur Nogueira',
    uf: 'SP'
  },
  {
    id: 3503901,
    name: 'Arujá',
    uf: 'SP'
  },
  {
    id: 3503950,
    name: 'Aspásia',
    uf: 'SP'
  },
  {
    id: 3504008,
    name: 'Assis',
    uf: 'SP'
  },
  {
    id: 3504107,
    name: 'Atibaia',
    uf: 'SP'
  },
  {
    id: 3504206,
    name: 'Auriflama',
    uf: 'SP'
  },
  {
    id: 3504305,
    name: 'Avaí',
    uf: 'SP'
  },
  {
    id: 3504404,
    name: 'Avanhandava',
    uf: 'SP'
  },
  {
    id: 3504503,
    name: 'Avaré',
    uf: 'SP'
  },
  {
    id: 3504602,
    name: 'Bady Bassitt',
    uf: 'SP'
  },
  {
    id: 3504701,
    name: 'Balbinos',
    uf: 'SP'
  },
  {
    id: 3504800,
    name: 'Bálsamo',
    uf: 'SP'
  },
  {
    id: 3504909,
    name: 'Bananal',
    uf: 'SP'
  },
  {
    id: 3505005,
    name: 'Barão de Antonina',
    uf: 'SP'
  },
  {
    id: 3505104,
    name: 'Barbosa',
    uf: 'SP'
  },
  {
    id: 3505203,
    name: 'Bariri',
    uf: 'SP'
  },
  {
    id: 3505302,
    name: 'Barra Bonita',
    uf: 'SP'
  },
  {
    id: 3505351,
    name: 'Barra do Chapéu',
    uf: 'SP'
  },
  {
    id: 3505401,
    name: 'Barra do Turvo',
    uf: 'SP'
  },
  {
    id: 3505500,
    name: 'Barretos',
    uf: 'SP'
  },
  {
    id: 3505609,
    name: 'Barrinha',
    uf: 'SP'
  },
  {
    id: 3505708,
    name: 'Barueri',
    uf: 'SP'
  },
  {
    id: 3505807,
    name: 'Bastos',
    uf: 'SP'
  },
  {
    id: 3505906,
    name: 'Batatais',
    uf: 'SP'
  },
  {
    id: 3506003,
    name: 'Bauru',
    uf: 'SP'
  },
  {
    id: 3506102,
    name: 'Bebedouro',
    uf: 'SP'
  },
  {
    id: 3506201,
    name: 'Bento de Abreu',
    uf: 'SP'
  },
  {
    id: 3506300,
    name: 'Bernardino de Campos',
    uf: 'SP'
  },
  {
    id: 3506359,
    name: 'Bertioga',
    uf: 'SP'
  },
  {
    id: 3506409,
    name: 'Bilac',
    uf: 'SP'
  },
  {
    id: 3506508,
    name: 'Birigui',
    uf: 'SP'
  },
  {
    id: 3506607,
    name: 'Biritiba Mirim',
    uf: 'SP'
  },
  {
    id: 3506706,
    name: 'Boa Esperança do Sul',
    uf: 'SP'
  },
  {
    id: 3506805,
    name: 'Bocaina',
    uf: 'SP'
  },
  {
    id: 3506904,
    name: 'Bofete',
    uf: 'SP'
  },
  {
    id: 3507001,
    name: 'Boituva',
    uf: 'SP'
  },
  {
    id: 3507100,
    name: 'Bom Jesus dos Perdões',
    uf: 'SP'
  },
  {
    id: 3507159,
    name: 'Bom Sucesso de Itararé',
    uf: 'SP'
  },
  {
    id: 3507209,
    name: 'Borá',
    uf: 'SP'
  },
  {
    id: 3507308,
    name: 'Boracéia',
    uf: 'SP'
  },
  {
    id: 3507407,
    name: 'Borborema',
    uf: 'SP'
  },
  {
    id: 3507456,
    name: 'Borebi',
    uf: 'SP'
  },
  {
    id: 3507506,
    name: 'Botucatu',
    uf: 'SP'
  },
  {
    id: 3507605,
    name: 'Bragança Paulista',
    uf: 'SP'
  },
  {
    id: 3507704,
    name: 'Braúna',
    uf: 'SP'
  },
  {
    id: 3507753,
    name: 'Brejo Alegre',
    uf: 'SP'
  },
  {
    id: 3507803,
    name: 'Brodowski',
    uf: 'SP'
  },
  {
    id: 3507902,
    name: 'Brotas',
    uf: 'SP'
  },
  {
    id: 3508009,
    name: 'Buri',
    uf: 'SP'
  },
  {
    id: 3508108,
    name: 'Buritama',
    uf: 'SP'
  },
  {
    id: 3508207,
    name: 'Buritizal',
    uf: 'SP'
  },
  {
    id: 3508306,
    name: 'Cabrália Paulista',
    uf: 'SP'
  },
  {
    id: 3508405,
    name: 'Cabreúva',
    uf: 'SP'
  },
  {
    id: 3508504,
    name: 'Caçapava',
    uf: 'SP'
  },
  {
    id: 3508603,
    name: 'Cachoeira Paulista',
    uf: 'SP'
  },
  {
    id: 3508702,
    name: 'Caconde',
    uf: 'SP'
  },
  {
    id: 3508801,
    name: 'Cafelândia',
    uf: 'SP'
  },
  {
    id: 3508900,
    name: 'Caiabu',
    uf: 'SP'
  },
  {
    id: 3509007,
    name: 'Caieiras',
    uf: 'SP'
  },
  {
    id: 3509106,
    name: 'Caiuá',
    uf: 'SP'
  },
  {
    id: 3509205,
    name: 'Cajamar',
    uf: 'SP'
  },
  {
    id: 3509254,
    name: 'Cajati',
    uf: 'SP'
  },
  {
    id: 3509304,
    name: 'Cajobi',
    uf: 'SP'
  },
  {
    id: 3509403,
    name: 'Cajuru',
    uf: 'SP'
  },
  {
    id: 3509452,
    name: 'Campina do Monte Alegre',
    uf: 'SP'
  },
  {
    id: 3509502,
    name: 'Campinas',
    uf: 'SP'
  },
  {
    id: 3509601,
    name: 'Campo Limpo Paulista',
    uf: 'SP'
  },
  {
    id: 3509700,
    name: 'Campos do Jordão',
    uf: 'SP'
  },
  {
    id: 3509809,
    name: 'Campos Novos Paulista',
    uf: 'SP'
  },
  {
    id: 3509908,
    name: 'Cananéia',
    uf: 'SP'
  },
  {
    id: 3509957,
    name: 'Canas',
    uf: 'SP'
  },
  {
    id: 3510005,
    name: 'Cândido Mota',
    uf: 'SP'
  },
  {
    id: 3510104,
    name: 'Cândido Rodrigues',
    uf: 'SP'
  },
  {
    id: 3510153,
    name: 'Canitar',
    uf: 'SP'
  },
  {
    id: 3510203,
    name: 'Capão Bonito',
    uf: 'SP'
  },
  {
    id: 3510302,
    name: 'Capela do Alto',
    uf: 'SP'
  },
  {
    id: 3510401,
    name: 'Capivari',
    uf: 'SP'
  },
  {
    id: 3510500,
    name: 'Caraguatatuba',
    uf: 'SP'
  },
  {
    id: 3510609,
    name: 'Carapicuíba',
    uf: 'SP'
  },
  {
    id: 3510708,
    name: 'Cardoso',
    uf: 'SP'
  },
  {
    id: 3510807,
    name: 'Casa Branca',
    uf: 'SP'
  },
  {
    id: 3510906,
    name: 'Cássia dos Coqueiros',
    uf: 'SP'
  },
  {
    id: 3511003,
    name: 'Castilho',
    uf: 'SP'
  },
  {
    id: 3511102,
    name: 'Catanduva',
    uf: 'SP'
  },
  {
    id: 3511201,
    name: 'Catiguá',
    uf: 'SP'
  },
  {
    id: 3511300,
    name: 'Cedral',
    uf: 'SP'
  },
  {
    id: 3511409,
    name: 'Cerqueira César',
    uf: 'SP'
  },
  {
    id: 3511508,
    name: 'Cerquilho',
    uf: 'SP'
  },
  {
    id: 3511607,
    name: 'Cesário Lange',
    uf: 'SP'
  },
  {
    id: 3511706,
    name: 'Charqueada',
    uf: 'SP'
  },
  {
    id: 3511904,
    name: 'Clementina',
    uf: 'SP'
  },
  {
    id: 3512001,
    name: 'Colina',
    uf: 'SP'
  },
  {
    id: 3512100,
    name: 'Colômbia',
    uf: 'SP'
  },
  {
    id: 3512209,
    name: 'Conchal',
    uf: 'SP'
  },
  {
    id: 3512308,
    name: 'Conchas',
    uf: 'SP'
  },
  {
    id: 3512407,
    name: 'Cordeirópolis',
    uf: 'SP'
  },
  {
    id: 3512506,
    name: 'Coroados',
    uf: 'SP'
  },
  {
    id: 3512605,
    name: 'Coronel Macedo',
    uf: 'SP'
  },
  {
    id: 3512704,
    name: 'Corumbataí',
    uf: 'SP'
  },
  {
    id: 3512803,
    name: 'Cosmópolis',
    uf: 'SP'
  },
  {
    id: 3512902,
    name: 'Cosmorama',
    uf: 'SP'
  },
  {
    id: 3513009,
    name: 'Cotia',
    uf: 'SP'
  },
  {
    id: 3513108,
    name: 'Cravinhos',
    uf: 'SP'
  },
  {
    id: 3513207,
    name: 'Cristais Paulista',
    uf: 'SP'
  },
  {
    id: 3513306,
    name: 'Cruzália',
    uf: 'SP'
  },
  {
    id: 3513405,
    name: 'Cruzeiro',
    uf: 'SP'
  },
  {
    id: 3513504,
    name: 'Cubatão',
    uf: 'SP'
  },
  {
    id: 3513603,
    name: 'Cunha',
    uf: 'SP'
  },
  {
    id: 3513702,
    name: 'Descalvado',
    uf: 'SP'
  },
  {
    id: 3513801,
    name: 'Diadema',
    uf: 'SP'
  },
  {
    id: 3513850,
    name: 'Dirce Reis',
    uf: 'SP'
  },
  {
    id: 3513900,
    name: 'Divinolândia',
    uf: 'SP'
  },
  {
    id: 3514007,
    name: 'Dobrada',
    uf: 'SP'
  },
  {
    id: 3514106,
    name: 'Dois Córregos',
    uf: 'SP'
  },
  {
    id: 3514205,
    name: 'Dolcinópolis',
    uf: 'SP'
  },
  {
    id: 3514304,
    name: 'Dourado',
    uf: 'SP'
  },
  {
    id: 3514403,
    name: 'Dracena',
    uf: 'SP'
  },
  {
    id: 3514502,
    name: 'Duartina',
    uf: 'SP'
  },
  {
    id: 3514601,
    name: 'Dumont',
    uf: 'SP'
  },
  {
    id: 3514700,
    name: 'Echaporã',
    uf: 'SP'
  },
  {
    id: 3514809,
    name: 'Eldorado',
    uf: 'SP'
  },
  {
    id: 3514908,
    name: 'Elias Fausto',
    uf: 'SP'
  },
  {
    id: 3514924,
    name: 'Elisiário',
    uf: 'SP'
  },
  {
    id: 3514957,
    name: 'Embaúba',
    uf: 'SP'
  },
  {
    id: 3515004,
    name: 'Embu das Artes',
    uf: 'SP'
  },
  {
    id: 3515103,
    name: 'Embu-Guaçu',
    uf: 'SP'
  },
  {
    id: 3515129,
    name: 'Emilianópolis',
    uf: 'SP'
  },
  {
    id: 3515152,
    name: 'Engenheiro Coelho',
    uf: 'SP'
  },
  {
    id: 3515186,
    name: 'Espírito Santo do Pinhal',
    uf: 'SP'
  },
  {
    id: 3515194,
    name: 'Espírito Santo do Turvo',
    uf: 'SP'
  },
  {
    id: 3515202,
    name: "Estrela d'Oeste",
    uf: 'SP'
  },
  {
    id: 3515301,
    name: 'Estrela do Norte',
    uf: 'SP'
  },
  {
    id: 3515350,
    name: 'Euclides da Cunha Paulista',
    uf: 'SP'
  },
  {
    id: 3515400,
    name: 'Fartura',
    uf: 'SP'
  },
  {
    id: 3515509,
    name: 'Fernandópolis',
    uf: 'SP'
  },
  {
    id: 3515608,
    name: 'Fernando Prestes',
    uf: 'SP'
  },
  {
    id: 3515657,
    name: 'Fernão',
    uf: 'SP'
  },
  {
    id: 3515707,
    name: 'Ferraz de Vasconcelos',
    uf: 'SP'
  },
  {
    id: 3515806,
    name: 'Flora Rica',
    uf: 'SP'
  },
  {
    id: 3515905,
    name: 'Floreal',
    uf: 'SP'
  },
  {
    id: 3516002,
    name: 'Flórida Paulista',
    uf: 'SP'
  },
  {
    id: 3516101,
    name: 'Florínea',
    uf: 'SP'
  },
  {
    id: 3516200,
    name: 'Franca',
    uf: 'SP'
  },
  {
    id: 3516309,
    name: 'Francisco Morato',
    uf: 'SP'
  },
  {
    id: 3516408,
    name: 'Franco da Rocha',
    uf: 'SP'
  },
  {
    id: 3516507,
    name: 'Gabriel Monteiro',
    uf: 'SP'
  },
  {
    id: 3516606,
    name: 'Gália',
    uf: 'SP'
  },
  {
    id: 3516705,
    name: 'Garça',
    uf: 'SP'
  },
  {
    id: 3516804,
    name: 'Gastão Vidigal',
    uf: 'SP'
  },
  {
    id: 3516853,
    name: 'Gavião Peixoto',
    uf: 'SP'
  },
  {
    id: 3516903,
    name: 'General Salgado',
    uf: 'SP'
  },
  {
    id: 3517000,
    name: 'Getulina',
    uf: 'SP'
  },
  {
    id: 3517109,
    name: 'Glicério',
    uf: 'SP'
  },
  {
    id: 3517208,
    name: 'Guaiçara',
    uf: 'SP'
  },
  {
    id: 3517307,
    name: 'Guaimbê',
    uf: 'SP'
  },
  {
    id: 3517406,
    name: 'Guaíra',
    uf: 'SP'
  },
  {
    id: 3517505,
    name: 'Guapiaçu',
    uf: 'SP'
  },
  {
    id: 3517604,
    name: 'Guapiara',
    uf: 'SP'
  },
  {
    id: 3517703,
    name: 'Guará',
    uf: 'SP'
  },
  {
    id: 3517802,
    name: 'Guaraçaí',
    uf: 'SP'
  },
  {
    id: 3517901,
    name: 'Guaraci',
    uf: 'SP'
  },
  {
    id: 3518008,
    name: "Guarani d'Oeste",
    uf: 'SP'
  },
  {
    id: 3518107,
    name: 'Guarantã',
    uf: 'SP'
  },
  {
    id: 3518206,
    name: 'Guararapes',
    uf: 'SP'
  },
  {
    id: 3518305,
    name: 'Guararema',
    uf: 'SP'
  },
  {
    id: 3518404,
    name: 'Guaratinguetá',
    uf: 'SP'
  },
  {
    id: 3518503,
    name: 'Guareí',
    uf: 'SP'
  },
  {
    id: 3518602,
    name: 'Guariba',
    uf: 'SP'
  },
  {
    id: 3518701,
    name: 'Guarujá',
    uf: 'SP'
  },
  {
    id: 3518800,
    name: 'Guarulhos',
    uf: 'SP'
  },
  {
    id: 3518859,
    name: 'Guatapará',
    uf: 'SP'
  },
  {
    id: 3518909,
    name: 'Guzolândia',
    uf: 'SP'
  },
  {
    id: 3519006,
    name: 'Herculândia',
    uf: 'SP'
  },
  {
    id: 3519055,
    name: 'Holambra',
    uf: 'SP'
  },
  {
    id: 3519071,
    name: 'Hortolândia',
    uf: 'SP'
  },
  {
    id: 3519105,
    name: 'Iacanga',
    uf: 'SP'
  },
  {
    id: 3519204,
    name: 'Iacri',
    uf: 'SP'
  },
  {
    id: 3519253,
    name: 'Iaras',
    uf: 'SP'
  },
  {
    id: 3519303,
    name: 'Ibaté',
    uf: 'SP'
  },
  {
    id: 3519402,
    name: 'Ibirá',
    uf: 'SP'
  },
  {
    id: 3519501,
    name: 'Ibirarema',
    uf: 'SP'
  },
  {
    id: 3519600,
    name: 'Ibitinga',
    uf: 'SP'
  },
  {
    id: 3519709,
    name: 'Ibiúna',
    uf: 'SP'
  },
  {
    id: 3519808,
    name: 'Icém',
    uf: 'SP'
  },
  {
    id: 3519907,
    name: 'Iepê',
    uf: 'SP'
  },
  {
    id: 3520004,
    name: 'Igaraçu do Tietê',
    uf: 'SP'
  },
  {
    id: 3520103,
    name: 'Igarapava',
    uf: 'SP'
  },
  {
    id: 3520202,
    name: 'Igaratá',
    uf: 'SP'
  },
  {
    id: 3520301,
    name: 'Iguape',
    uf: 'SP'
  },
  {
    id: 3520400,
    name: 'Ilhabela',
    uf: 'SP'
  },
  {
    id: 3520426,
    name: 'Ilha Comprida',
    uf: 'SP'
  },
  {
    id: 3520442,
    name: 'Ilha Solteira',
    uf: 'SP'
  },
  {
    id: 3520509,
    name: 'Indaiatuba',
    uf: 'SP'
  },
  {
    id: 3520608,
    name: 'Indiana',
    uf: 'SP'
  },
  {
    id: 3520707,
    name: 'Indiaporã',
    uf: 'SP'
  },
  {
    id: 3520806,
    name: 'Inúbia Paulista',
    uf: 'SP'
  },
  {
    id: 3520905,
    name: 'Ipaussu',
    uf: 'SP'
  },
  {
    id: 3521002,
    name: 'Iperó',
    uf: 'SP'
  },
  {
    id: 3521101,
    name: 'Ipeúna',
    uf: 'SP'
  },
  {
    id: 3521150,
    name: 'Ipiguá',
    uf: 'SP'
  },
  {
    id: 3521200,
    name: 'Iporanga',
    uf: 'SP'
  },
  {
    id: 3521309,
    name: 'Ipuã',
    uf: 'SP'
  },
  {
    id: 3521408,
    name: 'Iracemápolis',
    uf: 'SP'
  },
  {
    id: 3521507,
    name: 'Irapuã',
    uf: 'SP'
  },
  {
    id: 3521606,
    name: 'Irapuru',
    uf: 'SP'
  },
  {
    id: 3521705,
    name: 'Itaberá',
    uf: 'SP'
  },
  {
    id: 3521804,
    name: 'Itaí',
    uf: 'SP'
  },
  {
    id: 3521903,
    name: 'Itajobi',
    uf: 'SP'
  },
  {
    id: 3522000,
    name: 'Itaju',
    uf: 'SP'
  },
  {
    id: 3522109,
    name: 'Itanhaém',
    uf: 'SP'
  },
  {
    id: 3522158,
    name: 'Itaoca',
    uf: 'SP'
  },
  {
    id: 3522208,
    name: 'Itapecerica da Serra',
    uf: 'SP'
  },
  {
    id: 3522307,
    name: 'Itapetininga',
    uf: 'SP'
  },
  {
    id: 3522406,
    name: 'Itapeva',
    uf: 'SP'
  },
  {
    id: 3522505,
    name: 'Itapevi',
    uf: 'SP'
  },
  {
    id: 3522604,
    name: 'Itapira',
    uf: 'SP'
  },
  {
    id: 3522653,
    name: 'Itapirapuã Paulista',
    uf: 'SP'
  },
  {
    id: 3522703,
    name: 'Itápolis',
    uf: 'SP'
  },
  {
    id: 3522802,
    name: 'Itaporanga',
    uf: 'SP'
  },
  {
    id: 3522901,
    name: 'Itapuí',
    uf: 'SP'
  },
  {
    id: 3523008,
    name: 'Itapura',
    uf: 'SP'
  },
  {
    id: 3523107,
    name: 'Itaquaquecetuba',
    uf: 'SP'
  },
  {
    id: 3523206,
    name: 'Itararé',
    uf: 'SP'
  },
  {
    id: 3523305,
    name: 'Itariri',
    uf: 'SP'
  },
  {
    id: 3523404,
    name: 'Itatiba',
    uf: 'SP'
  },
  {
    id: 3523503,
    name: 'Itatinga',
    uf: 'SP'
  },
  {
    id: 3523602,
    name: 'Itirapina',
    uf: 'SP'
  },
  {
    id: 3523701,
    name: 'Itirapuã',
    uf: 'SP'
  },
  {
    id: 3523800,
    name: 'Itobi',
    uf: 'SP'
  },
  {
    id: 3523909,
    name: 'Itu',
    uf: 'SP'
  },
  {
    id: 3524006,
    name: 'Itupeva',
    uf: 'SP'
  },
  {
    id: 3524105,
    name: 'Ituverava',
    uf: 'SP'
  },
  {
    id: 3524204,
    name: 'Jaborandi',
    uf: 'SP'
  },
  {
    id: 3524303,
    name: 'Jaboticabal',
    uf: 'SP'
  },
  {
    id: 3524402,
    name: 'Jacareí',
    uf: 'SP'
  },
  {
    id: 3524501,
    name: 'Jaci',
    uf: 'SP'
  },
  {
    id: 3524600,
    name: 'Jacupiranga',
    uf: 'SP'
  },
  {
    id: 3524709,
    name: 'Jaguariúna',
    uf: 'SP'
  },
  {
    id: 3524808,
    name: 'Jales',
    uf: 'SP'
  },
  {
    id: 3524907,
    name: 'Jambeiro',
    uf: 'SP'
  },
  {
    id: 3525003,
    name: 'Jandira',
    uf: 'SP'
  },
  {
    id: 3525102,
    name: 'Jardinópolis',
    uf: 'SP'
  },
  {
    id: 3525201,
    name: 'Jarinu',
    uf: 'SP'
  },
  {
    id: 3525300,
    name: 'Jaú',
    uf: 'SP'
  },
  {
    id: 3525409,
    name: 'Jeriquara',
    uf: 'SP'
  },
  {
    id: 3525508,
    name: 'Joanópolis',
    uf: 'SP'
  },
  {
    id: 3525607,
    name: 'João Ramalho',
    uf: 'SP'
  },
  {
    id: 3525706,
    name: 'José Bonifácio',
    uf: 'SP'
  },
  {
    id: 3525805,
    name: 'Júlio Mesquita',
    uf: 'SP'
  },
  {
    id: 3525854,
    name: 'Jumirim',
    uf: 'SP'
  },
  {
    id: 3525904,
    name: 'Jundiaí',
    uf: 'SP'
  },
  {
    id: 3526001,
    name: 'Junqueirópolis',
    uf: 'SP'
  },
  {
    id: 3526100,
    name: 'Juquiá',
    uf: 'SP'
  },
  {
    id: 3526209,
    name: 'Juquitiba',
    uf: 'SP'
  },
  {
    id: 3526308,
    name: 'Lagoinha',
    uf: 'SP'
  },
  {
    id: 3526407,
    name: 'Laranjal Paulista',
    uf: 'SP'
  },
  {
    id: 3526506,
    name: 'Lavínia',
    uf: 'SP'
  },
  {
    id: 3526605,
    name: 'Lavrinhas',
    uf: 'SP'
  },
  {
    id: 3526704,
    name: 'Leme',
    uf: 'SP'
  },
  {
    id: 3526803,
    name: 'Lençóis Paulista',
    uf: 'SP'
  },
  {
    id: 3526902,
    name: 'Limeira',
    uf: 'SP'
  },
  {
    id: 3527009,
    name: 'Lindóia',
    uf: 'SP'
  },
  {
    id: 3527108,
    name: 'Lins',
    uf: 'SP'
  },
  {
    id: 3527207,
    name: 'Lorena',
    uf: 'SP'
  },
  {
    id: 3527256,
    name: 'Lourdes',
    uf: 'SP'
  },
  {
    id: 3527306,
    name: 'Louveira',
    uf: 'SP'
  },
  {
    id: 3527405,
    name: 'Lucélia',
    uf: 'SP'
  },
  {
    id: 3527504,
    name: 'Lucianópolis',
    uf: 'SP'
  },
  {
    id: 3527603,
    name: 'Luís Antônio',
    uf: 'SP'
  },
  {
    id: 3527702,
    name: 'Luiziânia',
    uf: 'SP'
  },
  {
    id: 3527801,
    name: 'Lupércio',
    uf: 'SP'
  },
  {
    id: 3527900,
    name: 'Lutécia',
    uf: 'SP'
  },
  {
    id: 3528007,
    name: 'Macatuba',
    uf: 'SP'
  },
  {
    id: 3528106,
    name: 'Macaubal',
    uf: 'SP'
  },
  {
    id: 3528205,
    name: 'Macedônia',
    uf: 'SP'
  },
  {
    id: 3528304,
    name: 'Magda',
    uf: 'SP'
  },
  {
    id: 3528403,
    name: 'Mairinque',
    uf: 'SP'
  },
  {
    id: 3528502,
    name: 'Mairiporã',
    uf: 'SP'
  },
  {
    id: 3528601,
    name: 'Manduri',
    uf: 'SP'
  },
  {
    id: 3528700,
    name: 'Marabá Paulista',
    uf: 'SP'
  },
  {
    id: 3528809,
    name: 'Maracaí',
    uf: 'SP'
  },
  {
    id: 3528858,
    name: 'Marapoama',
    uf: 'SP'
  },
  {
    id: 3528908,
    name: 'Mariápolis',
    uf: 'SP'
  },
  {
    id: 3529005,
    name: 'Marília',
    uf: 'SP'
  },
  {
    id: 3529104,
    name: 'Marinópolis',
    uf: 'SP'
  },
  {
    id: 3529203,
    name: 'Martinópolis',
    uf: 'SP'
  },
  {
    id: 3529302,
    name: 'Matão',
    uf: 'SP'
  },
  {
    id: 3529401,
    name: 'Mauá',
    uf: 'SP'
  },
  {
    id: 3529500,
    name: 'Mendonça',
    uf: 'SP'
  },
  {
    id: 3529609,
    name: 'Meridiano',
    uf: 'SP'
  },
  {
    id: 3529658,
    name: 'Mesópolis',
    uf: 'SP'
  },
  {
    id: 3529708,
    name: 'Miguelópolis',
    uf: 'SP'
  },
  {
    id: 3529807,
    name: 'Mineiros do Tietê',
    uf: 'SP'
  },
  {
    id: 3529906,
    name: 'Miracatu',
    uf: 'SP'
  },
  {
    id: 3530003,
    name: 'Mira Estrela',
    uf: 'SP'
  },
  {
    id: 3530102,
    name: 'Mirandópolis',
    uf: 'SP'
  },
  {
    id: 3530201,
    name: 'Mirante do Paranapanema',
    uf: 'SP'
  },
  {
    id: 3530300,
    name: 'Mirassol',
    uf: 'SP'
  },
  {
    id: 3530409,
    name: 'Mirassolândia',
    uf: 'SP'
  },
  {
    id: 3530508,
    name: 'Mococa',
    uf: 'SP'
  },
  {
    id: 3530607,
    name: 'Mogi das Cruzes',
    uf: 'SP'
  },
  {
    id: 3530706,
    name: 'Mogi Guaçu',
    uf: 'SP'
  },
  {
    id: 3530805,
    name: 'Mogi Mirim',
    uf: 'SP'
  },
  {
    id: 3530904,
    name: 'Mombuca',
    uf: 'SP'
  },
  {
    id: 3531001,
    name: 'Monções',
    uf: 'SP'
  },
  {
    id: 3531100,
    name: 'Mongaguá',
    uf: 'SP'
  },
  {
    id: 3531209,
    name: 'Monte Alegre do Sul',
    uf: 'SP'
  },
  {
    id: 3531308,
    name: 'Monte Alto',
    uf: 'SP'
  },
  {
    id: 3531407,
    name: 'Monte Aprazível',
    uf: 'SP'
  },
  {
    id: 3531506,
    name: 'Monte Azul Paulista',
    uf: 'SP'
  },
  {
    id: 3531605,
    name: 'Monte Castelo',
    uf: 'SP'
  },
  {
    id: 3531704,
    name: 'Monteiro Lobato',
    uf: 'SP'
  },
  {
    id: 3531803,
    name: 'Monte Mor',
    uf: 'SP'
  },
  {
    id: 3531902,
    name: 'Morro Agudo',
    uf: 'SP'
  },
  {
    id: 3532009,
    name: 'Morungaba',
    uf: 'SP'
  },
  {
    id: 3532058,
    name: 'Motuca',
    uf: 'SP'
  },
  {
    id: 3532108,
    name: 'Murutinga do Sul',
    uf: 'SP'
  },
  {
    id: 3532157,
    name: 'Nantes',
    uf: 'SP'
  },
  {
    id: 3532207,
    name: 'Narandiba',
    uf: 'SP'
  },
  {
    id: 3532306,
    name: 'Natividade da Serra',
    uf: 'SP'
  },
  {
    id: 3532405,
    name: 'Nazaré Paulista',
    uf: 'SP'
  },
  {
    id: 3532504,
    name: 'Neves Paulista',
    uf: 'SP'
  },
  {
    id: 3532603,
    name: 'Nhandeara',
    uf: 'SP'
  },
  {
    id: 3532702,
    name: 'Nipoã',
    uf: 'SP'
  },
  {
    id: 3532801,
    name: 'Nova Aliança',
    uf: 'SP'
  },
  {
    id: 3532827,
    name: 'Nova Campina',
    uf: 'SP'
  },
  {
    id: 3532843,
    name: 'Nova Canaã Paulista',
    uf: 'SP'
  },
  {
    id: 3532868,
    name: 'Nova Castilho',
    uf: 'SP'
  },
  {
    id: 3532900,
    name: 'Nova Europa',
    uf: 'SP'
  },
  {
    id: 3533007,
    name: 'Nova Granada',
    uf: 'SP'
  },
  {
    id: 3533106,
    name: 'Nova Guataporanga',
    uf: 'SP'
  },
  {
    id: 3533205,
    name: 'Nova Independência',
    uf: 'SP'
  },
  {
    id: 3533254,
    name: 'Novais',
    uf: 'SP'
  },
  {
    id: 3533304,
    name: 'Nova Luzitânia',
    uf: 'SP'
  },
  {
    id: 3533403,
    name: 'Nova Odessa',
    uf: 'SP'
  },
  {
    id: 3533502,
    name: 'Novo Horizonte',
    uf: 'SP'
  },
  {
    id: 3533601,
    name: 'Nuporanga',
    uf: 'SP'
  },
  {
    id: 3533700,
    name: 'Ocauçu',
    uf: 'SP'
  },
  {
    id: 3533809,
    name: 'Óleo',
    uf: 'SP'
  },
  {
    id: 3533908,
    name: 'Olímpia',
    uf: 'SP'
  },
  {
    id: 3534005,
    name: 'Onda Verde',
    uf: 'SP'
  },
  {
    id: 3534104,
    name: 'Oriente',
    uf: 'SP'
  },
  {
    id: 3534203,
    name: 'Orindiúva',
    uf: 'SP'
  },
  {
    id: 3534302,
    name: 'Orlândia',
    uf: 'SP'
  },
  {
    id: 3534401,
    name: 'Osasco',
    uf: 'SP'
  },
  {
    id: 3534500,
    name: 'Oscar Bressane',
    uf: 'SP'
  },
  {
    id: 3534609,
    name: 'Osvaldo Cruz',
    uf: 'SP'
  },
  {
    id: 3534708,
    name: 'Ourinhos',
    uf: 'SP'
  },
  {
    id: 3534757,
    name: 'Ouroeste',
    uf: 'SP'
  },
  {
    id: 3534807,
    name: 'Ouro Verde',
    uf: 'SP'
  },
  {
    id: 3534906,
    name: 'Pacaembu',
    uf: 'SP'
  },
  {
    id: 3535002,
    name: 'Palestina',
    uf: 'SP'
  },
  {
    id: 3535101,
    name: 'Palmares Paulista',
    uf: 'SP'
  },
  {
    id: 3535200,
    name: "Palmeira d'Oeste",
    uf: 'SP'
  },
  {
    id: 3535309,
    name: 'Palmital',
    uf: 'SP'
  },
  {
    id: 3535408,
    name: 'Panorama',
    uf: 'SP'
  },
  {
    id: 3535507,
    name: 'Paraguaçu Paulista',
    uf: 'SP'
  },
  {
    id: 3535606,
    name: 'Paraibuna',
    uf: 'SP'
  },
  {
    id: 3535705,
    name: 'Paraíso',
    uf: 'SP'
  },
  {
    id: 3535804,
    name: 'Paranapanema',
    uf: 'SP'
  },
  {
    id: 3535903,
    name: 'Paranapuã',
    uf: 'SP'
  },
  {
    id: 3536000,
    name: 'Parapuã',
    uf: 'SP'
  },
  {
    id: 3536109,
    name: 'Pardinho',
    uf: 'SP'
  },
  {
    id: 3536208,
    name: 'Pariquera-Açu',
    uf: 'SP'
  },
  {
    id: 3536257,
    name: 'Parisi',
    uf: 'SP'
  },
  {
    id: 3536307,
    name: 'Patrocínio Paulista',
    uf: 'SP'
  },
  {
    id: 3536406,
    name: 'Paulicéia',
    uf: 'SP'
  },
  {
    id: 3536505,
    name: 'Paulínia',
    uf: 'SP'
  },
  {
    id: 3536570,
    name: 'Paulistânia',
    uf: 'SP'
  },
  {
    id: 3536604,
    name: 'Paulo de Faria',
    uf: 'SP'
  },
  {
    id: 3536703,
    name: 'Pederneiras',
    uf: 'SP'
  },
  {
    id: 3536802,
    name: 'Pedra Bela',
    uf: 'SP'
  },
  {
    id: 3536901,
    name: 'Pedranópolis',
    uf: 'SP'
  },
  {
    id: 3537008,
    name: 'Pedregulho',
    uf: 'SP'
  },
  {
    id: 3537107,
    name: 'Pedreira',
    uf: 'SP'
  },
  {
    id: 3537156,
    name: 'Pedrinhas Paulista',
    uf: 'SP'
  },
  {
    id: 3537206,
    name: 'Pedro de Toledo',
    uf: 'SP'
  },
  {
    id: 3537305,
    name: 'Penápolis',
    uf: 'SP'
  },
  {
    id: 3537404,
    name: 'Pereira Barreto',
    uf: 'SP'
  },
  {
    id: 3537503,
    name: 'Pereiras',
    uf: 'SP'
  },
  {
    id: 3537602,
    name: 'Peruíbe',
    uf: 'SP'
  },
  {
    id: 3537701,
    name: 'Piacatu',
    uf: 'SP'
  },
  {
    id: 3537800,
    name: 'Piedade',
    uf: 'SP'
  },
  {
    id: 3537909,
    name: 'Pilar do Sul',
    uf: 'SP'
  },
  {
    id: 3538006,
    name: 'Pindamonhangaba',
    uf: 'SP'
  },
  {
    id: 3538105,
    name: 'Pindorama',
    uf: 'SP'
  },
  {
    id: 3538204,
    name: 'Pinhalzinho',
    uf: 'SP'
  },
  {
    id: 3538303,
    name: 'Piquerobi',
    uf: 'SP'
  },
  {
    id: 3538501,
    name: 'Piquete',
    uf: 'SP'
  },
  {
    id: 3538600,
    name: 'Piracaia',
    uf: 'SP'
  },
  {
    id: 3538709,
    name: 'Piracicaba',
    uf: 'SP'
  },
  {
    id: 3538808,
    name: 'Piraju',
    uf: 'SP'
  },
  {
    id: 3538907,
    name: 'Pirajuí',
    uf: 'SP'
  },
  {
    id: 3539004,
    name: 'Pirangi',
    uf: 'SP'
  },
  {
    id: 3539103,
    name: 'Pirapora do Bom Jesus',
    uf: 'SP'
  },
  {
    id: 3539202,
    name: 'Pirapozinho',
    uf: 'SP'
  },
  {
    id: 3539301,
    name: 'Pirassununga',
    uf: 'SP'
  },
  {
    id: 3539400,
    name: 'Piratininga',
    uf: 'SP'
  },
  {
    id: 3539509,
    name: 'Pitangueiras',
    uf: 'SP'
  },
  {
    id: 3539608,
    name: 'Planalto',
    uf: 'SP'
  },
  {
    id: 3539707,
    name: 'Platina',
    uf: 'SP'
  },
  {
    id: 3539806,
    name: 'Poá',
    uf: 'SP'
  },
  {
    id: 3539905,
    name: 'Poloni',
    uf: 'SP'
  },
  {
    id: 3540002,
    name: 'Pompéia',
    uf: 'SP'
  },
  {
    id: 3540101,
    name: 'Pongaí',
    uf: 'SP'
  },
  {
    id: 3540200,
    name: 'Pontal',
    uf: 'SP'
  },
  {
    id: 3540259,
    name: 'Pontalinda',
    uf: 'SP'
  },
  {
    id: 3540309,
    name: 'Pontes Gestal',
    uf: 'SP'
  },
  {
    id: 3540408,
    name: 'Populina',
    uf: 'SP'
  },
  {
    id: 3540507,
    name: 'Porangaba',
    uf: 'SP'
  },
  {
    id: 3540606,
    name: 'Porto Feliz',
    uf: 'SP'
  },
  {
    id: 3540705,
    name: 'Porto Ferreira',
    uf: 'SP'
  },
  {
    id: 3540754,
    name: 'Potim',
    uf: 'SP'
  },
  {
    id: 3540804,
    name: 'Potirendaba',
    uf: 'SP'
  },
  {
    id: 3540853,
    name: 'Pracinha',
    uf: 'SP'
  },
  {
    id: 3540903,
    name: 'Pradópolis',
    uf: 'SP'
  },
  {
    id: 3541000,
    name: 'Praia Grande',
    uf: 'SP'
  },
  {
    id: 3541059,
    name: 'Pratânia',
    uf: 'SP'
  },
  {
    id: 3541109,
    name: 'Presidente Alves',
    uf: 'SP'
  },
  {
    id: 3541208,
    name: 'Presidente Bernardes',
    uf: 'SP'
  },
  {
    id: 3541307,
    name: 'Presidente Epitácio',
    uf: 'SP'
  },
  {
    id: 3541406,
    name: 'Presidente Prudente',
    uf: 'SP'
  },
  {
    id: 3541505,
    name: 'Presidente Venceslau',
    uf: 'SP'
  },
  {
    id: 3541604,
    name: 'Promissão',
    uf: 'SP'
  },
  {
    id: 3541653,
    name: 'Quadra',
    uf: 'SP'
  },
  {
    id: 3541703,
    name: 'Quatá',
    uf: 'SP'
  },
  {
    id: 3541802,
    name: 'Queiroz',
    uf: 'SP'
  },
  {
    id: 3541901,
    name: 'Queluz',
    uf: 'SP'
  },
  {
    id: 3542008,
    name: 'Quintana',
    uf: 'SP'
  },
  {
    id: 3542107,
    name: 'Rafard',
    uf: 'SP'
  },
  {
    id: 3542206,
    name: 'Rancharia',
    uf: 'SP'
  },
  {
    id: 3542305,
    name: 'Redenção da Serra',
    uf: 'SP'
  },
  {
    id: 3542404,
    name: 'Regente Feijó',
    uf: 'SP'
  },
  {
    id: 3542503,
    name: 'Reginópolis',
    uf: 'SP'
  },
  {
    id: 3542602,
    name: 'Registro',
    uf: 'SP'
  },
  {
    id: 3542701,
    name: 'Restinga',
    uf: 'SP'
  },
  {
    id: 3542800,
    name: 'Ribeira',
    uf: 'SP'
  },
  {
    id: 3542909,
    name: 'Ribeirão Bonito',
    uf: 'SP'
  },
  {
    id: 3543006,
    name: 'Ribeirão Branco',
    uf: 'SP'
  },
  {
    id: 3543105,
    name: 'Ribeirão Corrente',
    uf: 'SP'
  },
  {
    id: 3543204,
    name: 'Ribeirão do Sul',
    uf: 'SP'
  },
  {
    id: 3543238,
    name: 'Ribeirão dos Índios',
    uf: 'SP'
  },
  {
    id: 3543253,
    name: 'Ribeirão Grande',
    uf: 'SP'
  },
  {
    id: 3543303,
    name: 'Ribeirão Pires',
    uf: 'SP'
  },
  {
    id: 3543402,
    name: 'Ribeirão Preto',
    uf: 'SP'
  },
  {
    id: 3543501,
    name: 'Riversul',
    uf: 'SP'
  },
  {
    id: 3543600,
    name: 'Rifaina',
    uf: 'SP'
  },
  {
    id: 3543709,
    name: 'Rincão',
    uf: 'SP'
  },
  {
    id: 3543808,
    name: 'Rinópolis',
    uf: 'SP'
  },
  {
    id: 3543907,
    name: 'Rio Claro',
    uf: 'SP'
  },
  {
    id: 3544004,
    name: 'Rio das Pedras',
    uf: 'SP'
  },
  {
    id: 3544103,
    name: 'Rio Grande da Serra',
    uf: 'SP'
  },
  {
    id: 3544202,
    name: 'Riolândia',
    uf: 'SP'
  },
  {
    id: 3544251,
    name: 'Rosana',
    uf: 'SP'
  },
  {
    id: 3544301,
    name: 'Roseira',
    uf: 'SP'
  },
  {
    id: 3544400,
    name: 'Rubiácea',
    uf: 'SP'
  },
  {
    id: 3544509,
    name: 'Rubinéia',
    uf: 'SP'
  },
  {
    id: 3544608,
    name: 'Sabino',
    uf: 'SP'
  },
  {
    id: 3544707,
    name: 'Sagres',
    uf: 'SP'
  },
  {
    id: 3544806,
    name: 'Sales',
    uf: 'SP'
  },
  {
    id: 3544905,
    name: 'Sales Oliveira',
    uf: 'SP'
  },
  {
    id: 3545001,
    name: 'Salesópolis',
    uf: 'SP'
  },
  {
    id: 3545100,
    name: 'Salmourão',
    uf: 'SP'
  },
  {
    id: 3545159,
    name: 'Saltinho',
    uf: 'SP'
  },
  {
    id: 3545209,
    name: 'Salto',
    uf: 'SP'
  },
  {
    id: 3545308,
    name: 'Salto de Pirapora',
    uf: 'SP'
  },
  {
    id: 3545407,
    name: 'Salto Grande',
    uf: 'SP'
  },
  {
    id: 3545506,
    name: 'Sandovalina',
    uf: 'SP'
  },
  {
    id: 3545605,
    name: 'Santa Adélia',
    uf: 'SP'
  },
  {
    id: 3545704,
    name: 'Santa Albertina',
    uf: 'SP'
  },
  {
    id: 3545803,
    name: "Santa Bárbara d'Oeste",
    uf: 'SP'
  },
  {
    id: 3546009,
    name: 'Santa Branca',
    uf: 'SP'
  },
  {
    id: 3546108,
    name: "Santa Clara d'Oeste",
    uf: 'SP'
  },
  {
    id: 3546207,
    name: 'Santa Cruz da Conceição',
    uf: 'SP'
  },
  {
    id: 3546256,
    name: 'Santa Cruz da Esperança',
    uf: 'SP'
  },
  {
    id: 3546306,
    name: 'Santa Cruz das Palmeiras',
    uf: 'SP'
  },
  {
    id: 3546405,
    name: 'Santa Cruz do Rio Pardo',
    uf: 'SP'
  },
  {
    id: 3546504,
    name: 'Santa Ernestina',
    uf: 'SP'
  },
  {
    id: 3546603,
    name: 'Santa Fé do Sul',
    uf: 'SP'
  },
  {
    id: 3546702,
    name: 'Santa Gertrudes',
    uf: 'SP'
  },
  {
    id: 3546801,
    name: 'Santa Isabel',
    uf: 'SP'
  },
  {
    id: 3546900,
    name: 'Santa Lúcia',
    uf: 'SP'
  },
  {
    id: 3547007,
    name: 'Santa Maria da Serra',
    uf: 'SP'
  },
  {
    id: 3547106,
    name: 'Santa Mercedes',
    uf: 'SP'
  },
  {
    id: 3547205,
    name: 'Santana da Ponte Pensa',
    uf: 'SP'
  },
  {
    id: 3547304,
    name: 'Santana de Parnaíba',
    uf: 'SP'
  },
  {
    id: 3547403,
    name: "Santa Rita d'Oeste",
    uf: 'SP'
  },
  {
    id: 3547502,
    name: 'Santa Rita do Passa Quatro',
    uf: 'SP'
  },
  {
    id: 3547601,
    name: 'Santa Rosa de Viterbo',
    uf: 'SP'
  },
  {
    id: 3547650,
    name: 'Santa Salete',
    uf: 'SP'
  },
  {
    id: 3547700,
    name: 'Santo Anastácio',
    uf: 'SP'
  },
  {
    id: 3547809,
    name: 'Santo André',
    uf: 'SP'
  },
  {
    id: 3547908,
    name: 'Santo Antônio da Alegria',
    uf: 'SP'
  },
  {
    id: 3548005,
    name: 'Santo Antônio de Posse',
    uf: 'SP'
  },
  {
    id: 3548054,
    name: 'Santo Antônio do Aracanguá',
    uf: 'SP'
  },
  {
    id: 3548104,
    name: 'Santo Antônio do Jardim',
    uf: 'SP'
  },
  {
    id: 3548203,
    name: 'Santo Antônio do Pinhal',
    uf: 'SP'
  },
  {
    id: 3548302,
    name: 'Santo Expedito',
    uf: 'SP'
  },
  {
    id: 3548401,
    name: 'Santópolis do Aguapeí',
    uf: 'SP'
  },
  {
    id: 3548500,
    name: 'Santos',
    uf: 'SP'
  },
  {
    id: 3548609,
    name: 'São Bento do Sapucaí',
    uf: 'SP'
  },
  {
    id: 3548708,
    name: 'São Bernardo do Campo',
    uf: 'SP'
  },
  {
    id: 3548807,
    name: 'São Caetano do Sul',
    uf: 'SP'
  },
  {
    id: 3548906,
    name: 'São Carlos',
    uf: 'SP'
  },
  {
    id: 3549003,
    name: 'São Francisco',
    uf: 'SP'
  },
  {
    id: 3549102,
    name: 'São João da Boa Vista',
    uf: 'SP'
  },
  {
    id: 3549201,
    name: 'São João das Duas Pontes',
    uf: 'SP'
  },
  {
    id: 3549250,
    name: 'São João de Iracema',
    uf: 'SP'
  },
  {
    id: 3549300,
    name: "São João do Pau d'Alho",
    uf: 'SP'
  },
  {
    id: 3549409,
    name: 'São Joaquim da Barra',
    uf: 'SP'
  },
  {
    id: 3549508,
    name: 'São José da Bela Vista',
    uf: 'SP'
  },
  {
    id: 3549607,
    name: 'São José do Barreiro',
    uf: 'SP'
  },
  {
    id: 3549706,
    name: 'São José do Rio Pardo',
    uf: 'SP'
  },
  {
    id: 3549805,
    name: 'São José do Rio Preto',
    uf: 'SP'
  },
  {
    id: 3549904,
    name: 'São José dos Campos',
    uf: 'SP'
  },
  {
    id: 3549953,
    name: 'São Lourenço da Serra',
    uf: 'SP'
  },
  {
    id: 3550001,
    name: 'São Luiz do Paraitinga',
    uf: 'SP'
  },
  {
    id: 3550100,
    name: 'São Manuel',
    uf: 'SP'
  },
  {
    id: 3550209,
    name: 'São Miguel Arcanjo',
    uf: 'SP'
  },
  {
    id: 3550308,
    name: 'São Paulo',
    uf: 'SP'
  },
  {
    id: 3550407,
    name: 'São Pedro',
    uf: 'SP'
  },
  {
    id: 3550506,
    name: 'São Pedro do Turvo',
    uf: 'SP'
  },
  {
    id: 3550605,
    name: 'São Roque',
    uf: 'SP'
  },
  {
    id: 3550704,
    name: 'São Sebastião',
    uf: 'SP'
  },
  {
    id: 3550803,
    name: 'São Sebastião da Grama',
    uf: 'SP'
  },
  {
    id: 3550902,
    name: 'São Simão',
    uf: 'SP'
  },
  {
    id: 3551009,
    name: 'São Vicente',
    uf: 'SP'
  },
  {
    id: 3551108,
    name: 'Sarapuí',
    uf: 'SP'
  },
  {
    id: 3551207,
    name: 'Sarutaiá',
    uf: 'SP'
  },
  {
    id: 3551306,
    name: 'Sebastianópolis do Sul',
    uf: 'SP'
  },
  {
    id: 3551405,
    name: 'Serra Azul',
    uf: 'SP'
  },
  {
    id: 3551504,
    name: 'Serrana',
    uf: 'SP'
  },
  {
    id: 3551603,
    name: 'Serra Negra',
    uf: 'SP'
  },
  {
    id: 3551702,
    name: 'Sertãozinho',
    uf: 'SP'
  },
  {
    id: 3551801,
    name: 'Sete Barras',
    uf: 'SP'
  },
  {
    id: 3551900,
    name: 'Severínia',
    uf: 'SP'
  },
  {
    id: 3552007,
    name: 'Silveiras',
    uf: 'SP'
  },
  {
    id: 3552106,
    name: 'Socorro',
    uf: 'SP'
  },
  {
    id: 3552205,
    name: 'Sorocaba',
    uf: 'SP'
  },
  {
    id: 3552304,
    name: 'Sud Mennucci',
    uf: 'SP'
  },
  {
    id: 3552403,
    name: 'Sumaré',
    uf: 'SP'
  },
  {
    id: 3552502,
    name: 'Suzano',
    uf: 'SP'
  },
  {
    id: 3552551,
    name: 'Suzanápolis',
    uf: 'SP'
  },
  {
    id: 3552601,
    name: 'Tabapuã',
    uf: 'SP'
  },
  {
    id: 3552700,
    name: 'Tabatinga',
    uf: 'SP'
  },
  {
    id: 3552809,
    name: 'Taboão da Serra',
    uf: 'SP'
  },
  {
    id: 3552908,
    name: 'Taciba',
    uf: 'SP'
  },
  {
    id: 3553005,
    name: 'Taguaí',
    uf: 'SP'
  },
  {
    id: 3553104,
    name: 'Taiaçu',
    uf: 'SP'
  },
  {
    id: 3553203,
    name: 'Taiúva',
    uf: 'SP'
  },
  {
    id: 3553302,
    name: 'Tambaú',
    uf: 'SP'
  },
  {
    id: 3553401,
    name: 'Tanabi',
    uf: 'SP'
  },
  {
    id: 3553500,
    name: 'Tapiraí',
    uf: 'SP'
  },
  {
    id: 3553609,
    name: 'Tapiratiba',
    uf: 'SP'
  },
  {
    id: 3553658,
    name: 'Taquaral',
    uf: 'SP'
  },
  {
    id: 3553708,
    name: 'Taquaritinga',
    uf: 'SP'
  },
  {
    id: 3553807,
    name: 'Taquarituba',
    uf: 'SP'
  },
  {
    id: 3553856,
    name: 'Taquarivaí',
    uf: 'SP'
  },
  {
    id: 3553906,
    name: 'Tarabai',
    uf: 'SP'
  },
  {
    id: 3553955,
    name: 'Tarumã',
    uf: 'SP'
  },
  {
    id: 3554003,
    name: 'Tatuí',
    uf: 'SP'
  },
  {
    id: 3554102,
    name: 'Taubaté',
    uf: 'SP'
  },
  {
    id: 3554201,
    name: 'Tejupá',
    uf: 'SP'
  },
  {
    id: 3554300,
    name: 'Teodoro Sampaio',
    uf: 'SP'
  },
  {
    id: 3554409,
    name: 'Terra Roxa',
    uf: 'SP'
  },
  {
    id: 3554508,
    name: 'Tietê',
    uf: 'SP'
  },
  {
    id: 3554607,
    name: 'Timburi',
    uf: 'SP'
  },
  {
    id: 3554656,
    name: 'Torre de Pedra',
    uf: 'SP'
  },
  {
    id: 3554706,
    name: 'Torrinha',
    uf: 'SP'
  },
  {
    id: 3554755,
    name: 'Trabiju',
    uf: 'SP'
  },
  {
    id: 3554805,
    name: 'Tremembé',
    uf: 'SP'
  },
  {
    id: 3554904,
    name: 'Três Fronteiras',
    uf: 'SP'
  },
  {
    id: 3554953,
    name: 'Tuiuti',
    uf: 'SP'
  },
  {
    id: 3555000,
    name: 'Tupã',
    uf: 'SP'
  },
  {
    id: 3555109,
    name: 'Tupi Paulista',
    uf: 'SP'
  },
  {
    id: 3555208,
    name: 'Turiúba',
    uf: 'SP'
  },
  {
    id: 3555307,
    name: 'Turmalina',
    uf: 'SP'
  },
  {
    id: 3555356,
    name: 'Ubarana',
    uf: 'SP'
  },
  {
    id: 3555406,
    name: 'Ubatuba',
    uf: 'SP'
  },
  {
    id: 3555505,
    name: 'Ubirajara',
    uf: 'SP'
  },
  {
    id: 3555604,
    name: 'Uchoa',
    uf: 'SP'
  },
  {
    id: 3555703,
    name: 'União Paulista',
    uf: 'SP'
  },
  {
    id: 3555802,
    name: 'Urânia',
    uf: 'SP'
  },
  {
    id: 3555901,
    name: 'Uru',
    uf: 'SP'
  },
  {
    id: 3556008,
    name: 'Urupês',
    uf: 'SP'
  },
  {
    id: 3556107,
    name: 'Valentim Gentil',
    uf: 'SP'
  },
  {
    id: 3556206,
    name: 'Valinhos',
    uf: 'SP'
  },
  {
    id: 3556305,
    name: 'Valparaíso',
    uf: 'SP'
  },
  {
    id: 3556354,
    name: 'Vargem',
    uf: 'SP'
  },
  {
    id: 3556404,
    name: 'Vargem Grande do Sul',
    uf: 'SP'
  },
  {
    id: 3556453,
    name: 'Vargem Grande Paulista',
    uf: 'SP'
  },
  {
    id: 3556503,
    name: 'Várzea Paulista',
    uf: 'SP'
  },
  {
    id: 3556602,
    name: 'Vera Cruz',
    uf: 'SP'
  },
  {
    id: 3556701,
    name: 'Vinhedo',
    uf: 'SP'
  },
  {
    id: 3556800,
    name: 'Viradouro',
    uf: 'SP'
  },
  {
    id: 3556909,
    name: 'Vista Alegre do Alto',
    uf: 'SP'
  },
  {
    id: 3556958,
    name: 'Vitória Brasil',
    uf: 'SP'
  },
  {
    id: 3557006,
    name: 'Votorantim',
    uf: 'SP'
  },
  {
    id: 3557105,
    name: 'Votuporanga',
    uf: 'SP'
  },
  {
    id: 3557154,
    name: 'Zacarias',
    uf: 'SP'
  },
  {
    id: 3557204,
    name: 'Chavantes',
    uf: 'SP'
  },
  {
    id: 3557303,
    name: 'Estiva Gerbi',
    uf: 'SP'
  },
  {
    id: 4100103,
    name: 'Abatiá',
    uf: 'PR'
  },
  {
    id: 4100202,
    name: 'Adrianópolis',
    uf: 'PR'
  },
  {
    id: 4100301,
    name: 'Agudos do Sul',
    uf: 'PR'
  },
  {
    id: 4100400,
    name: 'Almirante Tamandaré',
    uf: 'PR'
  },
  {
    id: 4100459,
    name: 'Altamira do Paraná',
    uf: 'PR'
  },
  {
    id: 4100509,
    name: 'Altônia',
    uf: 'PR'
  },
  {
    id: 4100608,
    name: 'Alto Paraná',
    uf: 'PR'
  },
  {
    id: 4100707,
    name: 'Alto Piquiri',
    uf: 'PR'
  },
  {
    id: 4100806,
    name: 'Alvorada do Sul',
    uf: 'PR'
  },
  {
    id: 4100905,
    name: 'Amaporã',
    uf: 'PR'
  },
  {
    id: 4101002,
    name: 'Ampére',
    uf: 'PR'
  },
  {
    id: 4101051,
    name: 'Anahy',
    uf: 'PR'
  },
  {
    id: 4101101,
    name: 'Andirá',
    uf: 'PR'
  },
  {
    id: 4101150,
    name: 'Ângulo',
    uf: 'PR'
  },
  {
    id: 4101200,
    name: 'Antonina',
    uf: 'PR'
  },
  {
    id: 4101309,
    name: 'Antônio Olinto',
    uf: 'PR'
  },
  {
    id: 4101408,
    name: 'Apucarana',
    uf: 'PR'
  },
  {
    id: 4101507,
    name: 'Arapongas',
    uf: 'PR'
  },
  {
    id: 4101606,
    name: 'Arapoti',
    uf: 'PR'
  },
  {
    id: 4101655,
    name: 'Arapuã',
    uf: 'PR'
  },
  {
    id: 4101705,
    name: 'Araruna',
    uf: 'PR'
  },
  {
    id: 4101804,
    name: 'Araucária',
    uf: 'PR'
  },
  {
    id: 4101853,
    name: 'Ariranha do Ivaí',
    uf: 'PR'
  },
  {
    id: 4101903,
    name: 'Assaí',
    uf: 'PR'
  },
  {
    id: 4102000,
    name: 'Assis Chateaubriand',
    uf: 'PR'
  },
  {
    id: 4102109,
    name: 'Astorga',
    uf: 'PR'
  },
  {
    id: 4102208,
    name: 'Atalaia',
    uf: 'PR'
  },
  {
    id: 4102307,
    name: 'Balsa Nova',
    uf: 'PR'
  },
  {
    id: 4102406,
    name: 'Bandeirantes',
    uf: 'PR'
  },
  {
    id: 4102505,
    name: 'Barbosa Ferraz',
    uf: 'PR'
  },
  {
    id: 4102604,
    name: 'Barracão',
    uf: 'PR'
  },
  {
    id: 4102703,
    name: 'Barra do Jacaré',
    uf: 'PR'
  },
  {
    id: 4102752,
    name: 'Bela Vista da Caroba',
    uf: 'PR'
  },
  {
    id: 4102802,
    name: 'Bela Vista do Paraíso',
    uf: 'PR'
  },
  {
    id: 4102901,
    name: 'Bituruna',
    uf: 'PR'
  },
  {
    id: 4103008,
    name: 'Boa Esperança',
    uf: 'PR'
  },
  {
    id: 4103024,
    name: 'Boa Esperança do Iguaçu',
    uf: 'PR'
  },
  {
    id: 4103040,
    name: 'Boa Ventura de São Roque',
    uf: 'PR'
  },
  {
    id: 4103057,
    name: 'Boa Vista da Aparecida',
    uf: 'PR'
  },
  {
    id: 4103107,
    name: 'Bocaiúva do Sul',
    uf: 'PR'
  },
  {
    id: 4103156,
    name: 'Bom Jesus do Sul',
    uf: 'PR'
  },
  {
    id: 4103206,
    name: 'Bom Sucesso',
    uf: 'PR'
  },
  {
    id: 4103222,
    name: 'Bom Sucesso do Sul',
    uf: 'PR'
  },
  {
    id: 4103305,
    name: 'Borrazópolis',
    uf: 'PR'
  },
  {
    id: 4103354,
    name: 'Braganey',
    uf: 'PR'
  },
  {
    id: 4103370,
    name: 'Brasilândia do Sul',
    uf: 'PR'
  },
  {
    id: 4103404,
    name: 'Cafeara',
    uf: 'PR'
  },
  {
    id: 4103453,
    name: 'Cafelândia',
    uf: 'PR'
  },
  {
    id: 4103479,
    name: 'Cafezal do Sul',
    uf: 'PR'
  },
  {
    id: 4103503,
    name: 'Califórnia',
    uf: 'PR'
  },
  {
    id: 4103602,
    name: 'Cambará',
    uf: 'PR'
  },
  {
    id: 4103701,
    name: 'Cambé',
    uf: 'PR'
  },
  {
    id: 4103800,
    name: 'Cambira',
    uf: 'PR'
  },
  {
    id: 4103909,
    name: 'Campina da Lagoa',
    uf: 'PR'
  },
  {
    id: 4103958,
    name: 'Campina do Simão',
    uf: 'PR'
  },
  {
    id: 4104006,
    name: 'Campina Grande do Sul',
    uf: 'PR'
  },
  {
    id: 4104055,
    name: 'Campo Bonito',
    uf: 'PR'
  },
  {
    id: 4104105,
    name: 'Campo do Tenente',
    uf: 'PR'
  },
  {
    id: 4104204,
    name: 'Campo Largo',
    uf: 'PR'
  },
  {
    id: 4104253,
    name: 'Campo Magro',
    uf: 'PR'
  },
  {
    id: 4104303,
    name: 'Campo Mourão',
    uf: 'PR'
  },
  {
    id: 4104402,
    name: 'Cândido de Abreu',
    uf: 'PR'
  },
  {
    id: 4104428,
    name: 'Candói',
    uf: 'PR'
  },
  {
    id: 4104451,
    name: 'Cantagalo',
    uf: 'PR'
  },
  {
    id: 4104501,
    name: 'Capanema',
    uf: 'PR'
  },
  {
    id: 4104600,
    name: 'Capitão Leônidas Marques',
    uf: 'PR'
  },
  {
    id: 4104659,
    name: 'Carambeí',
    uf: 'PR'
  },
  {
    id: 4104709,
    name: 'Carlópolis',
    uf: 'PR'
  },
  {
    id: 4104808,
    name: 'Cascavel',
    uf: 'PR'
  },
  {
    id: 4104907,
    name: 'Castro',
    uf: 'PR'
  },
  {
    id: 4105003,
    name: 'Catanduvas',
    uf: 'PR'
  },
  {
    id: 4105102,
    name: 'Centenário do Sul',
    uf: 'PR'
  },
  {
    id: 4105201,
    name: 'Cerro Azul',
    uf: 'PR'
  },
  {
    id: 4105300,
    name: 'Céu Azul',
    uf: 'PR'
  },
  {
    id: 4105409,
    name: 'Chopinzinho',
    uf: 'PR'
  },
  {
    id: 4105508,
    name: 'Cianorte',
    uf: 'PR'
  },
  {
    id: 4105607,
    name: 'Cidade Gaúcha',
    uf: 'PR'
  },
  {
    id: 4105706,
    name: 'Clevelândia',
    uf: 'PR'
  },
  {
    id: 4105805,
    name: 'Colombo',
    uf: 'PR'
  },
  {
    id: 4105904,
    name: 'Colorado',
    uf: 'PR'
  },
  {
    id: 4106001,
    name: 'Congonhinhas',
    uf: 'PR'
  },
  {
    id: 4106100,
    name: 'Conselheiro Mairinck',
    uf: 'PR'
  },
  {
    id: 4106209,
    name: 'Contenda',
    uf: 'PR'
  },
  {
    id: 4106308,
    name: 'Corbélia',
    uf: 'PR'
  },
  {
    id: 4106407,
    name: 'Cornélio Procópio',
    uf: 'PR'
  },
  {
    id: 4106456,
    name: 'Coronel Domingos Soares',
    uf: 'PR'
  },
  {
    id: 4106506,
    name: 'Coronel Vivida',
    uf: 'PR'
  },
  {
    id: 4106555,
    name: 'Corumbataí do Sul',
    uf: 'PR'
  },
  {
    id: 4106571,
    name: 'Cruzeiro do Iguaçu',
    uf: 'PR'
  },
  {
    id: 4106605,
    name: 'Cruzeiro do Oeste',
    uf: 'PR'
  },
  {
    id: 4106704,
    name: 'Cruzeiro do Sul',
    uf: 'PR'
  },
  {
    id: 4106803,
    name: 'Cruz Machado',
    uf: 'PR'
  },
  {
    id: 4106852,
    name: 'Cruzmaltina',
    uf: 'PR'
  },
  {
    id: 4106902,
    name: 'Curitiba',
    uf: 'PR'
  },
  {
    id: 4107009,
    name: 'Curiúva',
    uf: 'PR'
  },
  {
    id: 4107108,
    name: 'Diamante do Norte',
    uf: 'PR'
  },
  {
    id: 4107124,
    name: 'Diamante do Sul',
    uf: 'PR'
  },
  {
    id: 4107157,
    name: "Diamante D'Oeste",
    uf: 'PR'
  },
  {
    id: 4107207,
    name: 'Dois Vizinhos',
    uf: 'PR'
  },
  {
    id: 4107256,
    name: 'Douradina',
    uf: 'PR'
  },
  {
    id: 4107306,
    name: 'Doutor Camargo',
    uf: 'PR'
  },
  {
    id: 4107405,
    name: 'Enéas Marques',
    uf: 'PR'
  },
  {
    id: 4107504,
    name: 'Engenheiro Beltrão',
    uf: 'PR'
  },
  {
    id: 4107520,
    name: 'Esperança Nova',
    uf: 'PR'
  },
  {
    id: 4107538,
    name: 'Entre Rios do Oeste',
    uf: 'PR'
  },
  {
    id: 4107546,
    name: 'Espigão Alto do Iguaçu',
    uf: 'PR'
  },
  {
    id: 4107553,
    name: 'Farol',
    uf: 'PR'
  },
  {
    id: 4107603,
    name: 'Faxinal',
    uf: 'PR'
  },
  {
    id: 4107652,
    name: 'Fazenda Rio Grande',
    uf: 'PR'
  },
  {
    id: 4107702,
    name: 'Fênix',
    uf: 'PR'
  },
  {
    id: 4107736,
    name: 'Fernandes Pinheiro',
    uf: 'PR'
  },
  {
    id: 4107751,
    name: 'Figueira',
    uf: 'PR'
  },
  {
    id: 4107801,
    name: 'Floraí',
    uf: 'PR'
  },
  {
    id: 4107850,
    name: 'Flor da Serra do Sul',
    uf: 'PR'
  },
  {
    id: 4107900,
    name: 'Floresta',
    uf: 'PR'
  },
  {
    id: 4108007,
    name: 'Florestópolis',
    uf: 'PR'
  },
  {
    id: 4108106,
    name: 'Flórida',
    uf: 'PR'
  },
  {
    id: 4108205,
    name: 'Formosa do Oeste',
    uf: 'PR'
  },
  {
    id: 4108304,
    name: 'Foz do Iguaçu',
    uf: 'PR'
  },
  {
    id: 4108320,
    name: 'Francisco Alves',
    uf: 'PR'
  },
  {
    id: 4108403,
    name: 'Francisco Beltrão',
    uf: 'PR'
  },
  {
    id: 4108452,
    name: 'Foz do Jordão',
    uf: 'PR'
  },
  {
    id: 4108502,
    name: 'General Carneiro',
    uf: 'PR'
  },
  {
    id: 4108551,
    name: 'Godoy Moreira',
    uf: 'PR'
  },
  {
    id: 4108601,
    name: 'Goioerê',
    uf: 'PR'
  },
  {
    id: 4108650,
    name: 'Goioxim',
    uf: 'PR'
  },
  {
    id: 4108700,
    name: 'Grandes Rios',
    uf: 'PR'
  },
  {
    id: 4108809,
    name: 'Guaíra',
    uf: 'PR'
  },
  {
    id: 4108908,
    name: 'Guairaçá',
    uf: 'PR'
  },
  {
    id: 4108957,
    name: 'Guamiranga',
    uf: 'PR'
  },
  {
    id: 4109005,
    name: 'Guapirama',
    uf: 'PR'
  },
  {
    id: 4109104,
    name: 'Guaporema',
    uf: 'PR'
  },
  {
    id: 4109203,
    name: 'Guaraci',
    uf: 'PR'
  },
  {
    id: 4109302,
    name: 'Guaraniaçu',
    uf: 'PR'
  },
  {
    id: 4109401,
    name: 'Guarapuava',
    uf: 'PR'
  },
  {
    id: 4109500,
    name: 'Guaraqueçaba',
    uf: 'PR'
  },
  {
    id: 4109609,
    name: 'Guaratuba',
    uf: 'PR'
  },
  {
    id: 4109658,
    name: 'Honório Serpa',
    uf: 'PR'
  },
  {
    id: 4109708,
    name: 'Ibaiti',
    uf: 'PR'
  },
  {
    id: 4109757,
    name: 'Ibema',
    uf: 'PR'
  },
  {
    id: 4109807,
    name: 'Ibiporã',
    uf: 'PR'
  },
  {
    id: 4109906,
    name: 'Icaraíma',
    uf: 'PR'
  },
  {
    id: 4110003,
    name: 'Iguaraçu',
    uf: 'PR'
  },
  {
    id: 4110052,
    name: 'Iguatu',
    uf: 'PR'
  },
  {
    id: 4110078,
    name: 'Imbaú',
    uf: 'PR'
  },
  {
    id: 4110102,
    name: 'Imbituva',
    uf: 'PR'
  },
  {
    id: 4110201,
    name: 'Inácio Martins',
    uf: 'PR'
  },
  {
    id: 4110300,
    name: 'Inajá',
    uf: 'PR'
  },
  {
    id: 4110409,
    name: 'Indianópolis',
    uf: 'PR'
  },
  {
    id: 4110508,
    name: 'Ipiranga',
    uf: 'PR'
  },
  {
    id: 4110607,
    name: 'Iporã',
    uf: 'PR'
  },
  {
    id: 4110656,
    name: 'Iracema do Oeste',
    uf: 'PR'
  },
  {
    id: 4110706,
    name: 'Irati',
    uf: 'PR'
  },
  {
    id: 4110805,
    name: 'Iretama',
    uf: 'PR'
  },
  {
    id: 4110904,
    name: 'Itaguajé',
    uf: 'PR'
  },
  {
    id: 4110953,
    name: 'Itaipulândia',
    uf: 'PR'
  },
  {
    id: 4111001,
    name: 'Itambaracá',
    uf: 'PR'
  },
  {
    id: 4111100,
    name: 'Itambé',
    uf: 'PR'
  },
  {
    id: 4111209,
    name: "Itapejara d'Oeste",
    uf: 'PR'
  },
  {
    id: 4111258,
    name: 'Itaperuçu',
    uf: 'PR'
  },
  {
    id: 4111308,
    name: 'Itaúna do Sul',
    uf: 'PR'
  },
  {
    id: 4111407,
    name: 'Ivaí',
    uf: 'PR'
  },
  {
    id: 4111506,
    name: 'Ivaiporã',
    uf: 'PR'
  },
  {
    id: 4111555,
    name: 'Ivaté',
    uf: 'PR'
  },
  {
    id: 4111605,
    name: 'Ivatuba',
    uf: 'PR'
  },
  {
    id: 4111704,
    name: 'Jaboti',
    uf: 'PR'
  },
  {
    id: 4111803,
    name: 'Jacarezinho',
    uf: 'PR'
  },
  {
    id: 4111902,
    name: 'Jaguapitã',
    uf: 'PR'
  },
  {
    id: 4112009,
    name: 'Jaguariaíva',
    uf: 'PR'
  },
  {
    id: 4112108,
    name: 'Jandaia do Sul',
    uf: 'PR'
  },
  {
    id: 4112207,
    name: 'Janiópolis',
    uf: 'PR'
  },
  {
    id: 4112306,
    name: 'Japira',
    uf: 'PR'
  },
  {
    id: 4112405,
    name: 'Japurá',
    uf: 'PR'
  },
  {
    id: 4112504,
    name: 'Jardim Alegre',
    uf: 'PR'
  },
  {
    id: 4112603,
    name: 'Jardim Olinda',
    uf: 'PR'
  },
  {
    id: 4112702,
    name: 'Jataizinho',
    uf: 'PR'
  },
  {
    id: 4112751,
    name: 'Jesuítas',
    uf: 'PR'
  },
  {
    id: 4112801,
    name: 'Joaquim Távora',
    uf: 'PR'
  },
  {
    id: 4112900,
    name: 'Jundiaí do Sul',
    uf: 'PR'
  },
  {
    id: 4112959,
    name: 'Juranda',
    uf: 'PR'
  },
  {
    id: 4113007,
    name: 'Jussara',
    uf: 'PR'
  },
  {
    id: 4113106,
    name: 'Kaloré',
    uf: 'PR'
  },
  {
    id: 4113205,
    name: 'Lapa',
    uf: 'PR'
  },
  {
    id: 4113254,
    name: 'Laranjal',
    uf: 'PR'
  },
  {
    id: 4113304,
    name: 'Laranjeiras do Sul',
    uf: 'PR'
  },
  {
    id: 4113403,
    name: 'Leópolis',
    uf: 'PR'
  },
  {
    id: 4113429,
    name: 'Lidianópolis',
    uf: 'PR'
  },
  {
    id: 4113452,
    name: 'Lindoeste',
    uf: 'PR'
  },
  {
    id: 4113502,
    name: 'Loanda',
    uf: 'PR'
  },
  {
    id: 4113601,
    name: 'Lobato',
    uf: 'PR'
  },
  {
    id: 4113700,
    name: 'Londrina',
    uf: 'PR'
  },
  {
    id: 4113734,
    name: 'Luiziana',
    uf: 'PR'
  },
  {
    id: 4113759,
    name: 'Lunardelli',
    uf: 'PR'
  },
  {
    id: 4113809,
    name: 'Lupionópolis',
    uf: 'PR'
  },
  {
    id: 4113908,
    name: 'Mallet',
    uf: 'PR'
  },
  {
    id: 4114005,
    name: 'Mamborê',
    uf: 'PR'
  },
  {
    id: 4114104,
    name: 'Mandaguaçu',
    uf: 'PR'
  },
  {
    id: 4114203,
    name: 'Mandaguari',
    uf: 'PR'
  },
  {
    id: 4114302,
    name: 'Mandirituba',
    uf: 'PR'
  },
  {
    id: 4114351,
    name: 'Manfrinópolis',
    uf: 'PR'
  },
  {
    id: 4114401,
    name: 'Mangueirinha',
    uf: 'PR'
  },
  {
    id: 4114500,
    name: 'Manoel Ribas',
    uf: 'PR'
  },
  {
    id: 4114609,
    name: 'Marechal Cândido Rondon',
    uf: 'PR'
  },
  {
    id: 4114708,
    name: 'Maria Helena',
    uf: 'PR'
  },
  {
    id: 4114807,
    name: 'Marialva',
    uf: 'PR'
  },
  {
    id: 4114906,
    name: 'Marilândia do Sul',
    uf: 'PR'
  },
  {
    id: 4115002,
    name: 'Marilena',
    uf: 'PR'
  },
  {
    id: 4115101,
    name: 'Mariluz',
    uf: 'PR'
  },
  {
    id: 4115200,
    name: 'Maringá',
    uf: 'PR'
  },
  {
    id: 4115309,
    name: 'Mariópolis',
    uf: 'PR'
  },
  {
    id: 4115358,
    name: 'Maripá',
    uf: 'PR'
  },
  {
    id: 4115408,
    name: 'Marmeleiro',
    uf: 'PR'
  },
  {
    id: 4115457,
    name: 'Marquinho',
    uf: 'PR'
  },
  {
    id: 4115507,
    name: 'Marumbi',
    uf: 'PR'
  },
  {
    id: 4115606,
    name: 'Matelândia',
    uf: 'PR'
  },
  {
    id: 4115705,
    name: 'Matinhos',
    uf: 'PR'
  },
  {
    id: 4115739,
    name: 'Mato Rico',
    uf: 'PR'
  },
  {
    id: 4115754,
    name: 'Mauá da Serra',
    uf: 'PR'
  },
  {
    id: 4115804,
    name: 'Medianeira',
    uf: 'PR'
  },
  {
    id: 4115853,
    name: 'Mercedes',
    uf: 'PR'
  },
  {
    id: 4115903,
    name: 'Mirador',
    uf: 'PR'
  },
  {
    id: 4116000,
    name: 'Miraselva',
    uf: 'PR'
  },
  {
    id: 4116059,
    name: 'Missal',
    uf: 'PR'
  },
  {
    id: 4116109,
    name: 'Moreira Sales',
    uf: 'PR'
  },
  {
    id: 4116208,
    name: 'Morretes',
    uf: 'PR'
  },
  {
    id: 4116307,
    name: 'Munhoz de Melo',
    uf: 'PR'
  },
  {
    id: 4116406,
    name: 'Nossa Senhora das Graças',
    uf: 'PR'
  },
  {
    id: 4116505,
    name: 'Nova Aliança do Ivaí',
    uf: 'PR'
  },
  {
    id: 4116604,
    name: 'Nova América da Colina',
    uf: 'PR'
  },
  {
    id: 4116703,
    name: 'Nova Aurora',
    uf: 'PR'
  },
  {
    id: 4116802,
    name: 'Nova Cantu',
    uf: 'PR'
  },
  {
    id: 4116901,
    name: 'Nova Esperança',
    uf: 'PR'
  },
  {
    id: 4116950,
    name: 'Nova Esperança do Sudoeste',
    uf: 'PR'
  },
  {
    id: 4117008,
    name: 'Nova Fátima',
    uf: 'PR'
  },
  {
    id: 4117057,
    name: 'Nova Laranjeiras',
    uf: 'PR'
  },
  {
    id: 4117107,
    name: 'Nova Londrina',
    uf: 'PR'
  },
  {
    id: 4117206,
    name: 'Nova Olímpia',
    uf: 'PR'
  },
  {
    id: 4117214,
    name: 'Nova Santa Bárbara',
    uf: 'PR'
  },
  {
    id: 4117222,
    name: 'Nova Santa Rosa',
    uf: 'PR'
  },
  {
    id: 4117255,
    name: 'Nova Prata do Iguaçu',
    uf: 'PR'
  },
  {
    id: 4117271,
    name: 'Nova Tebas',
    uf: 'PR'
  },
  {
    id: 4117297,
    name: 'Novo Itacolomi',
    uf: 'PR'
  },
  {
    id: 4117305,
    name: 'Ortigueira',
    uf: 'PR'
  },
  {
    id: 4117404,
    name: 'Ourizona',
    uf: 'PR'
  },
  {
    id: 4117453,
    name: 'Ouro Verde do Oeste',
    uf: 'PR'
  },
  {
    id: 4117503,
    name: 'Paiçandu',
    uf: 'PR'
  },
  {
    id: 4117602,
    name: 'Palmas',
    uf: 'PR'
  },
  {
    id: 4117701,
    name: 'Palmeira',
    uf: 'PR'
  },
  {
    id: 4117800,
    name: 'Palmital',
    uf: 'PR'
  },
  {
    id: 4117909,
    name: 'Palotina',
    uf: 'PR'
  },
  {
    id: 4118006,
    name: 'Paraíso do Norte',
    uf: 'PR'
  },
  {
    id: 4118105,
    name: 'Paranacity',
    uf: 'PR'
  },
  {
    id: 4118204,
    name: 'Paranaguá',
    uf: 'PR'
  },
  {
    id: 4118303,
    name: 'Paranapoema',
    uf: 'PR'
  },
  {
    id: 4118402,
    name: 'Paranavaí',
    uf: 'PR'
  },
  {
    id: 4118451,
    name: 'Pato Bragado',
    uf: 'PR'
  },
  {
    id: 4118501,
    name: 'Pato Branco',
    uf: 'PR'
  },
  {
    id: 4118600,
    name: 'Paula Freitas',
    uf: 'PR'
  },
  {
    id: 4118709,
    name: 'Paulo Frontin',
    uf: 'PR'
  },
  {
    id: 4118808,
    name: 'Peabiru',
    uf: 'PR'
  },
  {
    id: 4118857,
    name: 'Perobal',
    uf: 'PR'
  },
  {
    id: 4118907,
    name: 'Pérola',
    uf: 'PR'
  },
  {
    id: 4119004,
    name: "Pérola d'Oeste",
    uf: 'PR'
  },
  {
    id: 4119103,
    name: 'Piên',
    uf: 'PR'
  },
  {
    id: 4119152,
    name: 'Pinhais',
    uf: 'PR'
  },
  {
    id: 4119202,
    name: 'Pinhalão',
    uf: 'PR'
  },
  {
    id: 4119251,
    name: 'Pinhal de São Bento',
    uf: 'PR'
  },
  {
    id: 4119301,
    name: 'Pinhão',
    uf: 'PR'
  },
  {
    id: 4119400,
    name: 'Piraí do Sul',
    uf: 'PR'
  },
  {
    id: 4119509,
    name: 'Piraquara',
    uf: 'PR'
  },
  {
    id: 4119608,
    name: 'Pitanga',
    uf: 'PR'
  },
  {
    id: 4119657,
    name: 'Pitangueiras',
    uf: 'PR'
  },
  {
    id: 4119707,
    name: 'Planaltina do Paraná',
    uf: 'PR'
  },
  {
    id: 4119806,
    name: 'Planalto',
    uf: 'PR'
  },
  {
    id: 4119905,
    name: 'Ponta Grossa',
    uf: 'PR'
  },
  {
    id: 4119954,
    name: 'Pontal do Paraná',
    uf: 'PR'
  },
  {
    id: 4120002,
    name: 'Porecatu',
    uf: 'PR'
  },
  {
    id: 4120101,
    name: 'Porto Amazonas',
    uf: 'PR'
  },
  {
    id: 4120150,
    name: 'Porto Barreiro',
    uf: 'PR'
  },
  {
    id: 4120200,
    name: 'Porto Rico',
    uf: 'PR'
  },
  {
    id: 4120309,
    name: 'Porto Vitória',
    uf: 'PR'
  },
  {
    id: 4120333,
    name: 'Prado Ferreira',
    uf: 'PR'
  },
  {
    id: 4120358,
    name: 'Pranchita',
    uf: 'PR'
  },
  {
    id: 4120408,
    name: 'Presidente Castelo Branco',
    uf: 'PR'
  },
  {
    id: 4120507,
    name: 'Primeiro de Maio',
    uf: 'PR'
  },
  {
    id: 4120606,
    name: 'Prudentópolis',
    uf: 'PR'
  },
  {
    id: 4120655,
    name: 'Quarto Centenário',
    uf: 'PR'
  },
  {
    id: 4120705,
    name: 'Quatiguá',
    uf: 'PR'
  },
  {
    id: 4120804,
    name: 'Quatro Barras',
    uf: 'PR'
  },
  {
    id: 4120853,
    name: 'Quatro Pontes',
    uf: 'PR'
  },
  {
    id: 4120903,
    name: 'Quedas do Iguaçu',
    uf: 'PR'
  },
  {
    id: 4121000,
    name: 'Querência do Norte',
    uf: 'PR'
  },
  {
    id: 4121109,
    name: 'Quinta do Sol',
    uf: 'PR'
  },
  {
    id: 4121208,
    name: 'Quitandinha',
    uf: 'PR'
  },
  {
    id: 4121257,
    name: 'Ramilândia',
    uf: 'PR'
  },
  {
    id: 4121307,
    name: 'Rancho Alegre',
    uf: 'PR'
  },
  {
    id: 4121356,
    name: "Rancho Alegre D'Oeste",
    uf: 'PR'
  },
  {
    id: 4121406,
    name: 'Realeza',
    uf: 'PR'
  },
  {
    id: 4121505,
    name: 'Rebouças',
    uf: 'PR'
  },
  {
    id: 4121604,
    name: 'Renascença',
    uf: 'PR'
  },
  {
    id: 4121703,
    name: 'Reserva',
    uf: 'PR'
  },
  {
    id: 4121752,
    name: 'Reserva do Iguaçu',
    uf: 'PR'
  },
  {
    id: 4121802,
    name: 'Ribeirão Claro',
    uf: 'PR'
  },
  {
    id: 4121901,
    name: 'Ribeirão do Pinhal',
    uf: 'PR'
  },
  {
    id: 4122008,
    name: 'Rio Azul',
    uf: 'PR'
  },
  {
    id: 4122107,
    name: 'Rio Bom',
    uf: 'PR'
  },
  {
    id: 4122156,
    name: 'Rio Bonito do Iguaçu',
    uf: 'PR'
  },
  {
    id: 4122172,
    name: 'Rio Branco do Ivaí',
    uf: 'PR'
  },
  {
    id: 4122206,
    name: 'Rio Branco do Sul',
    uf: 'PR'
  },
  {
    id: 4122305,
    name: 'Rio Negro',
    uf: 'PR'
  },
  {
    id: 4122404,
    name: 'Rolândia',
    uf: 'PR'
  },
  {
    id: 4122503,
    name: 'Roncador',
    uf: 'PR'
  },
  {
    id: 4122602,
    name: 'Rondon',
    uf: 'PR'
  },
  {
    id: 4122651,
    name: 'Rosário do Ivaí',
    uf: 'PR'
  },
  {
    id: 4122701,
    name: 'Sabáudia',
    uf: 'PR'
  },
  {
    id: 4122800,
    name: 'Salgado Filho',
    uf: 'PR'
  },
  {
    id: 4122909,
    name: 'Salto do Itararé',
    uf: 'PR'
  },
  {
    id: 4123006,
    name: 'Salto do Lontra',
    uf: 'PR'
  },
  {
    id: 4123105,
    name: 'Santa Amélia',
    uf: 'PR'
  },
  {
    id: 4123204,
    name: 'Santa Cecília do Pavão',
    uf: 'PR'
  },
  {
    id: 4123303,
    name: 'Santa Cruz de Monte Castelo',
    uf: 'PR'
  },
  {
    id: 4123402,
    name: 'Santa Fé',
    uf: 'PR'
  },
  {
    id: 4123501,
    name: 'Santa Helena',
    uf: 'PR'
  },
  {
    id: 4123600,
    name: 'Santa Inês',
    uf: 'PR'
  },
  {
    id: 4123709,
    name: 'Santa Isabel do Ivaí',
    uf: 'PR'
  },
  {
    id: 4123808,
    name: 'Santa Izabel do Oeste',
    uf: 'PR'
  },
  {
    id: 4123824,
    name: 'Santa Lúcia',
    uf: 'PR'
  },
  {
    id: 4123857,
    name: 'Santa Maria do Oeste',
    uf: 'PR'
  },
  {
    id: 4123907,
    name: 'Santa Mariana',
    uf: 'PR'
  },
  {
    id: 4123956,
    name: 'Santa Mônica',
    uf: 'PR'
  },
  {
    id: 4124004,
    name: 'Santana do Itararé',
    uf: 'PR'
  },
  {
    id: 4124020,
    name: 'Santa Tereza do Oeste',
    uf: 'PR'
  },
  {
    id: 4124053,
    name: 'Santa Terezinha de Itaipu',
    uf: 'PR'
  },
  {
    id: 4124103,
    name: 'Santo Antônio da Platina',
    uf: 'PR'
  },
  {
    id: 4124202,
    name: 'Santo Antônio do Caiuá',
    uf: 'PR'
  },
  {
    id: 4124301,
    name: 'Santo Antônio do Paraíso',
    uf: 'PR'
  },
  {
    id: 4124400,
    name: 'Santo Antônio do Sudoeste',
    uf: 'PR'
  },
  {
    id: 4124509,
    name: 'Santo Inácio',
    uf: 'PR'
  },
  {
    id: 4124608,
    name: 'São Carlos do Ivaí',
    uf: 'PR'
  },
  {
    id: 4124707,
    name: 'São Jerônimo da Serra',
    uf: 'PR'
  },
  {
    id: 4124806,
    name: 'São João',
    uf: 'PR'
  },
  {
    id: 4124905,
    name: 'São João do Caiuá',
    uf: 'PR'
  },
  {
    id: 4125001,
    name: 'São João do Ivaí',
    uf: 'PR'
  },
  {
    id: 4125100,
    name: 'São João do Triunfo',
    uf: 'PR'
  },
  {
    id: 4125209,
    name: "São Jorge d'Oeste",
    uf: 'PR'
  },
  {
    id: 4125308,
    name: 'São Jorge do Ivaí',
    uf: 'PR'
  },
  {
    id: 4125357,
    name: 'São Jorge do Patrocínio',
    uf: 'PR'
  },
  {
    id: 4125407,
    name: 'São José da Boa Vista',
    uf: 'PR'
  },
  {
    id: 4125456,
    name: 'São José das Palmeiras',
    uf: 'PR'
  },
  {
    id: 4125506,
    name: 'São José dos Pinhais',
    uf: 'PR'
  },
  {
    id: 4125555,
    name: 'São Manoel do Paraná',
    uf: 'PR'
  },
  {
    id: 4125605,
    name: 'São Mateus do Sul',
    uf: 'PR'
  },
  {
    id: 4125704,
    name: 'São Miguel do Iguaçu',
    uf: 'PR'
  },
  {
    id: 4125753,
    name: 'São Pedro do Iguaçu',
    uf: 'PR'
  },
  {
    id: 4125803,
    name: 'São Pedro do Ivaí',
    uf: 'PR'
  },
  {
    id: 4125902,
    name: 'São Pedro do Paraná',
    uf: 'PR'
  },
  {
    id: 4126009,
    name: 'São Sebastião da Amoreira',
    uf: 'PR'
  },
  {
    id: 4126108,
    name: 'São Tomé',
    uf: 'PR'
  },
  {
    id: 4126207,
    name: 'Sapopema',
    uf: 'PR'
  },
  {
    id: 4126256,
    name: 'Sarandi',
    uf: 'PR'
  },
  {
    id: 4126272,
    name: 'Saudade do Iguaçu',
    uf: 'PR'
  },
  {
    id: 4126306,
    name: 'Sengés',
    uf: 'PR'
  },
  {
    id: 4126355,
    name: 'Serranópolis do Iguaçu',
    uf: 'PR'
  },
  {
    id: 4126405,
    name: 'Sertaneja',
    uf: 'PR'
  },
  {
    id: 4126504,
    name: 'Sertanópolis',
    uf: 'PR'
  },
  {
    id: 4126603,
    name: 'Siqueira Campos',
    uf: 'PR'
  },
  {
    id: 4126652,
    name: 'Sulina',
    uf: 'PR'
  },
  {
    id: 4126678,
    name: 'Tamarana',
    uf: 'PR'
  },
  {
    id: 4126702,
    name: 'Tamboara',
    uf: 'PR'
  },
  {
    id: 4126801,
    name: 'Tapejara',
    uf: 'PR'
  },
  {
    id: 4126900,
    name: 'Tapira',
    uf: 'PR'
  },
  {
    id: 4127007,
    name: 'Teixeira Soares',
    uf: 'PR'
  },
  {
    id: 4127106,
    name: 'Telêmaco Borba',
    uf: 'PR'
  },
  {
    id: 4127205,
    name: 'Terra Boa',
    uf: 'PR'
  },
  {
    id: 4127304,
    name: 'Terra Rica',
    uf: 'PR'
  },
  {
    id: 4127403,
    name: 'Terra Roxa',
    uf: 'PR'
  },
  {
    id: 4127502,
    name: 'Tibagi',
    uf: 'PR'
  },
  {
    id: 4127601,
    name: 'Tijucas do Sul',
    uf: 'PR'
  },
  {
    id: 4127700,
    name: 'Toledo',
    uf: 'PR'
  },
  {
    id: 4127809,
    name: 'Tomazina',
    uf: 'PR'
  },
  {
    id: 4127858,
    name: 'Três Barras do Paraná',
    uf: 'PR'
  },
  {
    id: 4127882,
    name: 'Tunas do Paraná',
    uf: 'PR'
  },
  {
    id: 4127908,
    name: 'Tuneiras do Oeste',
    uf: 'PR'
  },
  {
    id: 4127957,
    name: 'Tupãssi',
    uf: 'PR'
  },
  {
    id: 4127965,
    name: 'Turvo',
    uf: 'PR'
  },
  {
    id: 4128005,
    name: 'Ubiratã',
    uf: 'PR'
  },
  {
    id: 4128104,
    name: 'Umuarama',
    uf: 'PR'
  },
  {
    id: 4128203,
    name: 'União da Vitória',
    uf: 'PR'
  },
  {
    id: 4128302,
    name: 'Uniflor',
    uf: 'PR'
  },
  {
    id: 4128401,
    name: 'Uraí',
    uf: 'PR'
  },
  {
    id: 4128500,
    name: 'Wenceslau Braz',
    uf: 'PR'
  },
  {
    id: 4128534,
    name: 'Ventania',
    uf: 'PR'
  },
  {
    id: 4128559,
    name: 'Vera Cruz do Oeste',
    uf: 'PR'
  },
  {
    id: 4128609,
    name: 'Verê',
    uf: 'PR'
  },
  {
    id: 4128625,
    name: 'Alto Paraíso',
    uf: 'PR'
  },
  {
    id: 4128633,
    name: 'Doutor Ulysses',
    uf: 'PR'
  },
  {
    id: 4128658,
    name: 'Virmond',
    uf: 'PR'
  },
  {
    id: 4128708,
    name: 'Vitorino',
    uf: 'PR'
  },
  {
    id: 4128807,
    name: 'Xambrê',
    uf: 'PR'
  },
  {
    id: 4200051,
    name: 'Abdon Batista',
    uf: 'SC'
  },
  {
    id: 4200101,
    name: 'Abelardo Luz',
    uf: 'SC'
  },
  {
    id: 4200200,
    name: 'Agrolândia',
    uf: 'SC'
  },
  {
    id: 4200309,
    name: 'Agronômica',
    uf: 'SC'
  },
  {
    id: 4200408,
    name: 'Água Doce',
    uf: 'SC'
  },
  {
    id: 4200507,
    name: 'Águas de Chapecó',
    uf: 'SC'
  },
  {
    id: 4200556,
    name: 'Águas Frias',
    uf: 'SC'
  },
  {
    id: 4200606,
    name: 'Águas Mornas',
    uf: 'SC'
  },
  {
    id: 4200705,
    name: 'Alfredo Wagner',
    uf: 'SC'
  },
  {
    id: 4200754,
    name: 'Alto Bela Vista',
    uf: 'SC'
  },
  {
    id: 4200804,
    name: 'Anchieta',
    uf: 'SC'
  },
  {
    id: 4200903,
    name: 'Angelina',
    uf: 'SC'
  },
  {
    id: 4201000,
    name: 'Anita Garibaldi',
    uf: 'SC'
  },
  {
    id: 4201109,
    name: 'Anitápolis',
    uf: 'SC'
  },
  {
    id: 4201208,
    name: 'Antônio Carlos',
    uf: 'SC'
  },
  {
    id: 4201257,
    name: 'Apiúna',
    uf: 'SC'
  },
  {
    id: 4201273,
    name: 'Arabutã',
    uf: 'SC'
  },
  {
    id: 4201307,
    name: 'Araquari',
    uf: 'SC'
  },
  {
    id: 4201406,
    name: 'Araranguá',
    uf: 'SC'
  },
  {
    id: 4201505,
    name: 'Armazém',
    uf: 'SC'
  },
  {
    id: 4201604,
    name: 'Arroio Trinta',
    uf: 'SC'
  },
  {
    id: 4201653,
    name: 'Arvoredo',
    uf: 'SC'
  },
  {
    id: 4201703,
    name: 'Ascurra',
    uf: 'SC'
  },
  {
    id: 4201802,
    name: 'Atalanta',
    uf: 'SC'
  },
  {
    id: 4201901,
    name: 'Aurora',
    uf: 'SC'
  },
  {
    id: 4201950,
    name: 'Balneário Arroio do Silva',
    uf: 'SC'
  },
  {
    id: 4202008,
    name: 'Balneário Camboriú',
    uf: 'SC'
  },
  {
    id: 4202057,
    name: 'Balneário Barra do Sul',
    uf: 'SC'
  },
  {
    id: 4202073,
    name: 'Balneário Gaivota',
    uf: 'SC'
  },
  {
    id: 4202081,
    name: 'Bandeirante',
    uf: 'SC'
  },
  {
    id: 4202099,
    name: 'Barra Bonita',
    uf: 'SC'
  },
  {
    id: 4202107,
    name: 'Barra Velha',
    uf: 'SC'
  },
  {
    id: 4202131,
    name: 'Bela Vista do Toldo',
    uf: 'SC'
  },
  {
    id: 4202156,
    name: 'Belmonte',
    uf: 'SC'
  },
  {
    id: 4202206,
    name: 'Benedito Novo',
    uf: 'SC'
  },
  {
    id: 4202305,
    name: 'Biguaçu',
    uf: 'SC'
  },
  {
    id: 4202404,
    name: 'Blumenau',
    uf: 'SC'
  },
  {
    id: 4202438,
    name: 'Bocaina do Sul',
    uf: 'SC'
  },
  {
    id: 4202453,
    name: 'Bombinhas',
    uf: 'SC'
  },
  {
    id: 4202503,
    name: 'Bom Jardim da Serra',
    uf: 'SC'
  },
  {
    id: 4202537,
    name: 'Bom Jesus',
    uf: 'SC'
  },
  {
    id: 4202578,
    name: 'Bom Jesus do Oeste',
    uf: 'SC'
  },
  {
    id: 4202602,
    name: 'Bom Retiro',
    uf: 'SC'
  },
  {
    id: 4202701,
    name: 'Botuverá',
    uf: 'SC'
  },
  {
    id: 4202800,
    name: 'Braço do Norte',
    uf: 'SC'
  },
  {
    id: 4202859,
    name: 'Braço do Trombudo',
    uf: 'SC'
  },
  {
    id: 4202875,
    name: 'Brunópolis',
    uf: 'SC'
  },
  {
    id: 4202909,
    name: 'Brusque',
    uf: 'SC'
  },
  {
    id: 4203006,
    name: 'Caçador',
    uf: 'SC'
  },
  {
    id: 4203105,
    name: 'Caibi',
    uf: 'SC'
  },
  {
    id: 4203154,
    name: 'Calmon',
    uf: 'SC'
  },
  {
    id: 4203204,
    name: 'Camboriú',
    uf: 'SC'
  },
  {
    id: 4203253,
    name: 'Capão Alto',
    uf: 'SC'
  },
  {
    id: 4203303,
    name: 'Campo Alegre',
    uf: 'SC'
  },
  {
    id: 4203402,
    name: 'Campo Belo do Sul',
    uf: 'SC'
  },
  {
    id: 4203501,
    name: 'Campo Erê',
    uf: 'SC'
  },
  {
    id: 4203600,
    name: 'Campos Novos',
    uf: 'SC'
  },
  {
    id: 4203709,
    name: 'Canelinha',
    uf: 'SC'
  },
  {
    id: 4203808,
    name: 'Canoinhas',
    uf: 'SC'
  },
  {
    id: 4203907,
    name: 'Capinzal',
    uf: 'SC'
  },
  {
    id: 4203956,
    name: 'Capivari de Baixo',
    uf: 'SC'
  },
  {
    id: 4204004,
    name: 'Catanduvas',
    uf: 'SC'
  },
  {
    id: 4204103,
    name: 'Caxambu do Sul',
    uf: 'SC'
  },
  {
    id: 4204152,
    name: 'Celso Ramos',
    uf: 'SC'
  },
  {
    id: 4204178,
    name: 'Cerro Negro',
    uf: 'SC'
  },
  {
    id: 4204194,
    name: 'Chapadão do Lageado',
    uf: 'SC'
  },
  {
    id: 4204202,
    name: 'Chapecó',
    uf: 'SC'
  },
  {
    id: 4204251,
    name: 'Cocal do Sul',
    uf: 'SC'
  },
  {
    id: 4204301,
    name: 'Concórdia',
    uf: 'SC'
  },
  {
    id: 4204350,
    name: 'Cordilheira Alta',
    uf: 'SC'
  },
  {
    id: 4204400,
    name: 'Coronel Freitas',
    uf: 'SC'
  },
  {
    id: 4204459,
    name: 'Coronel Martins',
    uf: 'SC'
  },
  {
    id: 4204509,
    name: 'Corupá',
    uf: 'SC'
  },
  {
    id: 4204558,
    name: 'Correia Pinto',
    uf: 'SC'
  },
  {
    id: 4204608,
    name: 'Criciúma',
    uf: 'SC'
  },
  {
    id: 4204707,
    name: 'Cunha Porã',
    uf: 'SC'
  },
  {
    id: 4204756,
    name: 'Cunhataí',
    uf: 'SC'
  },
  {
    id: 4204806,
    name: 'Curitibanos',
    uf: 'SC'
  },
  {
    id: 4204905,
    name: 'Descanso',
    uf: 'SC'
  },
  {
    id: 4205001,
    name: 'Dionísio Cerqueira',
    uf: 'SC'
  },
  {
    id: 4205100,
    name: 'Dona Emma',
    uf: 'SC'
  },
  {
    id: 4205159,
    name: 'Doutor Pedrinho',
    uf: 'SC'
  },
  {
    id: 4205175,
    name: 'Entre Rios',
    uf: 'SC'
  },
  {
    id: 4205191,
    name: 'Ermo',
    uf: 'SC'
  },
  {
    id: 4205209,
    name: 'Erval Velho',
    uf: 'SC'
  },
  {
    id: 4205308,
    name: 'Faxinal dos Guedes',
    uf: 'SC'
  },
  {
    id: 4205357,
    name: 'Flor do Sertão',
    uf: 'SC'
  },
  {
    id: 4205407,
    name: 'Florianópolis',
    uf: 'SC'
  },
  {
    id: 4205431,
    name: 'Formosa do Sul',
    uf: 'SC'
  },
  {
    id: 4205456,
    name: 'Forquilhinha',
    uf: 'SC'
  },
  {
    id: 4205506,
    name: 'Fraiburgo',
    uf: 'SC'
  },
  {
    id: 4205555,
    name: 'Frei Rogério',
    uf: 'SC'
  },
  {
    id: 4205605,
    name: 'Galvão',
    uf: 'SC'
  },
  {
    id: 4205704,
    name: 'Garopaba',
    uf: 'SC'
  },
  {
    id: 4205803,
    name: 'Garuva',
    uf: 'SC'
  },
  {
    id: 4205902,
    name: 'Gaspar',
    uf: 'SC'
  },
  {
    id: 4206009,
    name: 'Governador Celso Ramos',
    uf: 'SC'
  },
  {
    id: 4206108,
    name: 'Grão-Pará',
    uf: 'SC'
  },
  {
    id: 4206207,
    name: 'Gravatal',
    uf: 'SC'
  },
  {
    id: 4206306,
    name: 'Guabiruba',
    uf: 'SC'
  },
  {
    id: 4206405,
    name: 'Guaraciaba',
    uf: 'SC'
  },
  {
    id: 4206504,
    name: 'Guaramirim',
    uf: 'SC'
  },
  {
    id: 4206603,
    name: 'Guarujá do Sul',
    uf: 'SC'
  },
  {
    id: 4206652,
    name: 'Guatambú',
    uf: 'SC'
  },
  {
    id: 4206702,
    name: "Herval d'Oeste",
    uf: 'SC'
  },
  {
    id: 4206751,
    name: 'Ibiam',
    uf: 'SC'
  },
  {
    id: 4206801,
    name: 'Ibicaré',
    uf: 'SC'
  },
  {
    id: 4206900,
    name: 'Ibirama',
    uf: 'SC'
  },
  {
    id: 4207007,
    name: 'Içara',
    uf: 'SC'
  },
  {
    id: 4207106,
    name: 'Ilhota',
    uf: 'SC'
  },
  {
    id: 4207205,
    name: 'Imaruí',
    uf: 'SC'
  },
  {
    id: 4207304,
    name: 'Imbituba',
    uf: 'SC'
  },
  {
    id: 4207403,
    name: 'Imbuia',
    uf: 'SC'
  },
  {
    id: 4207502,
    name: 'Indaial',
    uf: 'SC'
  },
  {
    id: 4207577,
    name: 'Iomerê',
    uf: 'SC'
  },
  {
    id: 4207601,
    name: 'Ipira',
    uf: 'SC'
  },
  {
    id: 4207650,
    name: 'Iporã do Oeste',
    uf: 'SC'
  },
  {
    id: 4207684,
    name: 'Ipuaçu',
    uf: 'SC'
  },
  {
    id: 4207700,
    name: 'Ipumirim',
    uf: 'SC'
  },
  {
    id: 4207759,
    name: 'Iraceminha',
    uf: 'SC'
  },
  {
    id: 4207809,
    name: 'Irani',
    uf: 'SC'
  },
  {
    id: 4207858,
    name: 'Irati',
    uf: 'SC'
  },
  {
    id: 4207908,
    name: 'Irineópolis',
    uf: 'SC'
  },
  {
    id: 4208005,
    name: 'Itá',
    uf: 'SC'
  },
  {
    id: 4208104,
    name: 'Itaiópolis',
    uf: 'SC'
  },
  {
    id: 4208203,
    name: 'Itajaí',
    uf: 'SC'
  },
  {
    id: 4208302,
    name: 'Itapema',
    uf: 'SC'
  },
  {
    id: 4208401,
    name: 'Itapiranga',
    uf: 'SC'
  },
  {
    id: 4208450,
    name: 'Itapoá',
    uf: 'SC'
  },
  {
    id: 4208500,
    name: 'Ituporanga',
    uf: 'SC'
  },
  {
    id: 4208609,
    name: 'Jaborá',
    uf: 'SC'
  },
  {
    id: 4208708,
    name: 'Jacinto Machado',
    uf: 'SC'
  },
  {
    id: 4208807,
    name: 'Jaguaruna',
    uf: 'SC'
  },
  {
    id: 4208906,
    name: 'Jaraguá do Sul',
    uf: 'SC'
  },
  {
    id: 4208955,
    name: 'Jardinópolis',
    uf: 'SC'
  },
  {
    id: 4209003,
    name: 'Joaçaba',
    uf: 'SC'
  },
  {
    id: 4209102,
    name: 'Joinville',
    uf: 'SC'
  },
  {
    id: 4209151,
    name: 'José Boiteux',
    uf: 'SC'
  },
  {
    id: 4209177,
    name: 'Jupiá',
    uf: 'SC'
  },
  {
    id: 4209201,
    name: 'Lacerdópolis',
    uf: 'SC'
  },
  {
    id: 4209300,
    name: 'Lages',
    uf: 'SC'
  },
  {
    id: 4209409,
    name: 'Laguna',
    uf: 'SC'
  },
  {
    id: 4209458,
    name: 'Lajeado Grande',
    uf: 'SC'
  },
  {
    id: 4209508,
    name: 'Laurentino',
    uf: 'SC'
  },
  {
    id: 4209607,
    name: 'Lauro Müller',
    uf: 'SC'
  },
  {
    id: 4209706,
    name: 'Lebon Régis',
    uf: 'SC'
  },
  {
    id: 4209805,
    name: 'Leoberto Leal',
    uf: 'SC'
  },
  {
    id: 4209854,
    name: 'Lindóia do Sul',
    uf: 'SC'
  },
  {
    id: 4209904,
    name: 'Lontras',
    uf: 'SC'
  },
  {
    id: 4210001,
    name: 'Luiz Alves',
    uf: 'SC'
  },
  {
    id: 4210035,
    name: 'Luzerna',
    uf: 'SC'
  },
  {
    id: 4210050,
    name: 'Macieira',
    uf: 'SC'
  },
  {
    id: 4210100,
    name: 'Mafra',
    uf: 'SC'
  },
  {
    id: 4210209,
    name: 'Major Gercino',
    uf: 'SC'
  },
  {
    id: 4210308,
    name: 'Major Vieira',
    uf: 'SC'
  },
  {
    id: 4210407,
    name: 'Maracajá',
    uf: 'SC'
  },
  {
    id: 4210506,
    name: 'Maravilha',
    uf: 'SC'
  },
  {
    id: 4210555,
    name: 'Marema',
    uf: 'SC'
  },
  {
    id: 4210605,
    name: 'Massaranduba',
    uf: 'SC'
  },
  {
    id: 4210704,
    name: 'Matos Costa',
    uf: 'SC'
  },
  {
    id: 4210803,
    name: 'Meleiro',
    uf: 'SC'
  },
  {
    id: 4210852,
    name: 'Mirim Doce',
    uf: 'SC'
  },
  {
    id: 4210902,
    name: 'Modelo',
    uf: 'SC'
  },
  {
    id: 4211009,
    name: 'Mondaí',
    uf: 'SC'
  },
  {
    id: 4211058,
    name: 'Monte Carlo',
    uf: 'SC'
  },
  {
    id: 4211108,
    name: 'Monte Castelo',
    uf: 'SC'
  },
  {
    id: 4211207,
    name: 'Morro da Fumaça',
    uf: 'SC'
  },
  {
    id: 4211256,
    name: 'Morro Grande',
    uf: 'SC'
  },
  {
    id: 4211306,
    name: 'Navegantes',
    uf: 'SC'
  },
  {
    id: 4211405,
    name: 'Nova Erechim',
    uf: 'SC'
  },
  {
    id: 4211454,
    name: 'Nova Itaberaba',
    uf: 'SC'
  },
  {
    id: 4211504,
    name: 'Nova Trento',
    uf: 'SC'
  },
  {
    id: 4211603,
    name: 'Nova Veneza',
    uf: 'SC'
  },
  {
    id: 4211652,
    name: 'Novo Horizonte',
    uf: 'SC'
  },
  {
    id: 4211702,
    name: 'Orleans',
    uf: 'SC'
  },
  {
    id: 4211751,
    name: 'Otacílio Costa',
    uf: 'SC'
  },
  {
    id: 4211801,
    name: 'Ouro',
    uf: 'SC'
  },
  {
    id: 4211850,
    name: 'Ouro Verde',
    uf: 'SC'
  },
  {
    id: 4211876,
    name: 'Paial',
    uf: 'SC'
  },
  {
    id: 4211892,
    name: 'Painel',
    uf: 'SC'
  },
  {
    id: 4211900,
    name: 'Palhoça',
    uf: 'SC'
  },
  {
    id: 4212007,
    name: 'Palma Sola',
    uf: 'SC'
  },
  {
    id: 4212056,
    name: 'Palmeira',
    uf: 'SC'
  },
  {
    id: 4212106,
    name: 'Palmitos',
    uf: 'SC'
  },
  {
    id: 4212205,
    name: 'Papanduva',
    uf: 'SC'
  },
  {
    id: 4212239,
    name: 'Paraíso',
    uf: 'SC'
  },
  {
    id: 4212254,
    name: 'Passo de Torres',
    uf: 'SC'
  },
  {
    id: 4212270,
    name: 'Passos Maia',
    uf: 'SC'
  },
  {
    id: 4212304,
    name: 'Paulo Lopes',
    uf: 'SC'
  },
  {
    id: 4212403,
    name: 'Pedras Grandes',
    uf: 'SC'
  },
  {
    id: 4212502,
    name: 'Penha',
    uf: 'SC'
  },
  {
    id: 4212601,
    name: 'Peritiba',
    uf: 'SC'
  },
  {
    id: 4212650,
    name: 'Pescaria Brava',
    uf: 'SC'
  },
  {
    id: 4212700,
    name: 'Petrolândia',
    uf: 'SC'
  },
  {
    id: 4212809,
    name: 'Balneário Piçarras',
    uf: 'SC'
  },
  {
    id: 4212908,
    name: 'Pinhalzinho',
    uf: 'SC'
  },
  {
    id: 4213005,
    name: 'Pinheiro Preto',
    uf: 'SC'
  },
  {
    id: 4213104,
    name: 'Piratuba',
    uf: 'SC'
  },
  {
    id: 4213153,
    name: 'Planalto Alegre',
    uf: 'SC'
  },
  {
    id: 4213203,
    name: 'Pomerode',
    uf: 'SC'
  },
  {
    id: 4213302,
    name: 'Ponte Alta',
    uf: 'SC'
  },
  {
    id: 4213351,
    name: 'Ponte Alta do Norte',
    uf: 'SC'
  },
  {
    id: 4213401,
    name: 'Ponte Serrada',
    uf: 'SC'
  },
  {
    id: 4213500,
    name: 'Porto Belo',
    uf: 'SC'
  },
  {
    id: 4213609,
    name: 'Porto União',
    uf: 'SC'
  },
  {
    id: 4213708,
    name: 'Pouso Redondo',
    uf: 'SC'
  },
  {
    id: 4213807,
    name: 'Praia Grande',
    uf: 'SC'
  },
  {
    id: 4213906,
    name: 'Presidente Castello Branco',
    uf: 'SC'
  },
  {
    id: 4214003,
    name: 'Presidente Getúlio',
    uf: 'SC'
  },
  {
    id: 4214102,
    name: 'Presidente Nereu',
    uf: 'SC'
  },
  {
    id: 4214151,
    name: 'Princesa',
    uf: 'SC'
  },
  {
    id: 4214201,
    name: 'Quilombo',
    uf: 'SC'
  },
  {
    id: 4214300,
    name: 'Rancho Queimado',
    uf: 'SC'
  },
  {
    id: 4214409,
    name: 'Rio das Antas',
    uf: 'SC'
  },
  {
    id: 4214508,
    name: 'Rio do Campo',
    uf: 'SC'
  },
  {
    id: 4214607,
    name: 'Rio do Oeste',
    uf: 'SC'
  },
  {
    id: 4214706,
    name: 'Rio dos Cedros',
    uf: 'SC'
  },
  {
    id: 4214805,
    name: 'Rio do Sul',
    uf: 'SC'
  },
  {
    id: 4214904,
    name: 'Rio Fortuna',
    uf: 'SC'
  },
  {
    id: 4215000,
    name: 'Rio Negrinho',
    uf: 'SC'
  },
  {
    id: 4215059,
    name: 'Rio Rufino',
    uf: 'SC'
  },
  {
    id: 4215075,
    name: 'Riqueza',
    uf: 'SC'
  },
  {
    id: 4215109,
    name: 'Rodeio',
    uf: 'SC'
  },
  {
    id: 4215208,
    name: 'Romelândia',
    uf: 'SC'
  },
  {
    id: 4215307,
    name: 'Salete',
    uf: 'SC'
  },
  {
    id: 4215356,
    name: 'Saltinho',
    uf: 'SC'
  },
  {
    id: 4215406,
    name: 'Salto Veloso',
    uf: 'SC'
  },
  {
    id: 4215455,
    name: 'Sangão',
    uf: 'SC'
  },
  {
    id: 4215505,
    name: 'Santa Cecília',
    uf: 'SC'
  },
  {
    id: 4215554,
    name: 'Santa Helena',
    uf: 'SC'
  },
  {
    id: 4215604,
    name: 'Santa Rosa de Lima',
    uf: 'SC'
  },
  {
    id: 4215653,
    name: 'Santa Rosa do Sul',
    uf: 'SC'
  },
  {
    id: 4215679,
    name: 'Santa Terezinha',
    uf: 'SC'
  },
  {
    id: 4215687,
    name: 'Santa Terezinha do Progresso',
    uf: 'SC'
  },
  {
    id: 4215695,
    name: 'Santiago do Sul',
    uf: 'SC'
  },
  {
    id: 4215703,
    name: 'Santo Amaro da Imperatriz',
    uf: 'SC'
  },
  {
    id: 4215752,
    name: 'São Bernardino',
    uf: 'SC'
  },
  {
    id: 4215802,
    name: 'São Bento do Sul',
    uf: 'SC'
  },
  {
    id: 4215901,
    name: 'São Bonifácio',
    uf: 'SC'
  },
  {
    id: 4216008,
    name: 'São Carlos',
    uf: 'SC'
  },
  {
    id: 4216057,
    name: 'São Cristóvão do Sul',
    uf: 'SC'
  },
  {
    id: 4216107,
    name: 'São Domingos',
    uf: 'SC'
  },
  {
    id: 4216206,
    name: 'São Francisco do Sul',
    uf: 'SC'
  },
  {
    id: 4216255,
    name: 'São João do Oeste',
    uf: 'SC'
  },
  {
    id: 4216305,
    name: 'São João Batista',
    uf: 'SC'
  },
  {
    id: 4216354,
    name: 'São João do Itaperiú',
    uf: 'SC'
  },
  {
    id: 4216404,
    name: 'São João do Sul',
    uf: 'SC'
  },
  {
    id: 4216503,
    name: 'São Joaquim',
    uf: 'SC'
  },
  {
    id: 4216602,
    name: 'São José',
    uf: 'SC'
  },
  {
    id: 4216701,
    name: 'São José do Cedro',
    uf: 'SC'
  },
  {
    id: 4216800,
    name: 'São José do Cerrito',
    uf: 'SC'
  },
  {
    id: 4216909,
    name: 'São Lourenço do Oeste',
    uf: 'SC'
  },
  {
    id: 4217006,
    name: 'São Ludgero',
    uf: 'SC'
  },
  {
    id: 4217105,
    name: 'São Martinho',
    uf: 'SC'
  },
  {
    id: 4217154,
    name: 'São Miguel da Boa Vista',
    uf: 'SC'
  },
  {
    id: 4217204,
    name: 'São Miguel do Oeste',
    uf: 'SC'
  },
  {
    id: 4217253,
    name: 'São Pedro de Alcântara',
    uf: 'SC'
  },
  {
    id: 4217303,
    name: 'Saudades',
    uf: 'SC'
  },
  {
    id: 4217402,
    name: 'Schroeder',
    uf: 'SC'
  },
  {
    id: 4217501,
    name: 'Seara',
    uf: 'SC'
  },
  {
    id: 4217550,
    name: 'Serra Alta',
    uf: 'SC'
  },
  {
    id: 4217600,
    name: 'Siderópolis',
    uf: 'SC'
  },
  {
    id: 4217709,
    name: 'Sombrio',
    uf: 'SC'
  },
  {
    id: 4217758,
    name: 'Sul Brasil',
    uf: 'SC'
  },
  {
    id: 4217808,
    name: 'Taió',
    uf: 'SC'
  },
  {
    id: 4217907,
    name: 'Tangará',
    uf: 'SC'
  },
  {
    id: 4217956,
    name: 'Tigrinhos',
    uf: 'SC'
  },
  {
    id: 4218004,
    name: 'Tijucas',
    uf: 'SC'
  },
  {
    id: 4218103,
    name: 'Timbé do Sul',
    uf: 'SC'
  },
  {
    id: 4218202,
    name: 'Timbó',
    uf: 'SC'
  },
  {
    id: 4218251,
    name: 'Timbó Grande',
    uf: 'SC'
  },
  {
    id: 4218301,
    name: 'Três Barras',
    uf: 'SC'
  },
  {
    id: 4218350,
    name: 'Treviso',
    uf: 'SC'
  },
  {
    id: 4218400,
    name: 'Treze de Maio',
    uf: 'SC'
  },
  {
    id: 4218509,
    name: 'Treze Tílias',
    uf: 'SC'
  },
  {
    id: 4218608,
    name: 'Trombudo Central',
    uf: 'SC'
  },
  {
    id: 4218707,
    name: 'Tubarão',
    uf: 'SC'
  },
  {
    id: 4218756,
    name: 'Tunápolis',
    uf: 'SC'
  },
  {
    id: 4218806,
    name: 'Turvo',
    uf: 'SC'
  },
  {
    id: 4218855,
    name: 'União do Oeste',
    uf: 'SC'
  },
  {
    id: 4218905,
    name: 'Urubici',
    uf: 'SC'
  },
  {
    id: 4218954,
    name: 'Urupema',
    uf: 'SC'
  },
  {
    id: 4219002,
    name: 'Urussanga',
    uf: 'SC'
  },
  {
    id: 4219101,
    name: 'Vargeão',
    uf: 'SC'
  },
  {
    id: 4219150,
    name: 'Vargem',
    uf: 'SC'
  },
  {
    id: 4219176,
    name: 'Vargem Bonita',
    uf: 'SC'
  },
  {
    id: 4219200,
    name: 'Vidal Ramos',
    uf: 'SC'
  },
  {
    id: 4219309,
    name: 'Videira',
    uf: 'SC'
  },
  {
    id: 4219358,
    name: 'Vitor Meireles',
    uf: 'SC'
  },
  {
    id: 4219408,
    name: 'Witmarsum',
    uf: 'SC'
  },
  {
    id: 4219507,
    name: 'Xanxerê',
    uf: 'SC'
  },
  {
    id: 4219606,
    name: 'Xavantina',
    uf: 'SC'
  },
  {
    id: 4219705,
    name: 'Xaxim',
    uf: 'SC'
  },
  {
    id: 4219853,
    name: 'Zortéa',
    uf: 'SC'
  },
  {
    id: 4220000,
    name: 'Balneário Rincão',
    uf: 'SC'
  },
  {
    id: 4300034,
    name: 'Aceguá',
    uf: 'RS'
  },
  {
    id: 4300059,
    name: 'Água Santa',
    uf: 'RS'
  },
  {
    id: 4300109,
    name: 'Agudo',
    uf: 'RS'
  },
  {
    id: 4300208,
    name: 'Ajuricaba',
    uf: 'RS'
  },
  {
    id: 4300307,
    name: 'Alecrim',
    uf: 'RS'
  },
  {
    id: 4300406,
    name: 'Alegrete',
    uf: 'RS'
  },
  {
    id: 4300455,
    name: 'Alegria',
    uf: 'RS'
  },
  {
    id: 4300471,
    name: 'Almirante Tamandaré do Sul',
    uf: 'RS'
  },
  {
    id: 4300505,
    name: 'Alpestre',
    uf: 'RS'
  },
  {
    id: 4300554,
    name: 'Alto Alegre',
    uf: 'RS'
  },
  {
    id: 4300570,
    name: 'Alto Feliz',
    uf: 'RS'
  },
  {
    id: 4300604,
    name: 'Alvorada',
    uf: 'RS'
  },
  {
    id: 4300638,
    name: 'Amaral Ferrador',
    uf: 'RS'
  },
  {
    id: 4300646,
    name: 'Ametista do Sul',
    uf: 'RS'
  },
  {
    id: 4300661,
    name: 'André da Rocha',
    uf: 'RS'
  },
  {
    id: 4300703,
    name: 'Anta Gorda',
    uf: 'RS'
  },
  {
    id: 4300802,
    name: 'Antônio Prado',
    uf: 'RS'
  },
  {
    id: 4300851,
    name: 'Arambaré',
    uf: 'RS'
  },
  {
    id: 4300877,
    name: 'Araricá',
    uf: 'RS'
  },
  {
    id: 4300901,
    name: 'Aratiba',
    uf: 'RS'
  },
  {
    id: 4301008,
    name: 'Arroio do Meio',
    uf: 'RS'
  },
  {
    id: 4301057,
    name: 'Arroio do Sal',
    uf: 'RS'
  },
  {
    id: 4301073,
    name: 'Arroio do Padre',
    uf: 'RS'
  },
  {
    id: 4301107,
    name: 'Arroio dos Ratos',
    uf: 'RS'
  },
  {
    id: 4301206,
    name: 'Arroio do Tigre',
    uf: 'RS'
  },
  {
    id: 4301305,
    name: 'Arroio Grande',
    uf: 'RS'
  },
  {
    id: 4301404,
    name: 'Arvorezinha',
    uf: 'RS'
  },
  {
    id: 4301503,
    name: 'Augusto Pestana',
    uf: 'RS'
  },
  {
    id: 4301552,
    name: 'Áurea',
    uf: 'RS'
  },
  {
    id: 4301602,
    name: 'Bagé',
    uf: 'RS'
  },
  {
    id: 4301636,
    name: 'Balneário Pinhal',
    uf: 'RS'
  },
  {
    id: 4301651,
    name: 'Barão',
    uf: 'RS'
  },
  {
    id: 4301701,
    name: 'Barão de Cotegipe',
    uf: 'RS'
  },
  {
    id: 4301750,
    name: 'Barão do Triunfo',
    uf: 'RS'
  },
  {
    id: 4301800,
    name: 'Barracão',
    uf: 'RS'
  },
  {
    id: 4301859,
    name: 'Barra do Guarita',
    uf: 'RS'
  },
  {
    id: 4301875,
    name: 'Barra do Quaraí',
    uf: 'RS'
  },
  {
    id: 4301909,
    name: 'Barra do Ribeiro',
    uf: 'RS'
  },
  {
    id: 4301925,
    name: 'Barra do Rio Azul',
    uf: 'RS'
  },
  {
    id: 4301958,
    name: 'Barra Funda',
    uf: 'RS'
  },
  {
    id: 4302006,
    name: 'Barros Cassal',
    uf: 'RS'
  },
  {
    id: 4302055,
    name: 'Benjamin Constant do Sul',
    uf: 'RS'
  },
  {
    id: 4302105,
    name: 'Bento Gonçalves',
    uf: 'RS'
  },
  {
    id: 4302154,
    name: 'Boa Vista das Missões',
    uf: 'RS'
  },
  {
    id: 4302204,
    name: 'Boa Vista do Buricá',
    uf: 'RS'
  },
  {
    id: 4302220,
    name: 'Boa Vista do Cadeado',
    uf: 'RS'
  },
  {
    id: 4302238,
    name: 'Boa Vista do Incra',
    uf: 'RS'
  },
  {
    id: 4302253,
    name: 'Boa Vista do Sul',
    uf: 'RS'
  },
  {
    id: 4302303,
    name: 'Bom Jesus',
    uf: 'RS'
  },
  {
    id: 4302352,
    name: 'Bom Princípio',
    uf: 'RS'
  },
  {
    id: 4302378,
    name: 'Bom Progresso',
    uf: 'RS'
  },
  {
    id: 4302402,
    name: 'Bom Retiro do Sul',
    uf: 'RS'
  },
  {
    id: 4302451,
    name: 'Boqueirão do Leão',
    uf: 'RS'
  },
  {
    id: 4302501,
    name: 'Bossoroca',
    uf: 'RS'
  },
  {
    id: 4302584,
    name: 'Bozano',
    uf: 'RS'
  },
  {
    id: 4302600,
    name: 'Braga',
    uf: 'RS'
  },
  {
    id: 4302659,
    name: 'Brochier',
    uf: 'RS'
  },
  {
    id: 4302709,
    name: 'Butiá',
    uf: 'RS'
  },
  {
    id: 4302808,
    name: 'Caçapava do Sul',
    uf: 'RS'
  },
  {
    id: 4302907,
    name: 'Cacequi',
    uf: 'RS'
  },
  {
    id: 4303004,
    name: 'Cachoeira do Sul',
    uf: 'RS'
  },
  {
    id: 4303103,
    name: 'Cachoeirinha',
    uf: 'RS'
  },
  {
    id: 4303202,
    name: 'Cacique Doble',
    uf: 'RS'
  },
  {
    id: 4303301,
    name: 'Caibaté',
    uf: 'RS'
  },
  {
    id: 4303400,
    name: 'Caiçara',
    uf: 'RS'
  },
  {
    id: 4303509,
    name: 'Camaquã',
    uf: 'RS'
  },
  {
    id: 4303558,
    name: 'Camargo',
    uf: 'RS'
  },
  {
    id: 4303608,
    name: 'Cambará do Sul',
    uf: 'RS'
  },
  {
    id: 4303673,
    name: 'Campestre da Serra',
    uf: 'RS'
  },
  {
    id: 4303707,
    name: 'Campina das Missões',
    uf: 'RS'
  },
  {
    id: 4303806,
    name: 'Campinas do Sul',
    uf: 'RS'
  },
  {
    id: 4303905,
    name: 'Campo Bom',
    uf: 'RS'
  },
  {
    id: 4304002,
    name: 'Campo Novo',
    uf: 'RS'
  },
  {
    id: 4304101,
    name: 'Campos Borges',
    uf: 'RS'
  },
  {
    id: 4304200,
    name: 'Candelária',
    uf: 'RS'
  },
  {
    id: 4304309,
    name: 'Cândido Godói',
    uf: 'RS'
  },
  {
    id: 4304358,
    name: 'Candiota',
    uf: 'RS'
  },
  {
    id: 4304408,
    name: 'Canela',
    uf: 'RS'
  },
  {
    id: 4304507,
    name: 'Canguçu',
    uf: 'RS'
  },
  {
    id: 4304606,
    name: 'Canoas',
    uf: 'RS'
  },
  {
    id: 4304614,
    name: 'Canudos do Vale',
    uf: 'RS'
  },
  {
    id: 4304622,
    name: 'Capão Bonito do Sul',
    uf: 'RS'
  },
  {
    id: 4304630,
    name: 'Capão da Canoa',
    uf: 'RS'
  },
  {
    id: 4304655,
    name: 'Capão do Cipó',
    uf: 'RS'
  },
  {
    id: 4304663,
    name: 'Capão do Leão',
    uf: 'RS'
  },
  {
    id: 4304671,
    name: 'Capivari do Sul',
    uf: 'RS'
  },
  {
    id: 4304689,
    name: 'Capela de Santana',
    uf: 'RS'
  },
  {
    id: 4304697,
    name: 'Capitão',
    uf: 'RS'
  },
  {
    id: 4304705,
    name: 'Carazinho',
    uf: 'RS'
  },
  {
    id: 4304713,
    name: 'Caraá',
    uf: 'RS'
  },
  {
    id: 4304804,
    name: 'Carlos Barbosa',
    uf: 'RS'
  },
  {
    id: 4304853,
    name: 'Carlos Gomes',
    uf: 'RS'
  },
  {
    id: 4304903,
    name: 'Casca',
    uf: 'RS'
  },
  {
    id: 4304952,
    name: 'Caseiros',
    uf: 'RS'
  },
  {
    id: 4305009,
    name: 'Catuípe',
    uf: 'RS'
  },
  {
    id: 4305108,
    name: 'Caxias do Sul',
    uf: 'RS'
  },
  {
    id: 4305116,
    name: 'Centenário',
    uf: 'RS'
  },
  {
    id: 4305124,
    name: 'Cerrito',
    uf: 'RS'
  },
  {
    id: 4305132,
    name: 'Cerro Branco',
    uf: 'RS'
  },
  {
    id: 4305157,
    name: 'Cerro Grande',
    uf: 'RS'
  },
  {
    id: 4305173,
    name: 'Cerro Grande do Sul',
    uf: 'RS'
  },
  {
    id: 4305207,
    name: 'Cerro Largo',
    uf: 'RS'
  },
  {
    id: 4305306,
    name: 'Chapada',
    uf: 'RS'
  },
  {
    id: 4305355,
    name: 'Charqueadas',
    uf: 'RS'
  },
  {
    id: 4305371,
    name: 'Charrua',
    uf: 'RS'
  },
  {
    id: 4305405,
    name: 'Chiapetta',
    uf: 'RS'
  },
  {
    id: 4305439,
    name: 'Chuí',
    uf: 'RS'
  },
  {
    id: 4305447,
    name: 'Chuvisca',
    uf: 'RS'
  },
  {
    id: 4305454,
    name: 'Cidreira',
    uf: 'RS'
  },
  {
    id: 4305504,
    name: 'Ciríaco',
    uf: 'RS'
  },
  {
    id: 4305587,
    name: 'Colinas',
    uf: 'RS'
  },
  {
    id: 4305603,
    name: 'Colorado',
    uf: 'RS'
  },
  {
    id: 4305702,
    name: 'Condor',
    uf: 'RS'
  },
  {
    id: 4305801,
    name: 'Constantina',
    uf: 'RS'
  },
  {
    id: 4305835,
    name: 'Coqueiro Baixo',
    uf: 'RS'
  },
  {
    id: 4305850,
    name: 'Coqueiros do Sul',
    uf: 'RS'
  },
  {
    id: 4305871,
    name: 'Coronel Barros',
    uf: 'RS'
  },
  {
    id: 4305900,
    name: 'Coronel Bicaco',
    uf: 'RS'
  },
  {
    id: 4305934,
    name: 'Coronel Pilar',
    uf: 'RS'
  },
  {
    id: 4305959,
    name: 'Cotiporã',
    uf: 'RS'
  },
  {
    id: 4305975,
    name: 'Coxilha',
    uf: 'RS'
  },
  {
    id: 4306007,
    name: 'Crissiumal',
    uf: 'RS'
  },
  {
    id: 4306056,
    name: 'Cristal',
    uf: 'RS'
  },
  {
    id: 4306072,
    name: 'Cristal do Sul',
    uf: 'RS'
  },
  {
    id: 4306106,
    name: 'Cruz Alta',
    uf: 'RS'
  },
  {
    id: 4306130,
    name: 'Cruzaltense',
    uf: 'RS'
  },
  {
    id: 4306205,
    name: 'Cruzeiro do Sul',
    uf: 'RS'
  },
  {
    id: 4306304,
    name: 'David Canabarro',
    uf: 'RS'
  },
  {
    id: 4306320,
    name: 'Derrubadas',
    uf: 'RS'
  },
  {
    id: 4306353,
    name: 'Dezesseis de Novembro',
    uf: 'RS'
  },
  {
    id: 4306379,
    name: 'Dilermando de Aguiar',
    uf: 'RS'
  },
  {
    id: 4306403,
    name: 'Dois Irmãos',
    uf: 'RS'
  },
  {
    id: 4306429,
    name: 'Dois Irmãos das Missões',
    uf: 'RS'
  },
  {
    id: 4306452,
    name: 'Dois Lajeados',
    uf: 'RS'
  },
  {
    id: 4306502,
    name: 'Dom Feliciano',
    uf: 'RS'
  },
  {
    id: 4306551,
    name: 'Dom Pedro de Alcântara',
    uf: 'RS'
  },
  {
    id: 4306601,
    name: 'Dom Pedrito',
    uf: 'RS'
  },
  {
    id: 4306700,
    name: 'Dona Francisca',
    uf: 'RS'
  },
  {
    id: 4306734,
    name: 'Doutor Maurício Cardoso',
    uf: 'RS'
  },
  {
    id: 4306759,
    name: 'Doutor Ricardo',
    uf: 'RS'
  },
  {
    id: 4306767,
    name: 'Eldorado do Sul',
    uf: 'RS'
  },
  {
    id: 4306809,
    name: 'Encantado',
    uf: 'RS'
  },
  {
    id: 4306908,
    name: 'Encruzilhada do Sul',
    uf: 'RS'
  },
  {
    id: 4306924,
    name: 'Engenho Velho',
    uf: 'RS'
  },
  {
    id: 4306932,
    name: 'Entre-Ijuís',
    uf: 'RS'
  },
  {
    id: 4306957,
    name: 'Entre Rios do Sul',
    uf: 'RS'
  },
  {
    id: 4306973,
    name: 'Erebango',
    uf: 'RS'
  },
  {
    id: 4307005,
    name: 'Erechim',
    uf: 'RS'
  },
  {
    id: 4307054,
    name: 'Ernestina',
    uf: 'RS'
  },
  {
    id: 4307104,
    name: 'Herval',
    uf: 'RS'
  },
  {
    id: 4307203,
    name: 'Erval Grande',
    uf: 'RS'
  },
  {
    id: 4307302,
    name: 'Erval Seco',
    uf: 'RS'
  },
  {
    id: 4307401,
    name: 'Esmeralda',
    uf: 'RS'
  },
  {
    id: 4307450,
    name: 'Esperança do Sul',
    uf: 'RS'
  },
  {
    id: 4307500,
    name: 'Espumoso',
    uf: 'RS'
  },
  {
    id: 4307559,
    name: 'Estação',
    uf: 'RS'
  },
  {
    id: 4307609,
    name: 'Estância Velha',
    uf: 'RS'
  },
  {
    id: 4307708,
    name: 'Esteio',
    uf: 'RS'
  },
  {
    id: 4307807,
    name: 'Estrela',
    uf: 'RS'
  },
  {
    id: 4307815,
    name: 'Estrela Velha',
    uf: 'RS'
  },
  {
    id: 4307831,
    name: 'Eugênio de Castro',
    uf: 'RS'
  },
  {
    id: 4307864,
    name: 'Fagundes Varela',
    uf: 'RS'
  },
  {
    id: 4307906,
    name: 'Farroupilha',
    uf: 'RS'
  },
  {
    id: 4308003,
    name: 'Faxinal do Soturno',
    uf: 'RS'
  },
  {
    id: 4308052,
    name: 'Faxinalzinho',
    uf: 'RS'
  },
  {
    id: 4308078,
    name: 'Fazenda Vilanova',
    uf: 'RS'
  },
  {
    id: 4308102,
    name: 'Feliz',
    uf: 'RS'
  },
  {
    id: 4308201,
    name: 'Flores da Cunha',
    uf: 'RS'
  },
  {
    id: 4308250,
    name: 'Floriano Peixoto',
    uf: 'RS'
  },
  {
    id: 4308300,
    name: 'Fontoura Xavier',
    uf: 'RS'
  },
  {
    id: 4308409,
    name: 'Formigueiro',
    uf: 'RS'
  },
  {
    id: 4308433,
    name: 'Forquetinha',
    uf: 'RS'
  },
  {
    id: 4308458,
    name: 'Fortaleza dos Valos',
    uf: 'RS'
  },
  {
    id: 4308508,
    name: 'Frederico Westphalen',
    uf: 'RS'
  },
  {
    id: 4308607,
    name: 'Garibaldi',
    uf: 'RS'
  },
  {
    id: 4308656,
    name: 'Garruchos',
    uf: 'RS'
  },
  {
    id: 4308706,
    name: 'Gaurama',
    uf: 'RS'
  },
  {
    id: 4308805,
    name: 'General Câmara',
    uf: 'RS'
  },
  {
    id: 4308854,
    name: 'Gentil',
    uf: 'RS'
  },
  {
    id: 4308904,
    name: 'Getúlio Vargas',
    uf: 'RS'
  },
  {
    id: 4309001,
    name: 'Giruá',
    uf: 'RS'
  },
  {
    id: 4309050,
    name: 'Glorinha',
    uf: 'RS'
  },
  {
    id: 4309100,
    name: 'Gramado',
    uf: 'RS'
  },
  {
    id: 4309126,
    name: 'Gramado dos Loureiros',
    uf: 'RS'
  },
  {
    id: 4309159,
    name: 'Gramado Xavier',
    uf: 'RS'
  },
  {
    id: 4309209,
    name: 'Gravataí',
    uf: 'RS'
  },
  {
    id: 4309258,
    name: 'Guabiju',
    uf: 'RS'
  },
  {
    id: 4309308,
    name: 'Guaíba',
    uf: 'RS'
  },
  {
    id: 4309407,
    name: 'Guaporé',
    uf: 'RS'
  },
  {
    id: 4309506,
    name: 'Guarani das Missões',
    uf: 'RS'
  },
  {
    id: 4309555,
    name: 'Harmonia',
    uf: 'RS'
  },
  {
    id: 4309571,
    name: 'Herveiras',
    uf: 'RS'
  },
  {
    id: 4309605,
    name: 'Horizontina',
    uf: 'RS'
  },
  {
    id: 4309654,
    name: 'Hulha Negra',
    uf: 'RS'
  },
  {
    id: 4309704,
    name: 'Humaitá',
    uf: 'RS'
  },
  {
    id: 4309753,
    name: 'Ibarama',
    uf: 'RS'
  },
  {
    id: 4309803,
    name: 'Ibiaçá',
    uf: 'RS'
  },
  {
    id: 4309902,
    name: 'Ibiraiaras',
    uf: 'RS'
  },
  {
    id: 4309951,
    name: 'Ibirapuitã',
    uf: 'RS'
  },
  {
    id: 4310009,
    name: 'Ibirubá',
    uf: 'RS'
  },
  {
    id: 4310108,
    name: 'Igrejinha',
    uf: 'RS'
  },
  {
    id: 4310207,
    name: 'Ijuí',
    uf: 'RS'
  },
  {
    id: 4310306,
    name: 'Ilópolis',
    uf: 'RS'
  },
  {
    id: 4310330,
    name: 'Imbé',
    uf: 'RS'
  },
  {
    id: 4310363,
    name: 'Imigrante',
    uf: 'RS'
  },
  {
    id: 4310405,
    name: 'Independência',
    uf: 'RS'
  },
  {
    id: 4310413,
    name: 'Inhacorá',
    uf: 'RS'
  },
  {
    id: 4310439,
    name: 'Ipê',
    uf: 'RS'
  },
  {
    id: 4310462,
    name: 'Ipiranga do Sul',
    uf: 'RS'
  },
  {
    id: 4310504,
    name: 'Iraí',
    uf: 'RS'
  },
  {
    id: 4310538,
    name: 'Itaara',
    uf: 'RS'
  },
  {
    id: 4310553,
    name: 'Itacurubi',
    uf: 'RS'
  },
  {
    id: 4310579,
    name: 'Itapuca',
    uf: 'RS'
  },
  {
    id: 4310603,
    name: 'Itaqui',
    uf: 'RS'
  },
  {
    id: 4310652,
    name: 'Itati',
    uf: 'RS'
  },
  {
    id: 4310702,
    name: 'Itatiba do Sul',
    uf: 'RS'
  },
  {
    id: 4310751,
    name: 'Ivorá',
    uf: 'RS'
  },
  {
    id: 4310801,
    name: 'Ivoti',
    uf: 'RS'
  },
  {
    id: 4310850,
    name: 'Jaboticaba',
    uf: 'RS'
  },
  {
    id: 4310876,
    name: 'Jacuizinho',
    uf: 'RS'
  },
  {
    id: 4310900,
    name: 'Jacutinga',
    uf: 'RS'
  },
  {
    id: 4311007,
    name: 'Jaguarão',
    uf: 'RS'
  },
  {
    id: 4311106,
    name: 'Jaguari',
    uf: 'RS'
  },
  {
    id: 4311122,
    name: 'Jaquirana',
    uf: 'RS'
  },
  {
    id: 4311130,
    name: 'Jari',
    uf: 'RS'
  },
  {
    id: 4311155,
    name: 'Jóia',
    uf: 'RS'
  },
  {
    id: 4311205,
    name: 'Júlio de Castilhos',
    uf: 'RS'
  },
  {
    id: 4311239,
    name: 'Lagoa Bonita do Sul',
    uf: 'RS'
  },
  {
    id: 4311254,
    name: 'Lagoão',
    uf: 'RS'
  },
  {
    id: 4311270,
    name: 'Lagoa dos Três Cantos',
    uf: 'RS'
  },
  {
    id: 4311304,
    name: 'Lagoa Vermelha',
    uf: 'RS'
  },
  {
    id: 4311403,
    name: 'Lajeado',
    uf: 'RS'
  },
  {
    id: 4311429,
    name: 'Lajeado do Bugre',
    uf: 'RS'
  },
  {
    id: 4311502,
    name: 'Lavras do Sul',
    uf: 'RS'
  },
  {
    id: 4311601,
    name: 'Liberato Salzano',
    uf: 'RS'
  },
  {
    id: 4311627,
    name: 'Lindolfo Collor',
    uf: 'RS'
  },
  {
    id: 4311643,
    name: 'Linha Nova',
    uf: 'RS'
  },
  {
    id: 4311700,
    name: 'Machadinho',
    uf: 'RS'
  },
  {
    id: 4311718,
    name: 'Maçambará',
    uf: 'RS'
  },
  {
    id: 4311734,
    name: 'Mampituba',
    uf: 'RS'
  },
  {
    id: 4311759,
    name: 'Manoel Viana',
    uf: 'RS'
  },
  {
    id: 4311775,
    name: 'Maquiné',
    uf: 'RS'
  },
  {
    id: 4311791,
    name: 'Maratá',
    uf: 'RS'
  },
  {
    id: 4311809,
    name: 'Marau',
    uf: 'RS'
  },
  {
    id: 4311908,
    name: 'Marcelino Ramos',
    uf: 'RS'
  },
  {
    id: 4311981,
    name: 'Mariana Pimentel',
    uf: 'RS'
  },
  {
    id: 4312005,
    name: 'Mariano Moro',
    uf: 'RS'
  },
  {
    id: 4312054,
    name: 'Marques de Souza',
    uf: 'RS'
  },
  {
    id: 4312104,
    name: 'Mata',
    uf: 'RS'
  },
  {
    id: 4312138,
    name: 'Mato Castelhano',
    uf: 'RS'
  },
  {
    id: 4312153,
    name: 'Mato Leitão',
    uf: 'RS'
  },
  {
    id: 4312179,
    name: 'Mato Queimado',
    uf: 'RS'
  },
  {
    id: 4312203,
    name: 'Maximiliano de Almeida',
    uf: 'RS'
  },
  {
    id: 4312252,
    name: 'Minas do Leão',
    uf: 'RS'
  },
  {
    id: 4312302,
    name: 'Miraguaí',
    uf: 'RS'
  },
  {
    id: 4312351,
    name: 'Montauri',
    uf: 'RS'
  },
  {
    id: 4312377,
    name: 'Monte Alegre dos Campos',
    uf: 'RS'
  },
  {
    id: 4312385,
    name: 'Monte Belo do Sul',
    uf: 'RS'
  },
  {
    id: 4312401,
    name: 'Montenegro',
    uf: 'RS'
  },
  {
    id: 4312427,
    name: 'Mormaço',
    uf: 'RS'
  },
  {
    id: 4312443,
    name: 'Morrinhos do Sul',
    uf: 'RS'
  },
  {
    id: 4312450,
    name: 'Morro Redondo',
    uf: 'RS'
  },
  {
    id: 4312476,
    name: 'Morro Reuter',
    uf: 'RS'
  },
  {
    id: 4312500,
    name: 'Mostardas',
    uf: 'RS'
  },
  {
    id: 4312609,
    name: 'Muçum',
    uf: 'RS'
  },
  {
    id: 4312617,
    name: 'Muitos Capões',
    uf: 'RS'
  },
  {
    id: 4312625,
    name: 'Muliterno',
    uf: 'RS'
  },
  {
    id: 4312658,
    name: 'Não-Me-Toque',
    uf: 'RS'
  },
  {
    id: 4312674,
    name: 'Nicolau Vergueiro',
    uf: 'RS'
  },
  {
    id: 4312708,
    name: 'Nonoai',
    uf: 'RS'
  },
  {
    id: 4312757,
    name: 'Nova Alvorada',
    uf: 'RS'
  },
  {
    id: 4312807,
    name: 'Nova Araçá',
    uf: 'RS'
  },
  {
    id: 4312906,
    name: 'Nova Bassano',
    uf: 'RS'
  },
  {
    id: 4312955,
    name: 'Nova Boa Vista',
    uf: 'RS'
  },
  {
    id: 4313003,
    name: 'Nova Bréscia',
    uf: 'RS'
  },
  {
    id: 4313011,
    name: 'Nova Candelária',
    uf: 'RS'
  },
  {
    id: 4313037,
    name: 'Nova Esperança do Sul',
    uf: 'RS'
  },
  {
    id: 4313060,
    name: 'Nova Hartz',
    uf: 'RS'
  },
  {
    id: 4313086,
    name: 'Nova Pádua',
    uf: 'RS'
  },
  {
    id: 4313102,
    name: 'Nova Palma',
    uf: 'RS'
  },
  {
    id: 4313201,
    name: 'Nova Petrópolis',
    uf: 'RS'
  },
  {
    id: 4313300,
    name: 'Nova Prata',
    uf: 'RS'
  },
  {
    id: 4313334,
    name: 'Nova Ramada',
    uf: 'RS'
  },
  {
    id: 4313359,
    name: 'Nova Roma do Sul',
    uf: 'RS'
  },
  {
    id: 4313375,
    name: 'Nova Santa Rita',
    uf: 'RS'
  },
  {
    id: 4313391,
    name: 'Novo Cabrais',
    uf: 'RS'
  },
  {
    id: 4313409,
    name: 'Novo Hamburgo',
    uf: 'RS'
  },
  {
    id: 4313425,
    name: 'Novo Machado',
    uf: 'RS'
  },
  {
    id: 4313441,
    name: 'Novo Tiradentes',
    uf: 'RS'
  },
  {
    id: 4313466,
    name: 'Novo Xingu',
    uf: 'RS'
  },
  {
    id: 4313490,
    name: 'Novo Barreiro',
    uf: 'RS'
  },
  {
    id: 4313508,
    name: 'Osório',
    uf: 'RS'
  },
  {
    id: 4313607,
    name: 'Paim Filho',
    uf: 'RS'
  },
  {
    id: 4313656,
    name: 'Palmares do Sul',
    uf: 'RS'
  },
  {
    id: 4313706,
    name: 'Palmeira das Missões',
    uf: 'RS'
  },
  {
    id: 4313805,
    name: 'Palmitinho',
    uf: 'RS'
  },
  {
    id: 4313904,
    name: 'Panambi',
    uf: 'RS'
  },
  {
    id: 4313953,
    name: 'Pantano Grande',
    uf: 'RS'
  },
  {
    id: 4314001,
    name: 'Paraí',
    uf: 'RS'
  },
  {
    id: 4314027,
    name: 'Paraíso do Sul',
    uf: 'RS'
  },
  {
    id: 4314035,
    name: 'Pareci Novo',
    uf: 'RS'
  },
  {
    id: 4314050,
    name: 'Parobé',
    uf: 'RS'
  },
  {
    id: 4314068,
    name: 'Passa Sete',
    uf: 'RS'
  },
  {
    id: 4314076,
    name: 'Passo do Sobrado',
    uf: 'RS'
  },
  {
    id: 4314100,
    name: 'Passo Fundo',
    uf: 'RS'
  },
  {
    id: 4314134,
    name: 'Paulo Bento',
    uf: 'RS'
  },
  {
    id: 4314159,
    name: 'Paverama',
    uf: 'RS'
  },
  {
    id: 4314175,
    name: 'Pedras Altas',
    uf: 'RS'
  },
  {
    id: 4314209,
    name: 'Pedro Osório',
    uf: 'RS'
  },
  {
    id: 4314308,
    name: 'Pejuçara',
    uf: 'RS'
  },
  {
    id: 4314407,
    name: 'Pelotas',
    uf: 'RS'
  },
  {
    id: 4314423,
    name: 'Picada Café',
    uf: 'RS'
  },
  {
    id: 4314456,
    name: 'Pinhal',
    uf: 'RS'
  },
  {
    id: 4314464,
    name: 'Pinhal da Serra',
    uf: 'RS'
  },
  {
    id: 4314472,
    name: 'Pinhal Grande',
    uf: 'RS'
  },
  {
    id: 4314498,
    name: 'Pinheirinho do Vale',
    uf: 'RS'
  },
  {
    id: 4314506,
    name: 'Pinheiro Machado',
    uf: 'RS'
  },
  {
    id: 4314548,
    name: 'Pinto Bandeira',
    uf: 'RS'
  },
  {
    id: 4314555,
    name: 'Pirapó',
    uf: 'RS'
  },
  {
    id: 4314605,
    name: 'Piratini',
    uf: 'RS'
  },
  {
    id: 4314704,
    name: 'Planalto',
    uf: 'RS'
  },
  {
    id: 4314753,
    name: 'Poço das Antas',
    uf: 'RS'
  },
  {
    id: 4314779,
    name: 'Pontão',
    uf: 'RS'
  },
  {
    id: 4314787,
    name: 'Ponte Preta',
    uf: 'RS'
  },
  {
    id: 4314803,
    name: 'Portão',
    uf: 'RS'
  },
  {
    id: 4314902,
    name: 'Porto Alegre',
    uf: 'RS'
  },
  {
    id: 4315008,
    name: 'Porto Lucena',
    uf: 'RS'
  },
  {
    id: 4315057,
    name: 'Porto Mauá',
    uf: 'RS'
  },
  {
    id: 4315073,
    name: 'Porto Vera Cruz',
    uf: 'RS'
  },
  {
    id: 4315107,
    name: 'Porto Xavier',
    uf: 'RS'
  },
  {
    id: 4315131,
    name: 'Pouso Novo',
    uf: 'RS'
  },
  {
    id: 4315149,
    name: 'Presidente Lucena',
    uf: 'RS'
  },
  {
    id: 4315156,
    name: 'Progresso',
    uf: 'RS'
  },
  {
    id: 4315172,
    name: 'Protásio Alves',
    uf: 'RS'
  },
  {
    id: 4315206,
    name: 'Putinga',
    uf: 'RS'
  },
  {
    id: 4315305,
    name: 'Quaraí',
    uf: 'RS'
  },
  {
    id: 4315313,
    name: 'Quatro Irmãos',
    uf: 'RS'
  },
  {
    id: 4315321,
    name: 'Quevedos',
    uf: 'RS'
  },
  {
    id: 4315354,
    name: 'Quinze de Novembro',
    uf: 'RS'
  },
  {
    id: 4315404,
    name: 'Redentora',
    uf: 'RS'
  },
  {
    id: 4315453,
    name: 'Relvado',
    uf: 'RS'
  },
  {
    id: 4315503,
    name: 'Restinga Sêca',
    uf: 'RS'
  },
  {
    id: 4315552,
    name: 'Rio dos Índios',
    uf: 'RS'
  },
  {
    id: 4315602,
    name: 'Rio Grande',
    uf: 'RS'
  },
  {
    id: 4315701,
    name: 'Rio Pardo',
    uf: 'RS'
  },
  {
    id: 4315750,
    name: 'Riozinho',
    uf: 'RS'
  },
  {
    id: 4315800,
    name: 'Roca Sales',
    uf: 'RS'
  },
  {
    id: 4315909,
    name: 'Rodeio Bonito',
    uf: 'RS'
  },
  {
    id: 4315958,
    name: 'Rolador',
    uf: 'RS'
  },
  {
    id: 4316006,
    name: 'Rolante',
    uf: 'RS'
  },
  {
    id: 4316105,
    name: 'Ronda Alta',
    uf: 'RS'
  },
  {
    id: 4316204,
    name: 'Rondinha',
    uf: 'RS'
  },
  {
    id: 4316303,
    name: 'Roque Gonzales',
    uf: 'RS'
  },
  {
    id: 4316402,
    name: 'Rosário do Sul',
    uf: 'RS'
  },
  {
    id: 4316428,
    name: 'Sagrada Família',
    uf: 'RS'
  },
  {
    id: 4316436,
    name: 'Saldanha Marinho',
    uf: 'RS'
  },
  {
    id: 4316451,
    name: 'Salto do Jacuí',
    uf: 'RS'
  },
  {
    id: 4316477,
    name: 'Salvador das Missões',
    uf: 'RS'
  },
  {
    id: 4316501,
    name: 'Salvador do Sul',
    uf: 'RS'
  },
  {
    id: 4316600,
    name: 'Sananduva',
    uf: 'RS'
  },
  {
    id: 4316709,
    name: 'Santa Bárbara do Sul',
    uf: 'RS'
  },
  {
    id: 4316733,
    name: 'Santa Cecília do Sul',
    uf: 'RS'
  },
  {
    id: 4316758,
    name: 'Santa Clara do Sul',
    uf: 'RS'
  },
  {
    id: 4316808,
    name: 'Santa Cruz do Sul',
    uf: 'RS'
  },
  {
    id: 4316907,
    name: 'Santa Maria',
    uf: 'RS'
  },
  {
    id: 4316956,
    name: 'Santa Maria do Herval',
    uf: 'RS'
  },
  {
    id: 4316972,
    name: 'Santa Margarida do Sul',
    uf: 'RS'
  },
  {
    id: 4317004,
    name: 'Santana da Boa Vista',
    uf: 'RS'
  },
  {
    id: 4317103,
    name: "Sant'Ana do Livramento",
    uf: 'RS'
  },
  {
    id: 4317202,
    name: 'Santa Rosa',
    uf: 'RS'
  },
  {
    id: 4317251,
    name: 'Santa Tereza',
    uf: 'RS'
  },
  {
    id: 4317301,
    name: 'Santa Vitória do Palmar',
    uf: 'RS'
  },
  {
    id: 4317400,
    name: 'Santiago',
    uf: 'RS'
  },
  {
    id: 4317509,
    name: 'Santo Ângelo',
    uf: 'RS'
  },
  {
    id: 4317558,
    name: 'Santo Antônio do Palma',
    uf: 'RS'
  },
  {
    id: 4317608,
    name: 'Santo Antônio da Patrulha',
    uf: 'RS'
  },
  {
    id: 4317707,
    name: 'Santo Antônio das Missões',
    uf: 'RS'
  },
  {
    id: 4317756,
    name: 'Santo Antônio do Planalto',
    uf: 'RS'
  },
  {
    id: 4317806,
    name: 'Santo Augusto',
    uf: 'RS'
  },
  {
    id: 4317905,
    name: 'Santo Cristo',
    uf: 'RS'
  },
  {
    id: 4317954,
    name: 'Santo Expedito do Sul',
    uf: 'RS'
  },
  {
    id: 4318002,
    name: 'São Borja',
    uf: 'RS'
  },
  {
    id: 4318051,
    name: 'São Domingos do Sul',
    uf: 'RS'
  },
  {
    id: 4318101,
    name: 'São Francisco de Assis',
    uf: 'RS'
  },
  {
    id: 4318200,
    name: 'São Francisco de Paula',
    uf: 'RS'
  },
  {
    id: 4318309,
    name: 'São Gabriel',
    uf: 'RS'
  },
  {
    id: 4318408,
    name: 'São Jerônimo',
    uf: 'RS'
  },
  {
    id: 4318424,
    name: 'São João da Urtiga',
    uf: 'RS'
  },
  {
    id: 4318432,
    name: 'São João do Polêsine',
    uf: 'RS'
  },
  {
    id: 4318440,
    name: 'São Jorge',
    uf: 'RS'
  },
  {
    id: 4318457,
    name: 'São José das Missões',
    uf: 'RS'
  },
  {
    id: 4318465,
    name: 'São José do Herval',
    uf: 'RS'
  },
  {
    id: 4318481,
    name: 'São José do Hortêncio',
    uf: 'RS'
  },
  {
    id: 4318499,
    name: 'São José do Inhacorá',
    uf: 'RS'
  },
  {
    id: 4318507,
    name: 'São José do Norte',
    uf: 'RS'
  },
  {
    id: 4318606,
    name: 'São José do Ouro',
    uf: 'RS'
  },
  {
    id: 4318614,
    name: 'São José do Sul',
    uf: 'RS'
  },
  {
    id: 4318622,
    name: 'São José dos Ausentes',
    uf: 'RS'
  },
  {
    id: 4318705,
    name: 'São Leopoldo',
    uf: 'RS'
  },
  {
    id: 4318804,
    name: 'São Lourenço do Sul',
    uf: 'RS'
  },
  {
    id: 4318903,
    name: 'São Luiz Gonzaga',
    uf: 'RS'
  },
  {
    id: 4319000,
    name: 'São Marcos',
    uf: 'RS'
  },
  {
    id: 4319109,
    name: 'São Martinho',
    uf: 'RS'
  },
  {
    id: 4319125,
    name: 'São Martinho da Serra',
    uf: 'RS'
  },
  {
    id: 4319158,
    name: 'São Miguel das Missões',
    uf: 'RS'
  },
  {
    id: 4319208,
    name: 'São Nicolau',
    uf: 'RS'
  },
  {
    id: 4319307,
    name: 'São Paulo das Missões',
    uf: 'RS'
  },
  {
    id: 4319356,
    name: 'São Pedro da Serra',
    uf: 'RS'
  },
  {
    id: 4319364,
    name: 'São Pedro das Missões',
    uf: 'RS'
  },
  {
    id: 4319372,
    name: 'São Pedro do Butiá',
    uf: 'RS'
  },
  {
    id: 4319406,
    name: 'São Pedro do Sul',
    uf: 'RS'
  },
  {
    id: 4319505,
    name: 'São Sebastião do Caí',
    uf: 'RS'
  },
  {
    id: 4319604,
    name: 'São Sepé',
    uf: 'RS'
  },
  {
    id: 4319703,
    name: 'São Valentim',
    uf: 'RS'
  },
  {
    id: 4319711,
    name: 'São Valentim do Sul',
    uf: 'RS'
  },
  {
    id: 4319737,
    name: 'São Valério do Sul',
    uf: 'RS'
  },
  {
    id: 4319752,
    name: 'São Vendelino',
    uf: 'RS'
  },
  {
    id: 4319802,
    name: 'São Vicente do Sul',
    uf: 'RS'
  },
  {
    id: 4319901,
    name: 'Sapiranga',
    uf: 'RS'
  },
  {
    id: 4320008,
    name: 'Sapucaia do Sul',
    uf: 'RS'
  },
  {
    id: 4320107,
    name: 'Sarandi',
    uf: 'RS'
  },
  {
    id: 4320206,
    name: 'Seberi',
    uf: 'RS'
  },
  {
    id: 4320230,
    name: 'Sede Nova',
    uf: 'RS'
  },
  {
    id: 4320263,
    name: 'Segredo',
    uf: 'RS'
  },
  {
    id: 4320305,
    name: 'Selbach',
    uf: 'RS'
  },
  {
    id: 4320321,
    name: 'Senador Salgado Filho',
    uf: 'RS'
  },
  {
    id: 4320354,
    name: 'Sentinela do Sul',
    uf: 'RS'
  },
  {
    id: 4320404,
    name: 'Serafina Corrêa',
    uf: 'RS'
  },
  {
    id: 4320453,
    name: 'Sério',
    uf: 'RS'
  },
  {
    id: 4320503,
    name: 'Sertão',
    uf: 'RS'
  },
  {
    id: 4320552,
    name: 'Sertão Santana',
    uf: 'RS'
  },
  {
    id: 4320578,
    name: 'Sete de Setembro',
    uf: 'RS'
  },
  {
    id: 4320602,
    name: 'Severiano de Almeida',
    uf: 'RS'
  },
  {
    id: 4320651,
    name: 'Silveira Martins',
    uf: 'RS'
  },
  {
    id: 4320677,
    name: 'Sinimbu',
    uf: 'RS'
  },
  {
    id: 4320701,
    name: 'Sobradinho',
    uf: 'RS'
  },
  {
    id: 4320800,
    name: 'Soledade',
    uf: 'RS'
  },
  {
    id: 4320859,
    name: 'Tabaí',
    uf: 'RS'
  },
  {
    id: 4320909,
    name: 'Tapejara',
    uf: 'RS'
  },
  {
    id: 4321006,
    name: 'Tapera',
    uf: 'RS'
  },
  {
    id: 4321105,
    name: 'Tapes',
    uf: 'RS'
  },
  {
    id: 4321204,
    name: 'Taquara',
    uf: 'RS'
  },
  {
    id: 4321303,
    name: 'Taquari',
    uf: 'RS'
  },
  {
    id: 4321329,
    name: 'Taquaruçu do Sul',
    uf: 'RS'
  },
  {
    id: 4321352,
    name: 'Tavares',
    uf: 'RS'
  },
  {
    id: 4321402,
    name: 'Tenente Portela',
    uf: 'RS'
  },
  {
    id: 4321436,
    name: 'Terra de Areia',
    uf: 'RS'
  },
  {
    id: 4321451,
    name: 'Teutônia',
    uf: 'RS'
  },
  {
    id: 4321469,
    name: 'Tio Hugo',
    uf: 'RS'
  },
  {
    id: 4321477,
    name: 'Tiradentes do Sul',
    uf: 'RS'
  },
  {
    id: 4321493,
    name: 'Toropi',
    uf: 'RS'
  },
  {
    id: 4321501,
    name: 'Torres',
    uf: 'RS'
  },
  {
    id: 4321600,
    name: 'Tramandaí',
    uf: 'RS'
  },
  {
    id: 4321626,
    name: 'Travesseiro',
    uf: 'RS'
  },
  {
    id: 4321634,
    name: 'Três Arroios',
    uf: 'RS'
  },
  {
    id: 4321667,
    name: 'Três Cachoeiras',
    uf: 'RS'
  },
  {
    id: 4321709,
    name: 'Três Coroas',
    uf: 'RS'
  },
  {
    id: 4321808,
    name: 'Três de Maio',
    uf: 'RS'
  },
  {
    id: 4321832,
    name: 'Três Forquilhas',
    uf: 'RS'
  },
  {
    id: 4321857,
    name: 'Três Palmeiras',
    uf: 'RS'
  },
  {
    id: 4321907,
    name: 'Três Passos',
    uf: 'RS'
  },
  {
    id: 4321956,
    name: 'Trindade do Sul',
    uf: 'RS'
  },
  {
    id: 4322004,
    name: 'Triunfo',
    uf: 'RS'
  },
  {
    id: 4322103,
    name: 'Tucunduva',
    uf: 'RS'
  },
  {
    id: 4322152,
    name: 'Tunas',
    uf: 'RS'
  },
  {
    id: 4322186,
    name: 'Tupanci do Sul',
    uf: 'RS'
  },
  {
    id: 4322202,
    name: 'Tupanciretã',
    uf: 'RS'
  },
  {
    id: 4322251,
    name: 'Tupandi',
    uf: 'RS'
  },
  {
    id: 4322301,
    name: 'Tuparendi',
    uf: 'RS'
  },
  {
    id: 4322327,
    name: 'Turuçu',
    uf: 'RS'
  },
  {
    id: 4322343,
    name: 'Ubiretama',
    uf: 'RS'
  },
  {
    id: 4322350,
    name: 'União da Serra',
    uf: 'RS'
  },
  {
    id: 4322376,
    name: 'Unistalda',
    uf: 'RS'
  },
  {
    id: 4322400,
    name: 'Uruguaiana',
    uf: 'RS'
  },
  {
    id: 4322509,
    name: 'Vacaria',
    uf: 'RS'
  },
  {
    id: 4322525,
    name: 'Vale Verde',
    uf: 'RS'
  },
  {
    id: 4322533,
    name: 'Vale do Sol',
    uf: 'RS'
  },
  {
    id: 4322541,
    name: 'Vale Real',
    uf: 'RS'
  },
  {
    id: 4322558,
    name: 'Vanini',
    uf: 'RS'
  },
  {
    id: 4322608,
    name: 'Venâncio Aires',
    uf: 'RS'
  },
  {
    id: 4322707,
    name: 'Vera Cruz',
    uf: 'RS'
  },
  {
    id: 4322806,
    name: 'Veranópolis',
    uf: 'RS'
  },
  {
    id: 4322855,
    name: 'Vespasiano Corrêa',
    uf: 'RS'
  },
  {
    id: 4322905,
    name: 'Viadutos',
    uf: 'RS'
  },
  {
    id: 4323002,
    name: 'Viamão',
    uf: 'RS'
  },
  {
    id: 4323101,
    name: 'Vicente Dutra',
    uf: 'RS'
  },
  {
    id: 4323200,
    name: 'Victor Graeff',
    uf: 'RS'
  },
  {
    id: 4323309,
    name: 'Vila Flores',
    uf: 'RS'
  },
  {
    id: 4323358,
    name: 'Vila Lângaro',
    uf: 'RS'
  },
  {
    id: 4323408,
    name: 'Vila Maria',
    uf: 'RS'
  },
  {
    id: 4323457,
    name: 'Vila Nova do Sul',
    uf: 'RS'
  },
  {
    id: 4323507,
    name: 'Vista Alegre',
    uf: 'RS'
  },
  {
    id: 4323606,
    name: 'Vista Alegre do Prata',
    uf: 'RS'
  },
  {
    id: 4323705,
    name: 'Vista Gaúcha',
    uf: 'RS'
  },
  {
    id: 4323754,
    name: 'Vitória das Missões',
    uf: 'RS'
  },
  {
    id: 4323770,
    name: 'Westfália',
    uf: 'RS'
  },
  {
    id: 4323804,
    name: 'Xangri-lá',
    uf: 'RS'
  },
  {
    id: 5000203,
    name: 'Água Clara',
    uf: 'MS'
  },
  {
    id: 5000252,
    name: 'Alcinópolis',
    uf: 'MS'
  },
  {
    id: 5000609,
    name: 'Amambai',
    uf: 'MS'
  },
  {
    id: 5000708,
    name: 'Anastácio',
    uf: 'MS'
  },
  {
    id: 5000807,
    name: 'Anaurilândia',
    uf: 'MS'
  },
  {
    id: 5000856,
    name: 'Angélica',
    uf: 'MS'
  },
  {
    id: 5000906,
    name: 'Antônio João',
    uf: 'MS'
  },
  {
    id: 5001003,
    name: 'Aparecida do Taboado',
    uf: 'MS'
  },
  {
    id: 5001102,
    name: 'Aquidauana',
    uf: 'MS'
  },
  {
    id: 5001243,
    name: 'Aral Moreira',
    uf: 'MS'
  },
  {
    id: 5001508,
    name: 'Bandeirantes',
    uf: 'MS'
  },
  {
    id: 5001904,
    name: 'Bataguassu',
    uf: 'MS'
  },
  {
    id: 5002001,
    name: 'Batayporã',
    uf: 'MS'
  },
  {
    id: 5002100,
    name: 'Bela Vista',
    uf: 'MS'
  },
  {
    id: 5002159,
    name: 'Bodoquena',
    uf: 'MS'
  },
  {
    id: 5002209,
    name: 'Bonito',
    uf: 'MS'
  },
  {
    id: 5002308,
    name: 'Brasilândia',
    uf: 'MS'
  },
  {
    id: 5002407,
    name: 'Caarapó',
    uf: 'MS'
  },
  {
    id: 5002605,
    name: 'Camapuã',
    uf: 'MS'
  },
  {
    id: 5002704,
    name: 'Campo Grande',
    uf: 'MS'
  },
  {
    id: 5002803,
    name: 'Caracol',
    uf: 'MS'
  },
  {
    id: 5002902,
    name: 'Cassilândia',
    uf: 'MS'
  },
  {
    id: 5002951,
    name: 'Chapadão do Sul',
    uf: 'MS'
  },
  {
    id: 5003108,
    name: 'Corguinho',
    uf: 'MS'
  },
  {
    id: 5003157,
    name: 'Coronel Sapucaia',
    uf: 'MS'
  },
  {
    id: 5003207,
    name: 'Corumbá',
    uf: 'MS'
  },
  {
    id: 5003256,
    name: 'Costa Rica',
    uf: 'MS'
  },
  {
    id: 5003306,
    name: 'Coxim',
    uf: 'MS'
  },
  {
    id: 5003454,
    name: 'Deodápolis',
    uf: 'MS'
  },
  {
    id: 5003488,
    name: 'Dois Irmãos do Buriti',
    uf: 'MS'
  },
  {
    id: 5003504,
    name: 'Douradina',
    uf: 'MS'
  },
  {
    id: 5003702,
    name: 'Dourados',
    uf: 'MS'
  },
  {
    id: 5003751,
    name: 'Eldorado',
    uf: 'MS'
  },
  {
    id: 5003801,
    name: 'Fátima do Sul',
    uf: 'MS'
  },
  {
    id: 5003900,
    name: 'Figueirão',
    uf: 'MS'
  },
  {
    id: 5004007,
    name: 'Glória de Dourados',
    uf: 'MS'
  },
  {
    id: 5004106,
    name: 'Guia Lopes da Laguna',
    uf: 'MS'
  },
  {
    id: 5004304,
    name: 'Iguatemi',
    uf: 'MS'
  },
  {
    id: 5004403,
    name: 'Inocência',
    uf: 'MS'
  },
  {
    id: 5004502,
    name: 'Itaporã',
    uf: 'MS'
  },
  {
    id: 5004601,
    name: 'Itaquiraí',
    uf: 'MS'
  },
  {
    id: 5004700,
    name: 'Ivinhema',
    uf: 'MS'
  },
  {
    id: 5004809,
    name: 'Japorã',
    uf: 'MS'
  },
  {
    id: 5004908,
    name: 'Jaraguari',
    uf: 'MS'
  },
  {
    id: 5005004,
    name: 'Jardim',
    uf: 'MS'
  },
  {
    id: 5005103,
    name: 'Jateí',
    uf: 'MS'
  },
  {
    id: 5005152,
    name: 'Juti',
    uf: 'MS'
  },
  {
    id: 5005202,
    name: 'Ladário',
    uf: 'MS'
  },
  {
    id: 5005251,
    name: 'Laguna Carapã',
    uf: 'MS'
  },
  {
    id: 5005400,
    name: 'Maracaju',
    uf: 'MS'
  },
  {
    id: 5005608,
    name: 'Miranda',
    uf: 'MS'
  },
  {
    id: 5005681,
    name: 'Mundo Novo',
    uf: 'MS'
  },
  {
    id: 5005707,
    name: 'Naviraí',
    uf: 'MS'
  },
  {
    id: 5005806,
    name: 'Nioaque',
    uf: 'MS'
  },
  {
    id: 5006002,
    name: 'Nova Alvorada do Sul',
    uf: 'MS'
  },
  {
    id: 5006200,
    name: 'Nova Andradina',
    uf: 'MS'
  },
  {
    id: 5006259,
    name: 'Novo Horizonte do Sul',
    uf: 'MS'
  },
  {
    id: 5006275,
    name: 'Paraíso das Águas',
    uf: 'MS'
  },
  {
    id: 5006309,
    name: 'Paranaíba',
    uf: 'MS'
  },
  {
    id: 5006358,
    name: 'Paranhos',
    uf: 'MS'
  },
  {
    id: 5006408,
    name: 'Pedro Gomes',
    uf: 'MS'
  },
  {
    id: 5006606,
    name: 'Ponta Porã',
    uf: 'MS'
  },
  {
    id: 5006903,
    name: 'Porto Murtinho',
    uf: 'MS'
  },
  {
    id: 5007109,
    name: 'Ribas do Rio Pardo',
    uf: 'MS'
  },
  {
    id: 5007208,
    name: 'Rio Brilhante',
    uf: 'MS'
  },
  {
    id: 5007307,
    name: 'Rio Negro',
    uf: 'MS'
  },
  {
    id: 5007406,
    name: 'Rio Verde de Mato Grosso',
    uf: 'MS'
  },
  {
    id: 5007505,
    name: 'Rochedo',
    uf: 'MS'
  },
  {
    id: 5007554,
    name: 'Santa Rita do Pardo',
    uf: 'MS'
  },
  {
    id: 5007695,
    name: 'São Gabriel do Oeste',
    uf: 'MS'
  },
  {
    id: 5007703,
    name: 'Sete Quedas',
    uf: 'MS'
  },
  {
    id: 5007802,
    name: 'Selvíria',
    uf: 'MS'
  },
  {
    id: 5007901,
    name: 'Sidrolândia',
    uf: 'MS'
  },
  {
    id: 5007935,
    name: 'Sonora',
    uf: 'MS'
  },
  {
    id: 5007950,
    name: 'Tacuru',
    uf: 'MS'
  },
  {
    id: 5007976,
    name: 'Taquarussu',
    uf: 'MS'
  },
  {
    id: 5008008,
    name: 'Terenos',
    uf: 'MS'
  },
  {
    id: 5008305,
    name: 'Três Lagoas',
    uf: 'MS'
  },
  {
    id: 5008404,
    name: 'Vicentina',
    uf: 'MS'
  },
  {
    id: 5100102,
    name: 'Acorizal',
    uf: 'MT'
  },
  {
    id: 5100201,
    name: 'Água Boa',
    uf: 'MT'
  },
  {
    id: 5100250,
    name: 'Alta Floresta',
    uf: 'MT'
  },
  {
    id: 5100300,
    name: 'Alto Araguaia',
    uf: 'MT'
  },
  {
    id: 5100359,
    name: 'Alto Boa Vista',
    uf: 'MT'
  },
  {
    id: 5100409,
    name: 'Alto Garças',
    uf: 'MT'
  },
  {
    id: 5100508,
    name: 'Alto Paraguai',
    uf: 'MT'
  },
  {
    id: 5100607,
    name: 'Alto Taquari',
    uf: 'MT'
  },
  {
    id: 5100805,
    name: 'Apiacás',
    uf: 'MT'
  },
  {
    id: 5101001,
    name: 'Araguaiana',
    uf: 'MT'
  },
  {
    id: 5101209,
    name: 'Araguainha',
    uf: 'MT'
  },
  {
    id: 5101258,
    name: 'Araputanga',
    uf: 'MT'
  },
  {
    id: 5101308,
    name: 'Arenápolis',
    uf: 'MT'
  },
  {
    id: 5101407,
    name: 'Aripuanã',
    uf: 'MT'
  },
  {
    id: 5101605,
    name: 'Barão de Melgaço',
    uf: 'MT'
  },
  {
    id: 5101704,
    name: 'Barra do Bugres',
    uf: 'MT'
  },
  {
    id: 5101803,
    name: 'Barra do Garças',
    uf: 'MT'
  },
  {
    id: 5101852,
    name: 'Bom Jesus do Araguaia',
    uf: 'MT'
  },
  {
    id: 5101902,
    name: 'Brasnorte',
    uf: 'MT'
  },
  {
    id: 5102504,
    name: 'Cáceres',
    uf: 'MT'
  },
  {
    id: 5102603,
    name: 'Campinápolis',
    uf: 'MT'
  },
  {
    id: 5102637,
    name: 'Campo Novo do Parecis',
    uf: 'MT'
  },
  {
    id: 5102678,
    name: 'Campo Verde',
    uf: 'MT'
  },
  {
    id: 5102686,
    name: 'Campos de Júlio',
    uf: 'MT'
  },
  {
    id: 5102694,
    name: 'Canabrava do Norte',
    uf: 'MT'
  },
  {
    id: 5102702,
    name: 'Canarana',
    uf: 'MT'
  },
  {
    id: 5102793,
    name: 'Carlinda',
    uf: 'MT'
  },
  {
    id: 5102850,
    name: 'Castanheira',
    uf: 'MT'
  },
  {
    id: 5103007,
    name: 'Chapada dos Guimarães',
    uf: 'MT'
  },
  {
    id: 5103056,
    name: 'Cláudia',
    uf: 'MT'
  },
  {
    id: 5103106,
    name: 'Cocalinho',
    uf: 'MT'
  },
  {
    id: 5103205,
    name: 'Colíder',
    uf: 'MT'
  },
  {
    id: 5103254,
    name: 'Colniza',
    uf: 'MT'
  },
  {
    id: 5103304,
    name: 'Comodoro',
    uf: 'MT'
  },
  {
    id: 5103353,
    name: 'Confresa',
    uf: 'MT'
  },
  {
    id: 5103361,
    name: "Conquista D'Oeste",
    uf: 'MT'
  },
  {
    id: 5103379,
    name: 'Cotriguaçu',
    uf: 'MT'
  },
  {
    id: 5103403,
    name: 'Cuiabá',
    uf: 'MT'
  },
  {
    id: 5103437,
    name: 'Curvelândia',
    uf: 'MT'
  },
  {
    id: 5103452,
    name: 'Denise',
    uf: 'MT'
  },
  {
    id: 5103502,
    name: 'Diamantino',
    uf: 'MT'
  },
  {
    id: 5103601,
    name: 'Dom Aquino',
    uf: 'MT'
  },
  {
    id: 5103700,
    name: 'Feliz Natal',
    uf: 'MT'
  },
  {
    id: 5103809,
    name: "Figueirópolis D'Oeste",
    uf: 'MT'
  },
  {
    id: 5103858,
    name: 'Gaúcha do Norte',
    uf: 'MT'
  },
  {
    id: 5103908,
    name: 'General Carneiro',
    uf: 'MT'
  },
  {
    id: 5103957,
    name: "Glória D'Oeste",
    uf: 'MT'
  },
  {
    id: 5104104,
    name: 'Guarantã do Norte',
    uf: 'MT'
  },
  {
    id: 5104203,
    name: 'Guiratinga',
    uf: 'MT'
  },
  {
    id: 5104500,
    name: 'Indiavaí',
    uf: 'MT'
  },
  {
    id: 5104526,
    name: 'Ipiranga do Norte',
    uf: 'MT'
  },
  {
    id: 5104542,
    name: 'Itanhangá',
    uf: 'MT'
  },
  {
    id: 5104559,
    name: 'Itaúba',
    uf: 'MT'
  },
  {
    id: 5104609,
    name: 'Itiquira',
    uf: 'MT'
  },
  {
    id: 5104807,
    name: 'Jaciara',
    uf: 'MT'
  },
  {
    id: 5104906,
    name: 'Jangada',
    uf: 'MT'
  },
  {
    id: 5105002,
    name: 'Jauru',
    uf: 'MT'
  },
  {
    id: 5105101,
    name: 'Juara',
    uf: 'MT'
  },
  {
    id: 5105150,
    name: 'Juína',
    uf: 'MT'
  },
  {
    id: 5105176,
    name: 'Juruena',
    uf: 'MT'
  },
  {
    id: 5105200,
    name: 'Juscimeira',
    uf: 'MT'
  },
  {
    id: 5105234,
    name: "Lambari D'Oeste",
    uf: 'MT'
  },
  {
    id: 5105259,
    name: 'Lucas do Rio Verde',
    uf: 'MT'
  },
  {
    id: 5105309,
    name: 'Luciara',
    uf: 'MT'
  },
  {
    id: 5105507,
    name: 'Vila Bela da Santíssima Trindade',
    uf: 'MT'
  },
  {
    id: 5105580,
    name: 'Marcelândia',
    uf: 'MT'
  },
  {
    id: 5105606,
    name: 'Matupá',
    uf: 'MT'
  },
  {
    id: 5105622,
    name: "Mirassol d'Oeste",
    uf: 'MT'
  },
  {
    id: 5105903,
    name: 'Nobres',
    uf: 'MT'
  },
  {
    id: 5106000,
    name: 'Nortelândia',
    uf: 'MT'
  },
  {
    id: 5106109,
    name: 'Nossa Senhora do Livramento',
    uf: 'MT'
  },
  {
    id: 5106158,
    name: 'Nova Bandeirantes',
    uf: 'MT'
  },
  {
    id: 5106174,
    name: 'Nova Nazaré',
    uf: 'MT'
  },
  {
    id: 5106182,
    name: 'Nova Lacerda',
    uf: 'MT'
  },
  {
    id: 5106190,
    name: 'Nova Santa Helena',
    uf: 'MT'
  },
  {
    id: 5106208,
    name: 'Nova Brasilândia',
    uf: 'MT'
  },
  {
    id: 5106216,
    name: 'Nova Canaã do Norte',
    uf: 'MT'
  },
  {
    id: 5106224,
    name: 'Nova Mutum',
    uf: 'MT'
  },
  {
    id: 5106232,
    name: 'Nova Olímpia',
    uf: 'MT'
  },
  {
    id: 5106240,
    name: 'Nova Ubiratã',
    uf: 'MT'
  },
  {
    id: 5106257,
    name: 'Nova Xavantina',
    uf: 'MT'
  },
  {
    id: 5106265,
    name: 'Novo Mundo',
    uf: 'MT'
  },
  {
    id: 5106273,
    name: 'Novo Horizonte do Norte',
    uf: 'MT'
  },
  {
    id: 5106281,
    name: 'Novo São Joaquim',
    uf: 'MT'
  },
  {
    id: 5106299,
    name: 'Paranaíta',
    uf: 'MT'
  },
  {
    id: 5106307,
    name: 'Paranatinga',
    uf: 'MT'
  },
  {
    id: 5106315,
    name: 'Novo Santo Antônio',
    uf: 'MT'
  },
  {
    id: 5106372,
    name: 'Pedra Preta',
    uf: 'MT'
  },
  {
    id: 5106422,
    name: 'Peixoto de Azevedo',
    uf: 'MT'
  },
  {
    id: 5106455,
    name: 'Planalto da Serra',
    uf: 'MT'
  },
  {
    id: 5106505,
    name: 'Poconé',
    uf: 'MT'
  },
  {
    id: 5106653,
    name: 'Pontal do Araguaia',
    uf: 'MT'
  },
  {
    id: 5106703,
    name: 'Ponte Branca',
    uf: 'MT'
  },
  {
    id: 5106752,
    name: 'Pontes e Lacerda',
    uf: 'MT'
  },
  {
    id: 5106778,
    name: 'Porto Alegre do Norte',
    uf: 'MT'
  },
  {
    id: 5106802,
    name: 'Porto dos Gaúchos',
    uf: 'MT'
  },
  {
    id: 5106828,
    name: 'Porto Esperidião',
    uf: 'MT'
  },
  {
    id: 5106851,
    name: 'Porto Estrela',
    uf: 'MT'
  },
  {
    id: 5107008,
    name: 'Poxoréu',
    uf: 'MT'
  },
  {
    id: 5107040,
    name: 'Primavera do Leste',
    uf: 'MT'
  },
  {
    id: 5107065,
    name: 'Querência',
    uf: 'MT'
  },
  {
    id: 5107107,
    name: 'São José dos Quatro Marcos',
    uf: 'MT'
  },
  {
    id: 5107156,
    name: 'Reserva do Cabaçal',
    uf: 'MT'
  },
  {
    id: 5107180,
    name: 'Ribeirão Cascalheira',
    uf: 'MT'
  },
  {
    id: 5107198,
    name: 'Ribeirãozinho',
    uf: 'MT'
  },
  {
    id: 5107206,
    name: 'Rio Branco',
    uf: 'MT'
  },
  {
    id: 5107248,
    name: 'Santa Carmem',
    uf: 'MT'
  },
  {
    id: 5107263,
    name: 'Santo Afonso',
    uf: 'MT'
  },
  {
    id: 5107297,
    name: 'São José do Povo',
    uf: 'MT'
  },
  {
    id: 5107305,
    name: 'São José do Rio Claro',
    uf: 'MT'
  },
  {
    id: 5107354,
    name: 'São José do Xingu',
    uf: 'MT'
  },
  {
    id: 5107404,
    name: 'São Pedro da Cipa',
    uf: 'MT'
  },
  {
    id: 5107578,
    name: 'Rondolândia',
    uf: 'MT'
  },
  {
    id: 5107602,
    name: 'Rondonópolis',
    uf: 'MT'
  },
  {
    id: 5107701,
    name: 'Rosário Oeste',
    uf: 'MT'
  },
  {
    id: 5107743,
    name: 'Santa Cruz do Xingu',
    uf: 'MT'
  },
  {
    id: 5107750,
    name: 'Salto do Céu',
    uf: 'MT'
  },
  {
    id: 5107768,
    name: 'Santa Rita do Trivelato',
    uf: 'MT'
  },
  {
    id: 5107776,
    name: 'Santa Terezinha',
    uf: 'MT'
  },
  {
    id: 5107792,
    name: 'Santo Antônio do Leste',
    uf: 'MT'
  },
  {
    id: 5107800,
    name: 'Santo Antônio de Leverger',
    uf: 'MT'
  },
  {
    id: 5107859,
    name: 'São Félix do Araguaia',
    uf: 'MT'
  },
  {
    id: 5107875,
    name: 'Sapezal',
    uf: 'MT'
  },
  {
    id: 5107883,
    name: 'Serra Nova Dourada',
    uf: 'MT'
  },
  {
    id: 5107909,
    name: 'Sinop',
    uf: 'MT'
  },
  {
    id: 5107925,
    name: 'Sorriso',
    uf: 'MT'
  },
  {
    id: 5107941,
    name: 'Tabaporã',
    uf: 'MT'
  },
  {
    id: 5107958,
    name: 'Tangará da Serra',
    uf: 'MT'
  },
  {
    id: 5108006,
    name: 'Tapurah',
    uf: 'MT'
  },
  {
    id: 5108055,
    name: 'Terra Nova do Norte',
    uf: 'MT'
  },
  {
    id: 5108105,
    name: 'Tesouro',
    uf: 'MT'
  },
  {
    id: 5108204,
    name: 'Torixoréu',
    uf: 'MT'
  },
  {
    id: 5108303,
    name: 'União do Sul',
    uf: 'MT'
  },
  {
    id: 5108352,
    name: 'Vale de São Domingos',
    uf: 'MT'
  },
  {
    id: 5108402,
    name: 'Várzea Grande',
    uf: 'MT'
  },
  {
    id: 5108501,
    name: 'Vera',
    uf: 'MT'
  },
  {
    id: 5108600,
    name: 'Vila Rica',
    uf: 'MT'
  },
  {
    id: 5108808,
    name: 'Nova Guarita',
    uf: 'MT'
  },
  {
    id: 5108857,
    name: 'Nova Marilândia',
    uf: 'MT'
  },
  {
    id: 5108907,
    name: 'Nova Maringá',
    uf: 'MT'
  },
  {
    id: 5108956,
    name: 'Nova Monte Verde',
    uf: 'MT'
  },
  {
    id: 5200050,
    name: 'Abadia de Goiás',
    uf: 'GO'
  },
  {
    id: 5200100,
    name: 'Abadiânia',
    uf: 'GO'
  },
  {
    id: 5200134,
    name: 'Acreúna',
    uf: 'GO'
  },
  {
    id: 5200159,
    name: 'Adelândia',
    uf: 'GO'
  },
  {
    id: 5200175,
    name: 'Água Fria de Goiás',
    uf: 'GO'
  },
  {
    id: 5200209,
    name: 'Água Limpa',
    uf: 'GO'
  },
  {
    id: 5200258,
    name: 'Águas Lindas de Goiás',
    uf: 'GO'
  },
  {
    id: 5200308,
    name: 'Alexânia',
    uf: 'GO'
  },
  {
    id: 5200506,
    name: 'Aloândia',
    uf: 'GO'
  },
  {
    id: 5200555,
    name: 'Alto Horizonte',
    uf: 'GO'
  },
  {
    id: 5200605,
    name: 'Alto Paraíso de Goiás',
    uf: 'GO'
  },
  {
    id: 5200803,
    name: 'Alvorada do Norte',
    uf: 'GO'
  },
  {
    id: 5200829,
    name: 'Amaralina',
    uf: 'GO'
  },
  {
    id: 5200852,
    name: 'Americano do Brasil',
    uf: 'GO'
  },
  {
    id: 5200902,
    name: 'Amorinópolis',
    uf: 'GO'
  },
  {
    id: 5201108,
    name: 'Anápolis',
    uf: 'GO'
  },
  {
    id: 5201207,
    name: 'Anhanguera',
    uf: 'GO'
  },
  {
    id: 5201306,
    name: 'Anicuns',
    uf: 'GO'
  },
  {
    id: 5201405,
    name: 'Aparecida de Goiânia',
    uf: 'GO'
  },
  {
    id: 5201454,
    name: 'Aparecida do Rio Doce',
    uf: 'GO'
  },
  {
    id: 5201504,
    name: 'Aporé',
    uf: 'GO'
  },
  {
    id: 5201603,
    name: 'Araçu',
    uf: 'GO'
  },
  {
    id: 5201702,
    name: 'Aragarças',
    uf: 'GO'
  },
  {
    id: 5201801,
    name: 'Aragoiânia',
    uf: 'GO'
  },
  {
    id: 5202155,
    name: 'Araguapaz',
    uf: 'GO'
  },
  {
    id: 5202353,
    name: 'Arenópolis',
    uf: 'GO'
  },
  {
    id: 5202502,
    name: 'Aruanã',
    uf: 'GO'
  },
  {
    id: 5202601,
    name: 'Aurilândia',
    uf: 'GO'
  },
  {
    id: 5202809,
    name: 'Avelinópolis',
    uf: 'GO'
  },
  {
    id: 5203104,
    name: 'Baliza',
    uf: 'GO'
  },
  {
    id: 5203203,
    name: 'Barro Alto',
    uf: 'GO'
  },
  {
    id: 5203302,
    name: 'Bela Vista de Goiás',
    uf: 'GO'
  },
  {
    id: 5203401,
    name: 'Bom Jardim de Goiás',
    uf: 'GO'
  },
  {
    id: 5203500,
    name: 'Bom Jesus de Goiás',
    uf: 'GO'
  },
  {
    id: 5203559,
    name: 'Bonfinópolis',
    uf: 'GO'
  },
  {
    id: 5203575,
    name: 'Bonópolis',
    uf: 'GO'
  },
  {
    id: 5203609,
    name: 'Brazabrantes',
    uf: 'GO'
  },
  {
    id: 5203807,
    name: 'Britânia',
    uf: 'GO'
  },
  {
    id: 5203906,
    name: 'Buriti Alegre',
    uf: 'GO'
  },
  {
    id: 5203939,
    name: 'Buriti de Goiás',
    uf: 'GO'
  },
  {
    id: 5203962,
    name: 'Buritinópolis',
    uf: 'GO'
  },
  {
    id: 5204003,
    name: 'Cabeceiras',
    uf: 'GO'
  },
  {
    id: 5204102,
    name: 'Cachoeira Alta',
    uf: 'GO'
  },
  {
    id: 5204201,
    name: 'Cachoeira de Goiás',
    uf: 'GO'
  },
  {
    id: 5204250,
    name: 'Cachoeira Dourada',
    uf: 'GO'
  },
  {
    id: 5204300,
    name: 'Caçu',
    uf: 'GO'
  },
  {
    id: 5204409,
    name: 'Caiapônia',
    uf: 'GO'
  },
  {
    id: 5204508,
    name: 'Caldas Novas',
    uf: 'GO'
  },
  {
    id: 5204557,
    name: 'Caldazinha',
    uf: 'GO'
  },
  {
    id: 5204607,
    name: 'Campestre de Goiás',
    uf: 'GO'
  },
  {
    id: 5204656,
    name: 'Campinaçu',
    uf: 'GO'
  },
  {
    id: 5204706,
    name: 'Campinorte',
    uf: 'GO'
  },
  {
    id: 5204805,
    name: 'Campo Alegre de Goiás',
    uf: 'GO'
  },
  {
    id: 5204854,
    name: 'Campo Limpo de Goiás',
    uf: 'GO'
  },
  {
    id: 5204904,
    name: 'Campos Belos',
    uf: 'GO'
  },
  {
    id: 5204953,
    name: 'Campos Verdes',
    uf: 'GO'
  },
  {
    id: 5205000,
    name: 'Carmo do Rio Verde',
    uf: 'GO'
  },
  {
    id: 5205059,
    name: 'Castelândia',
    uf: 'GO'
  },
  {
    id: 5205109,
    name: 'Catalão',
    uf: 'GO'
  },
  {
    id: 5205208,
    name: 'Caturaí',
    uf: 'GO'
  },
  {
    id: 5205307,
    name: 'Cavalcante',
    uf: 'GO'
  },
  {
    id: 5205406,
    name: 'Ceres',
    uf: 'GO'
  },
  {
    id: 5205455,
    name: 'Cezarina',
    uf: 'GO'
  },
  {
    id: 5205471,
    name: 'Chapadão do Céu',
    uf: 'GO'
  },
  {
    id: 5205497,
    name: 'Cidade Ocidental',
    uf: 'GO'
  },
  {
    id: 5205513,
    name: 'Cocalzinho de Goiás',
    uf: 'GO'
  },
  {
    id: 5205521,
    name: 'Colinas do Sul',
    uf: 'GO'
  },
  {
    id: 5205703,
    name: 'Córrego do Ouro',
    uf: 'GO'
  },
  {
    id: 5205802,
    name: 'Corumbá de Goiás',
    uf: 'GO'
  },
  {
    id: 5205901,
    name: 'Corumbaíba',
    uf: 'GO'
  },
  {
    id: 5206206,
    name: 'Cristalina',
    uf: 'GO'
  },
  {
    id: 5206305,
    name: 'Cristianópolis',
    uf: 'GO'
  },
  {
    id: 5206404,
    name: 'Crixás',
    uf: 'GO'
  },
  {
    id: 5206503,
    name: 'Cromínia',
    uf: 'GO'
  },
  {
    id: 5206602,
    name: 'Cumari',
    uf: 'GO'
  },
  {
    id: 5206701,
    name: 'Damianópolis',
    uf: 'GO'
  },
  {
    id: 5206800,
    name: 'Damolândia',
    uf: 'GO'
  },
  {
    id: 5206909,
    name: 'Davinópolis',
    uf: 'GO'
  },
  {
    id: 5207105,
    name: 'Diorama',
    uf: 'GO'
  },
  {
    id: 5207253,
    name: 'Doverlândia',
    uf: 'GO'
  },
  {
    id: 5207352,
    name: 'Edealina',
    uf: 'GO'
  },
  {
    id: 5207402,
    name: 'Edéia',
    uf: 'GO'
  },
  {
    id: 5207501,
    name: 'Estrela do Norte',
    uf: 'GO'
  },
  {
    id: 5207535,
    name: 'Faina',
    uf: 'GO'
  },
  {
    id: 5207600,
    name: 'Fazenda Nova',
    uf: 'GO'
  },
  {
    id: 5207808,
    name: 'Firminópolis',
    uf: 'GO'
  },
  {
    id: 5207907,
    name: 'Flores de Goiás',
    uf: 'GO'
  },
  {
    id: 5208004,
    name: 'Formosa',
    uf: 'GO'
  },
  {
    id: 5208103,
    name: 'Formoso',
    uf: 'GO'
  },
  {
    id: 5208152,
    name: 'Gameleira de Goiás',
    uf: 'GO'
  },
  {
    id: 5208301,
    name: 'Divinópolis de Goiás',
    uf: 'GO'
  },
  {
    id: 5208400,
    name: 'Goianápolis',
    uf: 'GO'
  },
  {
    id: 5208509,
    name: 'Goiandira',
    uf: 'GO'
  },
  {
    id: 5208608,
    name: 'Goianésia',
    uf: 'GO'
  },
  {
    id: 5208707,
    name: 'Goiânia',
    uf: 'GO'
  },
  {
    id: 5208806,
    name: 'Goianira',
    uf: 'GO'
  },
  {
    id: 5208905,
    name: 'Goiás',
    uf: 'GO'
  },
  {
    id: 5209101,
    name: 'Goiatuba',
    uf: 'GO'
  },
  {
    id: 5209150,
    name: 'Gouvelândia',
    uf: 'GO'
  },
  {
    id: 5209200,
    name: 'Guapó',
    uf: 'GO'
  },
  {
    id: 5209291,
    name: 'Guaraíta',
    uf: 'GO'
  },
  {
    id: 5209408,
    name: 'Guarani de Goiás',
    uf: 'GO'
  },
  {
    id: 5209457,
    name: 'Guarinos',
    uf: 'GO'
  },
  {
    id: 5209606,
    name: 'Heitoraí',
    uf: 'GO'
  },
  {
    id: 5209705,
    name: 'Hidrolândia',
    uf: 'GO'
  },
  {
    id: 5209804,
    name: 'Hidrolina',
    uf: 'GO'
  },
  {
    id: 5209903,
    name: 'Iaciara',
    uf: 'GO'
  },
  {
    id: 5209937,
    name: 'Inaciolândia',
    uf: 'GO'
  },
  {
    id: 5209952,
    name: 'Indiara',
    uf: 'GO'
  },
  {
    id: 5210000,
    name: 'Inhumas',
    uf: 'GO'
  },
  {
    id: 5210109,
    name: 'Ipameri',
    uf: 'GO'
  },
  {
    id: 5210158,
    name: 'Ipiranga de Goiás',
    uf: 'GO'
  },
  {
    id: 5210208,
    name: 'Iporá',
    uf: 'GO'
  },
  {
    id: 5210307,
    name: 'Israelândia',
    uf: 'GO'
  },
  {
    id: 5210406,
    name: 'Itaberaí',
    uf: 'GO'
  },
  {
    id: 5210562,
    name: 'Itaguari',
    uf: 'GO'
  },
  {
    id: 5210604,
    name: 'Itaguaru',
    uf: 'GO'
  },
  {
    id: 5210802,
    name: 'Itajá',
    uf: 'GO'
  },
  {
    id: 5210901,
    name: 'Itapaci',
    uf: 'GO'
  },
  {
    id: 5211008,
    name: 'Itapirapuã',
    uf: 'GO'
  },
  {
    id: 5211206,
    name: 'Itapuranga',
    uf: 'GO'
  },
  {
    id: 5211305,
    name: 'Itarumã',
    uf: 'GO'
  },
  {
    id: 5211404,
    name: 'Itauçu',
    uf: 'GO'
  },
  {
    id: 5211503,
    name: 'Itumbiara',
    uf: 'GO'
  },
  {
    id: 5211602,
    name: 'Ivolândia',
    uf: 'GO'
  },
  {
    id: 5211701,
    name: 'Jandaia',
    uf: 'GO'
  },
  {
    id: 5211800,
    name: 'Jaraguá',
    uf: 'GO'
  },
  {
    id: 5211909,
    name: 'Jataí',
    uf: 'GO'
  },
  {
    id: 5212006,
    name: 'Jaupaci',
    uf: 'GO'
  },
  {
    id: 5212055,
    name: 'Jesúpolis',
    uf: 'GO'
  },
  {
    id: 5212105,
    name: 'Joviânia',
    uf: 'GO'
  },
  {
    id: 5212204,
    name: 'Jussara',
    uf: 'GO'
  },
  {
    id: 5212253,
    name: 'Lagoa Santa',
    uf: 'GO'
  },
  {
    id: 5212303,
    name: 'Leopoldo de Bulhões',
    uf: 'GO'
  },
  {
    id: 5212501,
    name: 'Luziânia',
    uf: 'GO'
  },
  {
    id: 5212600,
    name: 'Mairipotaba',
    uf: 'GO'
  },
  {
    id: 5212709,
    name: 'Mambaí',
    uf: 'GO'
  },
  {
    id: 5212808,
    name: 'Mara Rosa',
    uf: 'GO'
  },
  {
    id: 5212907,
    name: 'Marzagão',
    uf: 'GO'
  },
  {
    id: 5212956,
    name: 'Matrinchã',
    uf: 'GO'
  },
  {
    id: 5213004,
    name: 'Maurilândia',
    uf: 'GO'
  },
  {
    id: 5213053,
    name: 'Mimoso de Goiás',
    uf: 'GO'
  },
  {
    id: 5213087,
    name: 'Minaçu',
    uf: 'GO'
  },
  {
    id: 5213103,
    name: 'Mineiros',
    uf: 'GO'
  },
  {
    id: 5213400,
    name: 'Moiporá',
    uf: 'GO'
  },
  {
    id: 5213509,
    name: 'Monte Alegre de Goiás',
    uf: 'GO'
  },
  {
    id: 5213707,
    name: 'Montes Claros de Goiás',
    uf: 'GO'
  },
  {
    id: 5213756,
    name: 'Montividiu',
    uf: 'GO'
  },
  {
    id: 5213772,
    name: 'Montividiu do Norte',
    uf: 'GO'
  },
  {
    id: 5213806,
    name: 'Morrinhos',
    uf: 'GO'
  },
  {
    id: 5213855,
    name: 'Morro Agudo de Goiás',
    uf: 'GO'
  },
  {
    id: 5213905,
    name: 'Mossâmedes',
    uf: 'GO'
  },
  {
    id: 5214002,
    name: 'Mozarlândia',
    uf: 'GO'
  },
  {
    id: 5214051,
    name: 'Mundo Novo',
    uf: 'GO'
  },
  {
    id: 5214101,
    name: 'Mutunópolis',
    uf: 'GO'
  },
  {
    id: 5214408,
    name: 'Nazário',
    uf: 'GO'
  },
  {
    id: 5214507,
    name: 'Nerópolis',
    uf: 'GO'
  },
  {
    id: 5214606,
    name: 'Niquelândia',
    uf: 'GO'
  },
  {
    id: 5214705,
    name: 'Nova América',
    uf: 'GO'
  },
  {
    id: 5214804,
    name: 'Nova Aurora',
    uf: 'GO'
  },
  {
    id: 5214838,
    name: 'Nova Crixás',
    uf: 'GO'
  },
  {
    id: 5214861,
    name: 'Nova Glória',
    uf: 'GO'
  },
  {
    id: 5214879,
    name: 'Nova Iguaçu de Goiás',
    uf: 'GO'
  },
  {
    id: 5214903,
    name: 'Nova Roma',
    uf: 'GO'
  },
  {
    id: 5215009,
    name: 'Nova Veneza',
    uf: 'GO'
  },
  {
    id: 5215207,
    name: 'Novo Brasil',
    uf: 'GO'
  },
  {
    id: 5215231,
    name: 'Novo Gama',
    uf: 'GO'
  },
  {
    id: 5215256,
    name: 'Novo Planalto',
    uf: 'GO'
  },
  {
    id: 5215306,
    name: 'Orizona',
    uf: 'GO'
  },
  {
    id: 5215405,
    name: 'Ouro Verde de Goiás',
    uf: 'GO'
  },
  {
    id: 5215504,
    name: 'Ouvidor',
    uf: 'GO'
  },
  {
    id: 5215603,
    name: 'Padre Bernardo',
    uf: 'GO'
  },
  {
    id: 5215652,
    name: 'Palestina de Goiás',
    uf: 'GO'
  },
  {
    id: 5215702,
    name: 'Palmeiras de Goiás',
    uf: 'GO'
  },
  {
    id: 5215801,
    name: 'Palmelo',
    uf: 'GO'
  },
  {
    id: 5215900,
    name: 'Palminópolis',
    uf: 'GO'
  },
  {
    id: 5216007,
    name: 'Panamá',
    uf: 'GO'
  },
  {
    id: 5216304,
    name: 'Paranaiguara',
    uf: 'GO'
  },
  {
    id: 5216403,
    name: 'Paraúna',
    uf: 'GO'
  },
  {
    id: 5216452,
    name: 'Perolândia',
    uf: 'GO'
  },
  {
    id: 5216809,
    name: 'Petrolina de Goiás',
    uf: 'GO'
  },
  {
    id: 5216908,
    name: 'Pilar de Goiás',
    uf: 'GO'
  },
  {
    id: 5217104,
    name: 'Piracanjuba',
    uf: 'GO'
  },
  {
    id: 5217203,
    name: 'Piranhas',
    uf: 'GO'
  },
  {
    id: 5217302,
    name: 'Pirenópolis',
    uf: 'GO'
  },
  {
    id: 5217401,
    name: 'Pires do Rio',
    uf: 'GO'
  },
  {
    id: 5217609,
    name: 'Planaltina',
    uf: 'GO'
  },
  {
    id: 5217708,
    name: 'Pontalina',
    uf: 'GO'
  },
  {
    id: 5218003,
    name: 'Porangatu',
    uf: 'GO'
  },
  {
    id: 5218052,
    name: 'Porteirão',
    uf: 'GO'
  },
  {
    id: 5218102,
    name: 'Portelândia',
    uf: 'GO'
  },
  {
    id: 5218300,
    name: 'Posse',
    uf: 'GO'
  },
  {
    id: 5218391,
    name: 'Professor Jamil',
    uf: 'GO'
  },
  {
    id: 5218508,
    name: 'Quirinópolis',
    uf: 'GO'
  },
  {
    id: 5218607,
    name: 'Rialma',
    uf: 'GO'
  },
  {
    id: 5218706,
    name: 'Rianápolis',
    uf: 'GO'
  },
  {
    id: 5218789,
    name: 'Rio Quente',
    uf: 'GO'
  },
  {
    id: 5218805,
    name: 'Rio Verde',
    uf: 'GO'
  },
  {
    id: 5218904,
    name: 'Rubiataba',
    uf: 'GO'
  },
  {
    id: 5219001,
    name: 'Sanclerlândia',
    uf: 'GO'
  },
  {
    id: 5219100,
    name: 'Santa Bárbara de Goiás',
    uf: 'GO'
  },
  {
    id: 5219209,
    name: 'Santa Cruz de Goiás',
    uf: 'GO'
  },
  {
    id: 5219258,
    name: 'Santa Fé de Goiás',
    uf: 'GO'
  },
  {
    id: 5219308,
    name: 'Santa Helena de Goiás',
    uf: 'GO'
  },
  {
    id: 5219357,
    name: 'Santa Isabel',
    uf: 'GO'
  },
  {
    id: 5219407,
    name: 'Santa Rita do Araguaia',
    uf: 'GO'
  },
  {
    id: 5219456,
    name: 'Santa Rita do Novo Destino',
    uf: 'GO'
  },
  {
    id: 5219506,
    name: 'Santa Rosa de Goiás',
    uf: 'GO'
  },
  {
    id: 5219605,
    name: 'Santa Tereza de Goiás',
    uf: 'GO'
  },
  {
    id: 5219704,
    name: 'Santa Terezinha de Goiás',
    uf: 'GO'
  },
  {
    id: 5219712,
    name: 'Santo Antônio da Barra',
    uf: 'GO'
  },
  {
    id: 5219738,
    name: 'Santo Antônio de Goiás',
    uf: 'GO'
  },
  {
    id: 5219753,
    name: 'Santo Antônio do Descoberto',
    uf: 'GO'
  },
  {
    id: 5219803,
    name: 'São Domingos',
    uf: 'GO'
  },
  {
    id: 5219902,
    name: 'São Francisco de Goiás',
    uf: 'GO'
  },
  {
    id: 5220009,
    name: "São João d'Aliança",
    uf: 'GO'
  },
  {
    id: 5220058,
    name: 'São João da Paraúna',
    uf: 'GO'
  },
  {
    id: 5220108,
    name: 'São Luís de Montes Belos',
    uf: 'GO'
  },
  {
    id: 5220157,
    name: 'São Luiz do Norte',
    uf: 'GO'
  },
  {
    id: 5220207,
    name: 'São Miguel do Araguaia',
    uf: 'GO'
  },
  {
    id: 5220264,
    name: 'São Miguel do Passa Quatro',
    uf: 'GO'
  },
  {
    id: 5220280,
    name: 'São Patrício',
    uf: 'GO'
  },
  {
    id: 5220405,
    name: 'São Simão',
    uf: 'GO'
  },
  {
    id: 5220454,
    name: 'Senador Canedo',
    uf: 'GO'
  },
  {
    id: 5220504,
    name: 'Serranópolis',
    uf: 'GO'
  },
  {
    id: 5220603,
    name: 'Silvânia',
    uf: 'GO'
  },
  {
    id: 5220686,
    name: 'Simolândia',
    uf: 'GO'
  },
  {
    id: 5220702,
    name: "Sítio d'Abadia",
    uf: 'GO'
  },
  {
    id: 5221007,
    name: 'Taquaral de Goiás',
    uf: 'GO'
  },
  {
    id: 5221080,
    name: 'Teresina de Goiás',
    uf: 'GO'
  },
  {
    id: 5221197,
    name: 'Terezópolis de Goiás',
    uf: 'GO'
  },
  {
    id: 5221304,
    name: 'Três Ranchos',
    uf: 'GO'
  },
  {
    id: 5221403,
    name: 'Trindade',
    uf: 'GO'
  },
  {
    id: 5221452,
    name: 'Trombas',
    uf: 'GO'
  },
  {
    id: 5221502,
    name: 'Turvânia',
    uf: 'GO'
  },
  {
    id: 5221551,
    name: 'Turvelândia',
    uf: 'GO'
  },
  {
    id: 5221577,
    name: 'Uirapuru',
    uf: 'GO'
  },
  {
    id: 5221601,
    name: 'Uruaçu',
    uf: 'GO'
  },
  {
    id: 5221700,
    name: 'Uruana',
    uf: 'GO'
  },
  {
    id: 5221809,
    name: 'Urutaí',
    uf: 'GO'
  },
  {
    id: 5221858,
    name: 'Valparaíso de Goiás',
    uf: 'GO'
  },
  {
    id: 5221908,
    name: 'Varjão',
    uf: 'GO'
  },
  {
    id: 5222005,
    name: 'Vianópolis',
    uf: 'GO'
  },
  {
    id: 5222054,
    name: 'Vicentinópolis',
    uf: 'GO'
  },
  {
    id: 5222203,
    name: 'Vila Boa',
    uf: 'GO'
  },
  {
    id: 5222302,
    name: 'Vila Propício',
    uf: 'GO'
  },
  {
    id: 5300108,
    name: 'Brasília',
    uf: 'DF'
  }
];

export default cities;
