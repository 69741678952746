import api from 'api';
import { useQuery } from 'react-query';
import {
  useAuthCustomer,
  useSessionStorage,
  useCustomerLocation,
  useFilterModal
} from 'hooks';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMediaQuery } from '@chakra-ui/react';
import { createContext, ReactNode, useEffect, useRef } from 'react';
import * as analytics from 'analytics/events/customers';
import { Category, Option, Provider, Service } from 'types';
import { createCityOption, createLocationOption } from 'utils/functions';
import { isSmallerThan } from 'theme/foundations/breakpoints';
import {
  CUSTOMER_SEARCH_NODATA,
  CUSTOMER_SEARCH_FILTERS,
  CUSTOMER_SEARCH_FILTERS_LOCATION,
  CUSTOMER_SEARCH_FILTERS_SERVICE,
  CUSTOMER_SEARCH_FILTERS_CATEGORY
} from 'app/Router/Router.constants';
import { FilterModal } from 'hooks/useFilterModal';

const GLOBAL_LOCATION_ID = '1';
const N_RETURNED_PROVIDERS = 15;

const routesToExcludeFromNoDataNavigation = [
  CUSTOMER_SEARCH_NODATA,
  CUSTOMER_SEARCH_FILTERS,
  CUSTOMER_SEARCH_FILTERS_LOCATION,
  CUSTOMER_SEARCH_FILTERS_CATEGORY,
  CUSTOMER_SEARCH_FILTERS_SERVICE
];

const STORAGE_PROVIDERS = 'providers';
const STORAGE_HAS_MORE_RESULTS = 'search-has-more-results';
const STORAGE_FILTER_CATEGORY = 'search-filter-category';
const STORAGE_FILTER_SERVICE = 'search-filter-service';
const STORAGE_FILTER_LOCATION = 'search-filter-location';

export const SearchContext = createContext({} as SearchContextProps);
export const SearchContextProvider: React.FC<SearchContextProviderProps> = ({
  children
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isMobile] = useMediaQuery(isSmallerThan('lg'));
  const isDesktop = !isMobile;

  const modalLocation = useFilterModal('location');
  const modalCategory = useFilterModal('category');
  const modalService = useFilterModal('service');

  const { userData } = useAuthCustomer();

  const [cacheableResults, setCacheableResults] = useSessionStorage<Provider[]>(
    STORAGE_PROVIDERS,
    []
  );

  const selectedProviderRef = useRef<Provider | null>(null);

  const [hasMoreResults, setHasMoreResults] = useSessionStorage(
    STORAGE_HAS_MORE_RESULTS,
    false
  );

  const [category, setCategory] = useSessionStorage<Category | null>(
    STORAGE_FILTER_CATEGORY,
    null
  );

  const [service, setService] = useSessionStorage<Service | null>(
    STORAGE_FILTER_SERVICE,
    null
  );

  const [location, setLocation] = useSessionStorage<Option | null>(
    STORAGE_FILTER_LOCATION,
    userData?.location ? createLocationOption(userData.location) : null
  );

  useCustomerLocation({
    enabled: !userData?.location && !location,
    onFindLocation: city => setLocation(createCityOption(city))
  });

  const {
    data: categories,
    isLoading: isLoadingCategories,
    isFetching: isFetchingCategories
  } = useQuery('categories', api.categories.list, {
    refetchOnWindowFocus: false,
    refetchOnMount: false
  });

  const {
    data: services,
    isLoading: isLoadingServices,
    isFetching: isFetchingServices
  } = useQuery(
    ['services', category?.id!],
    ({ queryKey }) =>
      api.categories.getServices({ queryKey, removeEmpty: false }),
    {
      enabled: !!category,
      refetchOnWindowFocus: false,
      refetchOnMount: false
    }
  );

  const { refetch, isLoading: isLoadingSearch } = useQuery(
    ['search', location?.value, category?.id, service?.id],
    ({ queryKey }) =>
      api.providers.filter({ queryKey, lastProviders: cacheableResults }),
    {
      enabled: !!location,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      onSuccess: results => {
        setHasMoreResults(results.length === N_RETURNED_PROVIDERS);
        setCacheableResults(prev => [...prev, ...results]);
      }
    }
  );

  const onSelectProvider = (providerId: string) => {
    navigate(providerId);
  };

  const onSelectLocation = (location: Option) => {
    setLocation(location);
    analytics.selectLocation();
    modalLocation.onClose();
  };

  const onSelectCategory = (category: Category | null) => {
    setCategory(category);
    setService(null);
    analytics.selectCategory();
    modalCategory.onClose();
  };

  const onSelectService = (service: Service | null) => {
    setService(service);
    analytics.selectService();
    modalService.onClose();
  };

  const onResetCategory = () => {
    setCategory(null);
    setService(null);
  };

  const onResetService = () => {
    setService(null);
  };

  const onSearchByLocation = () => {
    setCategory(null);
    setService(null);
    refetch();
  };

  const onSearchByCategory = () => {
    setService(null);
    refetch();
  };

  const onSearchMore = () => {
    refetch();
  };

  const filteredProviders =
    cacheableResults?.filter((provider: Provider) => {
      const locationsIds = provider.locations.map(({ id }) => id.toString());
      const isGlobal = locationsIds.includes(GLOBAL_LOCATION_ID);

      const isMatchLocation = locationsIds.includes(location?.value!);
      const isMatchCategory = category?.id === provider.service.categoryId;
      const isMatchService = service?.id === provider.service.id;

      return (
        (!location || isGlobal || isMatchLocation) &&
        (!category || isMatchCategory) &&
        (!service || isMatchService)
      );
    }) ?? [];

  const loadingSearch = isLoadingSearch;
  const loadingServices = isLoadingServices || isFetchingServices;
  const loadingCategories = isLoadingCategories || isFetchingCategories;

  const hasResults = filteredProviders.length > 0;
  const hasServices = !!(services && services.length > 0);
  const hasCategories = !!(categories && categories.length > 0);

  const hasNoSelectLocationAndNoResults = !location && !hasResults;

  const hasSelectedOnlyLocationButNoResults =
    !hasResults && !!location && !category && !service;

  const hasSelectedOnlyLocationAndCategoryButNoResults =
    !hasResults && !!location && !!category && !service;

  const hasSelectedAllFiltersButNoResults =
    !hasResults && !!location && !!category && !!service;

  const isOnNoData = pathname === CUSTOMER_SEARCH_NODATA;
  const isUnselectedProvider = !selectedProviderRef?.current;
  const isProviderChange =
    filteredProviders[0] !== selectedProviderRef?.current;

  const shouldNavigateToNoData =
    !loadingSearch &&
    !hasResults &&
    !routesToExcludeFromNoDataNavigation.includes(pathname);

  const shouldNavigateToProvider =
    !loadingSearch &&
    hasResults &&
    (isOnNoData ||
      isProviderChange ||
      isUnselectedProvider ||
      !filteredProviders.includes(selectedProviderRef?.current!));

  useEffect(() => {
    if (shouldNavigateToNoData) {
      navigate(CUSTOMER_SEARCH_NODATA);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldNavigateToNoData]);

  useEffect(() => {
    const navigateToProvider = () => {
      if (isDesktop) {
        navigate(`/customers/search/${filteredProviders[0].id}`);
      }
      if (isMobile && isOnNoData) {
        navigate(-1);
      }
    };

    if (shouldNavigateToProvider) {
      navigateToProvider();
      selectedProviderRef.current = filteredProviders[0];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldNavigateToProvider]);

  return (
    <SearchContext.Provider
      value={{
        isMobile,
        filters: {
          location,
          category,
          service
        },
        categories: categories ?? [],
        services: services ?? [],
        searchResults: filteredProviders ?? [],
        hasMoreResults,
        hasServices,
        hasCategories,
        hasResults,
        hasNoSelectLocationAndNoResults,
        hasSelectedAllFiltersButNoResults,
        hasSelectedOnlyLocationButNoResults,
        hasSelectedOnlyLocationAndCategoryButNoResults,
        loadingSearch,
        loadingCategories,
        loadingServices,
        modalLocation,
        modalCategory,
        modalService,
        onSelectProvider,
        onSelectCategory,
        onSelectService,
        onSelectLocation,
        onResetCategory,
        onResetService,
        onSearchByLocation,
        onSearchByCategory,
        onSearchMore
      }}>
      {children}
    </SearchContext.Provider>
  );
};

export interface FiltersState {
  category: Category | null;
  service: Service | null;
  location: Option | null;
}

export interface SearchContextProps {
  isMobile: boolean;
  filters: FiltersState;
  categories: Category[];
  services: Service[];
  searchResults: Provider[];
  modalLocation: FilterModal;
  modalCategory: FilterModal;
  modalService: FilterModal;
  loadingCategories: boolean;
  loadingServices: boolean;
  loadingSearch: boolean;
  hasMoreResults: boolean;
  hasResults: boolean;
  hasCategories: boolean;
  hasServices: boolean;
  hasNoSelectLocationAndNoResults: boolean;
  hasSelectedAllFiltersButNoResults: boolean;
  hasSelectedOnlyLocationButNoResults: boolean;
  hasSelectedOnlyLocationAndCategoryButNoResults: boolean;
  onSelectProvider: (providerId: string) => void;
  onSelectCategory: (category: Category | null) => void;
  onSelectService: (service: Service | null) => void;
  onSelectLocation: (location: Option) => void;
  onResetCategory: () => void;
  onResetService: () => void;
  onSearchByLocation: () => void;
  onSearchByCategory: () => void;
  onSearchMore: () => void;
}

export interface SearchContextProviderProps {
  children: ReactNode;
}
