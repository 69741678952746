import { Center, Flex, Spinner, Text } from '@chakra-ui/react';
import Draggable from 'react-draggable';
import { FaUserShield } from 'react-icons/fa';
import { keyframes } from '@emotion/react';
import { useAuthProvider } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { useCallback, useState } from 'react';

const pulseAnimation = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(49, 130, 206, 0.8);
    z-index: 4999;
  }

  70% {
    box-shadow: 0 0 0 15px rgba(49, 130, 206, 0);
    z-index: 4999;
  }

  100% {
    box-shadow: 0 0 0 0 rgba(49, 130, 206, 0);
    z-index: 4999;
  }
`;

const AuthIndicator = () => {
  const [isDragging, setIsDragging] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { logout } = useAuthProvider();
  const navigate = useNavigate();

  const handleLogout = useCallback(() => {
    setIsLoading(true);
    if (!isDragging) {
      logout(() => navigate('/'));
    }
    setIsLoading(false);
  }, [isDragging, logout, navigate]);

  return (
    <Draggable
      onStart={() => setIsDragging(true)}
      onStop={() => setIsDragging(false)}>
      <Flex
        p={4}
        bgColor="blue.500"
        position="fixed"
        zIndex={5000}
        right={30}
        bottom={30}
        borderRadius="full"
        animation={`${pulseAnimation} 1s infinite`}>
        <Center bgColor="blue.600" mr={3} borderRadius="full" w="70px" h="70px">
          {isLoading ? (
            <Spinner color="white" size="md" />
          ) : (
            <FaUserShield color="#FFF" size={30} />
          )}
        </Center>
        <Flex direction="column">
          <Text fontWeight="bold" color="white">
            Login de ADM
          </Text>
          <Text color="white">Você está logado com outro usuário.</Text>
          <Text color="white" display="flex" flexDirection="row">
            <Text
              fontWeight="bold"
              color="white"
              mr={1}
              cursor="pointer"
              onClick={handleLogout}>
              Clique aqui
            </Text>
            para sair.
          </Text>
        </Flex>
      </Flex>
    </Draggable>
  );
};

export default AuthIndicator;
