import { Flex, FlexProps } from '@chakra-ui/react';

const CardFooter: React.FC<FlexProps> = ({ children, ...props }) => {
  return (
    <Flex p={3} align="center" {...props}>
      {children}
    </Flex>
  );
};

export default CardFooter;
