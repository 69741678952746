import { Flex, FlexProps } from '@chakra-ui/react';

const Card: React.FC<FlexProps> = ({ children, ...props }) => {
  return (
    <Flex
      bg="white"
      shadow="sm"
      rounded="xl"
      overflow="hidden"
      direction="column"
      borderWidth={1}
      borderColor="gray.400"
      {...props}>
      {children}
    </Flex>
  );
};

export default Card;
