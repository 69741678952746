import axios from 'axios';
import cities from 'utils/cities';
import { City } from '../types/common';
import { useState, useEffect, useCallback } from 'react';

const useCustomerLocation = ({
  enabled,
  onFindLocation
}: UseCustomerLocationParams) => {
  const [coords, setCoords] = useState<Coords>(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onFindLocationCallback = useCallback(onFindLocation, []);

  useEffect(() => {
    const getCoords = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            const { latitude, longitude } = position?.coords ?? {};

            if (latitude && longitude) {
              setCoords({ latitude, longitude });
            }
          },
          () => setCoords(null),
          {
            enableHighAccuracy: true
          }
        );
      }
    };
    if (enabled && !coords) getCoords();
  }, [enabled, coords]);

  useEffect(() => {
    if (coords) {
      axios
        .get(
          `https://api.opencagedata.com/geocode/v1/json?q=${coords.latitude},+${coords.longitude}&language=pt&pretty=1&key=${process.env.REACT_APP_OPEN_CAGE}`
        )
        .then(({ data }) => {
          const { city } = data?.results[0]?.components ?? {};
          const selectedLocation =
            cities.find(({ name }) => name === city) ?? null;

          if (selectedLocation) {
            onFindLocationCallback(selectedLocation);
          }
        });
    }
  }, [coords, onFindLocationCallback]);

  return null;
};

type Coords = {
  latitude: number;
  longitude: number;
} | null;

type UseCustomerLocationParams = {
  enabled: boolean;
  onFindLocation: (city: City) => void;
};

export default useCustomerLocation;
