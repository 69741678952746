import { useContext } from 'react';
import { ReactComponent as IllustrationLocation } from 'assets/illustrations/location.svg';
import { isSmallerThan } from 'theme/foundations/breakpoints';
import { AuthContext } from 'contexts/AuthContext';
import { FiAlertCircle } from 'react-icons/fi';
import {
  Flex,
  Heading,
  Icon,
  Stack,
  Text,
  useMediaQuery
} from '@chakra-ui/react';
import { Card, CardBody, CardHeader, CardTitle } from 'components/ui';

const CardBlockContext: React.FC = () => {
  const { userData } = useContext(AuthContext);
  const [isMobile] = useMediaQuery(isSmallerThan('md'));

  if (userData?.providerContextDisabled || userData?.customerContextDisabled) {
    return (
      <Card>
        <CardHeader>
          <CardTitle>
            Olá {userData?.firstName}, seja muito bem-vindo(a) à plataforma!
          </CardTitle>
        </CardHeader>
        <CardBody p={0}>
          <Stack
            direction={isMobile ? 'column' : 'row'}
            align="center"
            px={isMobile ? 6 : 10}
            py={8}
            gap={4}>
            <Icon
              as={IllustrationLocation}
              alignSelf="center"
              w="180px"
              h="180px"
            />
            <Flex flex={1} direction="column">
              <Heading fontSize="lg" fontWeight="bold" mb={4}>
                Estamos aguardando o número mínimo de usuários para liberação da
                plataforma em sua região
              </Heading>
              <Text>
                Assim que o número mínimo de usuários for alcançado,
                notificaremos você através dos meios de contato definidos
              </Text>
            </Flex>
          </Stack>
          <Flex
            bg="orange.100"
            direction="column"
            borderTopWidth={1}
            borderBottomWidth={isMobile ? 1 : 0}
            borderColor="yellow.700"
            px={6}
            py={3}>
            <Flex align="center" columnGap={3}>
              <Icon as={FiAlertCircle} w="26px" h="26px" color="yellow.900" />
              <Text fontSize="sm" fontWeight="medium" color="yellow.900">
                Por enquanto seu acesso está limitado às configurações do seu
                perfil. Mas fique tranquilo, em breve você terá acesso completo
                a plataforma
              </Text>
            </Flex>
          </Flex>
        </CardBody>
      </Card>
    );
  }

  return null;
};

export default CardBlockContext;
