import { useState } from 'react';

const useConfirmationModal = <T>() =>  {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [payload, setPayload] = useState<T | any>(null);

  const onOpen = (payload:T | null) => {
    setIsOpen(true);
    setPayload(payload);
  };

  const onClose = () => {
    setIsOpen(false);
    setPayload(null);
  };

  const onToggle = () => setIsOpen(prev => !prev);

  return {
    isOpen,
    payload,
    onOpen,
    onClose,
    onToggle
  };
};

export default useConfirmationModal;
