import { Flex, FlexProps } from '@chakra-ui/react';

const CardBody: React.FC<FlexProps> = ({ children, ...props }) => {
  return (
    <Flex px={8} py={6} direction="column" {...props}>
      {children}
    </Flex>
  );
};

export default CardBody;
