import ReactGA from 'react-ga4';

export const login = () => {
  ReactGA.event('customer-login');
};

export const selectLocation = () => {
  ReactGA.event('customer-select-location');
};

export const selectCategory = () => {
  ReactGA.event('customer-select-category');
};

export const selectService = () => {
  ReactGA.event('customer-select-service');
};

export const refetchSearch = () => {
  ReactGA.event('customer-refetch-search');
};

export const chatStart = () => {
  ReactGA.event('customer-chat-start');
};
