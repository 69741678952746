import { StyleConfig } from '@chakra-ui/theme-tools';

const Heading: StyleConfig = {
  baseStyle: {
    fontWeight: 'semibold',
    color: 'black',
    letterSpacing: -0.2,
  }
};

export default Heading;
