import { isSmallerThan } from 'theme/foundations/breakpoints';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMediaQuery, useDisclosure } from '@chakra-ui/react';
import { useMemo } from 'react';

const FILTERS_ROUTE = `/customers/search/filters`;

const getFilterRoute = (filter: Filter) => `${FILTERS_ROUTE}/${filter}`;

const useFilterModal = (filter: Filter) => {
  const [isMobile] = useMediaQuery(isSmallerThan('lg'));
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const modalLocation = useDisclosure();
  const modalCategory = useDisclosure();
  const modalService = useDisclosure();

  const disclosure = useMemo(
    () => ({
      service: modalService,
      location: modalLocation,
      category: modalCategory
    }),
    [modalLocation, modalCategory, modalService]
  );

  const onOpen = () =>
    isMobile ? navigate(getFilterRoute(filter)) : disclosure[filter].onOpen();

  const onClose = () =>
    isMobile
      ? navigate(FILTERS_ROUTE, { replace: true })
      : disclosure[filter].onClose();

  const isOpen = isMobile
    ? pathname === getFilterRoute(filter)
    : disclosure[filter].isOpen;

  return {
    onOpen,
    onClose,
    isOpen
  };
};

export type Filter = 'location' | 'category' | 'service';

export type FilterModal = {
  onOpen: () => void;
  onClose: () => void;
  isOpen: boolean;
};

export default useFilterModal;
