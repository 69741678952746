import { Flex, FlexProps } from '@chakra-ui/react';

const CardHeader: React.FC<FlexProps> = ({ children, ...props }) => {
  return (
    <Flex
      pl={6}
      pr={5}
      py={4}
      align="center"
      borderBottomWidth={1}
      borderColor="gray.200"
      {...props}>
      {children}
    </Flex>
  );
};

export default CardHeader;
