import http from '../http';
import { FieldValues } from 'react-hook-form';
import { createFormData } from 'utils/functions';
import { Provider, ReportReason, GalleryFile } from 'types';

const throwError = (errorMessage?: string) => {
  throw new Error(
    errorMessage ?? 'Ocorreu um erro, tente novamente mais tarde.'
  );
};

const create = async (providerData: FieldValues) => {
  const formatted = providerData;

  if (formatted.isCompany) formatted.lastName = '';
  delete formatted.isCompany;

  const { data, status } = await http.post('/providers/create', providerData);
  if (status !== 201)
    throwError('Ocorreu um erro, tente novamente mais tarde.');
  return data;
};

const check = async () => {
  const { data, status } = await http.get<{ hasAccount: boolean }>(
    '/providers/check'
  );
  if (status !== 200) throwError();
  return data;
};

const get = async () => {
  const { data, status } = await http.get<Provider>('/providers');
  if (status !== 200) throwError();
  return { data, status };
};

const updateProfile = async ({ providerId, fieldValues }: UpdateProfile) => {
  const formatted = fieldValues;

  if (formatted.isCompany) formatted.lastName = '';
  if (typeof formatted.avatar !== 'object') delete formatted.avatar;

  delete formatted.isCompany;

  const { data, status } = await http.patch(
    `/providers/${providerId}/update/profile`,
    createFormData(formatted)
  );

  if (status !== 200) throwError(data.message);
  return data;
};

const updateService = async ({ providerId, serviceId }: UpdateService) => {
  const { data, status } = await http.patch(
    `/providers/${providerId}/update/service`,
    { serviceId }
  );
  if (status !== 200) throwError(data.message);
  return data;
};

const updateDisc = async ({ providerId, disc }: UpdateDisc) => {
  const { data, status } = await http.patch(
    `/providers/${providerId}/update/disc`,
    { disc }
  );
  if (status !== 200) throwError(data.message);
  return { ...data, disc };
};

const updateLocations = async ({ providerId, locations }: UpdateLocations) => {
  const { data, status } = await http.patch(
    `/providers/${providerId}/update/locations`,
    {
      locations
    }
  );
  if (status !== 200) throwError(data.message);
  return { ...data };
};

const filter = async ({
  lastProviders,
  queryKey: [_, location, categoryId, serviceId]
}: Filter) => {
  const { data, status } = await http.get<{ providers: Provider[] }>(
    `/providers/filter`,
    {
      params: {
        serviceId,
        categoryId,
        location,
        lastProviders: lastProviders.length
          ? lastProviders.map(provider => provider.id).join('/')
          : undefined
      }
    }
  );
  if (status !== 200) throwError();
  return data.providers;
};

const favorite = async (params: Favorite) => {
  const { data, status } = await http.patch<{ message: string }>(
    `/providers/favorite/${params.providerId}`
  );
  if (status !== 201) throwError();
  return { data, status };
};

const report = async (params: Report) => {
  const { id, ...rest } = params;
  const { data, status } = await http.post(`/reports`, {
    providerId: id,
    ...rest
  });
  if (status !== 201) throwError();
  return { data, status };
};

const findById = async (params: FindById) => {
  const { data, status } = await http.get<Provider>(`/providers/${params.id}`);
  if (status !== 200) throwError();
  return { ...data, status };
};

const addNotificationToken = async (token: string) => {
  const { data, status } = await http.patch(`/providers/notification/token`, {
    token
  });
  if (status !== 200) throwError();
  return data;
};

const trending = async () => {
  const { data, status } = await http.get(`/providers/trending`);
  if (status !== 200) throwError();
  return data.providers;
};

const galleryAddPhotos = async ({ files }: GalleryAddPhotos) => {
  const descriptions = JSON.stringify(
    files.reduce((acc, current) => {
      acc[current.file.name] = current.description!;
      return acc;
    }, {} as { [key: string]: string })
  );

  let formData = new FormData();
  files.forEach(({ file }) => {
    formData.append('images', file, file.name);
  });
  formData.append('descriptions', descriptions);

  const { data, status } = await http.post(`/galleries/multiple`, formData);

  if (status !== 200) throwError();
  return { ...data };
};

const galleryEditPhoto = async ({ photoId, description }: GalleryEditPhoto) => {
  const { data, status } = await http.patch(`/galleries/${photoId}`, {
    description
  });

  if (status !== 200) throwError();
  return { ...data };
};

const galleryDeletePhoto = async ({ photoId }: GalleryDeletePhoto) => {
  const { data, status } = await http.delete(`/galleries/${photoId}`);
  if (status !== 200) throwError();
  return { ...data, photoId };
};

const questionAdd = async ({ fieldValues }: QuestionAdd) => {
  const { data, status } = await http.post(`/questions`, fieldValues);
  if (status !== 201) throwError();
  return { ...data };
};

const questionUpdate = async ({ fieldValues, questionId }: QuestionUpdate) => {
  const { data, status } = await http.patch(
    `/questions/${questionId}`,
    fieldValues
  );
  if (status !== 200) throwError();
  return { ...data };
};

const questionDelete = async ({ questionId }: QuestionDelete) => {
  const { data, status } = await http.delete(`/questions/${questionId}`);
  if (status !== 200) throwError();
  return { ...data, questionId };
};

interface UpdateProfile {
  providerId: string;
  fieldValues: FieldValues;
}

interface UpdateService {
  providerId: string;
  serviceId: string;
}

interface UpdateDisc {
  providerId: string;
  disc: string;
}

interface UpdateLocations {
  providerId: string;
  locations: string[];
}

interface Filter {
  queryKey: any[];
  lastProviders: Provider[];
}

interface Favorite {
  providerId: string;
}

interface Report {
  id: string;
  reason: ReportReason;
  description: string;
}

interface FindById {
  id: string;
}

interface GalleryAddPhotos {
  files: GalleryFile[];
}

interface GalleryEditPhoto {
  photoId: string;
  description: string;
}

interface GalleryDeletePhoto {
  photoId: string;
}

interface QuestionAdd {
  fieldValues: FieldValues;
}

interface QuestionUpdate {
  fieldValues: FieldValues;
  questionId: string;
}

interface QuestionDelete {
  questionId: string;
}

const providers = {
  create,
  check,
  get,
  update: updateProfile,
  updateService,
  updateDisc,
  filter,
  favorite,
  report,
  findById,
  addNotificationToken,
  trending,
  galleryAddPhotos,
  galleryEditPhoto,
  galleryDeletePhoto,
  updateLocations,
  questionAdd,
  questionUpdate,
  questionDelete
};

export default providers;
